import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AuthLayout from '../layouts/authLayout';
import SignUp from '../pages/signup';
import Login from '../pages/login';
import OtpVerification from '../pages/otpverification';
import Forgotpassword from '../pages/forgotpassword';
import Dashboard from '../pages/admin/dashboard';
import 'react-phone-input-2/lib/style.css';
import 'react-datetime/css/react-datetime.css';
import VerifiedSuccessfully from '../pages/verified-successfully';
import Subscription from '../pages/subscription';
import Payment from '../pages/payment';
import { ROUTE_PATH } from '../constants/routes';
import PaymentSuccessful from '../pages/paymentSuccessful';
import 'react-datepicker/dist/react-datepicker.css';
import TankListing from '../pages/user/tanks';
import UserLayout from '../layouts/userLayout';
import TankDetail from '../pages/user/tanks/tankDetail';
import StripeWrapper from '../components/stripe/StripeWrapper';
import MainLayout from '../layouts/mainLayout';
import SubscriptionLayout from '../layouts/subscriptionLayout';
import UserDashboard from '../pages/user/dashboard';
import EditUser from '../components/userManagement/editUser';
import AddUser from '../components/userManagement/addUser';
import UserListing from '../components/userManagement/userListing/userListing';
import Probes from '../pages/user/probes';
import ProbeDetails from '../pages/user/probes/probeDetails';
import UserProfileDetails from '../components/userManagement/userProfileDetails';
import AssignedProbes from '../components/userManagement/assignedProbes';
import ProbeListing from '../pages/admin/probe/probeListing';
import UserProfile from '../pages/userProfile';
import UserTransaction from '../pages/userTransaction';
import ProbeData from '../pages/user/dashboard/probeData';
import TransactionHistory from '../components/userManagement/transactionHistory/transactionHistory';
import EditUserProfile from '../pages/edituserprofile';
import AdminProbeDetail from '../pages/admin/probe/adminProbeDetail';
import AdminSubscription from '../components/adminSubscription';
import useResponsive from '../hooks/useResponsive';
import Resetpassword from '../pages/resetpassword';
import SuccessReset from '../pages/resetpassword/successReset';
import LandingPage from '../components/landingPage';
import About from '../components/about';
import TermsAndConditions from '../pages/termsAndConditions';
import PrivacyPolicy from '../pages/privacyPolicy';
import Contact from '../components/contact';
import { googleSiteKey } from '../constants/config';

function MainRoutes() {
  const { isMobile } = useResponsive();

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTE_PATH.HOME_PAGE} element={<LandingPage />} />
        <Route path={ROUTE_PATH.ABOUT_PAGE} element={<About />} />
        <Route
          path={ROUTE_PATH.TERMS_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route path={ROUTE_PATH.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={ROUTE_PATH.CONTACT_PAGE} element={<Contact />} />
        <Route element={<MainLayout />}>
          <Route element={<AuthLayout />}>
            <Route
              path={ROUTE_PATH.LOGIN}
              element={(
                <GoogleReCaptchaProvider reCaptchaKey={googleSiteKey}>
                  <Login />
                </GoogleReCaptchaProvider>
              )}
            />
            <Route
              path={ROUTE_PATH.SIGNUP}
              element={(
                <GoogleReCaptchaProvider reCaptchaKey={googleSiteKey}>
                  <SignUp />
                </GoogleReCaptchaProvider>
              )}
            />
            <Route path={ROUTE_PATH.VERIFY_OTP} element={<OtpVerification />} />
            <Route
              path="/forgotpassword"
              element={(
                <GoogleReCaptchaProvider reCaptchaKey={googleSiteKey}>
                  <Forgotpassword />
                </GoogleReCaptchaProvider>
              )}
            />
            <Route
              path={ROUTE_PATH.RESET_PASSWORD}
              element={<Resetpassword />}
            />
            <Route path={ROUTE_PATH.SUCCESS_RESET} element={<SuccessReset />} />
            <Route
              path={ROUTE_PATH.VERIFIED}
              element={<VerifiedSuccessfully />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
          {/* end of unauthenticated routes */}
          {/* user routes */}

          <Route element={<UserLayout />}>
            <Route path={ROUTE_PATH.USER_TANKS} element={<TankListing />} />
            <Route path={ROUTE_PATH.ADMIN_DASHBOARD} element={<Dashboard />} />
            <Route
              path={ROUTE_PATH.USER_DASHBOARD}
              element={<UserDashboard />}
            />
            <Route
              path={ROUTE_PATH.ADMIN_SUBSCRIPTION}
              element={<AdminSubscription />}
            />
            <Route
              path={ROUTE_PATH.ADMIN_USER_MANAGEMENT_EDIT_USER}
              element={<EditUser />}
            />
            <Route
              path={`${ROUTE_PATH.ADMIN_USER_MANAGEMENT_USER_DETAILS}/:id`}
              element={<UserProfileDetails />}
            />
            <Route
              path={ROUTE_PATH.ADMIN_USER_MANAGEMENT_ADD_USER}
              element={<AddUser />}
            />
            <Route
              path={ROUTE_PATH.ADMIN_USER_MANAGEMENT_TRANSACTION_HISTORY}
              element={<TransactionHistory />}
            />
            <Route
              path={ROUTE_PATH.ADMIN_USER_MANAGEMENT}
              element={<UserListing />}
            />
            <Route path={ROUTE_PATH.USER_PROBES} element={<Probes />} />
            <Route
              path={ROUTE_PATH.ADMIN_USER_MANAGEMENT_ASSIGNED_PROBES_LIST}
              element={<AssignedProbes />}
            />
            <Route
              path={ROUTE_PATH.ADMIN_PROBE_LISTING}
              element={<ProbeListing />}
            />
            <Route
              path={`${ROUTE_PATH.PROBE_ADMIN_DETAIL}/:id`}
              element={<AdminProbeDetail />}
            />
            <Route
              path={ROUTE_PATH.ADMIN_USER_MANAGEMENT_ASSIGNED_PROBES_DETAILS}
              element={<AdminProbeDetail />}
            />
            <Route path={ROUTE_PATH.USER_PROBES} />
            <Route
              path={ROUTE_PATH.USER_PROFILE}
              element={(
                <StripeWrapper>
                  <UserProfile />
                </StripeWrapper>
              )}
            />
            <Route
              path={ROUTE_PATH.USER_TRANSACTION}
              element={<UserTransaction />}
            />
          </Route>

          {/* routes which have different behavior in reponsive (detail pages) */}
          <Route element={<UserLayout hideNavBar={isMobile} />}>
            <Route
              path={ROUTE_PATH.USER_PROBE_DETAILS}
              element={<ProbeDetails />}
            />
            <Route path={ROUTE_PATH.TANKSDETAIL} element={<TankDetail />} />
            <Route
              path={ROUTE_PATH.USER_PROFILE_EDIT}
              element={<EditUserProfile />}
            />
            <Route
              path={ROUTE_PATH.USER_DASHBOARD_PROBEDETAILS}
              element={<ProbeData />}
            />
          </Route>

          <Route element={<SubscriptionLayout />}>
            <Route path={ROUTE_PATH.SUBSRIPTION} element={<Subscription />} />
            <Route
              path="/payment"
              element={(
                <StripeWrapper>
                  <Payment />
                </StripeWrapper>
              )}
            />
          </Route>
          <Route path="/payment-successful" element={<PaymentSuccessful />} />
          <Route
            path={ROUTE_PATH.TERMS_CONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route path={ROUTE_PATH.PRIVACY} element={<PrivacyPolicy />} />
        </Route>
        <Route path="*" element={<TermsAndConditions />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
