import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import UserDetails from '../../components/userProfile/UserDetails';
import SubscriptionPlan from '../../components/userProfile/SubscriptionPlan';
import SavedCard from '../../components/userProfile/SavedCard';
import UserHeading from '../../components/userProfile/ProfileHeader';
import CreditCardFormModel from '../../components/userProfile/CreditCardFormModel';
import useLogout from '../../hooks/useLogout';
import { CardData } from '../../types';
import {
  GET_USER_CARDS,
  GET_USER_INFORMATION,
  REMOVE_CARD_MUTATION,
  SET_DEFAULT_MUTATION,
} from '../../graphql/mutation';
import useLoader from '../../hooks/useLoader';
import CustomLoader from '../../components/shared/common/customLoader';
import { NETWORK_ONLY } from '../../constants';

const UserProfile = () => {
  const { openLogoutModal, closeLogoutModal, logoutModal } = useLogout();
  const [refetch, { loading }] = useLazyQuery(GET_USER_CARDS);
  const [userCards, setUserCards] = useState<CardData[]>([]);
  const [removeCard] = useMutation(REMOVE_CARD_MUTATION);
  const [setDefaultCard] = useMutation(SET_DEFAULT_MUTATION);
  const { isLoading, startLoading, stopLoading } = useLoader();
  const [getUserInfo, { data: userData, loading: userLoading }] = useLazyQuery(GET_USER_INFORMATION);
  const userProfileData = userData?.getUser?.userInfo || {};

  const getCard = async () => {
    try {
      const { data } = await refetch({ fetchPolicy: NETWORK_ONLY });
      const cardsData = data?.allCards?.data.map((card: any) => ({
        ...card,
        isDefault: card.id === data?.allCards?.defaultCard,
      }));
      setUserCards(() => cardsData || []);
    } catch (e) {
      // error
    }
  };

  const getData = async () => {
    await getUserInfo({ fetchPolicy: NETWORK_ONLY });
  };

  useEffect(() => {
    getCard();
    getData();
  }, []);

  const handleRemoveCard = async (cardId: any) => {
    startLoading();
    try {
      const { data } = await removeCard({
        variables: {
          cardId, // Replace with the actual cardId you want to remove
        },
      });
      getCard();
      toast.success(data.message);
      stopLoading();
    } catch (error) {
      stopLoading();
    }
  };

  const handleDefaultCard = async (cardId: any) => {
    startLoading();
    try {
      const { data } = await setDefaultCard({
        variables: {
          cardId, // Replace with the actual cardId you want to remove
        },
      });
      getCard();
      toast.success(data.setDefaultCard.message);
      stopLoading();
    } catch (error) {
      stopLoading();
    }
  };

  return (
    <div className="admin-common-body">
      {isLoading && <CustomLoader />}
      <UserHeading />
      <UserDetails loading={userLoading} userData={userProfileData} />
      <SubscriptionPlan userData={userProfileData} refetch={getData} />
      <SavedCard
        openLogoutModal={openLogoutModal}
        userCards={userCards}
        loading={loading}
        handleRemoveCard={handleRemoveCard}
        handleDefaultCard={handleDefaultCard}
      />
      <CreditCardFormModel
        show={logoutModal}
        close={closeLogoutModal}
        getCard={getCard}
      />
    </div>
  );
};

export default UserProfile;
