import React from 'react';

const SectionEight = () => (
  <>
    <h3>Section 10 - Links</h3>
    <p className="para">
      Either Wine Technology Inc. or any third parties may provide links to
      other websites and/or resources. Thus, you acknowledge and agree that we
      are not responsible for the availability of any such external sites or
      resources, and as such, we do not endorse nor are we responsible or liable
      for any content, products, advertising or any other materials, on or
      available from such third-party sites or resources. Furthermore, you
      acknowledge and agree that Wine Technology Inc. shall not be responsible
      or liable, directly or indirectly, for any such damage or loss which may
      be a result of, caused or allegedly to be caused by or in connection with
      the use of or the reliance on any such content, goods or Services made
      available on or through any such site or resource.
    </p>

    <h3>Section 11 - Proprietary Rights</h3>
    <p className="para">
      You do hereby acknowledge and agree that Wine Technology Inc.'s Services
      and any essential software that may be used in connection with our
      Services ("Software") shall contain proprietary and confidential material
      that is protected by applicable intellectual property rights and other
      laws. Furthermore, you herein acknowledge and agree that any Content which
      may be contained in any advertisements or information presented by and
      through our Services or by advertisers is protected by copyrights,
      trademarks, patents or other proprietary rights and laws. Therefore,
      except for that which is expressly permitted by applicable law or as
      authorized by Wine Technology Inc. or such applicable licensor, you agree
      not to alter, modify, lease, rent, loan, sell, distribute, transmit,
      broadcast, publicly perform and/or created any plagiaristic works which
      are based on Wine Technology Inc. Services (e.g. Content or Software), in
      whole or part.
    </p>
    <p className="para">
      Wine Technology Inc. hereby grants you a personal, non-transferable and
      non-exclusive right and/or license to make use of the object code or our
      Software on a single computer, as long as you do not, and shall not, allow
      any third party to duplicate, alter, modify, create or plagiarize work
      from, reverse engineer, reverse assemble or otherwise make an attempt to
      locate or discern any source code, sell, assign, sublicense, grant a
      security interest in and/or otherwise transfer any such right in the
      Software. Furthermore, you do herein agree not to alter or change the
      Software in any manner, nature or form, and as such, not to use any
      modified versions of the Software, including and without limitation, for
      the purpose of obtaining unauthorized access to our Services. Lastly, you
      also agree not to access or attempt to access our Services through any
      means other than through the interface which is provided by Wine
      Technology Inc. for use in accessing our Services.
    </p>
  </>
);

export default SectionEight;
