import React from 'react';
import Logo from '../../assets/img/logo.svg';

const LandingFooter = () => (
  <section className="landing-page-footer">
    <div className="footer-container">
      <div className="landing-footer-top">
        <img src={Logo} alt="logo" />
      </div>
      <div className="landing-footer-bottom">
        <div className="landing-footer-left">
          © 2023 Multi-Sensor Probe (MSP). All Rights Reserved.
        </div>
        <div className="landing-footer-right">
          {/* <span className="social-logo">
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.54169 7.38651H4.64339V14.1996H1.76729V7.38651H0.399414V4.99212H1.76729V3.44267C1.76729 2.33465 2.30455 0.599609 4.66899 0.599609L6.79941 0.608341V2.93251H5.25365C5.0001 2.93251 4.64358 3.05661 4.64358 3.58517V4.99435H6.79297L6.54169 7.38651Z" fill="#08080D"/>
            </svg>
          </span>
          <span className="social-logo">
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.41535 5.33814L11.4463 0.6521L7.41534 5.3378H7.4151L7.41535 5.33814ZM6.99101 4.72063H6.991L4.19546 0.6521H4.19547L6.99101 4.72063ZM10.3764 0.4021L7.01321 4.31149L4.32702 0.4021H0.496033L4.88347 6.78734L0.42627 11.9681H2.04113L5.60045 7.83075L8.44339 11.9681H12.2744L7.73029 5.35505L11.9912 0.4021H10.3764ZM9.1546 10.7817L6.35697 6.78015L5.88353 6.10276L2.75043 1.62121H3.6076L6.28329 5.44858L6.71162 6.06121L10.0118 10.7817H9.1546ZM9.02434 11.0317L6.10697 6.85864L2.27062 1.37121H2.2706L5.67863 6.24601L6.10696 6.85864V6.85888L9.02434 11.0317Z" fill="#0F101A"/>
            </svg>
          </span> */}
          <a className="social-logo" href="https://www.linkedin.com/company/vinwizard/" target="_blank">
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.81867 3.26106H1.80039C0.858907 3.26106 0.25 2.61295 0.25 1.80294C0.25 0.97466 0.87754 0.344482 1.83731 0.344482C2.79707 0.344482 3.3877 0.97466 3.40598 1.80294C3.40598 2.61295 2.79707 3.26106 1.81867 3.26106ZM3.22105 12.8545H0.415399V4.41361H3.22105V12.8545ZM10.5325 12.8548H13.3378V8.01486C13.3378 5.42225 11.9537 4.21586 10.1078 4.21586C8.61929 4.21586 7.9522 5.03395 7.57937 5.60911V4.41396H4.7739C4.81064 5.20586 4.7739 12.8548 4.7739 12.8548H7.57937V8.14107C7.57937 7.88882 7.59748 7.63675 7.67165 7.4564C7.87451 6.95243 8.33611 6.43036 9.11113 6.43036C10.1263 6.43036 10.5325 7.20433 10.5325 8.33917V12.8548Z" fill="#0F101A"/>
            </svg>
          </a>
          {/* <span className="social-logo">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4981 1.3016C12.7667 1.56242 12.9734 1.88017 13.1029 2.23123C13.2138 2.51611 13.3455 2.94429 13.3814 3.73254C13.4202 4.58503 13.4284 4.8407 13.4284 6.99964C13.4284 9.15837 13.4202 9.41404 13.3814 10.2667C13.3453 11.055 13.2136 11.483 13.1029 11.7678C12.8309 12.473 12.2736 13.0303 11.5685 13.3022C11.2836 13.4131 10.8554 13.5448 10.0672 13.5807C9.21488 13.6196 8.95922 13.6277 6.80003 13.6277C4.64106 13.6277 4.38561 13.6196 3.53311 13.5807C2.74485 13.5448 2.31666 13.4131 2.03178 13.3022C1.68051 13.1727 1.36297 12.966 1.10215 12.6975C0.833595 12.4366 0.62713 12.1191 0.497364 11.7678C0.386719 11.483 0.25502 11.055 0.219141 10.2667C0.180254 9.41404 0.171875 9.15837 0.171875 6.99964C0.171875 4.8407 0.180254 4.58503 0.219141 3.73254C0.25502 2.94429 0.386719 2.51611 0.497364 2.23123C0.62713 1.87996 0.83381 1.56242 1.10237 1.3016C1.36297 1.03305 1.68073 0.826373 2.032 0.696824C2.31666 0.58618 2.74485 0.454482 3.53311 0.418388C4.38561 0.379502 4.64128 0.371338 6.80025 0.371338C8.959 0.371338 9.21467 0.379717 10.0674 0.418603C10.8556 0.454482 11.2836 0.58618 11.5685 0.696824C11.9198 0.826373 12.2373 1.03305 12.4981 1.3016ZM2.64903 6.99954C2.64903 9.29254 4.50786 11.1511 6.80089 11.1511C9.09371 11.1511 10.9525 9.29254 10.9525 6.99954C10.9525 4.70653 9.09371 2.84793 6.80089 2.84793C4.50786 2.84793 2.64903 4.70653 2.64903 6.99954ZM11.1152 3.65402C10.5794 3.65402 10.1449 3.21961 10.1449 2.68379C10.1449 2.14797 10.5794 1.71356 11.1152 1.71356C11.651 1.71356 12.0854 2.14797 12.0854 2.68379C12.0852 3.21961 11.651 3.65402 11.1152 3.65402ZM6.80009 10.3C8.62267 10.3 10.1001 8.82258 10.1001 7.00002C10.1001 5.17746 8.62267 3.70005 6.80009 3.70005C4.97751 3.70005 3.50008 5.17746 3.50008 7.00002C3.50008 8.82258 4.97751 10.3 6.80009 10.3Z" fill="#08080D"/>
            </svg>
          </span> */}
          <a className="social-logo" href="https://www.youtube.com/channel/UCVuyZ4IiNuO9dHsPvngHagQ" target="_blank">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4454 9.64799C15.7566 8.46507 15.7566 6.01193 15.7566 6.01193C15.7566 6.01193 15.7691 3.5466 15.4454 2.36368C15.271 1.7162 14.7604 1.20549 14.113 1.03126C12.9301 0.70752 8.19842 0.70752 8.19842 0.70752C8.19842 0.70752 3.46677 0.70752 2.28386 1.01889C1.64875 1.19313 1.12568 1.7162 0.951446 2.36368C0.640076 3.5466 0.640076 5.99956 0.640076 5.99956C0.640076 5.99956 0.640076 8.46507 0.951446 9.63544C1.12586 10.2829 1.63639 10.7936 2.28386 10.9679C3.47914 11.2916 8.19842 11.2916 8.19842 11.2916C8.19842 11.2916 12.9301 11.2916 14.113 10.9802C14.7604 10.806 15.271 10.2955 15.4454 9.64799ZM10.6268 5.99974L6.69214 8.26592V3.73357L10.6268 5.99974Z" fill="#0F101A"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default LandingFooter;
