import React from 'react';
import LoginForm from '../../components/auth/login/loginForm';

function Login() {
  return (
    <LoginForm />
  );
}

export default Login;
