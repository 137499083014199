import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { USER_DELETE_MODAL } from '../../../constants/modal';
import userDeleteIcon from '../../../assets/icons/icon-delete.svg';
import { DELETE_USER } from '../../../graphql/mutation/admin.mutation';
import { GET_USER_LIST } from '../../../graphql/queries/admin.queries';
import PromptModal from '../../promptModal';
import CustomLoader from '../../shared/common/customLoader';
import { DELETE_USER_FAILED, ROUTE_PATH } from '../../../constants';
import { DeleteUserModalProps } from '../../../types';

const DeleteUserModal = ({
  selectedUser,
  isModalOpen,
  setIsModalOpen,
  setSelectedUser,
  userName,
}: DeleteUserModalProps) => {
  const [deleteUser, { loading: isDeleting, client }] =
    useMutation(DELETE_USER);
  const navigate = useNavigate();

  const handleUserDelete = async () => {
    try {
      const res = await deleteUser({
        variables: { id: selectedUser },
      });
      if (res?.data?.deleteUser) {
        toast.success(res.data.deleteUser.message);
        await client.refetchQueries({ include: [GET_USER_LIST] });
        setIsModalOpen(false);
        setSelectedUser('');
        navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
      } else {
        throw new Error(DELETE_USER_FAILED);
      }
    } catch (error) {
      navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {isDeleting && <CustomLoader />}
      {isModalOpen && (
        <PromptModal
          imgPath={userDeleteIcon}
          open={!!isModalOpen}
          cancel={() => setIsModalOpen(false)}
          handleAction={() => handleUserDelete()}
          {...USER_DELETE_MODAL}
          title={`Delete "${userName}"`}
        />
      )}
    </>
  );
};

export default DeleteUserModal;
