import React, { useEffect } from 'react';
import {
  Form, Button, Row, Col
} from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@apollo/client';
import Select from '../shared/forms/select';
import {
  BOOLEANS, FETCH_PROBES_VALIDATION_SCHEMA, PROBE_TIMESTAMP, PROBE_TIMESTAMP_VALUE
} from '../../constants';
import DateInput from '../shared/forms/datePicker';
import { PROBES_ASSIGNED_TO_TANK } from '../../graphql/queries/tank.queries';
import { getTimeStampDateObject } from '../../utils/helpers';
import { IProbeData } from '../../types';

function FetchProbeForm({ add }: any) {
  const {
    trigger, setValue, getValues, handleSubmit, reset, formState: { errors }
  } = useForm({
    resolver: yupResolver(FETCH_PROBES_VALIDATION_SCHEMA),
    shouldFocusError: BOOLEANS.FALSE,
  });

  const { data: { probesAssignedToTank: { probes = [] } = { } } = { } } = useQuery(PROBES_ASSIGNED_TO_TANK);

  /**
   * The submit function takes in values and adds them to a list, resets the form, and updates the
   * client's query data.
   * @param {FieldValues} values - An object containing the values of the form fields.
   */
  const submit = (values : FieldValues) => {
    const probeDetail = probes.find((i: IProbeData) => i?.id === values?.probeId);
    add({
      ...values, ...probeDetail, eventKey: probeDetail?.id, title: probeDetail?.probeId
    });
    reset({});
  };

  return (
    <div className="filter-sec">
      <div className="filter-by-heading">
        Filter by
        {' '}
        <span>(Max: 5)</span>
      </div>
      <Form onSubmit={handleSubmit(submit)}>
        <div className="filter-by-form">
          <Row className="filter-by-sec">
            <Col xs={12} sm={4} className="multi-select-custom">
              <Select
                placeholder="Select"
                setValue={setValue}
                value={getValues('probeId')}
                name="probeId"
                label="Probe ID"
                options={probes}
                labelKey="probeId"
                valueKey="id"
                error={errors?.probeId?.message}
              />
            </Col>
            <Col xs={12} sm={4} className="date-select-custom date-time-select-custom only-change-datetime">
              <DateInput
                selected={getTimeStampDateObject(
                  getValues('startTime')
                )}
                valueFormat={PROBE_TIMESTAMP_VALUE}
                dateFormat={PROBE_TIMESTAMP}
                label="Start Date & Time"
                name="startTime"
                iconClass="icon-calender on-input-icon-right"
                showMonthYearPicker={BOOLEANS.FALSE}
                setValue={(_: any, value: string) => {
                  setValue('startTime', value, { shouldValidate: BOOLEANS.TRUE });
                  if (getValues('endTime')) {
                    trigger('endTime');
                  }
                }}
                placeholder="Select"
                error={errors?.startTime?.message}
                showTimeSelect
                maxDate={new Date()}
              />
            </Col>
            <Col xs={12} sm={4} className="date-select-custom date-time-select-custom only-change-datetime">
              <DateInput
                selected={getTimeStampDateObject(
                  getValues('endTime')
                )}
                valueFormat={PROBE_TIMESTAMP_VALUE}
                dateFormat={PROBE_TIMESTAMP}
                label="End Date & Time"
                name="endTime"
                iconClass="icon-calender on-input-icon-right"
                showMonthYearPicker={BOOLEANS.FALSE}
                setValue={setValue}
                placeholder="Select"
                error={errors?.endTime?.message}
                showTimeSelect
                minDate={getTimeStampDateObject(
                  getValues('startTime')
                )}
                maxDate={new Date()}
              />
            </Col>
          </Row>
        </div>
        <div className="filter-by-btn">
          <Button type="submit" className="btn primary modal-btn-submit">
            Add
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default FetchProbeForm;
