/**
 * The `useListing` function is a custom hook that handles pagination, sorting, and searching for a
 * list of items fetched from an Apollo GraphQL query.
 * @param {DocumentNode} query - The `query` parameter is a GraphQL document node that represents the
 * GraphQL query to be executed.
 * @param {string} dataKey - The `dataKey` parameter is a string that represents the key in the
 * response data object where the list of items is located.
 * @param {string} listKey - The `listKey` parameter is a string that represents the key in the
 * response data object where the list of items is located. It is used to extract the list of items
 * from the response data and update the `results` state with the new list.
 * @returns The `useListing` function returns an object with the following properties:
 */
import { DocumentNode, useQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  NUMBER, PARAMS, SORT, SORT_BY,
} from '../constants';
import { IPagination } from '../types';
import { pagination } from '../graphql/reactiveVariables';

function useListing(query: DocumentNode, dataKey: string, listKey: string, extraVariables?: object) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [results, setResults] = useState<object[]>([]);
  const [totalPage, setTotalPage] = useState(NUMBER.ONE);
  const [activeProbeCount, setActiveProbeCount] = useState(NUMBER.ZERO);
  const [disableProbeCount, setDisableProbeCount] = useState(NUMBER.ZERO);
  const [totalCount, setTotalCount] = useState(NUMBER.ZERO);
  const paginationData: IPagination = useReactiveVar(pagination);
  const { page, sort, sortBy } = paginationData;
  const search = params.get(PARAMS.SEARCH);
  const { pathname, state } = useLocation();
  const searchParams = Object.fromEntries([...params]);

  const { loading, refetch: refetchData } = useQuery(query, {
    variables: {
      limit: NUMBER.TEN,
      search,
      page,
      sort,
      sortBy,
      ...extraVariables
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(res) {
      if (page === NUMBER.ONE) {
        setResults(res[dataKey][listKey]);
      } else {
        window.scrollBy(NUMBER.ZERO, -NUMBER.TEN); // scrolling up by 10px when new data loads
        setResults([...results, ...res[dataKey][listKey]]);
      }
      setTotalPage(Math.ceil(res[dataKey].totalCount / NUMBER.TEN));
      setTotalCount(res[dataKey].totalCount);
      setActiveProbeCount(res[dataKey].activeProbeCount);
      setDisableProbeCount(res[dataKey].disableProbeCount);
    },
  });

  /**
  * The function `refetch` updates the pagination data and navigates to a new URL with updated search
  * parameters.
  * @param {any} values - The `values` parameter is an object that contains various properties. It is
  * used to update the pagination data and search value in the URL.
  */
  const refetch = (values: any) => {
    pagination({ ...paginationData, ...values });
    navigate({
      pathname,
      search: createSearchParams({ ...searchParams, search: values?.search || '', }).toString(),
    }, { state });
  };

  const refetchCurrentPage = async (pageNumber?:number) => {
    await refetchData({ page: pageNumber || paginationData.page });
  };

  /**
   * The function `updateDetails` updates an array of results with new values if the values have an id
   * property, otherwise it refetches data.
   * @param {any} values - The `values` parameter is an object that contains the updated details.
   */
  const updateDetails = (values: any, action = 'edit') => {
    if (action === 'edit') {
      const res = [...results];
      const index = results.findIndex((i: any) => i?.id === values?.id);
      res[index] = values;
      setResults(res);
    } else {
      // refetchData({ ...pagination, page: NUMBER.ONE });
      const res = results.filter((i: any) => i.id !== values?.id);
      setResults(res);
    }
  };

  useEffect(() => () => {
    pagination({
      page: NUMBER.ONE,
      sort: SORT.CREATED_AT,
      sortBy: SORT_BY.DESC,
    });
  }, []);

  return {
    refetch,
    metaData: {
      page,
      totalPage,
      totalCount,
      activeProbeCount,
      disableProbeCount
    },
    results,
    loading,
    updateDetails,
    refetchCurrentPage,
  };
}

export default useListing;
