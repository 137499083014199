import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import OTPInput from 'react-otp-input';
import { Navigate } from 'react-router-dom';
import UpdateEmailModal from './updateEmail';
import { ROUTE_PATH } from '../../../constants/routes';
import { getSession } from '../../../utils/helpers';
import { USER_INFO } from '../../../constants/forms/keys';
import CustomLoader from '../../shared/common/customLoader';
import Timer from '../../shared/common/timer';
import useVerifyEmail from '../../../hooks/useVerifyEmail';
import { VerifyEmailProps } from '../../../types';
import { NUMBER } from '../../../constants';

const VerifyEmail: React.FC<VerifyEmailProps> = ({ optPaymentError }) => {
  const [otp, setOtp] = useState('');
  const [open, openModal] = useState(false);
  const [timerComplete, setTimerCompletion] = useState(false);
  const session: any = getSession(USER_INFO);
  const { loading, resendLoading, verify, resendOtp } = useVerifyEmail({
    setTimerCompletion,
  });

  if (!session?.email) {
    return <Navigate to={ROUTE_PATH.LOGIN} />;
  }

  const onSubmit = (): void => {
    verify(otp);
  };

  return (
    <>
      {(resendLoading || loading) && <CustomLoader />}
      <Col sm={NUMBER.SIX} className="p-0 boarding-login onboarding-right">
        <UpdateEmailModal
          show={open}
          handleClose={() => openModal(false)}
          oldEmail={session?.email}
        />
        <div className="login-inner">
          <div className="boarding-title">
            <h1> OTP Verification</h1>
            <p className="account-description-short">
              A verification code has been sent to your email address.
              <br />
            </p>
            <p className="otp-edit">
              {session?.email}
              <span
                className={`icon-edit ${!timerComplete && 'disable'}`}
                onClick={() => {
                  timerComplete && openModal(true);
                }}
              />
            </p>
          </div>
          <Form className="onboarding-top-form">
            <Row className="m-0">
              <Col xs={NUMBER.TWELVE} sm={NUMBER.TWELVE} className="first-50">
                <Form.Group className="opt-group">
                  <Form.Label className="form-label-otp" htmlFor="otpInput">
                    Please enter the OTP to verify your email
                  </Form.Label>
                  <div className="otp-sent">
                    <OTPInput
                      data-testid="otpInput"
                      value={otp}
                      onChange={setOtp}
                      numInputs={NUMBER.FOUR}
                      renderInput={(props) => <input {...props} />}
                      inputType="number"
                    />
                  </div>
                </Form.Group>

                <>
                  <div className="sign-up-terms">
                    <div className="sign-up-terms-text">
                      Didn&apos;t receive the code?{' '}
                      <span
                        data-testid="resend"
                        className={`sign-up-terms-link ${
                          timerComplete ? 'accomplished' : 'pe-none'
                        }`}
                        onClick={resendOtp}
                      >
                        Resend
                      </span>
                    </div>
                    <div className="successful-note">
                      <p>
                        <span>Note:</span> If you cannot see the email from MSP
                        <br />
                        in your inbox, make sure to check your spam folder.
                      </p>
                    </div>
                  </div>
                  {!timerComplete && (
                    <Timer
                      seconds={30}
                      completed={() => setTimerCompletion(true)}
                    />
                  )}
                </>
              </Col>
            </Row>
            {optPaymentError && <p className="text-red">{optPaymentError}</p>}
            <Row className="m-0 account-verify-payment">
              <Col xs={NUMBER.TWELVE} sm={NUMBER.TWELVE} className="first-50">
                <Form.Group className="mb-20" controlId="stare">
                  <div className="login-submit mt-2 mb-0">
                    <Button
                      className="btn primary w-100"
                      disabled={otp.length !== NUMBER.FOUR}
                      onClick={onSubmit}
                    >
                      VERIFY
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default VerifyEmail;
