import React, { useMemo } from 'react';
import {
  Button, Col, Form, Row
} from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import ModalWrapper from '../../shared/common/modalWrapper';
import Select from '../../shared/forms/select';
import {
  ADD_EVENT_SCHEMA, BOOLEANS, eventOptions, INPUT_ALPHANUMERIC, NUMBER
} from '../../../constants';
import Input from '../../shared/forms/input';
import { ADD_DASHBOARD_EVENT } from '../../../graphql/mutation';
import CustomLoader from '../../shared/common/customLoader';
import { getSensorOption } from '../../../utils/helpers';

function GraphEventForm({
  isOpen, close, payload, onSuccess
}: any) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(ADD_EVENT_SCHEMA),
    shouldFocusError: BOOLEANS.FALSE,
  });

  const [addEvent, { loading }] = useMutation(ADD_DASHBOARD_EVENT);

  const submit = async (values: FieldValues) => {
    await addEvent({
      variables: { ...values, id: payload?.id, sensorType: values?.sensor }
    });
    reset({});
    onSuccess(payload?.graphType);
  };

  useMemo(() => {
    reset({ sensor: payload?.dataKey });
  }, [payload?.dataKey]);

  return (
    <ModalWrapper
      show={isOpen}
      handleClose={close}
      title="Add Event Data"
    >
      {loading && <CustomLoader />}
      <Form onSubmit={handleSubmit(submit)} data-testid="addNewTankForm">
        <Row className="modal-body-form">
          {payload?.hasDualAxis && (
          <Col xs={12} sm={12} className=" ">
            <Select
              placeholder="Select"
              name="sensor"
              label="Choose sensor"
              setValue={setValue}
              value={getValues('sensor')}
              options={getSensorOption(payload?.lines)}
              error={errors?.event?.message}
            />
          </Col>
          )}
          <Col xs={12} sm={12} className=" ">
            <Select
              placeholder="Select"
              name="event"
              label="Event"
              setValue={setValue}
              value={getValues('event')}
              options={eventOptions}
              error={errors?.event?.message}
            />
          </Col>
          <Col xs={12} sm={12} className=" ">
            <div className="wrap-input">
              <Input
                as="textarea"
                label="Description"
                name="description"
                placeholder="Write description"
                value={getValues('description')}
                refValues={register}
                setValue={setValue}
                inputClass="mb-0"
                error={errors?.description?.message}
                valuePattern={INPUT_ALPHANUMERIC}
                maxLength={NUMBER.TWENTY}
              />
              <span className="word-count">
                {getValues('description')?.length || 0}
                /
                {NUMBER.TWENTY}
              </span>
            </div>
          </Col>
        </Row>
        <div className="footer-modal">
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            disabled={!isValid}
            data-testid="submitbtn"
          >
            Add
          </Button>
        </div>
      </Form>

    </ModalWrapper>
  );
}

export default GraphEventForm;
