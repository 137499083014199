import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  PAYMENT_STATUS, REDIRECT_WITH_ROLE, ROUTE_PATH,
} from '../../constants';
import { getUserInfo } from '../../utils/helpers';

function MainLayout() {
  const userInfo = getUserInfo();
  const { pathname } = useLocation();

  if (userInfo?.role === 'admin' && !pathname?.startsWith(`/${userInfo?.role}`)) {
    return <Navigate to={ROUTE_PATH.ADMIN_DASHBOARD} />;
  }

  if (userInfo?.role
    && !pathname?.startsWith(`/${userInfo?.role}`)
    && (userInfo?.paymentStatus === PAYMENT_STATUS.PAID && userInfo?.subscriptionId)) {
    return <Navigate to={REDIRECT_WITH_ROLE[userInfo?.role as keyof typeof REDIRECT_WITH_ROLE]} />;
  }

  return <Outlet />;
}

export default MainLayout;
