import React from 'react';
import PromptModal from '../promptModal';
import { PLAN_DEFAULT_MODAL } from '../../constants/modal';
import { SetDefaultPlanModalProps } from '../../types';

const SetDefaultPlan = ({
  selectedPlan,
  isModalDisableOpen,
  setIsModalDisableOpen,
  onSucess
}: SetDefaultPlanModalProps) => {
  const handleSetAsDefault = () => {
    onSucess(selectedPlan);
  };

  return (
    <>
      {isModalDisableOpen && (
        <PromptModal
          open={!!isModalDisableOpen}
          cancel={() => setIsModalDisableOpen(false)}
          handleAction={() => handleSetAsDefault()}
          {...PLAN_DEFAULT_MODAL}
        />
      )}
    </>
  );
};

export default SetDefaultPlan;
