import React from 'react';

const SectionSix = () => (
  <>
    <h3>Section 6 - Indemnity</h3>
    <p className="para">
      All users and/or members agree to insure and hold Wine Technology Inc.,
      our subsidiaries, affiliates, agents, employees, officers, partners and/or
      licensors blameless or not liable for any claim or demand, which may
      include, but is not limited to, reasonable attorney fees made by any third
      party which may arise from any content a member or user of our Site may
      submit, post, modify, transmit or otherwise make available through our
      Services, the use of MSP Services or your connection with these Services,
      your violations of the Terms of Service and/or your violation of any such
      rights of another person.
    </p>

    <h3>Section 7 - Commercial Reuse Of Services</h3>
    <p className="para">
      The member or user herein agrees not to replicate, duplicate, copy, trade,
      sell, resell nor exploit for any commercial reason any part, use of, or
      access to MSP's sites.
    </p>

    <h3>Section 8 - Modifications</h3>
    <p className="para">
      Wine Technology Inc.reserves the right at any time it may deem fit, to
      modify, alter and or discontinue, whether temporarily or permanently, our
      service, or any part thereof, with or without prior notice. In addition,
      we shall not be held liable to you or to any third party for any such
      alteration, modification, suspension and/or discontinuance of our
      Services, or any part thereof.
    </p>
  </>
);

export default SectionSix;
