import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  HttpLink,
} from '@apollo/client';
import React from 'react';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { getAccessToken } from '../../utils/helpers';
import { ApolloProviderProps } from '../../types';
import { NUMBER, ROUTE_PATH, USER_INFO } from '../../constants';
import { NERWORK_ERROR_SUFFIX } from '../../constants/string';

function ApplloProvider({ children }: ApolloProviderProps) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors?.some((i) => i.message === 'Unauthorized'
    || graphQLErrors?.some((error) => error.extensions.code === 'UNAUTHENTICATED'))) {
      toast.error(graphQLErrors[0]?.message);
      Cookies.remove(USER_INFO);
      setTimeout(() => {
        window.location.href = ROUTE_PATH.LOGIN;
      }, NUMBER.FOUR_THOUSAND);
      return;
    }
    if (graphQLErrors) {
      toast.error(graphQLErrors[0]?.message);
      return;
    }
    if (networkError) {
      toast.error(`${networkError?.message}, ${NERWORK_ERROR_SUFFIX}`);
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      uri: process.env.REACT_APP_GRPAHQL_SERVER_URL,
    }),
  ]);

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApplloProvider;
