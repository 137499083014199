import React from 'react';
import {
  Button, Col, Form, Row
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalWrapper from '../shared/common/modalWrapper';
import Input from '../shared/forms/input';
import { EditProbePriceValidationSchema, INPUT_ALPHANUMERIC, NUMBER } from '../../constants';
import { EditModalModalProps } from '../../types';

const EditModal = ({ closeModal, initialValue, onSuccess }:EditModalModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(EditProbePriceValidationSchema),
  });

  const onSubmitHandler = handleSubmit(async (values) => {
    const mutationVariables = {
      id: initialValue.id,
      ...values,
    };
    onSuccess(mutationVariables);
  });

  return (
    <ModalWrapper show handleClose={() => closeModal()} title="Edit Plan">
      <Form onSubmit={onSubmitHandler}>
        <Row className="modal-body-form">
          <Col xs={12} sm={12}>
            <Input
              label="Plan Name"
              name="productName"
              placeholder="Enter plan name"
              refValues={register}
              setValue={setValue}
              error={errors?.productName?.message}
            />
          </Col>
          <Col xs={12} sm={12}>
            <div className="wrap-input edit-plan-modal-textarea">
              <Input
                as="textarea"
                label="Description"
                isOptional
                name="description"
                placeholder="Write description"
                refValues={register}
                setValue={setValue}
                inputClass="mb-0"
                error={errors?.description?.message}
                valuePattern={INPUT_ALPHANUMERIC}
                maxLength={NUMBER.ONE_HUNDRED}
              />
              <span className="word-count">
                {getValues('description')?.length || 0}
                /
                {NUMBER.ONE_HUNDRED}
              </span>
            </div>
          </Col>
        </Row>
        <div className="footer-modal">
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            disabled={!isValid}
          >
            Update
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default EditModal;
