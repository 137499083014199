import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { checkString } from '../../../utils/helpers';
import { ProbeDetailSectionProps } from '../../../types';

function ProbeDetailSection({ probe }:ProbeDetailSectionProps) {
  return (
    <>
      {/* <div className="probes-detail-top">
        <Row className="probes-detail-top-row m-0">
          <Col sm={4} xs={12} className="p-0 probes-detail-left">
            <div className="probes-subtitle">{checkString(probe?.probeName)}</div>
            <div className="detail-subtexts">{checkString(probe?.probeId)}</div>
          </Col>
          <Col sm={4} xs={12} className="p-0 probes-detail-middle probes-detail-middle-mobile">
            <div className="detail-title">Description</div>
            <div className="detail-subtext2">{checkString(probe?.description)}</div>
          </Col>
          <Col sm={4} xs={12} className="p-0 probes-detail-right probes-detail-right-mobile">
            <p className="btn-status active">Active</p>
          </Col>
        </Row>
      </div> */}

      <div className="common-detail-wrapper probes-detail-top probe-detail-new">
        <div className="dashboard-probe-wrapper">
          <div>
            <h4 className="dashboard-probe-subtitle">{checkString(probe?.probeId)}</h4>
          </div>
          <p className={`btn-status ${probe?.isGettingRealData ? 'active' : 'deactive'}`}>{probe?.isGettingRealData ? 'Active' : 'Disabled'}</p>
        </div>
        <ListGroup className="w-100">
          <ListGroup.Item className="mb-0">
            <div className="detail-title">Probe Name</div>
            <div className="detail-subtext2">{checkString(probe?.probeName)}</div>
          </ListGroup.Item>
          <ListGroup.Item className="mb-0">
            <div className="detail-title">Description</div>
            <div className="detail-subtext2">{checkString(probe?.description)}</div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </>
  );
}

export default ProbeDetailSection;
