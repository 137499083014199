export const passwordStrengthParams = [
  {
    text: 'Must have minimum 8 characters.',
  },
  { text: 'Must be alphanumeric.' },
  { text: 'Must have at least 1 special character.' },
  { text: 'Must have at least 1 uppercase character.' },
];

export const productionVolumeCapacity = [
  { label: 'Liter', value: 'liter' },
  { label: 'Ton', value: 'ton' },
  { label: 'Gallon', value: 'gallon' },
];

export const signUpFormSteps = [1, 2];

export const measureMentOptions = [
  { label: 'Temperature Sensor', value: 'temperature' },
  { label: 'Density', value: 'density' },
  { label: 'Redox', value: 'redox' },
];

export const systemSensorTypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Temperature', value: 'temperature' },
  { label: 'Density', value: 'density' },
  { label: 'RedOx', value: 'redox' },
  { label: 'Fermentation', value: 'fermentation' },
  { label: 'Stratification', value: 'stratification' },
];

export const eventOptions = [
  { label: 'Pumpover', value: 'Pumpover' },
  { label: 'Nutrient Addition', value: 'Nutrient Addition' },
  { label: 'Water Add', value: 'Water Add' },
  { label: 'Pulsair', value: 'Pulsair' },
  { label: 'Venturi/Sparge', value: 'Venturi/Sparge' },
  { label: 'Crush Date', value: 'Crush Date' },
  { label: 'Inoculation Date', value: 'Inoculation Date' },
  { label: 'SO2 Date', value: 'SO2 Date' },
  { label: 'Other', value: 'Other' },
];

export const addNewPlan = [
  { label: 'Monthly', value: 'month' },
  { label: 'Annually', value: 'year' },
];

export const addNewPlanSelectPlan = [
  { label: 'Wine Making', value: 'wineMaking' },
  { label: 'Probes', value: 'probes' },
];

export const PAN_WINE_MAKING_TYPE = {
  WINE_MAKING: 'wineMaking',
  PROBES: 'probes'
};

export const EXPORT_TO_CSV_CONFIG = [
  { label: 'XLSX', value: 'excel' },
  { label: 'CSV', value: 'csv' }
];

export const EXPORT_TO_PDF_CONFIG = [
  { label: 'PDF', value: 'pdf' }
];

export const TEMP_TOOLTIP_CONFIG = [
  { label: 'Detailed Tooltip', value: 'detailed' },
  { label: 'Generic Tooltip', value: 'generic' }
];
