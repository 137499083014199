import React from 'react';

const SectionEleven = () => (
  <>
    <h3>Section 14 - Release</h3>
    <p className="para">
      In the event you have a dispute, you agree to release Wine Technology
      Inc.(and its officers, directors, employees, agents, parent subsidiaries,
      affiliates, co-branders, partners and any other third parties) from
      claims, demands and damages (actual and consequential) of every kind and
      nature, known and unknown, suspected or unsuspected, disclosed and
      undisclosed, arising out of or in any way connected to such dispute.
    </p>

    <h3>Section 15 - Notice</h3>
    <p className="para">
      Wine Technology Inc. may furnish you with notices, including those with
      regards to any changes to the Terms, including but not limited to email,
      regular mail, MMS or SMS, text messaging, postings on our website
      Services, or other reasonable means currently known or any which may be
      herein after developed. Any such notices may not be received if you
      violate any aspects of the Terms by accessing our Services in an
      unauthorized manner. Your acceptance of this Agreement constitutes your
      agreement that you are deemed to have received any and all notices that
      would have been delivered had you accessed our Services in an authorized
      manner.
    </p>

    <h3>Section 16 - Intellectual Property Rights</h3>
    <p className="para">
      You herein acknowledge, understand and agree that all of the Wine
      Technology Inc.trademarks, copyright, trade name, service marks, and other
      Wine Technology Inc.logos and any brand features, and/or product and
      service names are
    </p>
    <p className="para">
      Trademarks and as such, are and shall remain the property of Wine
      Technology Inc.. You herein agree not to display and/or use in any manner
      the Wine Technology Inc.logo or marks without obtaining Wine Technology
      Inc.'s prior written consent.
    </p>
    <p className="para">
      Wine Technology Inc. will always respect the intellectual property of
      others, and we ask that all of our users do the same. With regards to
      appropriate circumstances and at its sole discretion, Wine Technology Inc.
      may disable and/or terminate the accounts of any user who violates our
      Terms and/or infringes the rights of others. If you feel that your work
      has been duplicated in such a way that would constitute copyright
      infringement, or if you believe your intellectual property rights have
      been otherwise violated, you should provide to us the following
      information:
    </p>
    <div className="indent-sec">
      <p className="para">
        a) The electronic or the physical signature of the individual that is
        authorized on behalf of the owner of the copyright or other intellectual
        property interest.
      </p>
      <p className="para">
        b) A description of the copyrighted work or other intellectual property
        that you believe has been infringed upon.
      </p>
      <p className="para">
        c) A description of the location of the site which you allege has been
        infringing upon your work.
      </p>
      <p className="para">
        d) Your physical address, telephone number, and email address.
      </p>
      <p className="para">
        e) A statement, in which you state that the alleged and disputed use of
        your work is not authorized by the copyright owner, its agents or the
        law.
      </p>
      <p className="para">
        f) And finally, a statement, made under penalty of perjury, that the
        aforementioned information in your notice is truthful and accurate, and
        that you are the copyright or intellectual property owner,
        representative or agent authorized to act on the copyright or
        intellectual property owner's behalf.
      </p>
    </div>
    <p className="para">The Wine Technology Inc. agent for notice of claims of copyright or other intellectual property infringement can be contacted as follows:</p>
    <p className="para">Mailing Address: <br />
      Wine Technology Inc <br />
      Attn: Copyright Agent <br />
      474 Walten Way <br />
      Windsor, California 95492
    </p>
  <p className="para">
      Telephone: 720.284.2059 <br />
      Email: <a className="static-email" href="mailto:kelly@vinwizard.us">kelly@vinwizard.us</a>
    </p>
  </>
);

export default SectionEleven;
