import React from 'react';

const SectionThree = () => (
  <>
    <h3>Section 3 - Conduct</h3>
    <p className="para">
      As a user or member of the Site, you herein acknowledge, understand
      and agree that all information, text, software, data, photographs,
      music, video, messages, tags or any other content, whether it is
      publicly or privately posted and/or transmitted, is the expressed sole
      responsibility of the individual from whom the content originated. In
      short, this means that you are solely responsible for any and all
      content posted, uploaded, emailed, transmitted or otherwise made
      available by way of the MSP Services, and as such, we do not guarantee
      the accuracy, integrity or quality of such content. It is expressly
      understood that by use of our Services, you may be exposed to content
      including, but not limited to, any errors or omissions in any content
      posted, and/or any loss or damage of any kind incurred as a result of
      the use of any content posted, emailed, transmitted or otherwise made
      available by MSP.
    </p>
    <p className="para mb-30">
      Furthermore, you herein agree not to make use of Wine Technology
      Inc.&apos;s Services for the purpose of:
    </p>
    <div className="indent-sec">
      <p className="para">
        a) Uploading, posting, emailing, transmitting, or otherwise making
        available any content that shall be deemed unlawful, harmful,
        threatening, abusive, harassing, tortious, defamatory, vulgar,
        obscene, libelous, or invasive of another&apos;s privacy or which is
        hateful, and/or racially, ethnically, or otherwise objectionable;
      </p>
      <p className="para">
        b) Causing harm to minors in any manner whatsoever;
      </p>
      <p className="para">
        c) Impersonating any individual or entity, including, but not
        limited to, any MSP officials, forum leaders, guides or hosts or
        falsely stating or otherwise misrepresenting any affiliation with an
        individual or entity;
      </p>
      <p className="para">
        d) Forging captions, headings or titles or otherwise offering any
        content that you personally have no right to pursuant to any law nor
        having any contractual or fiduciary relationship with;
      </p>
      <p className="para">
        e) Uploading, posting, emailing, transmitting or otherwise offering
        any such content that may infringe upon any patent, copyright,
        trademark, or any other proprietary or intellectual rights of any
        other party;
      </p>
      <p className="para">
        f) Uploading, posting, emailing, transmitting or otherwise offering
        any content that you do not personally have any right to offer
        pursuant to any law or in accordance with any contractual or
        fiduciary relationship;
      </p>
      <p className="para">
        g) Uploading, posting, emailing, transmitting, or otherwise offering
        any unsolicited or unauthorized advertising, promotional flyers, &quot;
        <strong>junk mail</strong>
        ,&quot; &quot;
        <strong>spam</strong>
        ,&quot; or any other
        form of solicitation, except in any such areas that may have been
        designated for such purpose;
      </p>
      <p className="para">
        h) Uploading, posting, emailing, transmitting, or otherwise offering
        any source that may contain a software virus or other computer code,
        any files and/or programs which have been designed to interfere,
        destroy and/or limit the operation of any computer software,
        hardware, or telecommunication equipment;
      </p>
      <p className="para">
        i) Disrupting the normal flow of communication, or otherwise acting
        in any manner that would negatively affect other users&apos; ability to
        participate in any real time interactions;
      </p>
      <p className="para">
        j) Interfering with or disrupting any Wine Technology Inc.Services,
        servers and/or networks that may be connected or related to our
        website, including, but not limited to, the use of any device
        software and/or routine to bypass the robot exclusion headers;
      </p>
      <p className="para">
        k) Intentionally or unintentionally violating any local, state,
        federal, national or international law, including, but not limited
        to, rules, guidelines, and/or regulations decreed by the U.S.
        Securities and Exchange Commission, in addition to any rules of any
        nation or other securities exchange, that would include without
        limitation, the New York Stock Exchange, the American Stock
        Exchange, or the NASDAQ, and any regulations having the force of
        law;
      </p>
      <p className="para">
        l) Providing informational support or resources, concealing and/or
        disguising the character, location, and or source to any
        organization delegated by the United States government as a &quot;
        <strong>foreign terrorist organization</strong>
        &quot; in accordance to
        Section 219 of the Immigration Nationality Act;
      </p>
      <p className="para">
        m) &quot;
        <strong>Stalking</strong>
        with the intent to otherwise harass
        another individual; and/or
      </p>
      <p className="para">
        n) Collecting or storing of any personal data relating to any other
        member or user in connection with the prohibited conduct and/or
        activities which have been set forth in the aforementioned
        paragraphs.
      </p>
    </div>
    <p className="para">
      Wine Technology Inc. herein reserves the right to pre-screen, refuse
      and/or delete any content currently available through our Services. In
      addition, we reserve the right to remove and/or delete any such
      content that would violate the Terms or which would otherwise be
      considered offensive to other visitors, users and/or members.
    </p>
    <p className="para">
      Wine Technology Inc. herein reserves the right to access, preserve
      and/or disclose member account information and/or content if it is
      requested to do so by law or in good faith belief that any such action
      is deemed reasonably necessary for:
    </p>
    <div className="indent-sec">
      <p className="para">a) compliance with any legal process;</p>
      <p className="para">b) enforcement of the Terms;</p>
      <p className="para">
        c) responding to any claim that therein contained content is in
        violation of the rights of any third party;
      </p>
      <p className="para">
        d) responding to requests for customer service; or
      </p>
      <p className="para">
        e) protecting the rights, property or the personal safety of Wine
        Technology Inc., its visitors, users and members, including the
        general public.
      </p>
    </div>
    <p className="para">
      Wine Technology Inc. herein reserves the right to include the use of
      security components that may permit digital information or material to
      be protected, and that such use of information and/or material is
      subject to usage guidelines and regulations established by Wine
      Technology Inc. or any other content providers supplying content
      services to Wine Technology Inc.. You are hereby prohibited from
      making any attempt to override or circumvent any of the embedded usage
      rules in our Services. Furthermore, unauthorized reproduction,
      publication, distribution, or exhibition of any information or
      materials supplied by our Services, despite whether done so in whole
      or in part, is expressly prohibited.
    </p>
  </>
);

export default SectionThree;
