import React from 'react';

const SectionTwo = () => (
  <>
    <h3>Section 2 - Creating an Account</h3>
    <p className="para">
      Once you create an account with us, you are registered on the MSP
      Site. The terms &quot;
      <strong>member</strong>
      ,&quot; &quot;
      <strong>membership</strong>
      ,&quot; and &quot;
      <strong>account</strong>
      &quot; all refer
      to this registration as a member on MSP&apos;s Site. If you are merely
      surfing or browsing through the Site and have not yet created an
      account, your use of the Site is still subject to this Agreement; if
      you do not agree to this Agreement, do not use the Site.
    </p>
    <p className="para">
      When you create an account, you will provide a unique username and
      email. We will also ask you to create a password. Because any
      activities that occur under your username or password are your
      responsibility it is important for you to keep your username and/or
      password secure. You may not assign or otherwise transfer your account
      to any other person or entity. You acknowledge that MSP is not
      responsible for third party access to your account that results from
      theft or misappropriation of your account. Notify us immediately if
      you believe that someone has used your username, email, or password
      without your authorization.1
    </p>
    <p className="para mb-30">
      Furthermore, the registering party hereby acknowledges, understands
      and agrees to:
    </p>
    <div className="indent-sec">
      <p className="para">
        a) Furnish factual, correct, current and complete information with
        regards to yourself as may be requested by the data registration
        process, and
      </p>
      <p className="para">
        b) Maintain and promptly update your registration and profile
        information in an effort to maintain accuracy and completeness at
        all times.
      </p>
    </div>
    <p className="para">
      If anyone knowingly provides any information of a false, untrue,
      inaccurate or incomplete nature, Wine Technology Inc. will have
      sufficient grounds and rights to suspend or terminate the member in
      violation of this aspect of the Agreement, and as such refuse any and
      all current or future use of Wine Technology Inc. Services, or any
      portion thereof.
    </p>
  </>
);

export default SectionTwo;
