import React from 'react';
import { Form } from 'react-bootstrap';

function Checkbox({
  label,
  name,
  refValues,
  setValue,
  error,
  wrapperClass,
}: any) {
  return (
    <Form.Group
      className="form-mt-space remember-me"
      controlId="formBasicCheckbox"
    >
      {/* <Form.Label>{label}</Form.Label> */}
      <div className={wrapperClass ?? 'remember-me-wrap'}>
        <Form.Check
          type="checkbox"
          label={label}
          {...refValues(name)}
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(name, e.target.checked, { shouldValidate: true });
          }}
        />
      </div>
      <span className="input-error-message">{error as string}</span>
    </Form.Group>
  );
}

export default Checkbox;
