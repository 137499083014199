import React from 'react';
import {
  Col, Dropdown, DropdownButton, Row
} from 'react-bootstrap';
import { CreditCardDisplayProps } from '../../types';
import LogOutModal from '../auth/model/LogoutModal';
import useLogout from '../../hooks/useLogout';
import mastercard from '../../assets/icons/credit_cards/mastercard.svg';
import visa from '../../assets/icons/credit_cards/visa.svg';
import amex from '../../assets/icons/credit_cards/amex.svg';
import diners from '../../assets/icons/credit_cards/diners.svg';
import discover from '../../assets/icons/credit_cards/discover.svg';

import {
  DELETE_TEXT,
  DELETE_CARD_MODEL_BODY,
  DELETE_CARD_MODEL_HEADER,
  CANCEL_TEXT,
  CARD_DEFAULT_HEADER,
  CARD_DEFAULT_BODY,
  CONFIRM_TEXT,
  NUMBER,
} from '../../constants';

const CreditCardDisplay: React.FC<CreditCardDisplayProps> = ({
  cardData,
  handleRemoveCard,
  handleDefaultCard,
}) => {
  const { openLogoutModal, closeLogoutModal, logoutModal } = useLogout();
  const {
    openLogoutModal: openDefaultModel,
    closeLogoutModal: closeDefaultModel,
    logoutModal: defaultModel,
  } = useLogout();
  const deleteCardFunction = () => {
    handleRemoveCard(cardData.id);
    closeLogoutModal();
  };

  const defaultCardFunction = () => {
    handleDefaultCard(cardData.id);
    closeDefaultModel();
  };
  const expMonth = cardData?.exp_month < NUMBER.TEN
    ? `0${cardData?.exp_month}`
    : cardData?.exp_month;

  const expYearLastTwoDigits = String(cardData?.exp_year)?.slice(-NUMBER.TWO);

  const modifiedDate = `${expMonth}/${expYearLastTwoDigits}`;

  function getCardBrandLogo(brand: string) {
    switch (brand) {
      case 'Visa':
        return visa;
      case 'MasterCard':
        return mastercard;
      case 'American Express':
        return amex;
      case 'Discover':
        return discover;
      case 'Diners Club':
        return diners;
      default:
        return visa;
    }
  }
  return (
    <>
      <Col sm={NUMBER.TWELVE} md={NUMBER.SIX}>
        <div className="card-sec card-gradient-01">
          <div className="card-sec-top">
            <div className="card-sec-logo">
              <img
                src={getCardBrandLogo(cardData.brand)}
                alt="No Credit Card"
              />
            </div>
            <div className="card-sec-slider">
              <div className="card-slider">
                <div className="switch-main">
                  <span className="switch-main-text">Make As Default</span>
                  <label className="switch" htmlFor={cardData.id}>
                    <input
                      type="checkbox"
                      id={cardData.id}
                      checked={cardData.isDefault}
                      onClick={openDefaultModel}
                      disabled={cardData.isDefault}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
              {!cardData.isDefault && (
                <div className="card-more">
                  <div className="three-dot-outside-sec">
                    <DropdownButton
                      className="icon-three-dot manage-three-dot"
                      title=""
                    >
                      <Dropdown.Item onClick={openLogoutModal}>
                        Delete
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card-sec-bottom">
            <Row>
              <Col sm={12}>
                <div className="card-num">
                  XXXX - XXXX - XXXX -
                  {' '}
                  {cardData.last4}
                </div>
              </Col>
              <Col sm={6}>
                <div className="card-label">Card Holder Name</div>
                <div className="card-desc">{cardData.name}</div>
              </Col>
              <Col sm={6}>
                <div className="card-label">Expiration Date</div>
                <div className="card-desc">{modifiedDate}</div>
              </Col>
              <Col sm={12}>
                <div className="card-label">Address</div>
                <div className="card-desc mb-0">{cardData?.address_line1}</div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <LogOutModal
        open={logoutModal}
        logoutFuntion={deleteCardFunction}
        close={closeLogoutModal}
        headerText={DELETE_CARD_MODEL_HEADER}
        bodyText={DELETE_CARD_MODEL_BODY}
        confirmText={DELETE_TEXT}
        cancelText={CANCEL_TEXT}
      />

      <LogOutModal
        open={defaultModel}
        logoutFuntion={defaultCardFunction}
        close={closeDefaultModel}
        headerText={CARD_DEFAULT_HEADER}
        bodyText={CARD_DEFAULT_BODY}
        confirmText={CONFIRM_TEXT}
        cancelText={CANCEL_TEXT}
      />
    </>
  );
};

export default CreditCardDisplay;
