import React from 'react';
import { getUserInfo } from '../../utils/helpers';

function WelcomeWrapper() {
  const userInfo = getUserInfo();
  return (
    <div className="dashboard-wel-wrapper">
      <div className="name">
        Hi
        {' '}
        <span>{userInfo?.fullName}</span>
        ,
      </div>
      <div className="welcome">
        Welcome
        {' '}
        {!userInfo?.firstTimeUser && 'back'}
        {' '}
        to MSP!
      </div>
    </div>
  );
}

export default WelcomeWrapper;
