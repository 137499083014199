export const ROUTE_PATH = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  SIGNUP_STEP_1: '/signup?step=1',
  SIGNUP_STEP_2: '/signup?step=2',
  HOME: '/',
  USER: '/user',
  PROBES: '/probes/',
  PROBELIST: '/probelist',
  SUBSRIPTION: '/subscription',
  VERIFY_OTP: '/verifyOtp',
  REFRESH_PATH: '/auth/token/refresh',
  VERIFIED: '/verified',
  RESET_PASSWORD: '/reset-password',
  SUCCESS_RESET: '/successfuly-reset',
  USER_TANKS: '/user/tanks',
  TANKSDETAIL: '/user/tanks/:id',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_USER_MANAGEMENT_EDIT_USER: '/admin/users/:id/edit-user',
  ADMIN_USER_MANAGEMENT_ADD_USER: '/admin/users/add-user',
  ADMIN_USER_MANAGEMENT_USER_DETAILS: '/admin/users',
  ADMIN_USER_MANAGEMENT: '/admin/users',
  ADMIN_USER_MANAGEMENT_TRANSACTION_HISTORY:
    '/admin/users/:id/transaction-history/',
  TRANSACTION_HISTORY: 'transaction-history',
  EDIT_USER: 'edit-user',
  PROBES_LISTING: 'probes-listing',
  ADMIN_USER_MANAGEMENT_ASSIGNED_PROBES_LIST:
    '/admin/users/:id/probes-listing',
  ADMIN_USER_MANAGEMENT_ASSIGNED_PROBES_DETAILS:
    '/admin/users/:id/probes-listing/:id',
  PAYMENT_SUCCESS: '/payment-successful',
  PAYMENT_PAGE: '/payment',
  USER_DASHBOARD: '/user/dashboard',
  USER_PROBES: '/user/probes',
  USER_PROBE_LISTING: '/user/probes?tab=active',
  USER_PROBE_DETAILS: '/user/probes/:id',
  PRIVACY: '/privacyPolicy',
  TERMS_CONDITIONS: '/termsConditions',
  ADMIN_PROBE_LISTING: '/admin/probe-list',
  ADMIN_PROBE_DETAIL: '/admin/probe-Detail',
  PROBE_ADMIN_DETAIL: '/admin/probe-list',
  USER_PROFILE: '/user/profile',
  USER_TRANSACTION: '/user/profile/transaction',
  USER_DASHBOARD_PROBEDETAILS: '/user/dashboard/probeDetails',
  USER_PROFILE_EDIT: '/user/profile/edit',
  ADMIN_SUBSCRIPTION: '/admin/subscriptions',
  HOME_PAGE: '/',
  ABOUT_PAGE: '/about',
  CONTACT_PAGE: '/contact'
};

export const REDIRECT_WITH_ROLE = {
  user: ROUTE_PATH.USER_DASHBOARD,
  admin: ROUTE_PATH.ADMIN_DASHBOARD,
};
