import React from 'react';
import { Button } from 'react-bootstrap';
import noProbesPic from '../../assets/img/no-probes-icon.svg';
import { BOOLEANS } from '../../constants';
import { EmptyProbPageProps } from '../../types';

const EmptyProbPage: React.FC<EmptyProbPageProps> = ({ close, isAdmin = BOOLEANS.FALSE }) => (
  <div className="admin-header-wrapper">
    {!isAdmin ? <h1 className="admin-header-title">Probe Catalog</h1> : null}
    <div className="no-probes-card">
      <div className="subscription-nocard-sec ">
        <div className="nocard-img">
          <img src={noProbesPic} alt="No probes card" />
        </div>
        <div className="nocard-title">No Probes</div>
        <div className="nocard-subtitle">
          There are no probes assigned yet.
          {' '}
          {!isAdmin && `Please contact admin to assign
          probes to your account.`}
        </div>
        {!isAdmin
          ? (
            <div className="contact-to-admin">
              <Button className="btn primary" onClick={() => close?.(BOOLEANS.TRUE)}>
                Contact Admin
              </Button>
            </div>
          ) : null}
      </div>
    </div>
  </div>
);

export default EmptyProbPage;
