import React from 'react';
import { useQuery } from '@apollo/client';
import TanksCount from './TanksCount';
import ProbeCount from './probeCount';
import { DASHBOARD_COUNT } from '../../graphql/queries/user/dashboard.queries';
import { NETWORK_ONLY, NUMBER } from '../../constants';
import CustomLoader from '../shared/common/customLoader';

function DashboardCardWrapper() {
  const {
    loading,
    data: {
      getProbeAndTankCount
    } = {}
  } = useQuery(DASHBOARD_COUNT, { fetchPolicy: NETWORK_ONLY });

  return (
    <>
      {loading && <CustomLoader />}
      <div className="dashboard-card-wrapper user-dashboard-card-wrapper">
        <TanksCount count={getProbeAndTankCount?.totalTank || NUMBER.ZERO} />
        <ProbeCount
          assignedCount={getProbeAndTankCount?.assignProbe || NUMBER.ZERO}
          availableCount={getProbeAndTankCount?.totalAvailableProbe || NUMBER.ZERO}
          attachedCount={getProbeAndTankCount?.totalAttachedProbe || NUMBER.ZERO}
        />
      </div>
    </>
  );
}

export default DashboardCardWrapper;
