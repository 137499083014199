// eslint-disable-next-line max-len
export const awsIdentityCode = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || 'us-east-1:659f3c3a-fe62-4501-b0cd-876d1e434a80';
export const awsBucketName = process.env.REACT_APP_AWS_BUCKET_NAME || 'msp-vinwizard-dev-backend';
export const awsImgUrl = process.env.REACT_APP_IMG_URL || 'https://msp-vinwizard-dev-backend.s3.amazonaws.com/';
export const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyDvlFnyl9nkFXDeYYlhXaRM5CF0Ws7wsbU';
export const googleSiteKey = '6LejvQQpAAAAAM9NcHQKQdozV2p1iaRP6Z6IVV62';
// export const googleSiteKey = process.env.REACT_APP_SITE_KEY || '6Lf8cPonAAAAAARn1uWOBQ6gIeV1KtkkeMwd02aM';
export const serverUrl = process.env.REACT_APP_SERVER_URL || 'https://dev-api.msprobe.com';
export const graphqlServerUrl = process.env.REACT_APP_GRPAHQL_SERVER_URL
                || 'https://dev-api.msprobe.com/graphql';
