import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { RemoveTankProps } from '../../types';
import { BOOLEANS } from '../../constants';
import {
  CHECK_TANK_ASSIGNMENT,
  DELETE_TANK_QUERY,
  USER_TANK_LISTING,
} from '../../graphql/queries/tank.queries';
import CustomLoader from '../shared/common/customLoader';

function RemoveTank({
  open, close, tankId, onSuccess
}: RemoveTankProps) {
  const client = useApolloClient();
  const [checkTankIsAssignToProbe, { loading: dataLoading , data}] = useMutation(CHECK_TANK_ASSIGNMENT);
  const [deleteTank, { loading }] = useLazyQuery(DELETE_TANK_QUERY, {
    variables: { id: tankId },
  });

  /**
   * The function `handleAction` deletes a tank, displays a success message using toast, refetches a
   * list of user tanks, closes a modal, and calls the `onSuccess` function.
   */
  const handleAction = async () => {
    const res = await deleteTank();
    if (res?.data) {
      toast.success(res.data?.DeleteTankById?.message);
    }
    await client.refetchQueries({ include: [USER_TANK_LISTING] });
    onSuccess();
    close(BOOLEANS.FALSE);
  };

  useEffect(() => {
    handleCheckAssignment();
  }, []);

  const handleCheckAssignment = async () => {
    try {
      await checkTankIsAssignToProbe({
        variables: { tankId },
      });
    } catch (error: any) {
      // handle error
    }
  };

  return (
    <>
      {(loading || dataLoading) && <CustomLoader />}
      <Modal centered className="common-modal remove-modal" show={open}>
        <Modal.Header>
          <span className="icon-close-with-circle" />
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-form">
            <h4 className="remove-modal-tile">Remove Tank</h4>
            <p>Are you sure you want to remove this tank?</p>
            <div className="successful-note">
              <p>
                <span>Note:</span>
                {' '}
                If you remove this tank, all the associated probes will be
                permanently detached
                {data?.checkTankIsAssignToProbe?.status ? ' and wouldn\'t be able to show the data on graph.' : '.'}
              </p>
            </div>
          </div>
          <div className="footer-modal">
            <Button
              className="footer-outline-btn"
              onClick={handleAction}
              href="#"
            >
              Yes, Remove
            </Button>
            <Button
              type="submit"
              className="btn primary modal-btn-submit"
              onClick={() => close(BOOLEANS.FALSE)}
            >
              No, Keep It
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    </>
  );
}

export default RemoveTank;
