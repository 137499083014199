import React from 'react';
import {
  Row, Col, Form, Button
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import phoneIcon from '../../assets/icons/phone2.svg';
import mailIcon from '../../assets/icons/mail.svg';
import rightIcon from '../../assets/icons/arrow-right.svg';
import Input from '../shared/forms/input';
import PhoneInput from '../shared/forms/phoneInput';
import { ContactUsForm } from '../../types';
import { ContactUsFormSchema, INPUT_ALPHABETS, NUMBER } from '../../constants';
import { GET_CONTACT_SUBMISSION } from '../../graphql/mutation';
import CustomLoader from '../shared/common/customLoader';
import PublicNavBar from '../shared/common/navbar';
import LandingFooter from '../landingPage/LandingFooter';

function Contact() {
  const [submitContactForm, { loading }] = useMutation(GET_CONTACT_SUBMISSION);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
    reset
  } = useForm<ContactUsForm>({
    resolver: yupResolver(ContactUsFormSchema),
  });

  const onSubmitHandler = handleSubmit(async (values: Record<string, any>) => {
    try {
      const res = await submitContactForm({
        variables: values,
      });

      if (res?.data?.contactUsSubmisson) {
        toast.success(res.data.contactUsSubmisson.message);
        reset();
      } else {
        throw new Error('Update user failed');
      }
    } catch (error) {
      //
    }
  });

  return (
    <>
    <div className="landing-page-wrapper">
      <div className="landing-page-header">
        <PublicNavBar />

        <div className="contact-container">
          {loading && <CustomLoader />}
          <Row>
            <Col xs={12} sm={6} className="contact-left">
              <h1 className="contact-title">Get in Touch!</h1>
              <p className="contact-subtitle">
                We value your feedback, questions, and ideas. Feel free to reach out
                to us using the contact information provided below. Whether you have
                inquiries about our products, services, or simply want to connect,
                our dedicated team is here to assist you.
              </p>
              <div className="contact-infor">
                <h4 className="contact-infor-title">Contact Information</h4>
                <div className="contact-infor-wrapper contact-infor-ist">
                  <div className="contact-infor-left">
                    <img src={phoneIcon} alt="phone icon" />
                  </div>
                  <div className="contact-infor-right">
                    <p className="phone-text">Phone Number</p>
                    <p className="phone-number">+1 720-284-2059</p>
                  </div>
                </div>
                <div className="contact-infor-wrapper">
                  <div className="contact-infor-left">
                    <img src={mailIcon} alt="phone icon" />
                  </div>
                  <div className="contact-infor-right">
                    <p className="phone-text">Email Address</p>
                    <p className="phone-number">kelly@vinwizard.us</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} className="contact-right">
              <div className="contact-form-wrapper">
                <Form className="modal-body-form" onSubmit={onSubmitHandler}>
                  <Row>
                    <Col xs={12} sm={12} lg={6} className=" ">
                      <Input
                        label="Name"
                        name="fullName"
                        placeholder="Enter name"
                        refValues={register}
                        type="text"
                        setValue={setValue}
                        error={errors?.fullName?.message}
                        valuePattern={INPUT_ALPHABETS}
                        maxLength={NUMBER.THIRTY_TWO}
                      />
                    </Col>
                    <Col xs={12} sm={12} lg={6} className=" ">
                      <Input
                        label="Email Address"
                        name="email"
                        placeholder="Enter email address"
                        refValues={register}
                        error={errors?.email?.message}
                        setValue={setValue}
                        maxLength={NUMBER.FIFTY}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} lg={6} className=" ">
                      <PhoneInput
                        setValue={setValue}
                        name="contactNumber"
                        label="Contact Number"
                        error={errors?.contactNumber?.message}
                        phone={getValues('contactNumber')}
                        setCountryCode={() => {}}
                      />
                    </Col>
                    <Col xs={12} sm={12} lg={6} className=" ">
                      <Input
                        label="Subject"
                        name="subject"
                        placeholder="Enter subject"
                        refValues={register}
                        error={errors?.subject?.message}
                        setValue={setValue}
                        maxLength={NUMBER.FIFTY}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} className=" ">
                      <div className="wrap-input">
                        <Input
                          as="textarea"
                          label="Message"
                          name="message"
                          placeholder="Write your message..."
                          inputClass="mb-0"
                          refValues={register}
                          error={errors?.message?.message}
                          setValue={setValue}
                          maxLength={NUMBER.FIVE_HUNDRED}
                        />
                        <span className="word-count">
                          {getValues('message')?.length || NUMBER.ZERO}
                          /500
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <div className="contact-btn">
                    <Button
                      type="submit"
                      className="btn primary modal-btn-submit"
                      disabled={!isValid}
                    >
                      Submit Query
                      <img className="ml-4" src={rightIcon} alt="right arrow" />
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        <LandingFooter />
      </div>
      </div>
    </>
  );
}

export default Contact;
