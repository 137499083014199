import { NUMBER } from './number';
import { ASSIGEND_TANK, PROBE_NAME, TEXT_CENTER_CLASS, WINERY_NAME } from './static';

/* eslint-disable import/prefer-default-export */
export const USER_TANKS_TABLE_HEADS = [
  {
    name: 'TANK NAME',
    size: 35,
    sort: 'ASC',
    sortBy: 'name',
    className: 'width-100',
  },
  {
    name: 'TANK CAPACITY (UNIT)',
    size: 30,
    sort: '',
    sortBy: 'capacity',
    className: '',
  },
  {
    name: 'VOLUME',
    size: 30,
    sort: '',
    sortBy: 'volume',
    className: '',
  },
  { name: 'ACTION', size: 5 },
];

export const USER_PROBES_TABLE_HEADS = [
  {
    name: 'PROBE ID',
    size: 20,
    sort: 'ASC',
    sortBy: 'probeId',
    className: '',
  },
  {
    name: PROBE_NAME,
    size: 25,
    className: 'width-100',
  },
  {
    name: ASSIGEND_TANK,
    size: 30,
    sort: 'ASC',
    sortBy: 'tankName',
    className: '',
  },
  {
    name: 'STATUS',
    size: 20,
    sort: '',
    sortBy: '',
    className: '',
  },
  { name: 'ACTION', size: 5 },
];

export const PROBE_TABS_CONFIG = [
  { eventKey: 'active', title: 'Active Probes' },
  { eventKey: 'disabled', title: 'Disabled Probes' },
];

export const USER_TRANSACTION_TABLE_HEADS = [
  {
    name: 'TRANSACTION ID',
    size: 16,
    className: 'width-100',
  },
  {
    name: 'DATA & TIME',
    size: 16,
    sort: '',
    sortBy: 'volume',
    className: '',
  },
  {
    name: 'PLAN AMOUNT',
    size: 16,
    sort: '',
    sortBy: 'volume',
    className: '',
  },
  {
    name: 'ADD-ONS',
    size: 16,
    sort: '',
    sortBy: 'volume',
    className: '',
  },
  {
    name: 'PAYMENT TYPE',
    size: 16,
    sort: '',
    sortBy: 'volume',
    className: '',
  },
  {
    name: 'STATUS',
    size: 16,
    sort: '',
    sortBy: 'volume',
    className: '',
  },
];

export const USER_DASHBOARD_BASE_COMPUTATION_PROBES = [
  {
    name: 'TIME STAMP',
    size: 35,
    sortBy: 'timestampDate',
    sort: 'ASC',
    className: 'width-100',
  },
  {
    name: 'MEASUREMENT',
    size: 30,
    sort: '',
    sortBy: 'capacity',
    className: '',
  },
  {
    name: 'SENSOR',
    size: 20,
    sort: '',
    sortBy: 'volume',
    className: '',
  },
  { name: 'READING', size: 15 },
];

export const USER_LISTING_TABLE_HEADS = [
  {
    name: 'FULL NAME',
    size: 20,
    className: '',
  },
  {
    name: WINERY_NAME,
    size: 15,
    sort: 'ASC',
    sortBy: 'wineryName',
    className: '',
  },
  {
    name: 'Subscription',
    size: 15,
    className: '',
  },
  {
    name: 'CONTACT NUMBER',
    size: 15,
    className: '',
  },
  {
    name: 'Total Purchased Probes',
    size: 15,
    className: '',
  },
  {
    name: 'STATUS',
    size: 10,
    sort: 'ASC',
    sortBy: 'status',
    className: '',
  },
  {
    name: 'ACTION',
    size: 10,
    className: TEXT_CENTER_CLASS,
  },
];

export const PROBE_TABLE_FIELDS = [
  {
    name: 'PROBE ID',
    size: 15,
    sort: 'ASC',
    sortBy: 'probeId',
    className: 'width-100'
  },
  {
    name: PROBE_NAME,
    size: 15,
    sort: '',
    sortBy: '',
  },
  {
    name: WINERY_NAME,
    size: 15,
    sortable: true,
    sort: 'ASC',
    sortBy: 'wineryName',
  },
  {
    name: 'EMAIL ADDRESS',
    size: 20,
    sort: '',
    sortBy: '',
    sortable: false,
  },
  {
    name: ASSIGEND_TANK,
    size: 15,
    sort: '',
    sortBy: '',
  },
  {
    name: 'Status',
    size: 15,
    sortable: false,
    sort: '',
    sortBy: 'STATUS',
  },
  { name: 'ACTION', size: 5 },
];

export const TRANSACTION_HISTORY_TABLE_HEADS = [
  {
    name: 'Transaction ID',
    size: 25,
    className: '',
  },
  {
    name: 'Date & Time',
    size: 15,
    className: '',
  },
  {
    name: 'Plan Amount',
    size: 10,
    className: '',
  },
  {
    name: 'Add-ons',
    size: 10,
    className: '',
  },
  {
    name: 'Payment Type',
    size: 15,
    className: '',
  },
  {
    name: 'STATUS',
    size: 15,
    className: '',
  },
  {
    name: 'Invoice',
    size: 10,
    className: TEXT_CENTER_CLASS,
  },
];

export const ADMIN_TRANSACTION_HISTORY_TABLE_HEADS = [
  {
    name: 'Transaction ID',
    size: 25,
    className: '',
  },
  {
    name: 'Date & Time',
    size: 15,
    className: '',
  },
  {
    name: 'Plan Amount',
    size: 15,
    className: '',
  },
  {
    name: 'Add-ons',
    size: 15,
    className: '',
  },
  {
    name: 'Payment Type',
    size: 15,
    className: '',
  },
  {
    name: 'STATUS',
    size: 15,
    className: '',
  },
];

export const SELECTED_PROBES_TABS_CONFIG = [
  { eventKey: 'base', title: 'Selected MSP Data' },
  { eventKey: 'system', title: 'System Computation' },
];

export const USER_ASSIGN_PROBE_TABLE_HEADS = [
  {
    name: 'PROBE ID',
    size: 15,
    className: '',
    sort: 'ASC',
    sortBy: 'probeId',
  },
  {
    name: PROBE_NAME,
    size: 25,
    className: '',
  },
  {
    name: WINERY_NAME,
    size: 25,
    className: '',
    sort: 'ASC',
    sortBy: 'winery_name'
  },
  {
    name: ASSIGEND_TANK,
    size: 25,
    className: '',
  },
  {
    name: 'STATUS',
    size: 10,
    sort: 'ASC',
    sortBy: 'status',
    className: '',
  },
];

export const USER_DASHBOARD_SYSTEM_COMPUTATION_PROBES = [
  {
    name: 'TIME STAMP',
    size: 20,
    sortBy: 'timestampDate',
    sort: 'ASC',
    // className: 'width-100',
  },
  {
    name: 'SENSOR',
    size: 16,
  },
  {
    name: 'VALUE',
    size: 16,
  },
  {
    name: 'MAX TEMP',
    size: 16,
  },
  {
    name: 'MIN TEMP',
    size: 16,
  },
  {
    name: 'EVENT',
    size: 16,
  },
];

export const formattedTotalCount = (totalCount: number) => (totalCount >= NUMBER.ONE && totalCount <= NUMBER.NINE
  ? `${NUMBER.ZERO}${totalCount}`
  : totalCount);

export const ADMIN_SUBSCRIPTION_TABLE_HEADS = [
  {
    name: 'Plan name',
    size: 30,
    className: '',
  },
  {
    name: 'Price',
    size: 15,
    className: '',
  },
  {
    name: 'Interval',
    size: 20,
    className: '',
  },
  {
    name: 'WineMaking',
    size: 10,
    className: '',
  },
  {
    name: 'Status',
    size: 15,
    className: '',
  },
  {
    name: 'Action',
    size: 10,
    className: TEXT_CENTER_CLASS,
  },

];
