import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import closeIcon from '../../assets/icons/close.svg';
import CreditCardForm from '../stripe/CreditCardForm';
import useCreditForm from '../../hooks/useCreditForm';
import { CreditCardFormModelProps } from '../../types';
import { ADD_CARD_MUTATION } from '../../graphql/mutation';
import useLoader from '../../hooks/useLoader';
import CustomLoader from '../shared/common/customLoader';

const CreditCardFormModel = ({
  show,
  close,
  getCard,
}: CreditCardFormModelProps) => {
  const [addCardMutation] = useMutation(ADD_CARD_MUTATION);
  const {
    stripe,
    elements,
    cardElement,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    handleInputChange,
    paymentButtonDisable,
    cardFormData,
    cardError
  } = useCreditForm();

  const { isLoading, startLoading, stopLoading } = useLoader();

  const onAddCreditCard = () => {
    close();
  };

  const handlePaymentSubmit = async (event: any) => {
    event.preventDefault();
    startLoading();
    if (!stripe || !elements || !cardElement) {
      stopLoading();
      return;
    }

    try {
      const { token, error } = await stripe?.createToken(cardElement, {
        name: cardFormData.name,
        address_line1: cardFormData.address_line1,
      });
      if (error) {
        stopLoading();
      } else {
        const { data } = await addCardMutation({
          variables: { token: token.id },
        });
        getCard();
        onAddCreditCard();
        toast.success(data.addCard.message);
        stopLoading();
      }
    } catch (errors) {
      onAddCreditCard();
      stopLoading();
    }
  };

  return (
    <Modal centered className="common-modal add-credit-modal" show={show}>
      <Modal.Header>
        <h2 className="modal-title">Add Credit Card</h2>
        <img
          className="btn-close"
          src={closeIcon}
          alt="close icon"
          onClick={close}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="payment-ach-option-stripe">
          <CreditCardForm
            CardNumberElement={CardNumberElement}
            CardCvcElement={CardCvcElement}
            CardExpiryElement={CardExpiryElement}
            handleInputChange={handleInputChange}
            cardError={cardError}
          />
        </div>
      </Modal.Body>
      <div className="footer-modal">
        <Button
          className="btn primary w-100 ach-payment-btn"
          disabled={!paymentButtonDisable()}
          onClick={handlePaymentSubmit}
        >
          Add
        </Button>
      </div>
      {isLoading && <CustomLoader />}
    </Modal>
  );
};

export default CreditCardFormModel;
