/**
 * The `useEventToolTip` function is a custom React hook that manages the state and behavior of a
 * tooltip component used in the dashboard graphs.
 * @returns The `useEventToolTip` function returns an object with the following properties:
 */
import React, { useState } from 'react';
import { BOOLEANS, NUMBER } from '../constants';
import { IEventToolTipPayload, PageEvent } from '../types';

function useGraphToolTip() {
  const [toolTopPayload, setToolTipPayload] = useState<IEventToolTipPayload>();
  const [event, setEvent] = useState<any>();
  let tooltipTimeout: any;

  /**
   * The function adjusts the tooltip based on the given parameters.
   * @param {boolean} active - The `active` parameter is a boolean value that indicates whether the
   * tooltip or cell is active or not.
   * @param {React.MouseEvent | PageEvent | null} e - The parameter `e` is of type `React.MouseEvent |
   * PageEvent | null`. It represents the event object that triggered the tooltip adjustment. It can be
   * a mouse event, a page event, or `null` if no event is available.
   * @param {string} actionCreator - The `actionCreator` parameter is a string that specifies the type
   * of action being performed. It can have two possible values: "tooltip" or "cell".
   */
  const adjustToolTip = (active:boolean, e: React.MouseEvent | PageEvent | null, actionCreator:string) => {
    if (active) {
      if (actionCreator === 'tooltip') {
        if (tooltipTimeout) {
          clearTimeout(tooltipTimeout);
        }
      } else if (actionCreator === 'cell') {
        if (tooltipTimeout) {
          clearTimeout(tooltipTimeout);
        }
        setEvent(e);
      }
    } else {
      if (actionCreator === 'cell') {
        if (tooltipTimeout) {
          clearTimeout(tooltipTimeout);
        }
        tooltipTimeout = setTimeout(() => {
          setToolTipPayload({});
        }, NUMBER.FIVE_HUNDRED);
      }
      if (actionCreator === 'tooltip') {
        setToolTipPayload({});
      }
    }
  };


  const handleHover = (e: React.MouseEvent, payload: IEventToolTipPayload) => {
    e.persist();
    setToolTipPayload(payload);
    adjustToolTip(true, { pageX: e.pageX, pageY: e.pageY }, 'cell');
  };

  return {
    event, toolTopPayload, adjustToolTip, setToolTipPayload, handleHover
  };
}

export default useGraphToolTip;
