import React from 'react';
import {
  Button, Form, Row, Col
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalWrapper from '../shared/common/modalWrapper';
import { AddNewTankProps } from '../../types';
import Input from '../shared/forms/input';
import Select from '../shared/forms/select';
import {
  BOOLEANS,
  ADD_NEW_TANK_VALIDATION_SCHEMA,
  productionVolumeCapacity,
  INPUT_ALPHANUMERIC,
  NUMBER,
  INPUT_NUMERIC,
  EMPTY_TANK,
} from '../../constants';
import CustomLoader from '../shared/common/customLoader';
import useSaveTank from '../../hooks/useSaveTank';

function AddNewTank({
  open, close, tankData, success
}: AddNewTankProps) {
  const isEditMode = !!tankData?.id;
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { ...EMPTY_TANK, ...tankData },
    resolver: yupResolver(ADD_NEW_TANK_VALIDATION_SCHEMA),
    shouldFocusError: BOOLEANS.FALSE,
  });

  const onSuccess = () => {
    success(getValues());
    reset();
    close(false);
  };

  const { isLoading, submit } = useSaveTank({ isEditMode, onSuccess });

  return (
    <>
      {isLoading && <CustomLoader />}
      <ModalWrapper
        show={open}
        handleClose={close}
        title={isEditMode ? 'Edit Tank' : 'Add New Tank'}
        data-testid="addNewTankModal"
      >
        <Form onSubmit={handleSubmit(submit)} data-testid="addNewTankForm">
          <Row className="modal-body-form">
            <Col xs={12} sm={12} className=" ">
              <Input
                label="Tank Name"
                name="name"
                placeholder="Enter tank name"
                refValues={register}
                setValue={setValue}
                error={errors?.name?.message}
              />
            </Col>
            <Col xs={12} sm={12} className=" ">
              <Select
                placeholder="Select units"
                name="capacity"
                label="Capacity"
                setValue={setValue}
                value={getValues('capacity')}
                options={productionVolumeCapacity}
                error={errors?.capacity?.message}
              />
            </Col>
            <Col xs={12} sm={12} className=" ">
              <Input
                label="Volume of Tank"
                name="volume"
                placeholder="Enter volume"
                refValues={register}
                setValue={setValue}
                error={errors?.volume?.message}
                valuePattern={INPUT_NUMERIC}
                maxLength={10}
              />
            </Col>
            <Col xs={12} sm={12} className=" ">
              <Input
                label="Location"
                isOptional
                name="location"
                placeholder="Back cellar"
                refValues={register}
                setValue={setValue}
                error={errors?.location?.message}
                maxLength={NUMBER.FIFTY}
              />
            </Col>
            <Col xs={12} sm={12} className=" ">
              <div className="wrap-input">
                <Input
                  as="textarea"
                  label="Description"
                  isOptional
                  name="description"
                  placeholder="Write description"
                  refValues={register}
                  setValue={setValue}
                  inputClass="mb-0"
                  error={errors?.description?.message}
                  valuePattern={INPUT_ALPHANUMERIC}
                  maxLength={NUMBER.ONE_HUNDRED}
                />
                <span className="word-count">
                  {getValues('description')?.length || 0}
                  /
                  {NUMBER.ONE_HUNDRED}
                </span>
              </div>
            </Col>
          </Row>
          <div className="footer-modal">
            <Button
              type="submit"
              className="btn primary modal-btn-submit"
              disabled={!isValid}
              data-testid="submitbtn"
            >
              {isEditMode ? 'Update' : 'Add'}
            </Button>
          </div>
        </Form>
      </ModalWrapper>
    </>
  );
}

export default AddNewTank;
