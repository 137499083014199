import { NO_KEEP_IT, YES_REMOVE } from './static';

export const REMOVE_PROBE_MODAL = {
  title: 'Unassign Probe',
  cancelBtnText: NO_KEEP_IT,
  actionBtnText: YES_REMOVE,
  desc: 'Are you sure you want to unassign this probe from the tank?',
};

export const REMOVE_TANK_MODAL = {
  title: 'Remove Tank',
  cancelBtnText: NO_KEEP_IT,
  actionBtnText: YES_REMOVE,
  desc: 'Are you sure you want to remove this tank?',
  note: 'If you remove this tank, all the associated probes will be permanently detached.',
};

export const USER_DISABLE_MODAL = {
  title: 'Disable User',
  actionBtnText: 'YES, disable',
  cancelBtnText: NO_KEEP_IT,
  desc: 'Are you sure you want to disable this user?',
};

export const USER_ENABLE_MODAL = {
  title: 'Activate user',
  cancelBtnText: 'YES, ACTIVE',
  actionBtnText: NO_KEEP_IT,
  desc: 'Are you sure you want to activate this user?',
};

export const USER_DELETE_MODAL = {
  title: 'Delete',
  cancelBtnText: NO_KEEP_IT,
  actionBtnText: YES_REMOVE,
  desc: 'Are you sure you want to remove this user from list?',
  note: 'If you remove this user, all the associated data will be permanently deleted.',
};

export const PLAN_DEFAULT_MODAL = {
  title: 'Set as Default Plan',
  actionBtnText: 'YES, Make Default',
  cancelBtnText: NO_KEEP_IT,
  desc: 'Are you sure you want to make this plan default?',
};
