import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { InputPropsType } from '../../../types';
import { NUMBER } from '../../../constants/number';
import { serverUrl } from '../../../constants/config';
import { BOOLEANS } from '../../../constants';
import addressIcon from '../../../assets/icons/address-icon.svg';

function AddressInput(props: InputPropsType): JSX.Element {
  const {
    label,
    placeholder = 'Write description',
    disabled = false,
    maxLength = NUMBER.ONE_HUNDRED,
    refValues,
    setValue = () => undefined,
    error,
    name = '',
    valuePattern = /^/,
    wrapperClass = 'wrap-input',
    inputClass = '',
    isOptional,
    getValue,
    isAddressIcon = false,
    setError = () => undefined,
    ...rest
  } = props;

  const [suggestions, setSuggestions] = useState<string[]>([]);

  const fetchSuggestions = async () => {
    const apiUrl = `${serverUrl}/getPlaces`;

    try {
      const response = await axios.get(apiUrl, {
        params: {
          place: getValue(name),
        },
      });
      if (response?.data) {
        const descriptions = response.data.predictions.map((prediction: any) => prediction.description);
        setSuggestions(descriptions);
        if ((response?.data?.status === 'ZERO_RESULTS'
          || response?.data?.predictions?.length === NUMBER.ZERO) && setError) {
          setError(name, { message: 'Please enter a valid address' });
        }
      } else {
        setSuggestions([]);
      }
    } catch (getError) {
      setSuggestions([]);
    }
  };

  let filterTimeout:any;
  const handleChange = useCallback((s: string) => {
    setValue(name, s, { shouldValidate: true });
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      setError(name, { message: ' ' });
      fetchSuggestions();
    }, NUMBER.FIVE_HUNDRED);
  }, []);

  const handleSuggestionClick = (address: string) => {
    setValue(
      name,
      address.trimStart().replace(valuePattern, ''),
      { shouldValidate: true }
    );
    setSuggestions(() => []);
    if (setError) {
      setError(name, undefined);
    }
  };

  return (
    <Form.Group className={`form-mt-space ${inputClass}`}>
      {isOptional ? (
        <>
          <Form.Label>{label}</Form.Label>
          {' '}
          <Form.Text>(Optional)</Form.Text>
        </>
      ) : (
        <Form.Label>{label}</Form.Label>
      )}
      <div className={wrapperClass ?? 'wrap-input'}>
        <Form.Control
          type="text"
          className={`form-input ${inputClass ? 'red-border' : ''}`}
          placeholder={placeholder}
          disabled={disabled}
          {...refValues(name)}
          maxLength={maxLength.toString()}
          name={name}
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(e.target.value.replace(valuePattern, ''));
          }}
          // value={getValue(name)}
          {...rest}
          data-testid={`${name}Input`}
        />
        {isAddressIcon
          ? (
            <span className="on-input-icon-left">
              <img src={addressIcon} alt="address icon" />
            </span>
          ) : null }
      </div>
      <span className="input-error-message">{error as string}</span>
      {suggestions.length ? (
        <ul className="address-options">
          {suggestions.map((address, index) => (
            <li className="address-options-items" key={index} onClick={() => handleSuggestionClick(address)}>
              {address}
            </li>
          ))}
        </ul>
      ) : null}
    </Form.Group>
  );
}
export default AddressInput;
