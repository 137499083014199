import Cookies from 'js-cookie';
import { NUMBER } from '../constants/number';
import userprofile from '../assets/img/user-profile.svg';
import {
  REMEMBER_ME,
  SUBSCRIPTION_ID,
  USER_INFO,
  USER_FULLNAME,
  PAYMENT_PRICE,
  COUNTER_KEY,
} from '../constants/forms/keys';
import {
  BOOLEANS, LINE_COLORS_CODES, SENSOR_COLORS, SUBSCRIPTION_TYPE, TIME_DATE_FORMATE
} from '../constants';
import { awsImgUrl } from '../constants/config';
import { formatDate } from './dateHelper';
import { SelectOption } from '../types';

interface CookiesInterface {
  [name: string]: string;
}

export function getAllCookies(): CookiesInterface {
  const allCookiesString = document.cookie;
  const cookieArray = allCookiesString.split('; ');
  const cookies: CookiesInterface = {};

  for (const cookie of cookieArray) {
    const [name, value] = cookie.split('=');
    cookies[name] = decodeURIComponent(value);
  }

  return cookies;
}

export const getFiscalYearDateObject = (s: string) => {
  const parts = s?.split('/');
  let value: any;
  if (parts?.length > NUMBER.ZERO) {
    value = new Date(Number(parts[NUMBER.ONE]), Number(parts[NUMBER.ZERO]) - NUMBER.ONE);
  } else {
    value = null;
  }
  return value;
};

export const saveSessions = (value: any) => {
  Cookies?.set(USER_INFO, JSON.stringify(value));
};

export const savePaymentId = (value: any) => {
  Cookies?.set(SUBSCRIPTION_ID, JSON.stringify(value));
};

export function getSession(key: string) {
  try {
    return JSON.parse(Cookies?.get(key) as string);
  } catch (error) {
    return null;
  }
}

export const savePaymentPricing = (value: any) => {
  Cookies?.set(PAYMENT_PRICE, JSON.stringify(value));
};

export const saveUserInfo = (creds: object) => {
  localStorage?.setItem(USER_INFO, JSON.stringify(creds));
};

export const saveCounter = (creds: number | string) => {
  localStorage?.setItem(COUNTER_KEY, JSON.stringify(creds));
};

export const getCounter = () => JSON.parse(localStorage?.getItem(COUNTER_KEY) as string);

export const clearCounter = () => localStorage.removeItem(COUNTER_KEY);
export const getUserInfoStore = () => JSON.parse(localStorage?.getItem(USER_INFO) as string);

export const saveRememberMe = (creds: object) => {
  localStorage?.setItem(REMEMBER_ME, JSON.stringify(creds));
};

export const getRememberMe = () => JSON.parse(localStorage?.getItem(REMEMBER_ME) as string);

export const getAccessToken = () => {
  try {
    const user = JSON.parse(Cookies.get(USER_INFO) as string);
    if (!user) {
      return '';
    }
    return user?.token ? user?.token : '';
  } catch (error) {
    return '';
  }
};

export const getUserInfo = () => {
  try {
    return JSON.parse(Cookies?.get(USER_INFO || '') as string);
  } catch (err) {
    return '';
  }
};

export const removeCookies = () => Cookies.remove(USER_INFO);

export const checkString = (value: string | undefined) => {
  if (!value) {
    return '-';
  }
  return value;
};

/**
 * The function getCurrentMonthYear returns the current month and year in the format "MM/YYYY".
 * @returns a string in the format "MM/YYYY", where MM is the current month (padded with leading zero
 * if necessary) and YYYY is the current year.
 */
export const getCurrentMonthYear = () => {
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + NUMBER.ONE)
    .toString()
    .padStart(NUMBER.TWO, '0');
  const currentYear = currentDate.getFullYear().toString();
  return `${currentMonth}/${currentYear}`;
};

export const formatNumberToLocaleString = (number: number | undefined): string => {
  if (number === undefined || Number.isNaN(number)) {
    return '-';
  }

  return number.toLocaleString();
};

/**
 * The function `handleDownload` creates a link element, sets its href and download attributes, appends
 * it to the document body, triggers a click event on the link, and removes the link from the document
 * body.
 * @param {string} invoiceUrl - The `invoiceUrl` parameter is a string that represents the URL of the
 * invoice file that you want to download.
 */
export const handleDownload = (invoiceUrl: string) => {
  const link = document.createElement('a');
  link.href = invoiceUrl;
  link.download = 'invoice.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getTimeStampDateObject = (s: string, isIsoRequired = BOOLEANS.FALSE) => {
  if (!s?.length || !s || s === 'undefined') {
    return undefined;
  }
  const date = s?.split(' ');
  const parts = date[NUMBER.ZERO].split('/');
  let value: any;
  if (parts?.length > NUMBER.ZERO) {
    value = new Date(`${parts[NUMBER.TWO]}/${parts[NUMBER.ZERO]}/${parts[NUMBER.ONE]}
    ${date[NUMBER.ONE]} ${date[NUMBER.TWO]}`);
  } else {
    value = null;
  }
  if (isIsoRequired) {
    value = value?.toISOString();
  }
  return value;
};

export const getSubscriptionType = (subscriptionType: string) => {
  const validSubscriptionTypes = [SUBSCRIPTION_TYPE.ANNUAL, SUBSCRIPTION_TYPE.MONTHLY];
  return validSubscriptionTypes.includes(subscriptionType) ? `${subscriptionType} Payment` : 'Payment';
};

export const getCurrentUser = () => {
  const storedUserData = Cookies.get(USER_FULLNAME);
  return storedUserData ? JSON.parse(storedUserData) : null;
};

export const capitalizeFirst = (str = '') => {
  if (!str) {
    return '';
  }
  return str.charAt(NUMBER.ZERO).toUpperCase() + str.slice(NUMBER.ONE);
};

export function generateImageUrl(profileImg: string | undefined) {
  return profileImg ? `${awsImgUrl}${profileImg}` : userprofile;
}

export function parseBaseGraphsData(res:any, callBack:any) {
  const arr: any = [];
  const redoxData = res.dashboardSensor?.data;
  if (redoxData.length > 0) {
    redoxData.forEach((element:any, i:number) => {
      const prepareObj: any = {};
      prepareObj.timeStamp = formatDate(element.timestamp_date, TIME_DATE_FORMATE);
      prepareObj.value = i;
      element.values.forEach((val:any) => {
        const splitVal = val.split(',');
        prepareObj[splitVal[NUMBER.ZERO]] = parseInt(splitVal[NUMBER.ONE], 10);
        prepareObj.event = null;
      });
      arr.push(prepareObj);
      callBack(arr);
    });
  } else {
    callBack([]);
  }
}

export function parseSystemGraphData(res:any, callback:any, key = 'value') {
  const temp = res?.dashboardComputation.data;
  const arr: any = [];
  if (temp?.length > 0) {
    temp.forEach((element:any, i:number) => {
      const prepareObj: any = {};
      prepareObj.timeStamp = formatDate(element.timestamp_date, TIME_DATE_FORMATE);
      prepareObj.value = i;
      element.values.forEach((val:any) => {
        const splitVal = val.split(',');
        prepareObj[key] = parseInt(splitVal[NUMBER.ZERO], NUMBER.TEN);
        prepareObj.id = splitVal[NUMBER.ONE];
        prepareObj.event = splitVal[NUMBER.TWO];
        prepareObj.description = splitVal[NUMBER.THREE];
        prepareObj.graphType = key;
        prepareObj[`${key}Index`] = NUMBER.ONE;
      });
      arr.push(prepareObj);
      callback(arr);
    });
  } else {
    callback([]);
  }
}

export function parseSystemGraphDataForDualAxis(res:any, callback:any, key:string, key2:string) {
  const temp = res?.dashboardComputation.data;
  const arr: any = [];
  if (temp?.length > 0) {
    temp.forEach((element:any, i:number) => {
      const prepareObj: any = {};
      prepareObj.timeStamp = formatDate(element.timestamp_date, TIME_DATE_FORMATE);
      prepareObj.value = i;
      element.values.forEach((val:any) => {
        const splitVal = val.split(',');
        prepareObj[key] = parseInt(splitVal[NUMBER.ZERO], NUMBER.TEN);
        prepareObj[key2] = parseInt(splitVal[NUMBER.ONE], NUMBER.TEN);
        prepareObj.id = splitVal[NUMBER.TWO];
        prepareObj[`${key}Event`] = splitVal[NUMBER.THREE];
        prepareObj[`${key}Desc`] = splitVal[NUMBER.FOUR];
        prepareObj[`${key2}Event`] = splitVal[NUMBER.FIVE];
        prepareObj[`${key2}Desc`] = splitVal[NUMBER.SIX];
        prepareObj.graphType = key.concat(key2);
        prepareObj[`${key}Index`] = NUMBER.ONE;
        prepareObj[`${key2}Index`] = NUMBER.TWO;
        prepareObj.hasDualAxis = true;
        prepareObj.isOverlap = splitVal[NUMBER.ZERO] === splitVal[NUMBER.ONE];
      });
      arr.push(prepareObj);
      callback(arr);
    });
  } else {
    callback([]);
  }
}

export const delay = (delayInms : number) => new Promise((resolve) => setTimeout(resolve, delayInms));

export async function fetchFile(url: string) {
  await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // create a temporary URL for the blob
      const link = window.URL.createObjectURL(blob);
      // create a link element to download the file
      const a = document.createElement('a');
      a.href = link;
      a.download = url?.split('/').pop() || '';
      document.body.appendChild(a);
      a.click();
      // remove the link element and the temporary URL
      a.remove();
      window.URL.revokeObjectURL(link);
    })
    .catch((error) => error);
}

export const getProbeStatus = (s:string) => {
  if (s === 'active') {
    return 'Activated';
  }
  return 'Deactivated';
};

export const isNullValue = (s: string) => {
  if (s === '' || s === 'null') {
    return true;
  }
  return false;
};

export const isUnderLimit = (s: string, len = NUMBER.ONE_HUNDRED) => {
  if (s?.length > len) {
    return true;
  }
  return false;
};

export const validateTankCsv = (row: string[]) => {
  let err = '';
  if (isNullValue(row[NUMBER.ZERO])
   || isNullValue(row[NUMBER.ONE]) || isNullValue(row[NUMBER.FOUR])) {
    err = 'Missing fields, Please upload a valid csv';
  }
  if (isUnderLimit(row[NUMBER.ZERO]) || isUnderLimit(row[NUMBER.ONE])
   || isUnderLimit(row[NUMBER.TWO]) || isUnderLimit(row[NUMBER.FOUR])) {
    err = 'Character limit exceeded, Please upload a valid csv';
  }
  return err;
};

export const validateProbeCsv = (row: string[]) => {
  let err = '';
  if (isNullValue(row[NUMBER.ZERO]) || isNullValue(row[NUMBER.ONE])) {
    err = 'Missing fields, Please upload a valid csv';
  }
  if (isUnderLimit(row[NUMBER.ZERO]) || isUnderLimit(row[NUMBER.ONE])) {
    err = 'Character limit exceeded, Please upload a valid csv';
  }
  return err;
};

export const getSensorColor = (v: number) => {
  const {
    NINETY,
    EIGTHY,
    SEVENTY,
    SEVENTY_NINE,
    EIGHTY_NINE,
    SIXTY,
    SIXTY_NINE,
    FIFTY,
    FIFTY_NINE,
    FOURTY,
    FOURTY_NINE,
    THIRTY_NINE
  } = NUMBER;
  let color;
  switch (true) {
    case v >= NINETY:
      color = 'darkest-red';
      break;
    case (v >= EIGTHY && v < NINETY) || v === EIGHTY_NINE:
      color = 'orange';
      break;
    case (v >= SEVENTY && v < SEVENTY_NINE) || v === SEVENTY_NINE:
      color = 'tinted-yellow';
      break;
    case (v >= SIXTY && v < SIXTY_NINE) || v === SIXTY_NINE:
      color = 'yellow';
      break;
    case (v >= FIFTY && v < FIFTY_NINE) || v === FIFTY_NINE:
      color = 'light-green';
      break;
    case (v >= FOURTY && v < FOURTY_NINE) || v === FOURTY_NINE:
      color = 'dark-green';
      break;
    case v <= THIRTY_NINE:
      color = 'darkest-green';
      break;
    default:
      return color;
  }
  return color;
};

export const getSensorOption = (arr: string[]) => {
  const obj : SelectOption[] = [{ label: '', value: '' }, { label: '', value: '' }];
  arr.forEach((i: string, index: number) => {
    obj[index].value = i;
    obj[index].label = capitalizeFirst(i);
  });
  return obj;
};

export const getStartIndex = (len: number) => {
  if (!len) {
    return 0;
  }
  return Math.floor(len - ((len * NUMBER.TEN) / NUMBER.ONE_HUNDRED) - 1);
};

export const getUniqKey = () => Math.random().toString(NUMBER.THIRTY_TWO).substring(NUMBER.TWO, NUMBER.SEVEN);

export const getBaseIndex = (arr: string[], value: string) => {
  const index = arr.findIndex((i: string) => i === value);
  if (index === -NUMBER.ONE) {
    return NUMBER.ZERO;
  }
  return index;
};

export const getLinearGredient = (sensors: number[]) => {
  let colorStr = '';
  sensors.forEach((s: number) => {
    colorStr += `, ${SENSOR_COLORS[getSensorColor(s) as keyof typeof SENSOR_COLORS] || '#FFF'}`;
  });
  return `linear-gradient(to bottom ${colorStr})`;
};

export function removeDuplicates(arr:string[]) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
