import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import {
  Link, Navigate, useNavigate, useSearchParams,
} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import PersonalInfo from './personalInfo';
import { NUMBER } from '../../../constants/number';
import BuisnessInfo from './buisnessInfo';
import { ROUTE_PATH } from '../../../constants/routes';
import { BuisnessInfoType, PersonalInfoType } from '../../../types';
import 'react-step-progress-bar/styles.css';
import FormStepper from './formStepper';
import { EMAIL_EXIST_CHECK } from '../../../graphql/mutation';
import CustomLoader from '../../shared/common/customLoader';
import { String } from '../../../constants/string';

function SignupForm() {
  const [searchParams] = useSearchParams();
  const step = Number(searchParams.get('step'));
  const navigate = useNavigate();
  const [step1Values, setStep1Values] = useState<PersonalInfoType | null>(null);
  const [step2Values, setStep2Values] = useState<BuisnessInfoType | null>(null);
  const [emailExist, { data, loading }] = useMutation(EMAIL_EXIST_CHECK);
  const proceedToNext = async (values: PersonalInfoType | null) => {
    if (!values) {
      return;
    }
    const { email } = values;

    const result = await emailExist({
      variables: { email },
    });
    if (result.data.emailExist.isEmailExist === true) {
      toast.error(String.emailexistMessage);
    } else {
      setStep1Values(values);
      navigate(ROUTE_PATH.SIGNUP_STEP_2);
    }
  };

  if (!step) {
    return <Navigate to={ROUTE_PATH.SIGNUP_STEP_1} />;
  }

  return (
    <>
      {loading && <CustomLoader />}
      <Col sm={6} className="p-0 boarding-login onboarding-right">
        <div className="login-inner">
          <div className="boarding-title-signup">
            <h1>Create an Account</h1>
            <p>Fill out the below details to set up your account</p>
          </div>
          <FormStepper step={step} />
          {step === NUMBER.TWO ? (
            <BuisnessInfo
              step1Values={step1Values}
              step2Values={step2Values}
              setStep2Values={setStep2Values}
            />
          ) : (
            <PersonalInfo proceed={proceedToNext} storedValues={step1Values} />
          )}
          <div className="signin-bottom">
            Already have an account?
            <Link to={ROUTE_PATH.LOGIN} title="Sign up">
              Sign In
            </Link>
          </div>
        </div>
      </Col>
    </>
  );
}

export default SignupForm;
