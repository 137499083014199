import { gql } from '@apollo/client';

export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($totalProbe: Float!, $subscriptionType: String!, $isWineMakingPackage: Boolean!,
    $paymentType: String!, $paymentId: Boolean!, $accessToken: Boolean!) {
    createSubscription(totalProbe: $totalProbe, subscriptionType: $subscriptionType, isWineMakingPackage: $isWineMakingPackage,
    paymentType: $paymentType, paymentId: $paymentId, accessToken: $accessToken) {
      message
      secretToken
    }
  }
`;
