import { getUniqKey } from '../../utils/helpers';
import React from 'react';

function ToggleButton({ handleChange, checked, text }: any) {
  const id = getUniqKey();
  return (
    <div className="switch-main">
      <span className="switch-main-text">{text}</span>
      <label className="switch" htmlFor={`"toolTipSelector${id}`}>
        <input
          onChange={(e) => handleChange(e?.target?.checked)}
          type="checkbox"
          id={`"toolTipSelector${id}`}
          checked={checked}
        />
        <span className="slider round" />
      </label>
    </div>
  );
}

export default ToggleButton;
