import React from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from '../shared/common/modalWrapper';
import { ROUTE_PATH } from '../../constants';

function DisabledProbeModal({ isOpen, onClose }:any) {
  return (
    <ModalWrapper show={isOpen} title="Disabled Probe" handleClose={onClose} paymentClass="disabled-probe">
      <div className="modal-body-form disabled-probe-modal">
        <p>This probe appears to be disconnected from the network. Contact your MSP administrator for more details.</p>
        <Link className="disabled-probe-contact" to={ROUTE_PATH.CONTACT_PAGE}>Contact Us</Link>
      </div>
    </ModalWrapper>
  );
}

export default DisabledProbeModal;
