import React, { useRef, useState } from 'react';
import {
  Button, Container, Nav, Navbar, Dropdown
} from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import downArrow from '../../../../assets/icons/arrow.svg';
import closeIcon from '../../../../assets/icons/close.svg';
import {
  BOOLEANS,
  LOGOUT_MODEL_BODY,
  LOGOUT_MODEL_CANCEL,
  LOGOUT_MODEL_HEADER,
  ROUTE_PATH,
} from '../../../../constants';
import logoutIcon from '../../../../assets/img/logout.svg';
import Logo from '../../../../assets/img/logo.svg';
import DropDownMenu from '../DropDownMenu';
import useLogout from '../../../../hooks/useLogout';
import LogOutModal from '../../../auth/model/LogoutModal';
import ChangePassword from '../changePassword';

const PublicNavBar = () => {
  const navigate = useNavigate();
  const [showchangePasswordModal, setshowchangePasswordModal] = useState(
    BOOLEANS.FALSE
  );
  const handleModalStatus = () => setshowchangePasswordModal(!showchangePasswordModal);
  const location = useLocation();
  const handleLoginRoute = () => {
    navigate(ROUTE_PATH.LOGIN);
  };

  const isActive = (path: string) => location.pathname === path;
  const {
    logoutUser,
    openLogoutModal,
    closeLogoutModal,
    logoutModal,
    accessToken,
  } = useLogout();
  const [isOpen, setMenu] = useState(false);
  const closeNavbar = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setMenu(!isOpen);
  };

  return (
    <div className="header-scoll-margin">
      <div className="below-header-fixed">
        <Navbar expand="lg" className="bg-body-tertiary" expanded={isOpen}>
          <Container fluid className="landing-page-container">
            <Navbar.Brand>
              <Link to={ROUTE_PATH.USER_DASHBOARD}>
                <img src={Logo} alt="logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" onClick={closeNavbar} />
            <Navbar.Collapse id="navbarScroll" onClick={closeNavbar}>
              <Nav className="mx-auto">
                <Nav.Link
                  className={isActive(ROUTE_PATH.HOME_PAGE) ? 'active' : ''}
                >
                  <Link to="/">Home</Link>
                </Nav.Link>
                <Nav.Link
                  className={isActive(ROUTE_PATH.ABOUT_PAGE) ? 'active' : ''}
                >
                  <Link to={ROUTE_PATH.ABOUT_PAGE}>About Us</Link>
                </Nav.Link>
                <Nav.Link
                  className={
                    isActive(ROUTE_PATH.TERMS_CONDITIONS) ? 'active' : ''
                  }
                >
                  <Link to={ROUTE_PATH.TERMS_CONDITIONS}>
                    Terms & Conditions
                  </Link>
                </Nav.Link>
                <Nav.Link
                  className={isActive(ROUTE_PATH.PRIVACY) ? 'active' : ''}
                >
                  <Link to={ROUTE_PATH.PRIVACY}>Privacy Policy</Link>
                </Nav.Link>
                <Nav.Link
                  className={isActive(ROUTE_PATH.CONTACT_PAGE) ? 'active' : ''}
                >
                  <Link to={ROUTE_PATH.CONTACT_PAGE}>Contact Us</Link>
                </Nav.Link>
                <div className="nav-bar-close" onClick={closeNavbar}>
                  <img src={closeIcon} alt="down arrow" />
                </div>
              </Nav>
              <div className="for-mobile btn-signin-mobile">
                {!accessToken ? (
                  <Button className="btn-signin" variant="outline-success" onClick={() => navigate(ROUTE_PATH.LOGIN)}>
                    Sign In
                  </Button>
                ) : (
                  <DropDownMenu
                    accessToken={accessToken}
                    openLogoutModal={openLogoutModal}
                    isHomePage={BOOLEANS.TRUE}
                    handleModalStatus={handleModalStatus}
                  />
                )}
              </div>
            </Navbar.Collapse>
            <div className="dropdown-signin-sec menu-dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  className="dropdown-list-btn menu-dropdown-toggle"
                  variant="success"
                  id="dropdown-basic"
                >
                  vinwizard / octent
                  <span className="dropdown-img">
                    <img src={downArrow} alt="down arrow" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="header-profile-top-arrow">
                  <Dropdown.Item
                    href="https://www.vinwizard.com/"
                    target="_blank"
                  >
                    VinWizard
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://www.octentautomation.com/"
                    target="_blank"
                  >
                    Octent Automation
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {
                    accessToken ? (
                      <div className="for-desktop">
                        <DropDownMenu
                          accessToken={accessToken}
                          openLogoutModal={openLogoutModal}
                          isHomePage={BOOLEANS.TRUE}
                          handleModalStatus={handleModalStatus}
                        />
                      </div>
                    ) : (
                      <Button
                        className="btn-signin for-desktop-ipad"
                        variant="outline-success"
                        onClick={handleLoginRoute}
                      >
                        Sign In
                      </Button>
                    )
}
            </div>
          </Container>
        </Navbar>
      </div>
      <LogOutModal
        open={logoutModal}
        close={closeLogoutModal}
        logoutFuntion={logoutUser}
        headerText={LOGOUT_MODEL_HEADER}
        bodyText={LOGOUT_MODEL_BODY}
        confirmText={LOGOUT_MODEL_HEADER}
        imgPath={logoutIcon}
        cancelText={LOGOUT_MODEL_CANCEL}
      />
      <ChangePassword
        open={showchangePasswordModal}
        close={handleModalStatus}
      />
    </div>
  );
};

export default PublicNavBar;
