import React from 'react';
import { Col, Row } from 'react-bootstrap';
import trusted from '../../assets/img/icon-trusted.svg';
import ssl from '../../assets/img/icon-ssl.svg';
import anytime from '../../assets/img/icon-anytime.svg';

const PaymentSecure: React.FC = () => (
  <div className="secure-payment-sec">
    <h2 className="secure-payment-title">Easy and Secure Payment</h2>
    <p className="secure-payment-subtitle">
      We prioritize your convenience and security when it comes to making payments.
      <br />
      With our easy and secure payment options, you can enjoy a hassle-free transaction process.
    </p>
    <div className="secure-payment-wrapper">
      <Row className="secure-payment-cards m-0">
        <Col sm={4} xs={12} className="secure-payment-card-item">
          <img className="icon-trusted" src={trusted} alt="trusted" />
          <h5 className="payment-card-title">Trusted payment methods</h5>
          <p className="payment-card-des">Pay with ACH & credit card</p>
        </Col>
        <Col sm={4} xs={12} className="secure-payment-card-item">
          <img className="icon-trusted" src={ssl} alt="ssl" />
          <h5 className="payment-card-title">SSL Encrypted Payment</h5>
          <p className="payment-card-des">
            Your information is protected by 256-bit SSL encryption.
          </p>
        </Col>
        <Col sm={4} xs={12} className="secure-payment-card-item">
          <img className="icon-trusted" src={anytime} alt="anytime" />
          <h5 className="payment-card-title">Cancel Any Time</h5>
          <p className="payment-card-des">
            Canceling your subscription is quick and simple.
          </p>
        </Col>
      </Row>
    </div>
  </div>
);

export default PaymentSecure;
