import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { pagination } from '../../graphql/reactiveVariables';
import { SORT_BY } from '../../constants';
import { IPagination, TableHeadProps } from '../../types';
import SortIcon from './sortIcon';

function TableHead({ tableFields, handleSorting }: TableHeadProps) {
  const paginationData: IPagination = useReactiveVar(pagination);
  const { sortBy, sort } = paginationData;

  const onClick = async (item: any) => {
    if (sortBy === SORT_BY.ASC) {
      handleSorting({
        ...paginationData,
        sortBy: SORT_BY.DESC,
        sort: item.sortBy,
      });
    } else if (sortBy === SORT_BY.DESC) {
      handleSorting({
        ...paginationData,
        sortBy: SORT_BY.ASC,
        sort: item.sortBy,
      });
    }
  };

  return (
    <div className="thead">
      <div className="row tr">
        {tableFields.map((item: any) => (
          <div
            key={item?.name}
            className={`th flex-table-column-${item.size} ${item.className}`}
          >
            <span className="table-heading">
              <span>{item.name}</span>
              {item.sort && (
              <span className="icon-filter-custom" onClick={() => onClick(item)} data-testid="sortIcon">
                <SortIcon selected={sort} sortKey={item?.sortBy} sortBy={sortBy} />
              </span>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TableHead;
