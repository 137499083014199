import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { AssignedProbeRowProps } from '../../types';
import tankPic from '../../assets/img/tank-pic.png';
import ActionDropDown from '../tanks/actionDropDown';
import { checkString } from '../../utils/helpers';
import {
  ROUTE_PATH, UNASSIGN_MODEL_BODY, UNASSIGN_MODEL_CANCEL, UNASSIGN_MODEL_HEADER, REMOVE_TEXT
} from '../../constants';
import useLogout from '../../hooks/useLogout';
import LogOutModal from '../auth/model/LogoutModal';
import { UNASSIGN_PROBE } from '../../graphql/mutation';
import CustomLoader from '../shared/common/customLoader';
import DisabledProbeModal from './disabledProbeModal';

function AssignedProbeRow({ probe, setEditProbe, refetch }: AssignedProbeRowProps) {
  const navigate = useNavigate();
  const { openLogoutModal, closeLogoutModal, logoutModal } = useLogout();
  const [openDisableModal, setIsDisable] = useState(false);
  const [unassignProbe, { loading }] = useMutation(UNASSIGN_PROBE);
  const handleUnassign = async () => {
    try {
      await unassignProbe({ variables: { ProbeId: probe?.id } });
      await refetch({ ...probe, tank: null });
      closeLogoutModal();
    } catch (errors) {
      closeLogoutModal();
    }
  };

  const redirectToProbeId = () => {
    if (probe?.isGettingRealData) {
      navigate(`${ROUTE_PATH.USER_PROBES}/${probe?.id}`);
    } else {
      setIsDisable(true);
    }
  };
  if (loading) {
    return <CustomLoader />;
  }

  return (
    <div className="row tr" key={probe?.id}>
      <DisabledProbeModal isOpen={openDisableModal} onClose={() => setIsDisable(false)} />
      <div
        className="td flex-table-column-20"
        data-title="Probe ID"
        onClick={redirectToProbeId}
      >
        <div className="">
          <h5 className="listing-title">{checkString(probe?.probeId)}</h5>
        </div>
      </div>
      <div
        className="td flex-table-column-25"
        data-title="Probe Name"
        onClick={redirectToProbeId}
      >
        <div className="">
          <p className="listing-normal">{checkString(probe?.probeName)}</p>
        </div>
      </div>
      <div
        className="td flex-table-column-30"
        data-title="Assigned Tank"
      >
        <div className="first-column-wrapper">
          <span
            className="listing-normal pick-on-click"
          >
            {probe?.tank ? (
              <>
                <img
                  className="tank-pic"
                  src={tankPic}
                  alt="tank pic"
                />
                <p className="listing-normal">{probe?.tank?.name}</p>
              </>
            ) : '-'}
          </span>
        </div>
      </div>
      <div
        className="td flex-table-column-20"
        data-title="Status"
      >
        <div className="">
          <p className={`btn-status ${probe?.isGettingRealData ? 'active' : 'deactive'}`}>{probe?.isGettingRealData ? 'Online' : 'Offline'}</p>
        </div>
        {/* <div className="">
          <p className="btn-status deactive">Disabled</p>
        </div> */}
      </div>
      <div className="td flex-table-column-5">
        <div className="">
          <ActionDropDown
            deleteItem={openLogoutModal}
            editItem={() => setEditProbe(probe)}
            deleteName="Unassign"
            showDelete={!!probe?.tankId}
          />
        </div>
      </div>
      <LogOutModal
        open={logoutModal}
        logoutFuntion={handleUnassign}
        close={closeLogoutModal}
        headerText={UNASSIGN_MODEL_HEADER}
        bodyText={UNASSIGN_MODEL_BODY}
        confirmText={REMOVE_TEXT}
        cancelText={UNASSIGN_MODEL_CANCEL}
      />
    </div>
  );
}

export default AssignedProbeRow;
