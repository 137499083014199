import React, { useMemo } from 'react';
import {
  createSearchParams, useSearchParams, useNavigate, useLocation
} from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ExportDropDown from '../shared/common/exportDropDown';
import DateInput from '../shared/forms/datePicker';
import Select from '../shared/forms/select';
import {
  BOOLEANS, EXPORT_TO_CSV_CONFIG, EXPORT_TO_PDF_CONFIG, NUMBER, PARAMS, PROBE_TIMESTAMP,
  PROBE_TIMESTAMP_VALUE, TABULAR_COMPUTATION_FILTERS_SCHEMA, measureMentOptions, systemSensorTypeOptions
} from '../../constants';
import { pagination, selectedProbes } from '../../graphql/reactiveVariables';
import { capitalizeFirst, getTimeStampDateObject } from '../../utils/helpers';
import { IPagination, IProbeData } from '../../types';
import useGraphExport from '../../hooks/useGraphExport';
import CustomLoader from '../shared/common/customLoader';
import useTableExport from '../../hooks/useTableExport';

function FilterSection() {
  const cachedProbes: IProbeData[] = useReactiveVar(selectedProbes);
  const paginationData: IPagination = useReactiveVar(pagination);

  const { state = [], pathname } = useLocation();
  const [params] = useSearchParams();
  const searchParams = Object.fromEntries([...params]);
  const navigate = useNavigate();
  const { loading, exportGraph } = useGraphExport();
  const { exportTable } = useTableExport();

  const probeId = params.get(PARAMS.PROBE_ID);
  const probeIndex = state?.findIndex((i: any) => i.id === probeId);

  const {
    setValue, getValues, formState: { errors }, reset, trigger
  } = useForm({
    resolver: yupResolver(TABULAR_COMPUTATION_FILTERS_SCHEMA),
    shouldFocusError: BOOLEANS.FALSE,
    defaultValues: { measurement: searchParams?.measurement, sensorType: searchParams?.sensorType }
  });

  /* saving the probe values to the as default values in the form to show prefilled data when
  `probeId` dependency changes. */
  useMemo(() => {
    const probe = state?.filter((i: any) => i.id === probeId);
    if (probe) {
      reset({
        startTime: searchParams?.startTime,
        endTime: searchParams?.endTime,
        measurement: searchParams?.measurement
      });
    }
  }, [probeId]);

  /**
  * The handleChange function updates the value of a form input and  push values to the url params and
  * updating the cached data. */
  const handleChange = (name: any, value: string) => {
    setValue(name, value, { shouldValidate: true });
    state[probeIndex] = { ...state[probeIndex], startTime: getValues('startTime'), endTime: getValues('endTime') };
    cachedProbes[probeIndex] = { // updating the cached probes on dashboard
      ...cachedProbes[probeIndex], startTime: getValues('startTime'), endTime: getValues('endTime')
    };
    trigger(); // triggering the form to validate the fields
    navigate({
      pathname,
      search: createSearchParams(
        { ...searchParams, ...getValues() as any }
      ).toString()
    }, { state });
    selectedProbes(cachedProbes);
    pagination({ ...paginationData, page: NUMBER.ONE });
  };

  const handleExport = async (type: string) => {
    if (type === 'pdf') {
      exportGraph();
    } else {
      exportTable(type);
    }
  };

  return (
    <>
      {loading && <CustomLoader />}
      <div className="tabular-data-wrapper">
        <div className="tabular-data-title">
          {capitalizeFirst(searchParams?.dataFormat)}
          {' '}
          Data
        </div>
        <div className="tabular-data-filter">
          <form className="filter-by-sec">
              {searchParams?.computationType === 'base' && searchParams?.dataFormat === 'tabular' && (
              <div className="filter-by-first">
                <Select
                  value={getValues('measurement')}
                  placeholder="Measurement"
                  name="measurement"
                  options={measureMentOptions}
                  valueKey="value"
                  labelKey="label"
                  setValue={handleChange}
                />
              </div>
              )}
              {searchParams?.computationType === 'system' && searchParams?.dataFormat === 'tabular' && (
              <div className="filter-by-first">
                <Select
                  value={searchParams?.sensorType || 'all'}
                  placeholder="Measurement"
                  name="sensorType"
                  options={systemSensorTypeOptions}
                  valueKey="value"
                  labelKey="label"
                  setValue={handleChange}
                />
              </div>
              )}
              <div className="filter-by-second">
                <div className="date-select-custom date-time-select-custom only-change-datetime">
                  <DateInput
                    placeholder="Start Date & Time"
                    name="startTime"
                    iconClass="icon-calender on-input-icon-right"
                    valueFormat={PROBE_TIMESTAMP_VALUE}
                    dateFormat={PROBE_TIMESTAMP}
                    setValue={handleChange}
                    selected={getTimeStampDateObject(
                      getValues('startTime')
                    )}
                    error={errors?.startTime?.message}
                    maxDate={new Date()}
                    showTimeSelect
                  />
                </div>
              </div>
              <div className="filter-by-second">
                <div className="date-select-custom date-time-select-custom only-change-datetime">
                  <DateInput
                    placeholder="End Date & Time"
                    name="endTime"
                    iconClass="icon-calender on-input-icon-right"
                    setValue={handleChange}
                    valueFormat={PROBE_TIMESTAMP_VALUE}
                    dateFormat={PROBE_TIMESTAMP}
                    selected={getTimeStampDateObject(
                      getValues('endTime')
                    )}
                    error={errors?.endTime?.message}
                    minDate={getTimeStampDateObject(
                      getValues('startTime')
                    )}
                    maxDate={new Date()}
                    showTimeSelect
                  />
                </div>
              </div>
            
            <ExportDropDown
              onSelect={handleExport}
              config={searchParams?.dataFormat === 'tabular' ? EXPORT_TO_CSV_CONFIG : EXPORT_TO_PDF_CONFIG}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default FilterSection;
