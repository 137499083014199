import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { CreditCardFormProps } from '../../types';
import { NUMBER, keyPressHandleAlphabate } from '../../constants';

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  handleInputChange,
  cardError,
}) => (
  <Form className="modal-body-form" autoComplete="off">
    <div>
      <Form.Label>Card Holder Name</Form.Label>
      <input
        onKeyDown={keyPressHandleAlphabate}
        type="text"
        name="name"
        onChange={handleInputChange}
        placeholder="Card Holder Name"
        maxLength={NUMBER.FOURTY}
      />
    </div>
    <div>
      <Form.Label>Credit Card</Form.Label>
      <CardNumberElement
        className="stripe-card-number"
        options={{
          style: {
            base: {
              color: '#fff',
              fontSize: '14px',
            },
          },
        }}
        onChange={handleInputChange}
      />
      {cardError.cardNumber && (
        <span className="input-error-message" style={{ marginTop: '-20px' }}>
          {cardError.cardNumber}
        </span>
      )}
    </div>
    <Row className="mb-0">
      <Col sm={6} xs={12} className="mb-0">
        <Form.Label>Expiration Date</Form.Label>
        <CardExpiryElement
          className="stripe-card-number"
          options={{
            style: {
              base: {
                color: '#fff',
                fontSize: '14px',
              },
            },
          }}
          onChange={handleInputChange}
        />
        {cardError.cardExpiry && (
          <span className="input-error-message" style={{ marginTop: '-20px' }}>
            {cardError.cardExpiry}
          </span>
        )}
      </Col>
      <Col sm={6} xs={12} className="mb-0">
        <Form.Label>CVC</Form.Label>
        <CardCvcElement
          placeholder="cvv"
          className="stripe-card-number"
          options={{
            style: {
              base: {
                color: '#fff',
                fontSize: '14px',
              },
            },
          }}
          onChange={handleInputChange}
        />
      </Col>
    </Row>
    <div className="add-credit-address">
      <Form.Label>Address</Form.Label>
      <input
        type="text"
        name="address_line1"
        onChange={handleInputChange}
        placeholder="Enter address"
      />
    </div>
  </Form>
);

export default CreditCardForm;
