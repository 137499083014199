import React from 'react';
import { useNavigate } from 'react-router-dom';
import tankPic from '../../assets/img/tank-pic.png';
import { AssignedProbeItemProps } from '../../types';
import { ACTIVE_TAG, ROUTE_PATH } from '../../constants';
import { checkString, getCurrentUser } from '../../utils/helpers';

const AssignedProbItem = ({ data }: AssignedProbeItemProps) => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const probeURL = `${ROUTE_PATH.ADMIN_USER_MANAGEMENT}/${currentUser.id}/${ROUTE_PATH.PROBES_LISTING}/${data.id}`;

  return (
    <div className="row tr " onClick={() => navigate(probeURL)}>
      <div className="td flex-table-column-15">
        <div
          className="cursor-pointer"
        >
          <p className="listing-title">{checkString(data.probeId)}</p>
        </div>
      </div>
      <div className="td flex-table-column-25">
        <div className="">
          <p className="listing-normal">{checkString(data.probeName)}</p>
        </div>
      </div>
      <div className="td flex-table-column-25">
        <div className="">
          <p className="listing-normal">{checkString(data.user?.wineryName)}</p>
        </div>
      </div>
      <div className="td flex-table-column-25">
        <div className="first-column-wrapper">
          <img className="tank-pic" src={tankPic} alt="tank pic" />
          <h5 className="listing-normal">{checkString(data?.tank?.name)}</h5>
        </div>
      </div>
      <div className="td flex-table-column-10">
        <div className="">
          <p className={`btn-status ${data?.isGettingRealData ? 'active' : 'deactive'}`}>{data?.isGettingRealData ? 'Online' : 'Offline'}</p>
        </div>
      </div>
    </div>
  );
};

export default AssignedProbItem;
