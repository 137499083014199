import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BOOLEANS, ROUTE_PATH } from '../../constants';
import ChangePassword from '../shared/common/changePassword';

const ProfileHeader = () => {
  const navigate = useNavigate();

  const [showchangePasswordModal, setshowchangePasswordModal] = useState(
    BOOLEANS.FALSE
  );

  const handleModalStatus = () => setshowchangePasswordModal(!showchangePasswordModal);
  return (
    <>
      <div className="admin-heading">
        <div className="header-wrapper">
          <div className="header-left">
            <h1 className="admin-header-title mb-26">My Account</h1>
          </div>
          <div className="header-right mb-26 mobile-hidden">
            <Button className="btn-outline-border header-outline-btn edit-profile-btn" onClick={handleModalStatus}>
              Change Password
            </Button>
            <Button
              className="btn primary header-primary-btn edit-profile-btn"
              type="submit"
              onClick={() => navigate(ROUTE_PATH.USER_PROFILE_EDIT)}
            >
              Edit Profile
            </Button>
          </div>
        </div>
      </div>
      <ChangePassword
        open={showchangePasswordModal}
        close={handleModalStatus}
      />
    </>
  );
};

export default ProfileHeader;
