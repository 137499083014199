import { gql } from '@apollo/client';

export const GET_LATEST_REVIEWS_AND_LOCATIONS = gql`
  query {
    countries {
      awsRegion
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!, $captchaToken: String!) {
  login(email: $email, password: $password, captchaToken: $captchaToken) {
      message
      userInfo {
        id
        fullName
        email
        role
        token
        contactNumber
        wineryName
        isEmailVerified
        subscriptionId
        paymentStatus
        profileImg
        wineryProductionVolumeUnit
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation signUp(
    $fullName: String!
    $email: String!
    $password: String!
    $wineryName: String!
    $contactNumber: String!
    $wineryAddress: String!
    $wineryFiscalYearEndMonth: String!
    $wineryProductionVolume: Float!
    $differentBillingAddress: Boolean!
    $wineryProductionVolumeUnit: String!
    $billingAddress: String!
    $captchaToken :String!
  ) {
    signUp(
      fullName: $fullName
      email: $email
      password: $password
      wineryName: $wineryName
      contactNumber: $contactNumber
      wineryAddress: $wineryAddress
      wineryFiscalYearEndMonth: $wineryFiscalYearEndMonth
      wineryProductionVolume: $wineryProductionVolume
      differentBillingAddress: $differentBillingAddress
      wineryProductionVolumeUnit: $wineryProductionVolumeUnit
      billingAddress: $billingAddress
      captchaToken: $captchaToken
    ) {
      message
      userInfo {
        fullName
        email
        role
        token
        isEmailVerified
        wineryName
        paymentStatus
        wineryProductionVolumeUnit
      }
    }
  }
`;

export const UPDATE_EMAIL_MUTATION = gql`
  mutation changeEmailSignUp($email: String!) {
    changeEmailSignUp(email: $email) {
      message
    }
  }
`;

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($otp: String!) {
    verifyEmail(otp: $otp) {
      message
    }
  }
`;

export const RESEND_OTP_MUTATION = gql`
  mutation resendOtp {
    resendOtp {
      message
    }
  }
`;

export const ADD_NEW_TANK_MUTATION = gql`
  mutation addTank(
    $name: String!
    $capacity: String!
    $volume: Float!
    $location: String!
    $description: String!
  ) {
    addTank(
      name: $name
      capacity: $capacity
      volume: $volume
      location: $location
      description: $description
    ) {
      message
    }
  }
`;

export const PAYMENT_MUTATION = gql`
  mutation CreateSubscription(
    $totalProbe: Float!
    $subscriptionType: String!
    $isWineMakingPackage: Boolean!
    $paymentType: String!
    $paymentId: String!
    $accessToken: String!
  ) {
    createSubscription(
      totalProbe: $totalProbe
      subscriptionType: $subscriptionType
      isWineMakingPackage: $isWineMakingPackage
      paymentType: $paymentType
      paymentId: $paymentId
      accessToken: $accessToken
    ) {
      message
      secretToken
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logOut {
      message
    }
  }
`;

export const EDIT_TANK_MUTATION = gql`
  mutation updateTank(
    $id: String!
    $name: String!
    $capacity: String!
    $volume: Float!
    $location: String!
    $description: String!
  ) {
    updateTank(
      id: $id
      name: $name
      capacity: $capacity
      volume: $volume
      location: $location
      description: $description
    ) {
      message
    }
  }
`;

export const UPDATE_PAYMENT_STATUS = gql`
  mutation UpdatePaymentStatus(
    $status: String!
    $accessToken: String!
    $paymentId: String!
  ) {
    updatePaymentStatus(
      status: $status
      accessToken: $accessToken
      paymentId: $paymentId
    ) {
      message
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      userInfo {
        subscriptionId
        totalProbe
        paymentStatus
      }
    }
  }
`;

export const GET_USER_INFORMATION = gql`
  query GetUser {
    getUser {
      userInfo {
        subscriptionId
        totalProbe
        paymentStatus
        isEmailVerified
        contactNumber
        fullName
        wineryAddress
        wineryFiscalYearEndMonth
        wineryName
        wineryProductionVolume
        email
        wineryProductionVolumeUnit
        subscriptionType
        totalProbe
        lastAmountPaid
        isWineMakingPackage
        subscriptionPeriodEnd
        subscriptionPeriodStart
        profileImg
        isCancelSubscription
      }
    }
  }
`;

export const EMAIL_EXIST_CHECK = gql`
  mutation emailExist($email: String!) {
    emailExist(email: $email) {
      isEmailExist
    }
  }
`;

export const TRANSACTION_HISTORY_QUERY = gql`
  query transactionHistory($userId: String) {
    transactionHistory(userId: $userId) {
      data {
        amount
        createdAt
        invoiceId
        paymentStatus
        invoiceUrl
        transactionId
        isWineMakingPackage
      }
    }
  }
`;

export const GET_USER_CARDS = gql`
  query {
    allCards {
      data {
        id
        brand
        exp_month
        exp_year
        last4
        name
        fingerprint
        address_line1
      }
      defaultCard
    }
  }
`;

export const ADD_CARD_MUTATION = gql`
  mutation AddCard($token: String!) {
    addCard(token: $token) {
      message
    }
  }
`;

export const ASSIGN_PROBE_MUTATION = gql`
  mutation assignProbeByUser(
    $probeId: String!
    $probeName: String!
    $tankId: String!
    $description: String!
  ) {
    assignProbeByUser(
      probeId: $probeId
      probeName: $probeName
      tankId: $tankId
      description: $description
    ) {
      message
    }
  }
`;

export const REMOVE_CARD_MUTATION = gql`
  mutation RemoveCard($cardId: String!) {
    removeCard(cardId: $cardId) {
      message
    }
  }
`;

export const SET_DEFAULT_MUTATION = gql`
  mutation SetDefaultCard($cardId: String!) {
    setDefaultCard(cardId: $cardId) {
      message
    }
  }
`;

export const UPDATE_PROBE_MUTATION = gql`
  mutation updateProbeByUser(
    $probeId: String!
    $probeName: String
    $tankId: String
    $description: String
  ) {
    updateProbeByUser(
      probeId: $probeId
      probeName: $probeName
      tankId: $tankId
      description: $description
    ) {
      message
    }
  }
`;

export const LAST_TRANSACTION = gql`
  query {
    lastTransaction {
      data {
        amount
        paymentStatus
        createdAt
        invoiceId
        isWineMakingPackage
        created
      }
    }
  }
`;

export const SEND_PROBE_REQUEST = gql`
mutation SendProbeRequestToAdmin($subject: String!, $description: String!) {
  sendProbeRequestToAdmin(subject: $subject, description: $description) {
    message
  }
}
`;

export const GET_USER_DETAILS_FOR_UPDATE = gql`
  query GetUser {
    getUser {
      userInfo {
        email
        contactNumber
        fullName
        wineryName
        wineryProductionVolume
        wineryProductionVolumeUnit
        wineryFiscalYearEndMonth
        wineryAddress
        billingAddress
        differentBillingAddress
        profileImg
      }
    }
  }
`;

export const EDIT_USER_MUTATION = gql`
  mutation EditUser(
    $fullName: String!
    $contactNumber: String!
    $wineryName: String!
    $wineryAddress: String!
    $wineryProductionVolume: Float!
    $wineryProductionVolumeUnit: String!
    $differentBillingAddress: Boolean!
    $billingAddress: String!
    $wineryFiscalYearEndMonth: String!
    $profileImg: String
  ) {
    editUser(
      fullName: $fullName
      contactNumber: $contactNumber
      wineryName: $wineryName
      wineryAddress: $wineryAddress
      wineryProductionVolume: $wineryProductionVolume
      wineryProductionVolumeUnit: $wineryProductionVolumeUnit
      differentBillingAddress: $differentBillingAddress
      billingAddress: $billingAddress
      wineryFiscalYearEndMonth: $wineryFiscalYearEndMonth
      profileImg: $profileImg
    ) {
      message
    }
  }
`;

export const UNASSIGN_PROBE = gql`
  mutation UnassignProbe($ProbeId: String!) {
    unassignProbeByUser(ProbeId: $ProbeId) {
      message
    }
  }
`;

export const UPDATE_WINE_PACKAGE = gql`
mutation UpdateWinePackage {
  updateWinePackage {
    message
  }
}
`;

export const ADD_DASHBOARD_EVENT = gql`
mutation dashboardComputionEvent($event: String!, $description: String!, $id: String!, $sensorType: String!) {
  dashboardComputionEvent(event: $event, description: $description, id: $id, sensorType: $sensorType) {
    message
  }
}
`;

export const CANCEL_SUBSCRIPTION = gql`
mutation cancelSubscription {
  cancelSubscription {
    message
  }
}
`;

export const RENEW_SUBSCRIPTION_MUTATION = gql`
  mutation {
    renewSubscription{
      message
    }
  }
`;

export const GET_CONTACT_SUBMISSION = gql`
  mutation contactUsSubmisson(
    $message: String!
    $subject: String!
    $fullName: String!
    $email: String!
    $contactNumber: String!
  ) {
    contactUsSubmisson(
      message: $message
      subject: $subject
      fullName: $fullName
      email: $email
      contactNumber: $contactNumber
    ) {
      message
    }
  }
`;

export const GET_USER_HEADER = gql`
  query GetUser {
    getUser {
      userInfo {
        fullName
        wineryName
        profileImg
      }
    }
  }
`;
