import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ActionDropDownProps } from '../../types';
import { BOOLEANS } from '../../constants';

function ActionDropDown({
  deleteItem, editItem, deleteName = 'Remove', editName = 'Edit', showDelete = BOOLEANS.TRUE
}: ActionDropDownProps) {
  return (
    <div
      className="listing-normal text-center"
      onClick={(e:any) => {
        e.stopPropagation();
      }}
    >
      <DropdownButton
        className="icon-three-dot manage-three-dot"
        title=""
        data-testid="actionDropdown"
        rootCloseEvent="mousedown"
      >
        {editItem && <Dropdown.Item onClick={editItem}>{editName}</Dropdown.Item>}
        {showDelete && <><Dropdown.Item onClick={deleteItem}>{deleteName}</Dropdown.Item></>}
      </DropdownButton>
    </div>
  );
}

export default ActionDropDown;
