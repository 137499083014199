import React from 'react';
import { formatDate } from '../../../utils/dateHelper';
import { TabularDataRowProps } from '../../../types';

function BaseTableRow({ sensor }:TabularDataRowProps) {
  return (
    <div className="row tr" key={sensor?.id}>
      <div
        className="td flex-table-column-35"
        data-title="Time Stamp"
      >
        <div className="first-column-wrapper">
          <span
            className="pick-on-click"
          >
            <h5 className="listing-normal">{formatDate(sensor?.timestampDate, 'MMM DD, YYYY hh:mm A')}</h5>
          </span>
        </div>
      </div>
      <div
        className="td flex-table-column-30"
        data-title="Measurement"
      >
        <div className="">
          <p className="listing-normal text-capitalize">{sensor?.sensorType}</p>
        </div>
      </div>
      <div
        className="td flex-table-column-20"
        data-title="Sensor"
      >
        <div className="">
          <p className="listing-normal">{sensor?.sensor}</p>
        </div>
      </div>
      <div
        className="td flex-table-column-15"
        data-title="Reading"
      >
        <div className="">
          <p className="listing-normal">{sensor?.value}</p>
        </div>
      </div>
    </div>
  );
}

export default BaseTableRow;
