import React from 'react';
import { Button, Form } from 'react-bootstrap';
import uploadIcon from '../../../../assets/icons/upload-icon.svg';
import ModalWrapper from '../modalWrapper';
import useCsvUploader from '../../../../hooks/useCsvUploader';
import CustomLoader from '../customLoader';

interface UploadCsvProps {
    open: boolean;
    close: (value: boolean) => void;
    mutation: any;
    onSubmit: () => void;
    title: string;
    tankData: any;
    type: any;
  }
const UploadCsv = ({
  open,
  close,
  mutation,
  onSubmit,
  title,
  type,
}: UploadCsvProps) => {
  const {
    inputRef,
    fileName,
    loading,
    handleFileChange,
    handleUpload,
    openFilePicker,
    handleDownloadSample,
  } = useCsvUploader({
    mutation,
    onSubmit,
    type
  });

  return (
    <>
      <ModalWrapper show={open} handleClose={() => close(false)} title={title}>
        {loading && <CustomLoader />}
        <Form>
          <div className="modal-body-form row">
            <div className="modal-id">
              <Form.Group className="form-mt-space" controlId="name">
                <Form.Label>Upload File</Form.Label>
                <div className="fileUploadInput admin-fileUploadInput admin-file-width-height">
                  <input
                    ref={inputRef}
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept=".csv"
                  />
                  <Form.Control
                    className="form-input admin-layout admin-form-input"
                    name="name"
                    value={fileName}
                    placeholder="E.g.(file-abc.csv)"
                    onChange={() => {}}
                    onBlur={() => {}}
                    readOnly
                  />
                  <Button
                    className="text-capitalize"
                    onClick={openFilePicker}
                    type="button"
                  >
                    <img
                      className="upload-icon"
                      src={uploadIcon}
                      alt="Upload icon"
                    />
                    Browse File
                  </Button>
                </div>
                <h5
                  className="upload-info text-decoration mt-27"
                >
                  <span className="showHand" onClick={handleDownloadSample}>Download CSV sample file </span>
                </h5>
              </Form.Group>
            </div>
          </div>
          <div className="footer-modal">
            <Button
              onClick={handleUpload}
              disabled={!fileName}
              className="btn primary modal-btn-submit"
            >
              Upload
            </Button>
          </div>
        </Form>
      </ModalWrapper>
    </>
  );
};

export default UploadCsv;
