import React from 'react';

const SectionSeven = () => (
  <>
    <h3>Section 9 - Termination</h3>
    <p className="para">
      As a member of{' '}
      <a href="www.msprobe.com" target="_blank">
        www.msprobe.com
      </a>
      , you may cancel or terminate your account, associated email address
      and/or access to our Services by submitting a cancellation or termination
      request to.
    </p>
    <p className="para">
      As a member, you agree that Wine Technology Inc. may, without any prior
      written notice, immediately suspend, terminate, discontinue and/or limit
      your account, any email associated with your account, and access to any of
      our Services. The cause for such termination, discontinuance, suspension
      and/or limitation of access shall include, but is not limited to:
    </p>
    <div className="indent-sec">
      <p className="para">
        a) Any breach or violation of our Terms or any other incorporated
        agreement, regulation and/or guideline.
      </p>
      <p className="para">
        b) By way of requests from law enforcement or any other governmental
        agencies.
      </p>
      <p className="para">
        c) The discontinuance, alteration and/or material modification to our
        Services, or any part thereof.
      </p>
      <p className="para">
        d) Unexpected technical or security issues and/or problems.
      </p>
      <p className="para">e) Any extended periods of inactivity.</p>
      <p className="para">
        f) Any engagement by you in any fraudulent or illegal activities.
      </p>
      <p className="para">
        g) The nonpayment of any associated fees that may be owed by you in
        connection with your{' '}
        <a href="www.msprobe.com" target="_blank">
          www.msprobe.com
        </a>{' '}
        account Services.
      </p>
    </div>
    <p className="para">
      Furthermore, you herein agree that any and all terminations, suspensions,
      discontinuances, and or limitations of access for
    </p>
    <p className="para">
      Cause shall be made at our sole discretion and that we shall not be liable
      to you or any other third party with regards to the termination of your
      account, associated email address and/or access to any of our Services.
    </p>
    <p className="para">
      The termination of your account with{' '}
      <a href="www.msprobe.com" target="_blank">
        www.msprobe.com
      </a>{' '}
      shall include any and/or all of the following:
    </p>

    <div className="indent-sec">
      <p className="para">
        a) The removal of any access to all or part of the Services offered
        within{' '}
        <a href="www.msprobe.com" target="_blank">
          www.msprobe.com
        </a>.
      </p>
      <p className="para">
        b) The deletion of your password and any and all related information,
        files, and any such content that may be associated with or inside your
        account, or any part thereof.
      </p>
      <p className="para">
        c) The barring of any further use of all or part of our Services.
      </p>
    </div>
  </>
);

export default SectionSeven;
