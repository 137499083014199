import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import LineGraph from '../../graphs/LineGraphCard';
import { GRPAH_SENSOR_DATA } from '../../../graphql/queries/user/dashboard.queries';
import { getTimeStampDateObject, parseBaseGraphsData } from '../../../utils/helpers';
import { BOOLEANS, TOOLTIP } from '../../../constants';
import { BASIC_DASHBOARD } from '../../../constants/dashboard';
import CustomLoader from '../../shared/common/customLoader';

function GraphicalBaseComputation() {
  const [params] = useSearchParams();
  const searchParams = Object.fromEntries([...params]);
  const [temperature, setTemperature] = useState<any>([]);
  const [presssure, setPressure] = useState<any>([]);
  const [redox, setRedox] = useState<any>([]);

  const [tempLines, setTempLines] = useState<string[]>([]);
  const [densityLines, setDensityLines] = useState<string[]>([]);
  const [redoxLines, setRedoxLines] = useState<string[]>([]);

  const { loading } = useQuery(GRPAH_SENSOR_DATA, {
    variables: {
      sensorType: BASIC_DASHBOARD.TEMPERATURE,
      startDate: getTimeStampDateObject(searchParams?.startTime, BOOLEANS.TRUE),
      endDate: getTimeStampDateObject(searchParams?.endTime, BOOLEANS.TRUE),
      probeId: searchParams?.probeId
    },
    onCompleted: (res: any) => {
      parseBaseGraphsData(res, setTemperature);
      setTempLines(res?.dashboardSensor?.sensor);
    }
  });

  const { loading: isPressureDataLoading } = useQuery(GRPAH_SENSOR_DATA, {

    variables: {
      sensorType: BASIC_DASHBOARD.DENSITY,
      startDate: getTimeStampDateObject(searchParams?.startTime, BOOLEANS.TRUE),
      endDate: getTimeStampDateObject(searchParams?.endTime, BOOLEANS.TRUE),
      probeId: searchParams?.probeId
    },
    onCompleted: (res: any) => {
      parseBaseGraphsData(res, setPressure);
      setDensityLines(res?.dashboardSensor?.sensor);
    }
  });

  const { loading: isRedoxDataLoading } = useQuery(GRPAH_SENSOR_DATA, {

    variables: {
      sensorType: BASIC_DASHBOARD.REDOX,
      startDate: getTimeStampDateObject(searchParams?.startTime, BOOLEANS.TRUE),
      endDate: getTimeStampDateObject(searchParams?.endTime, BOOLEANS.TRUE),
      probeId: searchParams?.probeId
    },
    onCompleted: (res: any) => {
      parseBaseGraphsData(res, setRedox);
      setRedoxLines(res?.dashboardSensor?.sensor);
    }
  });

  return (
    <>
      {(loading) && <CustomLoader /> }
      <LineGraph
        datatoPlot={temperature}
        title="All Temp Sensors Over Time"
        removeEvent={() => { }}
        setEventModal={() => { }}
        lines={tempLines}
        yAxisLabel={['Temperature (°F)']}
        type="base"
        toolTipType={TOOLTIP.DETAILED}
        minRange={0}
        maxRange={100}
        showToolTipSelector
        selectDot
      />
      <LineGraph
        datatoPlot={presssure}
        title="All Density Sensors Over Time"
        lines={densityLines}
        yAxisLabel={['Density (grams/liter)']}
        type="base"
        setEventModal={() => { }}
        selectDot
      />
      <LineGraph
        datatoPlot={redox}
        title="All Redox Sensors Over Time"
        lines={redoxLines}
        yAxisLabel={['RedOx (millivolts)']}
        type="base"
        setEventModal={() => { }}
        selectDot
      />
    </>
  );
}

export default GraphicalBaseComputation;
