import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { FormStepperProps } from '../../../types';
import { signUpFormSteps } from '../../../constants/forms/static';
import { NUMBER } from '../../../constants';

function FormStepper({ step }: FormStepperProps) {
  return (
    <div className="two-step-wrap">
      <ProgressBar percent={step * NUMBER.FIFTY} filledBackground="#CC3333">
        {signUpFormSteps.map((i) => (
          <Step key={i}>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${
                  accomplished ? 'accomplished' : null
                }`}
              >
                {i}
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
}

export default FormStepper;
