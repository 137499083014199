import React, { useCallback, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import filterIcon from '../../../assets/icons/filter-k.svg';
import mapIcon from '../../../assets/icons/map.svg';
import { SIGNUP_BUISNESS_INFO_VALIDATION_SCHEMA } from '../../../constants/forms/validationSchema';
import Input from '../../shared/forms/input';
import Select from '../../shared/forms/select';
import Checkbox from '../../shared/forms/checkbox';
import { productionVolumeCapacity } from '../../../constants/forms/static';
import { FISCAL_YEAR_MONTH_FORMAT } from '../../../constants/formats';
import { BuisnessInfoType, BuisnessInfoProps, ShouldValidate } from '../../../types';
import { SIGNUP_MUTATION } from '../../../graphql/mutation';
import {
  getFiscalYearDateObject,
  saveSessions,
  saveUserInfo,
} from '../../../utils/helpers';
import CustomLoader from '../../shared/common/customLoader';
import DateInput from '../../shared/forms/datePicker';
import {
  BOOLEANS,
  INPUT_ALPHANUMERIC,
  INPUT_NUMERIC,
  INPUT_WINERY_ADDRESS,
  NUMBER,
  ROUTE_PATH,
} from '../../../constants';
import IagreeCheckBox from '../../shared/forms/IagreeCheckBox';
import AddressInput from '../../shared/forms/addressInput';

function BuisnessInfo({
  step2Values,
  step1Values,
  setStep2Values,
}: BuisnessInfoProps) {
  const navigate = useNavigate();
  const minFiscalDate = new Date();
  minFiscalDate.setMonth(minFiscalDate.getMonth() + 1);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: step2Values as BuisnessInfoType,
    // @ts-ignore
    resolver: yupResolver(SIGNUP_BUISNESS_INFO_VALIDATION_SCHEMA),
    shouldFocusError: false,
  });

  const [searchParams] = useSearchParams();
  const step = Number(searchParams.get('step'));

  const [signUp, { loading }] = useMutation(SIGNUP_MUTATION);

  useEffect(
    () => () => {
      setStep2Values(getValues());
    },
    []
  );

  const onSubmitHandler = handleSubmit(async (values: FieldValues) => {
    if (!executeRecaptcha) {
      return;
    }
    const captchaToken = await executeRecaptcha();
    const res = await signUp({
      variables: {
        captchaToken,
        ...step1Values,
        ...values,
        wineryProductionVolume: parseFloat(values?.wineryProductionVolume),
        billingAddress: values?.billingAddress || '',
      },
    });
    if (res?.data) {
      const {
        email,
        token,
        fullName,
        wineryName,
        role,
        wineryProductionVolumeUnit,
      } = res.data?.signUp?.userInfo;
      saveSessions({
        token,
        email,
        fullName,
        wineryName,
        role,
        wineryProductionVolumeUnit,
      });
      navigate(ROUTE_PATH.VERIFY_OTP);
      toast.success(res?.data?.signUp?.message);
      reset();
      saveUserInfo(res.data?.signUp?.userInfo);
    }
  });

  const handleAddressChange = useCallback((name:any, value:string, options?: ShouldValidate) => {
    setValue(name, value, options);
  }, []);

  if (!step1Values?.email) {
    return <Navigate to={ROUTE_PATH.SIGNUP_STEP_1} />;
  }

  return (
    <>
      {loading && <CustomLoader />}
      <Form onSubmit={onSubmitHandler}>
        <Row>
          <Col xs={12} sm={12}>
            <Input
              type="text"
              name="wineryName"
              label="Winery Name"
              placeholder="Enter winery name"
              refValues={register}
              error={errors?.wineryName?.message}
              setValue={setValue}
              iconClass="on-input-icon-left"
              icon={<img src={filterIcon} alt="filter icon" />}
              valuePattern={INPUT_ALPHANUMERIC}
              maxLength={50}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} className="winery-address">
            <AddressInput
              type="text"
              name="wineryAddress"
              label="Winery Address"
              placeholder="Enter winery address"
              refValues={register}
              setValue={handleAddressChange}
              setError={setError}
              error={errors?.wineryAddress?.message}
              iconClass="on-input-icon-left"
              icon={<img src={mapIcon} alt="filter icon" />}
              valuePattern={INPUT_WINERY_ADDRESS}
              maxLength={50}
              getValue={getValues}
              isAddressIcon={BOOLEANS.TRUE}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            <Checkbox
              name="differentBillingAddress"
              label="Different billing address?"
              refValues={register}
              setValue={setValue}
            />
          </Col>
        </Row>
        {getValues('differentBillingAddress') && (
          <Row>
            <Col xs={12} sm={12}>
              <AddressInput
                type="text"
                name="billingAddress"
                label="Billing Address"
                placeholder="Enter billing address"
                refValues={register}
                setValue={setValue}
                setError={setError}
                error={errors?.billingAddress?.message}
                iconClass="on-input-icon-left"
                icon={<img src={mapIcon} alt="filter icon" />}
                valuePattern={INPUT_WINERY_ADDRESS}
                maxLength={50}
                getValue={getValues}
                isAddressIcon={BOOLEANS.TRUE}
              />
            </Col>
          </Row>
        )}
        <Row className="m-0 mt-10">
          <Col xs={12} sm={6} className="first-50">
            <Select
              options={productionVolumeCapacity}
              placeholder="Select units"
              value={getValues('wineryProductionVolumeUnit')}
              name="wineryProductionVolumeUnit"
              icon={<img src={filterIcon} alt="filter icon" />}
              iconClass="on-input-icon-left"
              label="Winery Production Volume"
              error={errors?.wineryProductionVolumeUnit?.message}
              setValue={setValue}
            />
          </Col>
          <Col xs={12} sm={6} className="second-50">
            <Input
              wrapperClass=""
              inputClass="form-mt-space"
              type="text"
              name="wineryProductionVolume"
              label="Volume"
              placeholder="Enter volume"
              refValues={register}
              setValue={setValue}
              error={errors?.wineryProductionVolume?.message}
              valuePattern={INPUT_NUMERIC}
              maxLength={NUMBER.EIGHT}
            />
          </Col>
        </Row>
          {step === NUMBER.TWO ? (
            <div className="sign-up-note">
              <p>
                <span>Note: </span>Winery production volume will be your preferred
                unit for tank capacity.
              </p>
          </div>
          ) : null}
        <Row>
          <Col xs={12} sm={12}>
            <DateInput
              dateFormat={FISCAL_YEAR_MONTH_FORMAT}
              label="Winery Fiscal Year End Month"
              name="wineryFiscalYearEndMonth"
              setValue={setValue}
              iconClass="icon-calender on-input-icon-left"
              error={errors?.wineryFiscalYearEndMonth?.message}
              minDate={minFiscalDate}
              selected={getFiscalYearDateObject(
                getValues('wineryFiscalYearEndMonth')
              )}
              showMonthYearPicker
              placeholder="MM/DD"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            <IagreeCheckBox register={register} />
          </Col>
        </Row>
        <div className="login-submit">
          <Link
            to={ROUTE_PATH.SIGNUP_STEP_1}
            className="btn-outline-border login-previous"
            onClick={() => setStep2Values(getValues())}
          >
            PREVIOUS
          </Link>
          <Button
            type="submit"
            className="btn primary w-100"
            disabled={Object.keys(errors).length > 0 || !isValid}
          >
            SIGN UP
          </Button>
        </div>
      </Form>
    </>
  );
}

export default BuisnessInfo;
