import React, { useEffect } from 'react';
import {
  Form, Col, Row, Button
} from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import Input from '../../shared/forms/input';
import { FORGOT_PASSWORD_VALIDATION_SCHEMA } from '../../../constants/forms/validationSchema';
import { UPDATE_EMAIL_MUTATION } from '../../../graphql/mutation';
import ModalWrapper from '../../shared/common/modalWrapper';
import { getSession, saveSessions } from '../../../utils/helpers';
import { USER_INFO } from '../../../constants/forms/keys';
import CustomLoader from '../../shared/common/customLoader';

function UpdateEmailModal({ show, handleClose, oldEmail }: any) {
  const session = getSession(USER_INFO);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FORGOT_PASSWORD_VALIDATION_SCHEMA),
    shouldFocusError: false,
    defaultValues: { email: oldEmail },
  });

  const [updateEmail, { loading }] = useMutation(UPDATE_EMAIL_MUTATION);

  const onSubmitHandler = handleSubmit(async (values: FieldValues) => {
    const res = await updateEmail({
      variables: { ...values, accessToken: session?.token },
    });
    reset();
    saveSessions({ ...session, ...values });
    toast.success(res.data?.changeEmailSignUp?.message);
    handleClose();
  });

  useEffect(() => {
    setValue('email', oldEmail);
  }, [oldEmail]);

  return (
    <ModalWrapper show={show} handleClose={handleClose} title="Edit Email">
      {loading && <CustomLoader />}
      <Form onSubmit={onSubmitHandler}>
        <Row className="modal-body-form">
          <Col xs={12} sm={12}>
            <Input
              label="Email Address"
              name="email"
              placeholder="Enter email address"
              refValues={register}
              setValue={setValue}
              error={errors?.email?.message}
            />
          </Col>
        </Row>
        <div className="footer-modal">
          <Button
            disabled={oldEmail === getValues('email')}
            type="submit"
            className="btn primary modal-btn-submit"
          >
            Update
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
}

export default UpdateEmailModal;
