import { getSession } from '../utils/helpers';
import { ROUTE_PATH } from './routes';
import { USER_INFO } from './forms/keys';

const accessToken = getSession(USER_INFO);
const { wineryProductionVolumeUnit } = accessToken || {};

export const BOOLEANS = {
  TRUE: true,
  FALSE: false,
};

export const EMPTY_TANK = {
  name: '',
  capacity: wineryProductionVolumeUnit || '',
  location: '',
  description: '',
};

export const SUBSCRIPTION_TYPE = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly',
};

export const PAYMENT_STATUS = {
  PAID: 'paid',
  OPEN: 'open',
  PROGRESS: 'progress',
};
export const AUTO_CLOSE = { autoClose: 5000 };

export const LOGOUT_MODEL_HEADER = 'Log Out';
export const LOGOUT_MODEL_CANCEL = 'No, Stay';
export const RENEW_TEXT = 'Renew';
export const LOGOUT_MODEL_BODY = 'Are you sure you want to log out from MSP?';
export const SUBSRIPTION_MODEL_HEADER = "We're sorry to see you go!";
// eslint-disable-next-line max-len
export const SUBSRIPTION_MODEL_BODY = 'Are you sure want to cancel the subscription plan? Your subscription will stop at the end of the current billing cycle.';
export const CANCEL_TEXT = 'cancel';
export const ACTIVE_TEXT = 'Activated';
export const INACTIVE_TEXT = 'inactive';
export const SCREEN_SIZE = {
  xs: 'xs',
  lg: 'lg',
  md: 'md',
  xl: 'xl',
};
export const ACTIVE_TAG = 'Active';
export const INACTIVE_TAG = 'Disabled';
export const DEACTIVATED_TAG = 'Deactivated';
export const INACTIVE_STATUS = 'inActive';
export const DELETE_CARD_MODEL_HEADER = 'Delete Credit Card';
export const DELETE_CARD_MODEL_BODY = 'Are you sure want to delete your credit card.';
export const DELETE_TEXT = 'delete';
export const ACTIVATE_TEXT = 'Activate';
export const DEACTIVATE_TEXT = 'Deactivate';

export const EMPTY_PROBE = {
  probeName: '',
  probeId: '',
  tankId: '',
  description: '',
};

export const menuConfig = {
  user: [
    {
      lable: 'Dashboard',
      class: '',
      route: ROUTE_PATH.USER_DASHBOARD,
      iconClass: 'icon-dashboard',
    },
    {
      lable: 'Tank Catalog',
      class: '',
      route: ROUTE_PATH.USER_TANKS,
      iconClass: 'icon-tank',
    },
    {
      lable: 'Probe Catalog',
      class: '',
      route: ROUTE_PATH.USER_PROBE_LISTING,
      iconClass: 'icon-probes-catalog',
    },
  ],
  admin: [
    {
      lable: 'Dashboard',
      class: '',
      route: ROUTE_PATH.ADMIN_DASHBOARD,
      iconClass: 'icon-dashboard',
    },
    {
      lable: 'User Management',
      class: '',
      route: ROUTE_PATH.ADMIN_USER_MANAGEMENT,
      iconClass: 'icon-user-magmt',
    },
    {
      lable: 'Probe Catalog',
      class: '',
      route: ROUTE_PATH.ADMIN_PROBE_LISTING,
      iconClass: 'icon-probes-catalog',
      // icon: probeIcon,
    },
    {
      lable: 'Subscription',
      class: '',
      route: ROUTE_PATH.ADMIN_SUBSCRIPTION,
      iconClass: 'icon-subscription',
    },
  ],
};

export const DELETE = 'delete';
export const DISABLE = 'disable';
export const DISABLED_TAG = 'Disabled';
export const YES = 'Yes';
export const NO = 'No';
export const PAID = 'paid';
export const CARD_DEFAULT_HEADER = 'Mark as Default Card';
export const CARD_DEFAULT_BODY = 'Are you sure to make this your Default Card for payment.';
export const CONFIRM_TEXT = 'Confirm';
export const FORM_SUBMITTED = 'form submitted';
export const DATE_TIME_FORMATE = 'MMM D, YYYY [at] hh:mm A';
export const REPAY = 'REPAY';
export const GO_TO_DASHBOARD = 'Go to dashboard';
export const PROBE_MODEL_ID = 'probe_model_id';
export const NETWORK_ONLY = 'network-only';
export const API_ISSUE = 'Api issue, Something went wrong.';
export const ASSIGEND = 'Assigned';
export const NOT_ASSIGNED = 'Not Assigned';
export const SAMPLE_FILE = 'sample_data.csv';
export const US_REGION = 'us-east-1';
export const AWS_ACL_CONSTANT = 'public-read';
export const UNASSIGN_MODEL_HEADER = 'Unassign Probe';
export const UNASSIGN_MODEL_BODY = 'Are you sure you want to unassign this probe from the tank?';
export const REMOVE_TEXT = 'REMOVE';
export const UNASSIGN_MODEL_CANCEL = 'No, Keep It';
export const SAMPLE_CSV_PROBE_DATA = [['Email Id', 'Probe Id'],
  ['john_doe@example.com', '123'], ['jane_smith@example.com', '456']];
export const SAMPLE_CSV_TANK_DATA = [['Name', 'Capacity', 'Location', 'Description', 'Volume'],
  ['Hari', 'liter', 'noida', 'Lorem ipsum dolor sit amet.', '100'],
  ['H-1', 'liter', 'N-d', 'Lorem ipsum ', '100']];
export const COMPUTATION_TYPE = 'computationType';
export const BASE = 'base';
export const SYSTEM = 'system';
export const SUBSRIPTION_RENEW_MODEL_HEADER = 'Subscription Renewal Confirmation';
export const SUBSRIPTION_RENEW_MODEL_BODY = `Are you certain about renewing your 
subscription plan? Once confirmed, your subscription will begin immediately.`;
export const PROBE_NAME = 'PROBE NAME';
export const ASSIGEND_TANK = 'ASSIGNED TANK';
export const WINERY_NAME = 'WINERY NAME';
export const TEXT_CENTER_CLASS = 'text-center';
export const NO_KEEP_IT = 'No, keep it';
export const YES_REMOVE = 'Yes, Remove';
export const LITER_UNIT = 'liter';
export const NINE_LE_CASE = '9le cases';
export const DELETE_MODEL_HEADER = 'Delete Probe';
export const DELETE_MODEL_BODY = 'Are you sure want to Delete this probe from tank?';
export const DEACTIVE_MODEL_HEADER = 'Change Probe Status';
export const DEACTIVE_MODEL_BODY = 'Are you sure want to change status of this probe?';
export const DEACTIVE_TEXT = 'Deactive';

export const PROBE_STATUS = {
  INACTIVE: 'inActive',
  ACTIVE: 'active',
};

export const TIME_DATE_FORMATE = 'MM/DD h:mm A';
