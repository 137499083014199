/**
 * The useResponsive function is a custom React hook that detects the screen resolution and updates the
 * state accordingly.
 * @returns The `useResponsive` custom hook returns an object containing the current state of the
 * screen resolutions. The object has three properties: `isMobile`, `isTablet`, and `isDesktop`, which
 * indicate whether the screen is currently in a mobile, tablet, or desktop view, respectively.
 */
import { useEffect, useState } from 'react';
import { NUMBER } from '../constants';

const useResponsive = () => {
  // screen resolutions
  const [state, setState] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    // update the state on the initial load
    onResizeHandler();

    // assign the event
    Setup();

    return () => {
      // remove the event
      Cleanup();
    };
  }, []);

  // update the state on window resize
  const onResizeHandler = () => {
    const isMobile = window.innerWidth <= NUMBER.MOBILE_WIDTH;
    const isTablet = window.innerWidth >= NUMBER.MOBILE_WIDTH && window.innerWidth <= NUMBER.TABLET_WIDTH;
    const isDesktop = window.innerWidth > NUMBER.TABLET_WIDTH;

    setState({ isMobile, isTablet, isDesktop });
  };

  // debounce the resize call
  //   const debouncedCall = useDebounce(onResizeHandler, 500);
  let filterTimeout: any; // using debouncing for search
  const debouncedCall = () => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      onResizeHandler();
    }, NUMBER.FIVE_HUNDRED);
  };

  // add event listener
  const Setup = () => {
    window.addEventListener('resize', debouncedCall, false);
  };

  // remove the listener
  const Cleanup = () => {
    window.removeEventListener('resize', debouncedCall, false);
  };

  return state;
};

export default useResponsive;
