import React from 'react';
import { Navigate, Outlet, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Logo from '../../assets/img/logo.svg';
import loginFrame from '../../assets/img/login-frame.png';
import loginShadow from '../../assets/img/login-left-shadow.png';
import loginBottomLeft from '../../assets/img/onboarding-bottom-left.png';
import loginBottomRight from '../../assets/img/onboarding-bottom-right.png';
import ScrollToTop from '../../components/shared/common/scrollToTop';
import { getSession } from '../../utils/helpers';
import { ROUTE_PATH, USER_INFO } from '../../constants';

function AuthLayout() {
  const user = getSession(USER_INFO);

  if (user && user?.isEmailVerified) {
    return <Navigate to={ROUTE_PATH.USER_DASHBOARD} />;
  }

  return (
    <div className="onboarding">
      <ScrollToTop />
      <div className="onboarding-container">
        <img
          className="onboarding-frame"
          src={loginFrame}
          alt="onboarding-frame"
        />
        <img
          className="onboarding-shadow"
          src={loginShadow}
          alt="onboarding-shadow"
        />
        <Row className="m-0 login-form">
          <Col sm={6} className="p-0 onboarding-left">
            <Link to={ROUTE_PATH.HOME_PAGE}>
              <img src={Logo} alt="Logo" className="logo" />
            </Link>
            <h1 className="login-title">
              Providing Unparalleled Knowledge For Beverage Producers
            </h1>
            <p className="login-info">
              Utilizing a patent-pending combination of sanitary stainless steel
              and electronics, what happens inside a tank is no longer a
              mystery.
            </p>
          </Col>
          <Outlet />
        </Row>
      </div>
      <div className="onboarding-bottom-wrap">
        <img
          className="onboarding-bottom"
          src={loginBottomLeft}
          alt="onboarding-bottom"
        />
        <img
          className="onboarding-bottom"
          src={loginBottomRight}
          alt="onboarding-bottom"
        />
      </div>
    </div>
  );
}

export default AuthLayout;
