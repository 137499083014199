import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProbeCountProps } from '../../types';
import bgRight from '../../assets/img/removebg-preview.png';
import { ROUTE_PATH } from '../../constants';

function ProbeCount({ assignedCount, availableCount, attachedCount }: ProbeCountProps) {
  const navigate = useNavigate();
  return (
    <div className="card-right dashboard-card-right cursor-pointer" onClick={() => navigate(ROUTE_PATH.USER_PROBE_LISTING)}>
      <img className="dashboard-card-right-bg" src={bgRight} alt="right dashboard bg" />
      <div className="card-data-sec">
        <div className="card-num">{assignedCount}</div>
        <div className="card-total">Total Probes Assigned by Admin</div>
      </div>
      <div className="admin-main-subtitle">
        <div className="card-data-subsec">
          <div className="card-num">{attachedCount}</div>
          <div className="card-total">Total Attached Probes </div>
        </div>
        <div className="card-data-subsec">
          <div className="card-num">{availableCount}</div>
          <div className="card-total">Total Available Probes </div>
        </div>
      </div>
    </div>
  );
}

export default ProbeCount;
