import React from 'react';

const SectionOne = () => (
  <>
    <h3>Section 1 - General Terms</h3>
    <p className="para">
      By agreeing to these Terms, you represent that you are at least the
      age of majority in your state or province of residence, or that you
      are the age of majority in your state or province of residence and you
      have given us your consent to allow any of your minor dependents to
      use this Site.
    </p>
    <p className="para">
      You may not use our products or Site for any illegal or unauthorized
      purpose nor may you, in the use of our products or Site, violate any
      laws in your jurisdiction (including but not limited to motor vehicle
      laws).
    </p>
    <p className="para">
      You must not transmit any worms or viruses or any code of a
      destructive nature.
    </p>
    <p className="para">
      A breach or violation of any of the Terms will result in an immediate
      termination of your account and right to use our Service.
    </p>
    <p className="para mb-30">
      We have the right, but not the obligation, to take any of the
      following actions in our sole discretion at any time and for any
      reason without giving you any prior notice:
    </p>
    <div className="indent-sec">
      <p className="indent-num">
        1. Restrict, suspend or terminate your access to all or any part of
        our Site.
      </p>
      <p className="indent-num">
        2. Change, suspend or discontinue all or any part of our products or
        Site.
      </p>
      <p className="indent-num">
        3. Refuse, move, or remove any content that is available on all or
        any part of our Site.
      </p>
      <p className="indent-num">4. Deactivate or delete your accounts.</p>
      <p className="indent-num mb-50">
        5. Establish general practices and limits concerning use of our
        Site.
      </p>
    </div>
    <p className="para">
      You agree that we will not be liable to you or any third party for
      taking any of these actions.
    </p>
    <p className="para">
      You understand and agree that our Site may include communications such
      as service announcements and administrative or legal notices from us.
      Please note that you cannot opt out of receiving these notices.
    </p>
    <p className="para">
      You understand that your content (not including credit card
      information), may be transferred unencrypted and involve (a)
      transmissions over various networks; and (b) changes to conform and
      adapt to technical requirements of connecting networks or devices.
      Credit card information is always encrypted during transfer over
      networks.
    </p>
    <p className="para">
      You agree not to reproduce, duplicate, copy, sell, resell or exploit
      any portion of the Site, use of the Site, or access to the Site or any
      contact on the Site, without express written permission by us.
    </p>
    <p className="para">
      You may not modify, publish, transmit, reverse engineer, participate
      in the transfer or sale, create derivative works, or in any way
      exploit any of the content, in whole or in part, found on the Site.
      MSPcontent is not for resale. Use of the Site does not entitle users
      to make any unauthorized use of any protected content, and in
      particular you will not delete or alter any proprietary rights or
      attribution notices in any content. You will use protected content
      solely for your personal use, and will make no other use of the
      content without the express written permission of MSP and the
      copyright owner. You agree that you do not acquire any ownership
      rights in any protected content. We do not grant you any licenses,
      express or implied, to the intellectual property of MSP or our
      licensors except as expressly authorized by these Terms.
    </p>
  </>
);

export default SectionOne;
