import React from 'react';
import { Row } from 'react-bootstrap';
import { Navigate, Outlet, Link } from 'react-router-dom';
import UserNavbar from '../../components/shared/common/userNavbar';
import UserSideBar from '../../components/sidebar/userSideBar';
import Logo from '../../assets/img/logo.svg';
import Footer from '../../components/footer';
import { getSession } from '../../utils/helpers';
import {
  BOOLEANS, ROLE, ROUTE_PATH, USER_INFO
} from '../../constants';
import { UserLayoutProps } from '../../types/index';

function UserLayout({ hideNavBar = BOOLEANS.FALSE }: UserLayoutProps) {
  const user = getSession(USER_INFO);
  const isAdmin = user?.role === ROLE.ADMIN;

  if (!user || (user && !user?.isEmailVerified)) {
    return <Navigate to={ROUTE_PATH.LOGIN} />;
  }

  return (
    <>
      <div className="admin-dashaboard">
        <Row className="m-0">
          <div className={`admin-sidebar ${isAdmin ? 'admin-dashboard-sidebar' : ''}`}>
            <div className="admin-sidebar-wraper">
              <div className="sidebar-logo">
                <Link to={ROUTE_PATH.USER_DASHBOARD}>
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <UserSideBar />
            </div>
            <div className="sidebar-bottom">
              <p className="sidebar-bottom-tag">Multi-Sensor Probe</p>
              <h6 className="sidebar-bottom-title">
                Unlock the art
                {' '}
                <br />
                of winemaking
                {' '}
                <br />
                with MSP
              </h6>
            </div>
          </div>
          <div className="admin-dashboard-right">
            {!hideNavBar && <UserNavbar showLogo={false} />}
            <div className="admin-right-main">
              <Outlet />
              <Footer />
            </div>
          </div>
        </Row>
      </div>
    </>
  );
}

export default UserLayout;
