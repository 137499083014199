import React from 'react';
import { NUMBER, formatDate, priceFormate } from '../../constants';
import dropDown from '../../assets/icons/download.svg';
import { handleDownload } from '../../utils/helpers';
import TableHead from '../../components/table/tableHead';
import noUserPic from '../../assets/img/no-user.svg';
import {
  ADMIN_TRANSACTION_HISTORY_TABLE_HEADS,
  TRANSACTION_HISTORY_TABLE_HEADS,
} from '../../constants/tableConfig';
import { TransactionHistoryProps } from '../../types';
import CustomLoader from '../../components/shared/common/customLoader';
import EmptyState from '../../components/shared/common/emptyState';

const UserTransactionHistory = ({
  transactionData,
  isAdmin,
  loading,
}: TransactionHistoryProps) => (
  <div className="list-container service-list-container">
    <div className="table-wrapper mobile-optimised">
      {loading && <CustomLoader />}
      <TableHead
        tableFields={
          isAdmin
            ? ADMIN_TRANSACTION_HISTORY_TABLE_HEADS
            : TRANSACTION_HISTORY_TABLE_HEADS
        }
        handleSorting={() => { }}
      />
      {transactionData.length === 0 && !loading && (
      <EmptyState
        title="No transaction history found"
        subtitle="There is no any transaction history found on this account."
        imgSrc={noUserPic}
      />
      )}
      <div className="tbody transaction-history-mobile">
        {transactionData?.map((item: any) => (
          <div className="row tr" key={item.transactionId}>
            <div className="td flex-table-column-25" data-title="Transaction Id">
              <p className="listing-normal">{item.transactionId}</p>
            </div>
            <div
              className="td flex-table-column-15"
              data-title="Date & time"
            >
              <div className="">
                <p className="listing-normal">{formatDate(item.createdAt)}</p>
              </div>
            </div>
            <div
              className={`td flex-table-column-${isAdmin ? NUMBER.FIFTEEN : NUMBER.TEN
              }`}
              data-title="Plan Amount"
            >
              <p className="listing-normal">{priceFormate(item.amount)}</p>
            </div>
            <div
              className={`td flex-table-column-${isAdmin ? NUMBER.FIFTEEN : NUMBER.TEN
              }`}
              data-title="Add-ons"
            >
              <p className="listing-normal">{ item?.isWineMakingPackage ? 'Yes' : 'No'}</p>
            </div>
            <div className="td flex-table-column-15" data-title="Payment type">
              <p className="listing-normal">
                {item.paymentType || 'Subscription'}
              </p>
            </div>
            <div className="td flex-table-column-15" data-title="Status">
              <div className="">
                <p className="btn-status active">{item.paymentStatus}</p>
              </div>
            </div>
            {!isAdmin && (
              <div className="td flex-table-column-10" data-title="Invoice">
                <div
                  className="text-center"
                  onClick={() => handleDownload(item.invoiceUrl)}
                >
                  <img src={dropDown} alt="Download Icon" />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default UserTransactionHistory;
