import { NUMBER } from './number';
import { BOOLEANS } from './static';

export const FISCAL_YEAR_MONTH_FORMAT = 'MM/yy';
export const FISCAL_YEAR_VALUE_FORMAT = 'MM/YYYY';

export const PROBE_TIMESTAMP = 'MM/dd/yyyy hh:mm a';
export const PROBE_TIMESTAMP_VALUE = 'MM/DD/YYYY hh:mm A';
export const DASHBOARD_YAXIS_FORMAT = 'DD/MM hh:mm';
export const MMMM_DO_YYYY = 'MMMM Do YYYY';
export const H_MM_A = 'h:mm A';
export const AD_BOTTOM_SLIDER_SETTINGS = {
  centerPadding: '15',
  dots: BOOLEANS.TRUE,
  infinite: BOOLEANS.TRUE,
  speed: NUMBER.FIVE_HUNDRED,
  slidesToShow: NUMBER.TWO,
  slidesToScroll: NUMBER.ONE,
  autoplay: BOOLEANS.TRUE,
  autoplaySpeed: NUMBER.FIVE_THOUSAND,
  initialSlide: NUMBER.ZERO,
  responsive: [
    {
      breakpoint: NUMBER.THOUSAND_TWENTY_FOUR,
      settings: {
        slidesToShow: NUMBER.ONE,
        slidesToScroll: NUMBER.ONE,
        infinite: BOOLEANS.TRUE,
        dots: BOOLEANS.TRUE,
      },
    },
    {
      breakpoint: NUMBER.SIX_HUNDRED,
      settings: {
        slidesToShow: NUMBER.ONE,
        slidesToScroll: NUMBER.ONE,
        initialSlide: NUMBER.TWO,
      },
    },
    {
      breakpoint: NUMBER.FOUR_EIGHTY,
      settings: {
        slidesToShow: NUMBER.ONE,
        slidesToScroll: NUMBER.ONE,
      },
    },
  ],
};
