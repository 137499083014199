import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import {
  BOOLEAN_VALUES, BUTTON, NUMBER, ROUTE_PATH, USER_FULLNAME, USER_MANAGEMENT
} from '../../constants';
import { GET_USER_LIST_BY_ID } from '../../graphql/queries/admin.queries';
import CustomLoader from '../shared/common/customLoader';
import DisableUserModal from './userListing/disableUserModal ';
import DeleteUserModal from './userListing/deleteUserModal';
import SubscriptionPlan from '../userProfile/SubscriptionPlan';
import UserDetails from '../userProfile/UserDetails';
import Breadcrumbs from '../shared/common/breadCrumbs';

function UserProfileDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDisableOpen, setIsModalDisableOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>('');

  const { data, loading } = useQuery(GET_USER_LIST_BY_ID, {
    variables: { id },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const user = data?.getUser?.userInfo || {};
  const [status, setStatus] = useState(user?.status);

  useEffect(() => {
    if (id && selectedUser === '') {
      setSelectedUser(id);
    }
  }, [id, selectedUser]);

  const handleUserDetails = () => {
    navigate(`${ROUTE_PATH.ADMIN_USER_MANAGEMENT}/${id}/${ROUTE_PATH.PROBES_LISTING}`);
  };

  useEffect(() => {
    if (user) {
      setStatus(user.status);
      const currentUser = { fullName: user.fullName, id };
      Cookies.set(USER_FULLNAME, JSON.stringify(currentUser));
    }
  }, [user]);

  return (
    <>
      {loading && <CustomLoader />}
      <div className="admin-common-body">
        <div className="admin-heading">
          <Breadcrumbs crumbs={[USER_MANAGEMENT, user?.fullName]} />
          <div className="header-wrapper">
            <div className="header-left">
              <h1 className="admin-header-title mb-26">User Details</h1>
            </div>
            <div className="header-right mb-26">
              <Button
                className="btn primary header-primary-btn w-auto"
                type="submit"
                onClick={() => handleUserDetails()}
              >
                VIEW ASSIGNED PROBES:
                {' '}
                {user?.assignedProbe || NUMBER.ZERO}
              </Button>
              <div className="three-dot-outside-sec">
                <DropdownButton
                  className="icon-three-dot manage-three-dot"
                  title=""
                >
                  <Dropdown.Item
                    onClick={() => {
                      setIsModalDisableOpen(true);
                    }}
                  >
                    {status === 'inActive' ? BUTTON.ENABLE : BUTTON.DISABLE}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => navigate(
                      `${ROUTE_PATH.ADMIN_USER_MANAGEMENT}/${id}/${ROUTE_PATH.EDIT_USER}`
                    )}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
        <UserDetails status={status} userData={user} />

        {isModalDisableOpen && (
          <DisableUserModal
            selectedUser={selectedUser}
            isModalDisableOpen={isModalDisableOpen}
            setIsModalDisableOpen={setIsModalDisableOpen}
            setSelectedUser={setSelectedUser}
            status={status}
            onSuccess={(s: string) => setStatus(s)}
          />
        )}
        {isModalOpen && (
          <DeleteUserModal
            selectedUser={selectedUser}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setSelectedUser={setSelectedUser}
            userName={user.fullName}
          />
        )}

        <SubscriptionPlan isAdmin={BOOLEAN_VALUES.TRUE} userData={user} />
      </div>
    </>
  );
}

export default UserProfileDetails;
