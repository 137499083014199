import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import EditModal from './editModal';
import AddNewPlan from './addNewPlan';
import SetDefaultPlan from './setDefaultPlan';
import CustomLoader from '../shared/common/customLoader';
import TableHead from '../table/tableHead';
import { ADMIN_SUBSCRIPTION_TABLE_HEADS } from '../../constants/tableConfig';
import { GET_SUBSCRIPTION_PLAN } from '../../graphql/queries/admin.queries';
import {
  CREATE_SUBSCRIPTION_PLAN,
  DEFAULT_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN
} from '../../graphql/mutation/admin.mutation';
import PlanListing from './planListing';
import { PAN_WINE_MAKING_TYPE } from '../../constants';
import { IAddSubscriptionPlan } from '../../types';

const AdminSubscription = () => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const { data, loading, refetch } = useQuery(GET_SUBSCRIPTION_PLAN);

  const [updateSubscriptionPlan, { loading: isUpdating }] = useMutation(UPDATE_SUBSCRIPTION_PLAN);
  const [CreateSubscriptionPrice, { loading: isCreating }] = useMutation(CREATE_SUBSCRIPTION_PLAN);
  const [makePlanCurrent, { loading: isdeafult }] = useMutation(DEFAULT_SUBSCRIPTION_PLAN);

  const [modalData, setModalData] = useState({ isOpen: false, item: {} });
  const [addNewPlan, setAddNewPlan] = useState(false);
  const [isModalDisableOpen, setIsModalDisableOpen] = useState(false);

  const openModal = (item:any) => {
    setModalData({ isOpen: true, item });
  };

  const closeModal = () => {
    setModalData({ isOpen: false, item: {} });
  };

  const onUpdate = async (mutationVariables:object) => {
    const response = await updateSubscriptionPlan({ variables: mutationVariables });
    if (response.data.updateSubscriptionPlan) {
      toast.success(response.data.updateSubscriptionPlan.message);
      refetch();
    }
    closeModal();
  };

  const handleAddNewPlan = async (value: IAddSubscriptionPlan) => {
    const isWineMaking = value.isWineMaking === PAN_WINE_MAKING_TYPE.WINE_MAKING;
    const res = await CreateSubscriptionPrice({ variables: { ...value, ...{ isWineMaking } } });
    if (res.data) {
      toast.success(res.data.createSubscriptionPrice.message);
      refetch();
    }
  };

  const onSetDefault = async (value:object) => {
    const res = await makePlanCurrent({ variables: value });
    if (res.data) {
      toast.success(res.data.makePlanCurrent.message);
      refetch();
    }
    setIsModalDisableOpen(false);
  };

  return (
    <div className="admin-common-body">
      {(loading || isUpdating || isCreating || isdeafult) && <CustomLoader />}
      <div className="admin-header-wrapper admin-btn-right">
        <h1 className="admin-header-title m-0">Subscriptions</h1>
        <Button className="btn primary header-primary-btn" type="submit" onClick={() => setAddNewPlan(true)}>
          Create New Plan
        </Button>
      </div>
      {addNewPlan && (
        <AddNewPlan
          open={addNewPlan}
          close={() => setAddNewPlan(false)}
          success={(value: IAddSubscriptionPlan) => handleAddNewPlan(value)}
        />
      )}
      <div className="list-container service-list-container">
        <div className="table-wrapper mobile-optimised">
          <TableHead
            tableFields={ADMIN_SUBSCRIPTION_TABLE_HEADS}
            handleSorting={() => {}}
          />
          <PlanListing
            data={data}
            openModal={openModal}
            setIsModalDisableOpen={setIsModalDisableOpen}
            setSelectedPlan={setSelectedPlan}
          />
        </div>
      </div>

      {modalData.isOpen && (
        <EditModal
          closeModal={closeModal}
          onSuccess={async (mutationVariables:object) => {
            await onUpdate(mutationVariables);
            refetch();
          }}
          initialValue={modalData.item}
        />
      )}
      {isModalDisableOpen && (
        <SetDefaultPlan
          selectedPlan={selectedPlan}
          isModalDisableOpen={isModalDisableOpen}
          setIsModalDisableOpen={setIsModalDisableOpen}
          onSucess={(value:any) => onSetDefault({ id: value.id })}
        />
      )}
    </div>
  );
};

export default AdminSubscription;
