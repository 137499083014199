import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import LineGraph from '../../graphs/LineGraphCard';
import { SYSTEM_COMPUTATION } from '../../../graphql/queries/user/dashboard.queries';
import GraphEventForm from './graphEventForm';
import { CustomizeDotProps } from '../../../types';
import CustomLoader from '../../shared/common/customLoader';
import { ADD_DASHBOARD_EVENT } from '../../../graphql/mutation';
import { getTimeStampDateObject, parseSystemGraphData, parseSystemGraphDataForDualAxis } from '../../../utils/helpers';
import {
  DENSITY, FERMENTATION, NETWORK_ONLY,
  REDOX, STRATIFICATION, SYSTEM_COMPUTATION_DASHBOARD, SYSTEM_COMPUTATION_GRAPH, TEMPERATURE
} from '../../../constants';

function GraphicalSystemComputation() {
  const [processedTemp, setProcessedTemp] = useState<any>([]);
  const [processedDensity, setProcessedDensity] = useState<any>([]);
  const [processedfSpeed, setProcessedfSpeed] = useState<any>([]);
  const [processedStratification, setProcessedStratification] = useState<any>([]);

  const [processedTempDensity, setProcessedTempDensity] = useState<any>([]);
  const [processedDensityRedox, setProcessedDensityRedox] = useState<any>([]);
  const [processedTempRedox, setProcessedTempRedox] = useState<any>([]);

  const [params] = useSearchParams();
  const searchParams = Object.fromEntries([...params]);
  const [eventPayload, setEventPayload] = useState<CustomizeDotProps | null>();

  const {
    loading,
    refetch: refetchFermentation,
  } = useQuery(SYSTEM_COMPUTATION, {
    variables: {
      sensorType: SYSTEM_COMPUTATION_DASHBOARD.FERMENTATION,
      startDate: getTimeStampDateObject(searchParams?.startTime)?.toISOString(),
      endDate: getTimeStampDateObject(searchParams?.endTime)?.toISOString(),
      probeId: searchParams?.probeId
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (res: any) => parseSystemGraphData(res, setProcessedfSpeed, FERMENTATION)
  });

  const { refetch: refetchTemperature } = useQuery(SYSTEM_COMPUTATION, {
    variables: {
      sensorType: SYSTEM_COMPUTATION_DASHBOARD.AVG_TEMPERATURE,
      startDate: getTimeStampDateObject(searchParams?.startTime)?.toISOString(),
      endDate: getTimeStampDateObject(searchParams?.endTime)?.toISOString(),
      probeId: searchParams?.probeId
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (res: any) => parseSystemGraphData(res, setProcessedTemp, TEMPERATURE)
  });

  const { refetch: refetchDensity } = useQuery(SYSTEM_COMPUTATION, {
    variables: {
      sensorType: SYSTEM_COMPUTATION_DASHBOARD.AVG_DENSITY,
      startDate: getTimeStampDateObject(searchParams?.startTime)?.toISOString(),
      endDate: getTimeStampDateObject(searchParams?.endTime)?.toISOString(),
      probeId: searchParams?.probeId
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (res: any) => parseSystemGraphData(res, setProcessedDensity, DENSITY)
  });

  const { refetch: refetchStratification } = useQuery(SYSTEM_COMPUTATION, {
    variables: {
      sensorType: SYSTEM_COMPUTATION_DASHBOARD.STRATIFICATION,
      startDate: getTimeStampDateObject(searchParams?.startTime)?.toISOString(),
      endDate: getTimeStampDateObject(searchParams?.endTime)?.toISOString(),
      probeId: searchParams?.probeId
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (res: any) => parseSystemGraphData(res, setProcessedStratification, STRATIFICATION)
  });

  const { refetch: refetchTempDensity } = useQuery(SYSTEM_COMPUTATION, {
    variables: {
      sensorType: SYSTEM_COMPUTATION_DASHBOARD.DENSITY_TEMP,
      startDate: getTimeStampDateObject(searchParams?.startTime)?.toISOString(),
      endDate: getTimeStampDateObject(searchParams?.endTime)?.toISOString(),
      probeId: searchParams?.probeId
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (res: any) => parseSystemGraphDataForDualAxis(res, setProcessedTempDensity, DENSITY, TEMPERATURE)
  });

  const { refetch: refetchTempRedox } = useQuery(SYSTEM_COMPUTATION, {
    variables: {
      sensorType: SYSTEM_COMPUTATION_DASHBOARD.TEMP_REDOX,
      startDate: getTimeStampDateObject(searchParams?.startTime)?.toISOString(),
      endDate: getTimeStampDateObject(searchParams?.endTime)?.toISOString(),
      probeId: searchParams?.probeId
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (res: any) => parseSystemGraphDataForDualAxis(res, setProcessedTempRedox, TEMPERATURE, REDOX)
  });

  const { refetch: refetchDensityRedox } = useQuery(SYSTEM_COMPUTATION, {
    variables: {
      sensorType: SYSTEM_COMPUTATION_DASHBOARD.DENSITY_REDOX,
      startDate: getTimeStampDateObject(searchParams?.startTime)?.toISOString(),
      endDate: getTimeStampDateObject(searchParams?.endTime)?.toISOString(),
      probeId: searchParams?.probeId
    },
    fetchPolicy: NETWORK_ONLY,
    onCompleted: (res: any) => parseSystemGraphDataForDualAxis(res, setProcessedDensityRedox, DENSITY, REDOX)
  });

  const [removeEvent, { loading: isRemoving }] = useMutation(ADD_DASHBOARD_EVENT);

  /**
   * The function `onSuccess` takes a string parameter and based on its value, it calls different
   * functions to refetch data.
  */
  const onSuccess = (s: string) => {
    setEventPayload(null);
    switch (s) {
      case SYSTEM_COMPUTATION_GRAPH.DENSITY:
        refetchDensity();
        break;
      case SYSTEM_COMPUTATION_GRAPH.STRATIFICATION:
        refetchStratification();
        break;
      case SYSTEM_COMPUTATION_GRAPH.TEMPERATURE:
        refetchTemperature();
        break;
      case SYSTEM_COMPUTATION_GRAPH.FERMENTATION:
        refetchFermentation();
        break;
      case SYSTEM_COMPUTATION_GRAPH.TEMP_REDOX:
        refetchTempRedox();
        break;
      case SYSTEM_COMPUTATION_GRAPH.TEMP_DENSITY:
        refetchTempDensity();
        break;
      case SYSTEM_COMPUTATION_GRAPH.DENSITY_REDOX:
        refetchDensityRedox();
        break;
      default:
        break;
    }
  };

  /**
  * The function `remove` removes an event from a database and calls the `onSuccess` function with the
  * `dataKey` as a parameter.
  */
  const remove = async (data: any) => {
    await removeEvent({
      variables: {
        id: data?.id, event: '', description: '', sensorType: data?.dataKey
      }
    });
    await onSuccess(data?.graphType);
  };

  const toggleEventModal = useCallback((d:any) => {
    setEventPayload(d);
  }, []);

  return (
    <>
      {(loading || isRemoving) && <CustomLoader />}
      {eventPayload && (
      <GraphEventForm
        isOpen={eventPayload}
        close={() => setEventPayload(null)}
        payload={eventPayload}
        onSuccess={onSuccess}
      />
      )}
      <LineGraph
        datatoPlot={processedTemp}
        title="Average Temp Over Time"
        removeEvent={remove}
        setEventModal={(d: CustomizeDotProps) => toggleEventModal(d)}
        lines={['temperature']}
        showLegend={false}
        yAxisLabel={['Temperature (°F)']}
        selectDot
        // maxRange={250}
      />
      <LineGraph
        datatoPlot={processedDensity}
        title="Average Density Over Time"
        removeEvent={remove}
        setEventModal={(d: CustomizeDotProps) => setEventPayload(d)}
        lines={['density']}
        showLegend={false}
        yAxisLabel={['Density (grams/liter)']}
        selectDot
        // maxRange={350}
      />
      <LineGraph
        datatoPlot={processedStratification}
        title="Stratification Temp Over Time"
        removeEvent={remove}
        setEventModal={(d: CustomizeDotProps) => setEventPayload(d)}
        lines={['stratification']}
        showLegend={false}
        yAxisLabel={['Stratification']}
        selectDot
      />
      <LineGraph
        datatoPlot={processedfSpeed}
        title="Fermentation Speed Over Time"
        removeEvent={remove}
        setEventModal={(d: CustomizeDotProps) => setEventPayload(d)}
        lines={['fermentation']}
        showLegend={false}
        yAxisLabel={['Fermentation Speed (°Brix/hour)']}
        selectDot
      />
      <LineGraph
        datatoPlot={processedTempDensity}
        title="Temperature with Density"
        removeEvent={remove}
        setEventModal={(d: CustomizeDotProps) => setEventPayload(d)}
        lines={['temperature', 'density']}
        yAxisLabel={['Temperature (°F)', 'Density (grams/liter)']}
        hasDualAxis
        showLegend
        selectDot
      />
      <LineGraph
        datatoPlot={processedTempRedox}
        title="Temperature with Redox"
        removeEvent={remove}
        setEventModal={(d: CustomizeDotProps) => setEventPayload(d)}
        lines={['temperature', 'redox']}
        yAxisLabel={['Temperature (°F)', 'RedOx (millivolts)']}
        rangeY2={[-250, 250]}
        hasDualAxis
        showLegend
        selectDot
      />
      <LineGraph
        datatoPlot={processedDensityRedox}
        title="Density with Redox"
        removeEvent={remove}
        setEventModal={(d: CustomizeDotProps) => setEventPayload(d)}
        lines={['density', 'redox']}
        yAxisLabel={['Density (grams/liter)', 'RedOx (millivolts)']}
        rangeY2={[-250, 250]}
        hasDualAxis
        showLegend
        selectDot
      />
    </>
  );
}

export default GraphicalSystemComputation;
