import React, { useState } from 'react';
import {
  Form, Button, Row, Col
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LOGIN_VALIDATION_SCHEMA } from '../../../constants/forms/validationSchema';
import { LoginFormInputs } from '../../../types';
import { LOGIN_MUTATION } from '../../../graphql/mutation';
import { ROUTE_PATH } from '../../../constants/routes';
import {
  saveRememberMe,
  getRememberMe,
  saveSessions,
  saveUserInfo,
} from '../../../utils/helpers';
import { USER_INFO } from '../../../constants/forms/keys';
import CustomLoader from '../../shared/common/customLoader';
import useLogout from '../../../hooks/useLogout';
import emailIcon from '../../../assets/icons/email-icon.svg';
import useGetProbePrice from '../../../hooks/useGetProbePrice';
import { BOOLEANS } from '../../../constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const LoginForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState(BOOLEANS.FALSE);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION);
  const navigate = useNavigate();
  const { handleRedirect } = useLogout();
  const { fetchDataFromApi } = useGetProbePrice();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    defaultValues: getRememberMe(),
    resolver: yupResolver(LOGIN_VALIDATION_SCHEMA),
    shouldFocusError: false,
  });
  const handleLoginSuccess = (response: any): void => {
    const {
      role,
      isEmailVerified,
      fullName,
      email,
      token,
      subscriptionId,
      paymentStatus,
      isWineMakingPackage,
      profileImg,
      wineryProductionVolumeUnit
    } = response?.data?.login?.userInfo;
    if (!isEmailVerified) {
      saveSessions({
        token,
        email,
        fullName,
        isEmailVerified,
        paymentStatus,
        role,
        subscriptionId,
        profileImg,
        wineryProductionVolumeUnit,
        isWineMakingPackage: isWineMakingPackage || true
      });
      navigate(ROUTE_PATH.VERIFY_OTP);
      return;
    }
    Cookies.set(USER_INFO, JSON.stringify({ ...response?.data?.login?.userInfo, isWineMakingPackage: false }));
    saveUserInfo({ ...response?.data?.login?.userInfo, isWineMakingPackage: false });
    toast.success(response.data.login.message);
    fetchDataFromApi();
    handleRedirect(role, subscriptionId);
  };

  const onSubmit = async (data: LoginFormInputs): Promise<void> => {
    try {
      if (!executeRecaptcha) {
        return;
      }
      const captchaToken = await executeRecaptcha();
      const dataWithToken = {
        ...data,
        captchaToken,
      };
      const response = await loginMutation({
        variables: dataWithToken,
      });

      if (data?.rememberMe) {
        saveRememberMe({ email: data.email, password: data?.password, rememberMe: data?.rememberMe });
      }
      handleLoginSuccess(response);
    } catch (e) {
      // error
    }
  };

  return (
    <>
      {loading && <CustomLoader />}
      <Col sm={6} className="p-0 boarding-login onboarding-right">
        <div className="login-inner">
          <div className="boarding-title">
            <h1>Sign In</h1>
            <p>Enter your registered credentials to sign in to the MSP</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} sm={12}>
                <Form.Group
                  className="form-mt-space"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email Address</Form.Label>
                  <div className="wrap-input">
                    <Form.Control
                      className="form-input"
                      placeholder="Enter email address"
                      {...register('email')}
                    />
                    <img
                      className="on-input-icon-left"
                      src={emailIcon}
                      alt="email icon"
                    />
                  </div>
                  {errors.email && (
                    <p className="input-error-message">
                      {errors.email.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <Form.Group
                  className="form-mt-space"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Password</Form.Label>
                  <div className="wrap-input">
                    <Form.Control
                      className="form-input"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      {...register('password')}
                    />
                    <span className="icon-lock on-input-icon-left" />
                    <span
                      className={`icon-${showPassword ? 'open' : 'cut'}-eye on-input-icon-right`}
                      onClick={() => setShowPassword((prev) => !prev)}
                    />
                  </div>
                  {errors.password && (
                    <p className="input-error-message">
                      {errors.password.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Form.Group
              className="form-mt-space remember-me"
              controlId="formBasicCheckbox"
            >
              <div className="remember-me-wrap">
                <Form.Check
                  type="checkbox"
                  label="Remember Me"
                  data-testid="rememberMeCheckBox"
                  {...register('rememberMe')}
                />
                <Link to="/forgotpassword" className="forgot-link">
                  Forgot Password?
                </Link>
              </div>
            </Form.Group>
            <div className="login-submit">
              <Button
                type="submit"
                className="btn primary w-100"
                disabled={!!Object.keys(errors).length}
              >
                SIGN IN
              </Button>
            </div>
          </Form>
          <div className="signin-bottom">
            Don’t have an account?
            <Link to={ROUTE_PATH.SIGNUP_STEP_1} title="Sign up">
              Sign Up
            </Link>
          </div>
        </div>
      </Col>
    </>
  );
};

export default LoginForm;
