import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { toast } from 'react-toastify';
import ProbeDetailsCard from './probeDetailsCard';
import { NUMBER, ROUTE_PATH } from '../../constants';
import FetchProbeForm from './fetchProbeForm';
import { IProbeData } from '../../types';
import { PROBES_ASSIGNED_TO_TANK } from '../../graphql/queries/tank.queries';
import { selectedProbes } from '../../graphql/reactiveVariables';
import { MAX_PROBES_WARN } from '../../constants/string';

function ProbeSection() {
  const navigate = useNavigate();
  const cachedProbes: IProbeData[] = useReactiveVar(selectedProbes);
  const [probes, setProbes] = useState<IProbeData[]>([]);
  const { client, data: { probesAssignedToTank = { } } = { } } = useQuery(PROBES_ASSIGNED_TO_TANK);

  /**
   * The function `add` adds a probe to the `probes` array if the length of the array is less than 5.
   * @param {IProbeData} probe - The parameter `probe` is of type `IProbeData`.
   */
  const add = (probe: IProbeData) => {
    if (probes?.length >= NUMBER.FIVE) {
      toast.error(MAX_PROBES_WARN);
      return;
    }
    const filtered = {
      probesAssignedToTank: {
        probes:
          probesAssignedToTank?.probes.filter((i: any) => i.id !== probe?.id)
      }
    };
    client.writeQuery({ query: PROBES_ASSIGNED_TO_TANK, data: filtered });
    setProbes([...probes, probe]);
  };

  /**
   * The function removes an item from a list and updates the client's cache.
   * @param {IProbeData} item - The `item` parameter is an object of type `IProbeData`.
   */
  const remove = (item:IProbeData) => {
    setProbes([...probes.filter((probe: IProbeData) => probe?.id !== item.id)]);
    const filtered = { probesAssignedToTank: { probes: [...probesAssignedToTank?.probes, item] } };
    client.writeQuery({ query: PROBES_ASSIGNED_TO_TANK, data: filtered });
  };

  /**
   * The function "viewData" navigates to a specific route and passes the "probes" data as state, and
   * then calls the "selectedProbes" function with the "probes" data as an argument.
   */
  const viewData = () => {
    navigate({
      pathname: ROUTE_PATH.USER_DASHBOARD_PROBEDETAILS,
      search: createSearchParams({
        probeId: probes[0]?.id || '',
        computationType: 'base',
        measurement: 'temperature',
        startTime: probes[0]?.startTime,
        endTime: probes[0]?.endTime,
        dataFormat: 'graphical'
      }).toString()
    }, { state: probes });
    client.refetchQueries({ include: [PROBES_ASSIGNED_TO_TANK] });
  };

  return (
    <>
      <div className="dashboard-filter-wrapper dashboard-filter-space-top">
        <div className="filter-heading">
          Select Filter Details To View Computations
        </div>
        <FetchProbeForm add={add} />
      </div>
      {probes?.length > NUMBER.ZERO && (
      <div className="dashboard-selected-probes">
        <h4 className="selected-probes-title">Selected Probes by Filters</h4>
        <Row className="selected-probes-wrapper m-0">
          {probes.map((item) => (
            <ProbeDetailsCard
              key={item.id}
              className="selected-probes-card col-sm-6 col-12"
              probe={item}
              remove={() => remove(item)}
              isRemovable
            />
          ))}
        </Row>
        <div className="selected-probes-btn">
          <button
            type="button"
            className="btn primary"
            onClick={viewData}
          >
            {' '}
            view probe data
            {' '}
            <span> (tabular and graphical format)</span>
          </button>
        </div>
      </div>
      )}
    </>
  );
}

export default ProbeSection;
