import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {
  BOOLEANS, BUTTON, NUMBER, SUBSCRIPTION_TYPE
} from '../../constants';
import { PlanListingProps } from '../../types';
import { capitalizeFirst } from '../../utils/helpers';

const PlanListing = ({
  data, setIsModalDisableOpen, setSelectedPlan, openModal
}: PlanListingProps) => (
  <div className="tbody">
    {data && data.getSubscriptionPrice.data && (
      <>
        {data.getSubscriptionPrice.data.map((item: any) => (
          <div className="row tr cursor-default" key={item.id}>
            <div className="td flex-table-column-30">
              <p className="listing-normal">{item.productName}</p>
            </div>
            <div className="td flex-table-column-15">
              <p className="listing-normal">
                $
                {Number(+item.priceMonthly / NUMBER.ONE_HUNDRED).toLocaleString()}
                {' '}
                /
                {' '}
                $
                {Number(+item.priceYearly / NUMBER.ONE_HUNDRED).toLocaleString()}
              </p>
            </div>
            <div className="td flex-table-column-20">
              <p className="listing-normal">
                {capitalizeFirst(SUBSCRIPTION_TYPE.MONTHLY)}
                {' '}
                /
                {' '}
                {capitalizeFirst(SUBSCRIPTION_TYPE.ANNUAL)}
              </p>
            </div>
            <div className="td flex-table-column-10">
              <p className="listing-normal">
                {item?.isWineMaking === BOOLEANS.TRUE ? 'Yes' : 'No'}
              </p>
            </div>
            <div className="td flex-table-column-15">
              <div className="">
                <p className={`btn-status ${item.isCurrent === BOOLEANS.TRUE ? BUTTON.Active : BUTTON.Deactive}`}>
                  {item.isCurrent === BOOLEANS.TRUE ? BUTTON.ACTIVE : BUTTON.DISABLE}
                </p>
              </div>
            </div>
            <div className="td flex-table-column-10">
              <div className="">
                <div className="listing-normal text-center three-dot-align">
                  <DropdownButton
                    className="icon-three-dot manage-three-dot"
                    title=""
                  >
                    <Dropdown.Item onClick={() => openModal(item)}>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      setIsModalDisableOpen(BOOLEANS.TRUE);
                      setSelectedPlan(item);
                    }}
                    >
                      Make as Default
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    )}
  </div>
);

export default PlanListing;
