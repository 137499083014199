/**
 * The `useGraphExport` function is a custom React hook that handles exporting graphs as PDF files.
 * @returns The `useGraphExport` function returns an object with two properties: `loading` and
 * `exportGraph`.
 */
import { useState } from 'react';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  BOOLEANS, NUMBER, PARAMS, PDF_CONFIG
} from '../constants';
import { isGraphExporting } from '../graphql/reactiveVariables';
import { delay } from '../utils/helpers';

function useGraphExport() {
  const [params] = useSearchParams();
  const { state = [] } = useLocation();
  const probeId = params.get(PARAMS.PROBE_ID);
  const [loading, setLoading] = useState(BOOLEANS.FALSE);
  const selectedProbe = state?.find((i: any) => i.id === probeId);
  /**
   * The function `exportGraph` exports multiple graphs in a PDF document.
   */
  const exportGraph = async () => {
    setLoading(BOOLEANS.TRUE);
    isGraphExporting(BOOLEANS.TRUE);
    await delay(NUMBER.TWO_THOUSAND); // delay here to update async state properly
    const doc = new JsPDF();
    let mt = PDF_CONFIG.MARGIN_TOP;
    const addElementToPDF = async (element:HTMLElement) => {
      const canvas = await html2canvas(element);
      const imageData = canvas.toDataURL('image/png');
      if (mt % PDF_CONFIG.THRESHOLD_HEIGHT === NUMBER.ZERO) {
        doc.addPage();
        mt = PDF_CONFIG.MARGIN_TOP;
      }
      // Adjust the positioning and dimensions as needed for every graph
      doc.addImage(imageData, PDF_CONFIG.IMAGE_TYPE, PDF_CONFIG.MARGIN_LEFT, mt, PDF_CONFIG.WIDTH, PDF_CONFIG.HEIGHT);
    };
    const elements = document.getElementsByClassName('chart-container') as any;
    for (const element of elements) {
      // eslint-disable-next-line no-await-in-loop
      await addElementToPDF(element);
      mt += PDF_CONFIG.GRAPH_HEIGHT;
    }
    // Save or display the PDF
    doc.save(`Exported-Graphs-${selectedProbe?.probeId}.pdf`);
    setLoading(BOOLEANS.FALSE);
    isGraphExporting(BOOLEANS.FALSE);
  };

  return { loading, exportGraph };
}

export default useGraphExport;
