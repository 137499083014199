import React from 'react';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { USER_DASHBOARD_BASE_COMPUTATION_PROBES } from '../../../constants/tableConfig';
import TableHead from '../../table/tableHead';
import { SENSOR_LIST } from '../../../graphql/queries/user/dashboard.queries';
import BaseTableRow from './baseTableRow';
import { BOOLEANS, NUMBER } from '../../../constants';
import CustomLoader from '../../shared/common/customLoader';
import { getTimeStampDateObject, getUniqKey } from '../../../utils/helpers';
import NoData from '../../tanks/noData';
import useListing from '../../../hooks/useListing';

function TabularBaseComputation() {
  const [params] = useSearchParams();
  const searchParams = Object.fromEntries([...params]);

  const {
    results, metaData, loading, refetch
  } = useListing(SENSOR_LIST, 'sensorList', 'data', {
    sensorType: searchParams?.measurement,
    startDate: getTimeStampDateObject(searchParams?.startTime, BOOLEANS.TRUE),
    endDate: getTimeStampDateObject(searchParams?.endTime, BOOLEANS.TRUE),
    probeId: searchParams?.probeId,
  });

  return (
    <>
      {loading && <CustomLoader />}
      <div className="list-container service-list-container">
        <div className="table-wrapper mobile-optimised">
          <TableHead
            tableFields={USER_DASHBOARD_BASE_COMPUTATION_PROBES}
            handleSorting={(obj) => refetch({ ...obj, page: NUMBER.ONE })}
          />
          <div className="tbody">
            <InfiniteScroll
              dataLength={results?.length || 0}
              hasMore={metaData?.page < metaData?.totalPage}
              next={() => refetch({ page: metaData.page + NUMBER.ONE })}
              loader=""
              className="tanks-infinite"
            >
              {results.map((sensor:any) => (
                <BaseTableRow sensor={sensor} key={getUniqKey()} />
              ))}
            </InfiniteScroll>
          </div>
        </div>
        {!loading && results?.length === NUMBER.ZERO
            && <NoData isSearch img="" text="" handleAction={() => { }} heading="" />}
      </div>
    </>
  );
}

export default TabularBaseComputation;
