import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TabularBaseComputation from './tabularBaseComputation';
import GraphicalBaseComputation from './graphicalBaseComputation';

function BaseComputation() {
  const [params] = useSearchParams();
  const dataFormat = params.get('dataFormat');
  return <>{dataFormat === 'tabular' || !dataFormat ? <TabularBaseComputation /> : <GraphicalBaseComputation /> }</>;
}

export default BaseComputation;
