import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import arrowUpRed from '../../assets/img/arrow-up-red.svg';
import aboutusImg1 from '../../assets/img/aboutus1.png';
import aboutusImg2 from '../../assets/img/aboutus2.png';
import aboutusImg3 from '../../assets/img/aboutus3.png';
import aboutDots from '../../assets/img/about-dots.png';
import phoneImg from '../../assets/img/phone-icon.svg';
import emailImg from '../../assets/img/email-icon.svg';
import arrowRight from '../../assets/icons/arrow-right.svg';
import PublicNavBar from '../shared/common/navbar';
import LandingFooter from '../landingPage/LandingFooter';
import ScrollToTop from '../shared/common/scrollToTop';

function About() {
  return (
    <>
    <div className="landing-page-wrapper">
      <div className="landing-page-header">
        <ScrollToTop />
        <PublicNavBar />
        <section className="policy-wrapper bg-img about-bg-center">
          <div className="policy-sec">
            <Row>
              <Col md={12} lg={6}>
                <h1 className="mainheading">About us</h1>
                <div className="aboutus-img-sec for-mobile">
                  <img src={aboutusImg1} alt="About us Img" />
                </div>
                <p className="para">
                  The Multi-Sensor Probe (MSP) builds on the prior successes of
                  VinWizard&apos;s Multi-Level Probe which debuted in New Zealand about
                  a decade ago and began making headlines in the US wine industry
                  in 2018. The MLP was able to prove the value of knowing
                  temperature throughout the entirety of the wine tank, thus
                  enabling more informed winemaking decisions.
                </p>
                {/* <div className="aboutus-btn-sec for-mobile">
                  <a href="https://www.ruralnewsgroup.co.nz/wine-grower/wg-general-news/multi-level-probe-wins-us-innovation-award"
                  target="_blank"
                    className="btn-signin pre-footer-btn pre-footer-btn-new"
                  >
                    READ ARTICLE
                    {' '}
                    <img src={arrowRight} alt="right arrow" />
                  </a>
                </div> */}
                <div className="temperature-btn-sec aboutus-btn-sec">
                  <a href="https://www.ruralnewsgroup.co.nz/wine-grower/wg-general-news/multi-level-probe-wins-us-innovation-award" target="_blank" className="temperature-arrow-btn">
                    Read Article
                    {' '}
                    <img src={arrowUpRed} alt="Arrow Up Red" />
                  </a>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className="aboutus-upperimg aboutus-upperimg-new for-desktop">
                  <img className="about-img1" src={aboutusImg1} alt="About us Img" />
                  <img className="about-dots" src={aboutDots} alt="about dots" />
                </div>
              </Col>
            </Row>
            <p className="para">
              Luxury producers found value in the MLP&apos;s ability to more tightly
              control fermentation parameters, notably cap temperatures. By
              providing insight into the highest temperatures in the tank (and the
              lowest), winemakers could feel comfortable running the yeast at the
              higher end of their tolerance zones, generating highly sought after
              flavors and aromatics without running the risk of a stuck
              fermentation.
            </p>
            <div className="aboutus-img-sec">
              <img className="w-100" src={aboutusImg2} alt="About us Img" />
            </div>
            <p className="para">
              Large winemaking facilities benefitted most from using the MLP by
              increasing their tank turns and reducing their cooling demands. When
              the choice is installing additional tanks or getting more use out of
              your existing assets with a little help from the MLP, the choice is
              pretty simple.
            </p>
            {/* <div className="aboutus-btn-sec for-mobile">
              <a href="https://www.spiritedbiz.com/supplier-spotlight-wine-technology-groups-vinwizard"
                target="_blank"
                className="btn-signin pre-footer-btn pre-footer-btn-new"
              >
                Read Article
                {' '}
                <img src={arrowRight} alt="right arrow" />
              </a>
            </div> */}
            <div className="temperature-btn-sec aboutus-btn-sec">
              <a href="https://www.spiritedbiz.com/supplier-spotlight-wine-technology-groups-vinwizard/" target="_blank" className="temperature-arrow-btn">
                Read Article
                {' '}
                <img src={arrowUpRed} alt="Arrow Up Red" />
              </a>
            </div>
            <p className="para">
              Having achieved global success with the MLP, it was time to take a
              stab at addressing one of the biggest opportunities during harvest,
              providing real-time Brix (density) measurement during fermentation
              without costing a fortune. Any solution had to provide a positive
              return on investment when compared to sending an employee out to the
              tank front twice daily to pull samples and spin a hydrometer. Tank
              mounted refractometers work great but cost more than $10k per tank.
              Wall mounted dual pressure transducers are also reliable, but
              required a level of engineering &quot;know-how&quot; and data management
              expertise most wineries don&apos;t have in-house.
            </p>
            <p className="para">
              The MSP&apos;s analytical methods are not unique, rather it relies on
              &quot;tried-and-true&quot; sensors and technology to provide real-time
              temperature and Brix information. It is the sensor delivery
              mechanism which provides the real value. By entering through the top
              of the tank and running the height of the vessel, winemakers are
              able to gather multi-level Brix readings while avoiding the cooling
              jacket impacts that skew results. The MSP&apos;s placement within the
              tank has been determined to yield the most accurate temperature and
              rate of fermentation at the core of the tank, while not interfering
              with pumpover devices and CIP set-ups.
            </p>
            <p className="para">
              The data and preconfigured visualizations from the MSP by itself can
              be extremely powerful and useful for winemakers. However, when the
              data is combined with an automation platform like VinWizard or
              Octent Automation, the real value is unlocked. By integrating with
              winery management systems, winemaking and fermentation events can
              proceed automatically when certain parameters are observed via the
              MSP; for example, triggering a low volume pumpover cycle and tank
              warming when a Brix rate of change is first detected, then
              increasing the frequency and duration of tank mixing while also
              introducing in-line air for yeast propagation during growth phase
              and increasing sugar consumption. Combine the MSP&apos;s third sensor,
              ORP/RedOx, and now a winemaker can really understand their
              fermentation&apos;s health.
            </p>
            <div className="aboutus-img-sec">
              <img className="w-100" src={aboutusImg3} alt="About us Img" />
            </div>
            <p className="para">
              Wine quality, asset use efficiency, and overall winery outcomes will
              be improved by the proper employment of MSP provided information. We
              are excited about the global potential for MSP deployment. It&apos;s
              simple technology, designed well, and meant to last in a rough
              winery environment.
            </p>
            <div className="contact-heading">
              Please reach out with questions as we&apos;d like to hear from you.
            </div>
            <div className="contact-wrapper">
              <div className="contact-sec">
                <div className="contact-left">
                  <img src={phoneImg} alt="phone img" />
                </div>
                <div className="contact-right">
                  <div className="contact-title">Phone Number</div>
                  <div className="contact-no">+1 720-284-2059</div>
                </div>
              </div>
              <div className="contact-sec">
                <div className="contact-left">
                  <img src={emailImg} alt="email img" />
                </div>
                <div className="contact-right">
                  <div className="contact-title">Email Address</div>
                  <div className="contact-no"><a className="static-email static-email" href="mailto:kelly@vinwizard.us">kelly@vinwizard.us</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LandingFooter />
      </div>
      </div>
    </>
  );
}

export default About;
