import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TabularSystemComputation from './tabularSystemComputation';
import GraphicalSystemComputation from './graphicalSystemComputation';

function SystemComputation() {
  const [params] = useSearchParams();
  const dataFormat = params.get('dataFormat');
  return <>{dataFormat === 'tabular' ? <TabularSystemComputation /> : <GraphicalSystemComputation /> }</>;
}

export default SystemComputation;
