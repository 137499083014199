import React, { MutableRefObject, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import {
  BOOLEANS,
  NUMBER,
  SAMPLE_CSV_PROBE_DATA,
  SAMPLE_CSV_TANK_DATA,
  SAMPLE_FILE,
  UPLOAD_CSV_MESSAGE,
} from '../constants';
import { UploadResponse } from '../types';
import { CSV_SUCCESS_MESAAGE } from '../constants/string';
import { validateProbeCsv, validateTankCsv } from '../utils/helpers';

interface UseCsvUploaderProps {
  mutation: any;
  type: 'tankFile' | 'file';
  onSubmit: () => void;
}

const useCsvUploader = ({ mutation, onSubmit, type }: UseCsvUploaderProps) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [addProbesMutation, { loading: mutationLoading }] = useMutation(
    mutation
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setErrorMessage('');
    } else {
      setFile(null);
      setFileName('');
      setErrorMessage(UPLOAD_CSV_MESSAGE.ERROR_CSV);
    }
  };

  const handleDownloadSample = () => {
    let csvData;
    if (type === 'tankFile') {
      csvData = Papa.unparse(SAMPLE_CSV_TANK_DATA, {
        delimiter: ',',
      });
    } else {
      csvData = Papa.unparse(SAMPLE_CSV_PROBE_DATA, {
        delimiter: ',',
      });
    }

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = SAMPLE_FILE;
    a.click();
  };
  const handleUpload = async () => {
    if (type === 'file' && file) {
      setLoading(true);
      Papa.parse(file, {
        worker: BOOLEANS.TRUE,
        complete: async (result: UploadResponse) => {
          let hasErr = '';
          const tankHeader = ['Email Id', 'Probe Id'];
          for (const key of tankHeader) {
            if (!result.data[0].includes(key)) {
              toast.error('Please pass a valid CSV');
              inputRef.current.value = '';
              setLoading(false);
              setFile(null);
              return;
            }
          }
          const parsedProbes = result.data.map((row: Array<string>) => {
            if (row.length === NUMBER.TWO) {
              hasErr = validateProbeCsv(row);
            }
            return {
              email: row[NUMBER.ZERO],
              probeId: row[NUMBER.ONE],
            };
          });
          if (hasErr) {
            setLoading(false);
            setFile(null);
            setFileName('');
            toast.error(hasErr);
            inputRef.current.value = '';
            return;
          }
          if (parsedProbes.length > NUMBER.ZERO) {
            const lastElement = parsedProbes[parsedProbes.length - NUMBER.ONE];
            if (!lastElement.email || !lastElement.probeId) {
              parsedProbes.pop();
            }
          }
          try {
            parsedProbes.shift();
            const { data } = await addProbesMutation({
              variables: { probes: parsedProbes },
            });
            if (data) {
              setFile(null);
              toast.success(CSV_SUCCESS_MESAAGE, {
                position: toast.POSITION.TOP_CENTER,
              });
            }

            onSubmit();
            setFile(() => null);
            setFileName(() => '');
          } catch (error) {
            setErrorMessage(UPLOAD_CSV_MESSAGE.CSV_NOT_UPLOAD);
          }

          setLoading(false);
        },
        error: () => {
          setErrorMessage(UPLOAD_CSV_MESSAGE.CSV_NOT_UPLOAD);
          setLoading(false);
        },
      });

      setFileName('');
    } else if (type === 'tankFile' && file) {
      setLoading(true);
      Papa.parse(file, {
        worker: BOOLEANS.TRUE,
        complete: async (result: UploadResponse) => {
          let hasErr = '';
          const tankHeader = ['Name', 'Capacity', 'Location', 'Description', 'Volume'];
          for (const key of tankHeader) {
            if (!result.data[0].includes(key)) {
              toast.error('Please pass a valid CSV');
              inputRef.current.value = '';
              setLoading(false);
              return;
            }
          }
          const parsedTanks = result.data.map((row: Array<string>) => {
            if (row.length === NUMBER.FIVE || (row.length < NUMBER.THREE && row.length > NUMBER.ONE)) {
              hasErr = validateTankCsv(row);
            }
            return {
              name: row[NUMBER.ZERO],
              capacity: (row[NUMBER.ONE] || '').toString().toLowerCase() || '',
              location: row[NUMBER.TWO],
              description: row[NUMBER.THREE],
              volume: parseFloat(row[NUMBER.FOUR]) || NUMBER.ZERO,
            };
          });
          if (hasErr) {
            setLoading(false);
            setFile(null);
            setFileName('');
            toast.error(hasErr);
            inputRef.current.value = '';
            return;
          }
          if (parsedTanks.length > NUMBER.ZERO) {
            const lastElement = parsedTanks[parsedTanks.length - NUMBER.ONE];
            if (!lastElement.name
              || !lastElement.capacity
              || !lastElement.location
              || !lastElement.description
              || !lastElement.volume) {
              parsedTanks.pop();
            }
          }
          try {
            parsedTanks.shift();
            await addProbesMutation({ variables: { tanks: parsedTanks } });
            setFile(null);
            toast.success(CSV_SUCCESS_MESAAGE, {
              position: toast.POSITION.TOP_CENTER,
            });
            onSubmit();
          } catch (error) {
            setErrorMessage(UPLOAD_CSV_MESSAGE.CSV_NOT_UPLOAD);
          }
          setLoading(false);
          inputRef.current.value = '';
        },
        error: () => {
          setErrorMessage(UPLOAD_CSV_MESSAGE.CSV_NOT_UPLOAD);
          setLoading(false);
        },
      });

      setFileName('');
    } else {
      setErrorMessage(UPLOAD_CSV_MESSAGE.VALID_CSV);
    }
  };
  const openFilePicker = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return {
    inputRef,
    fileName,
    file,
    errorMessage,
    loading: loading || mutationLoading,
    handleFileChange,
    handleUpload,
    openFilePicker,
    handleDownloadSample,
  };
};

export default useCsvUploader;
