import React, { useCallback, useMemo, useState } from 'react';
import {
  Brush,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useReactiveVar } from '@apollo/client';
import SelectableLegend from '../shared/common/selectableLegend';
import CustomizedDot from './customizeDot';
import {
  BOOLEANS,
  LINE_COLORS_CODES,
  LINE_GRAPH_MARGINS,
  NUMBER,
  TOOLTIP,
} from '../../constants';
import { isGraphExporting } from '../../graphql/reactiveVariables';
import CustomizedLabel from './customLabel';
import useGraphToolTip from '../../hooks/useGraphToolTip';
import ColorCodeInfoModal from './colorCodeInfoModal';
import ToolTipWrapper from './toolTipWrapper';
import { getBaseIndex, getStartIndex } from '../../utils/helpers';
import ToggleButton from './toggleButton';

function LineGraphCard({
  datatoPlot,
  title,
  showLegend = BOOLEANS.TRUE,
  removeEvent,
  setEventModal,
  lines,
  yAxisLabel = '',
  hasDualAxis = BOOLEANS.FALSE,
  toolTipType = TOOLTIP.GENERIC,
  showToolTipSelector = BOOLEANS.FALSE,
  minRange = '',
  maxRange = '',
  rangeY2 = [NUMBER.ZERO, NUMBER.TWO_FIFTY],
  selectDot,
}: any) {
  const isExporting = useReactiveVar(isGraphExporting);
  const [visibleLines, setVisibleLines] = useState<string[]>([]);
  const [isOpen, toggleModal] = useState(false);
  const [tooltipValue, setToolTipValue] = useState('');
  const [showDots, toggleDots] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  const { event, toolTopPayload, adjustToolTip, handleHover } =
    useGraphToolTip();

  /**
   * The function `selectLines` is used to toggle the visibility of lines based on user input.
   * @param e - The parameter `e` is of type `React.ChangeEvent<HTMLElement>`. This is an event object
   */
  const selectLines = (e: React.MouseEvent<HTMLElement>) => {
    const lineName = (e.target as HTMLElement)?.id;
    let tempArr: string[] = [];
    tempArr = [...visibleLines];
    if (visibleLines.includes(lineName)) {
      tempArr = visibleLines.filter((i: string) => i !== lineName);
    } else {
      if (lineName) {
        tempArr.push(lineName);
      }
    }
    setVisibleLines([...tempArr]);
  };

  /**
   * The function `ToogleToolTipValue` sets the value of a tooltip based on a boolean input.
   * @param {boolean} bool - A boolean value that determines whether to toggle the tooltip value.
   */
  const toogleToolTipValue = (bool: boolean) => {
    if (bool) {
      setToolTipValue(TOOLTIP.DETAILED);
    } else {
      setToolTipValue(TOOLTIP.GENERIC);
    }
  };

  useMemo(() => {
    setVisibleLines(lines.slice(NUMBER.ZERO, NUMBER.FIVE));
  }, [lines]);

  useMemo(() => {
    setToolTipValue(toolTipType);
  }, [toolTipType]);

  useMemo(() => {
    if (datatoPlot.length === 0) return;
    if (datatoPlot?.length < 10) {
      setStartIndex(0);
      setEndIndex(datatoPlot?.length - 1);
      return;
    }
    setStartIndex(getStartIndex(datatoPlot?.length));
    setEndIndex(datatoPlot?.length - 1);
  }, [datatoPlot.length]);

  const captureEvent = useCallback((p: any) => {
    setEventModal(p);
  }, []);

  return (
    <div className="temp-sensor mt-4 mb-4 chart-container">
      <div className="temp-sensor-wrapper">
        <h6 className="temp-sensor-title mt-0 mb-0">{title}</h6>
        <div className="switch-on-graph">
          {showToolTipSelector && !isExporting && (
            <ToggleButton
              handleChange={(f: boolean) => toogleToolTipValue(f)}
              checked={tooltipValue === TOOLTIP.DETAILED}
              text="Tank Temperature Bar"
            />
          )}
          {selectDot && !isExporting && (
            <ToggleButton
              handleChange={(f: boolean) => toggleDots(f)}
              checked={showDots}
              text="Show Dots"
            />
          )}
        </div>
      </div>
      {showLegend ? (
        <SelectableLegend
          legends={lines || []}
          handleChange={selectLines}
          selectedLegends={visibleLines}
        />
      ) : null}
      {toolTopPayload?.type ? (
        <ToolTipWrapper
          openInfo={() => toggleModal(true)}
          visibleLines={visibleLines}
          lines={lines}
          event={event}
          onhover={adjustToolTip}
          onRemove={removeEvent}
          payload={toolTopPayload || {}}
        />
      ) : null}
      <ResponsiveContainer width="100%" minHeight="430px" minWidth="300px">
        <ComposedChart
          width={680}
          height={450}
          data={datatoPlot}
          margin={LINE_GRAPH_MARGINS}
        >
          {!isExporting && (
            <Brush
              dataKey="timeStamp"
              height={25}
              stroke="#8884d8"
              y={400}
              travellerWidth={7}
              startIndex={startIndex}
              endIndex={endIndex}
              onChange={(e) => {
                setStartIndex(e?.startIndex as number);
                setEndIndex(e?.endIndex as number);
              }}
            />
          )}
          <XAxis
            dy={10}
            dx={-18}
            angle={-18}
            fontSize={14}
            tickCount={8}
            dataKey="timeStamp"
            padding={{ left: NUMBER.THIRTY, right: NUMBER.TWENTY }}
            label={{
              value: 'Time Stamp',
              position: 'insideBottomCenter',
              dy: NUMBER.FOURTY,
              fill: '#fff',
            }}
          />
          {visibleLines?.map((i: string, index: number) => (
            <Line
              type="linear"
              dataKey={i}
              strokeWidth={1.5}
              stroke={LINE_COLORS_CODES[getBaseIndex(lines, i)]}
              key={i}
              yAxisId={hasDualAxis ? i : undefined}
              dot={(props) => (
                <>
                  {isExporting && showDots && (
                    <CustomizedLabel key={NUMBER.NINETEEN * index} {...props} />
                  )}
                  <CustomizedDot
                    {...props}
                    key={NUMBER.THIRTEEN * index}
                    isExporting={isExporting}
                    onClick={(payload) => captureEvent({ ...payload, lines })}
                    onhover={handleHover}
                    onLeave={() => {
                      adjustToolTip(false, null, 'cell');
                    }}
                    toolTipType={tooltipValue}
                    showDots={showDots}
                  />
                </>
              )}
            />
          ))}
          <YAxis
            axisLine={false}
            tickLine={false}
            yAxisId={hasDualAxis ? lines[NUMBER.ZERO] : undefined}
            type="number"
            orientation="left"
            tickCount={6}
            dataKey={lines[0]}
            domain={
              minRange === '' && maxRange === ''
                ? undefined
                : [minRange, maxRange]
            }
            label={{
              value: yAxisLabel[NUMBER.ZERO],
              angle: -90,
              position: 'insideCenter',
              dx: -20,
              fill: LINE_COLORS_CODES[0],
            }}
          />
          {hasDualAxis && (
            <YAxis
              axisLine={false}
              tickLine={false}
              yAxisId={hasDualAxis ? lines[NUMBER.ONE] : undefined}
              type="number"
              orientation="right"
              dataKey={lines[1]}
              tickCount={6}
              // domain={rangeY2}
              label={{
                value: yAxisLabel[NUMBER.ONE],
                angle: -90,
                position: 'insideCenter',
                dx: 20,
                fill: LINE_COLORS_CODES[1],
              }}
            />
          )}
          <CartesianGrid vertical={false} strokeDasharray="3 3" opacity={0.2} />
        </ComposedChart>
      </ResponsiveContainer>
      {isOpen && (
        <ColorCodeInfoModal close={() => toggleModal(false)} show={isOpen} />
      )}
    </div>
  );
}

export default LineGraphCard;
