import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { PackageSectionProps } from '../../types';
import { SUBSCRIPTION_TYPE } from '../../constants';
import { usePaymentContext } from '../../context/PaymentContextProvider';
import {
  DURATION_MONTH, DURATION_YEARLY, CURRENCY_SYMBOL, formatPrice, MONTHLY
} from '../../constants/string';
import CustomLoader from '../shared/common/customLoader';

const PackageSection: React.FC<PackageSectionProps> = ({ handleShow }) => {
  const {
    updateData,
    isWineMakingPackage,
    subscriptionType,
    singleProbPrice,
    addOnProbePrice,
    addOnPriceYearly,
    yearlyProbPrice,
    laoding
  } = usePaymentContext();
  const DurationLabel = subscriptionType === MONTHLY ? DURATION_MONTH : DURATION_YEARLY;
  const showSingleProbPrice = formatPrice(subscriptionType === MONTHLY
    ? singleProbPrice : yearlyProbPrice);
  const formattedAddOnPrice = formatPrice(
    subscriptionType === MONTHLY ? addOnProbePrice : addOnPriceYearly
  );

  if (laoding) {
    <CustomLoader />;
  }

  return (
    <div className="select-package-sec">
      <div className="no-probes-sec">
        <div className="no-probes-wrap">
          <div className="no-probes-left">
            <p className="no-probes-title">Number of Probes (Each Probe)</p>
            <h1 className="probs-dollar">
              $
              {showSingleProbPrice}
              <span className="default-month">
                /
                {DurationLabel}
              </span>
              {subscriptionType === SUBSCRIPTION_TYPE.ANNUAL && (
                <span className="annually">10% Off</span>
              )}
            </h1>
          </div>
        </div>
      </div>
      <h6 className="package-heading">Add-Ons</h6>
      <div className="addon-sec">
        <div className="package-tag">
          <p>Popular</p>
        </div>
        <div className="remember-me-wrap">
          <Form.Check
            name="rememberMe"
            type="checkbox"
            label="Winemaking Event Packages"
            data-testid="rememberMeCheckBox"
            defaultChecked={isWineMakingPackage}
            onClick={() => updateData('isWineMakingPackage', !isWineMakingPackage)}
          />
        </div>
        <div className="addon-infor">
          <h1 className="subprobs-dollar">
            {CURRENCY_SYMBOL}
            {formattedAddOnPrice}
            <span className="default-month">
              /
              {DurationLabel}
            </span>
            {subscriptionType === SUBSCRIPTION_TYPE.ANNUAL && (
            <span className="annually">10% Off</span>
            )}
          </h1>
          <div className="addon-des">
            <p>
              This package enables advanced options, including fermentation
              kinetics, temperature stratification, and more.
            </p>
            <p>
              Winemakers will be able to tag data points with winemaking events
              like pump-overs or nutrient additions to help identify fermentation cause and effect.
            </p>
          </div>
        </div>
      </div>
      <div className="probes-select-package">
        <Button className="btn primary select-package-btn" onClick={handleShow}>
          Select Package
        </Button>
      </div>
    </div>
  );
};

export default PackageSection;
