/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const DASHBOARD_COUNT = gql`
  query getProbeAndTankCount {
     getProbeAndTankCount {
        totalTank
        totalAttachedProbe
        assignProbe
        totalAvailableProbe
    }    
  }`;

export const SENSOR_LIST = gql`
  query sensorList(
    $sensorType: String
    $startDate: DateTime
    $endDate: DateTime
    $sort: String
    $sortBy: String
    $page: Int
    $limit: Int
    $probeId: String!
  ) {
    sensorList(
      sensorType: $sensorType
      startDate: $startDate
      endDate: $endDate
      sort: $sort
      sortBy: $sortBy
      page: $page
      limit: $limit
      probeId: $probeId
    ) {
      data {
        sensorType
        id
        probeName
        timestampDate
        value
        sensor
      }
      totalCount
    }
  }
`;

export const GRPAH_SENSOR_DATA = gql`
  query sensorList($sensorType: String!, $startDate: DateTime, $endDate: DateTime, $probeId: String){
    dashboardSensor(sensorType: $sensorType, startDate: $startDate, endDate: $endDate, probeId: $probeId) {
      data {
        timestamp_date
        values
      }
      sensor
    }
}`;

export const ACTIVE_SUBSCRIPTION_PLAN = gql`
  query {
    activeSubscriptionPlan {
      data {
        isWineMaking
        priceMonthly
        priceYearly
      }
    }
  }
`;

export const SYSTEM_COMPUTATION_SENSOR_LIST = gql`
query dashboardComputationList(
  $startDate: DateTime, 
  $endDate: DateTime, 
  $sort: String, 
  $sortBy: String,
  $page: Int,
  $limit: Int,
  $probeId: String!
  $sensorType: String!){
  dashboardComputationList(
    startDate: $startDate, 
    endDate: $endDate, 
    sort: $sort, 
    sortBy: $sortBy,
    page: $page,
    limit: $limit,
    probeId: $probeId
    sensorType: $sensorType) {
    data {
      value
      highTemperature
      lowTemperature
      avgTemperature
      timestampDate
      sensorType
      event
      description
    }
    totalCount
  }
}`;

export const SYSTEM_COMPUTATION = gql`
query dashboardComputation($sensorType: String, $startDate: DateTime, $endDate: DateTime, $probeId: String){
    dashboardComputation(sensorType: $sensorType, startDate: $startDate, endDate: $endDate, probeId: $probeId) {
    data {
      timestamp_date
      values
    }
    }
  }
`;

export const SENSOR_LIST_EXPORT = gql`
  query sensorList(
    $sensorType: String
    $startDate: DateTime
    $endDate: DateTime
    $sort: String
    $sortBy: String
    $page: Int
    $limit: Int
    $downloadType: String
    $isDownload: Boolean
    $probeId: String!
  ) {
    sensorList(
      sensorType: $sensorType
      startDate: $startDate
      endDate: $endDate
      sort: $sort
      sortBy: $sortBy
      page: $page
      limit: $limit
      isDownload: $isDownload
      downloadType: $downloadType
      probeId: $probeId
    ) {
        filePath
    }
  }
`;

export const SYSTEM_COMPUTATION_EXPORT = gql`
query dashboardComputationList(
  $sensorType: String
  $startDate: DateTime, 
  $endDate: DateTime, 
  $sort: String, 
  $sortBy: String,
  $page: Int,
  $limit: Int,
  $downloadType: String
  $isDownload: Boolean
  $probeId: String!
  ){
  dashboardComputationList(
    startDate: $startDate, 
    endDate: $endDate, 
    sort: $sort, 
    sortBy: $sortBy,
    page: $page,
    limit: $limit,
    isDownload: $isDownload
    downloadType: $downloadType
    probeId: $probeId
    sensorType: $sensorType
    ) {
    filePath
  }
}`;
