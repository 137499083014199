import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import {
  DATE_TIME_FORMATE,
  NUMBER,
  ROLE,
  ROUTE_PATH,
  SUBSCRIPTION_TYPE,
} from '../../constants';
import useLogout from '../../hooks/useLogout';
import { SubscriptionPlanProps } from '../../types';
import {
  formatNumberToLocaleString,
  getSubscriptionType,
} from '../../utils/helpers';
import useGetProbePrice from '../../hooks/useGetProbePrice';
import CustomLoader from '../shared/common/customLoader';
import {
  CANCEL_SUBSCRIPTION,
  RENEW_SUBSCRIPTION_MUTATION,
  UPDATE_WINE_PACKAGE,
} from '../../graphql/mutation';
import SubscriptionPayment from './SubscriptionPayment';
import SunbscriptionNoPackage from './SunbscriptionNoPackage';
import AllSubscriptionModel from './AllSubscriptionModel';

const SubscriptionPlan = ({
  userData,
  isAdmin,
  refetch,
}: SubscriptionPlanProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { openLogoutModal, closeLogoutModal, logoutModal } = useLogout();
  const {
    priceLoading,
    addOnPriceMonthly,
    addOnPriceYearly,
    priceMonthly,
    priceYearly,
  } = useGetProbePrice();
  const {
    openLogoutModal: openAddOnModal,
    closeLogoutModal: closeAddOnModal,
    logoutModal: addOnModal,
  } = useLogout();
  const {
    openLogoutModal: openSubscriptionModal,
    closeLogoutModal: closeSubscriptionModal,
    logoutModal: subscriptionModal,
  } = useLogout();
  const { fetchDataFromApi } = useGetProbePrice();
  const [updateWinePackage, { loading: updateLoading }] = useMutation(UPDATE_WINE_PACKAGE);
  const [cancelSubscription, { loading }] = useMutation(CANCEL_SUBSCRIPTION);
  const [renewSubscription, { loading: renewLoading }] = useMutation(
    RENEW_SUBSCRIPTION_MUTATION
  );

  useEffect(() => {
    fetchDataFromApi();
  }, []);
  const {
    totalProbe = NUMBER.ZERO,
    subscriptionType = '',
    isWineMakingPackage,
    lastAmountPaid = NUMBER.ZERO,
    subscriptionPeriodEnd = NUMBER.ZERO,
    subscriptionId,
    isCancelSubscription,
    role
  } = userData || {};
  const eachProbePrice = subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? priceMonthly : priceYearly;
  const totalProbAmount = (totalProbe * eachProbePrice).toFixed(NUMBER.TWO);

  const addOnPrice = subscriptionType === SUBSCRIPTION_TYPE.MONTHLY
    ? formatNumberToLocaleString(addOnPriceMonthly)
    : formatNumberToLocaleString(addOnPriceYearly);

  const formattedDate = moment
    .unix(subscriptionPeriodEnd)
    .format(DATE_TIME_FORMATE);

  const buyAddOnPlan = async () => {
    try {
      const response = await updateWinePackage();
      if (response) {
        await refetch?.();
        toast.success(response.data.updateWinePackage.message);
      }
      closeAddOnModal();
    } catch (paymentError) {
      closeAddOnModal();
    }
  };

  if (priceLoading) {
    return <CustomLoader />;
  }

  const userCancelSubscription = async () => {
    try {
      const response = await cancelSubscription();
      if (response) {
        await refetch?.();
        toast.success(response.data.cancelSubscription.message);
      }
      closeAddOnModal();
    } catch (paymentError) {
      closeAddOnModal();
    }
  };

  const userRenewSubscription = async () => {
    try {
      const response = await renewSubscription();
      if (response) {
        await refetch?.();
        toast.success(response.data.renewSubscription.message);
      }
      closeSubscriptionModal();
    } catch (paymentError) {
      closeSubscriptionModal();
    }
  };

  const canclePlan = async () => {
    await userCancelSubscription();
    closeLogoutModal();
  };

  const handleNavigation = () => {
    navigate(ROUTE_PATH.SUBSRIPTION);
  };

  return (
    <div className="filter-sec account-profile-sec">
      <div className="subscription-head-sec">
        <div className="subscription-head-left">
          <div className="box-heading">
            {' '}
            {!isCancelSubscription
              ? 'Subscription Plan'
              : 'Subscription Cancelled'}
          </div>
        </div>
      </div>
      {(loading || renewLoading) && <CustomLoader />}
      {subscriptionId
      && (
      <>
        <div className="subscription-payment-box">
          <div className="subscription-payment-top">
            <div className="payment-left">
              <div className="payment-name">
                {getSubscriptionType(subscriptionType)}
              </div>
              <div className="payment-price">
                {`$${
                  Number.isNaN(
                    +(lastAmountPaid / NUMBER.ONE_HUNDRED).toFixed(NUMBER.TWO)
                  )
                    ? NUMBER.ZERO
                    : formatNumberToLocaleString(
                      +(lastAmountPaid / NUMBER.ONE_HUNDRED).toFixed(NUMBER.TWO)
                    )
                }`}
              </div>
            </div>
            <div className="payment-right">
              <div className="payment-due">
                {isCancelSubscription ? 'Cancelled On' : 'Next Payment Due On'}
              </div>
              <div className="payment-date">
                {subscriptionPeriodEnd ? formattedDate : '-'}
              </div>
              {isCancelSubscription ? (
                <div
                  className="payment-renew bold-text"
                  onClick={openSubscriptionModal}
                >
                  Renew Now
                </div>
              ) : (
                <div className="payment-renew">(Renew Automatically)</div>
              )}
            </div>
          </div>
          <SubscriptionPayment
            totalProbe={totalProbe}
            eachProbePrice={eachProbePrice}
            totalProbAmount={totalProbAmount}
            isWineMakingPackage={isWineMakingPackage}
            addOnPrice={addOnPrice}
          />
        </div>
        <SunbscriptionNoPackage
          openAddOnModal={openAddOnModal}
          isWineMakingPackage={isWineMakingPackage}
          subscriptionId={subscriptionId}
          isCancelSubscription={isCancelSubscription}
        />
      </>
      )}
      <div className="subscription-head-cancel">
        {!isAdmin && !isCancelSubscription && subscriptionId && (
          <div className="subscription-plan text-cap" onClick={openLogoutModal}>
            CANCEL PLAN
          </div>
        )}

        {!subscriptionId && role === ROLE.USER && (
          <div className="subscription-plan text-cap" onClick={handleNavigation}>
            Buy Now
          </div>
        )}

        {/* {!subscriptionId && role === ROLE.USER && (
          <Button
       `     type="submit"
            className="btn primary header-primary-btn edit-profile-btn btn btn-primary height44"
            onClick={handleNavigation}
          >
            Buy Now
          </Button>
        )} */}

        <div
          className={`subscription-history text-cap ${!subscriptionId && 'margin27Px'}`}
          style={{ }}
          onClick={() => navigate(
            isAdmin
              ? `${ROUTE_PATH.ADMIN_USER_MANAGEMENT}/${id}/${ROUTE_PATH.TRANSACTION_HISTORY}`
              : ROUTE_PATH.USER_TRANSACTION
          )}
        >
          View Transaction History
        </div>
      </div>
      <AllSubscriptionModel
        logoutModal={logoutModal}
        canclePlan={canclePlan}
        closeAddOnModal={closeAddOnModal}
        closeLogoutModal={closeLogoutModal}
        addOnModal={addOnModal}
        buyAddOnPlan={buyAddOnPlan}
        subscriptionType={subscriptionType}
        updateLoading={updateLoading}
        subscriptionModal={subscriptionModal}
        userRenewSubscription={userRenewSubscription}
        closeSubscriptionModal={closeSubscriptionModal}
      />
    </div>
  );
};

export default SubscriptionPlan;
