import React from 'react';
import {
  Col, Form, Button, Row
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import emailIcon from '../../assets/icons/email-icon.svg';
import leftArrow from '../../assets/icons/arrow-left.svg';
import { FORGOT_PASSWORD_VALIDATION_SCHEMA, ROUTE_PATH } from '../../constants';
import { FORGOT_PASSWORD_MUTATION } from '../../graphql/mutation/admin.mutation';
import Input from '../../components/shared/forms/input';
import CustomLoader from '../../components/shared/common/customLoader';

function Forgotpassword() {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD_MUTATION);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(FORGOT_PASSWORD_VALIDATION_SCHEMA),
    shouldFocusError: false,
  });

  const onSubmitHandler = handleSubmit(async (formData) => {
    if (!executeRecaptcha) {
      return;
    }
    const captchaToken = await executeRecaptcha();
    await forgotPassword({
      variables: { email: formData.email, captchaToken },
    })
      .then((res) => {
        toast.success(res.data.forgotPassword.message);
      })
      .catch(() => '');
    reset();
  });

  return (
    <Col sm={6} className="p-0 boarding-login onboarding-right">
      {loading && <CustomLoader /> }
      <div className="login-inner">
        <div className="back-page-wrap space-for-back">
          <img
            className="left-arrow"
            src={leftArrow}
            alt="Arrow"
            onClick={() => navigate(ROUTE_PATH.LOGIN)}
          />
          <h5 className="back-title-heading mb-0">Back to Sign In</h5>
        </div>
        <div className="boarding-title back-boarding-title">
          <h1>Forgot Password</h1>
          <p className="back-subtitle">
            Please enter your registered email address to reset your password.
          </p>
        </div>
        <Form onSubmit={onSubmitHandler}>
          <Row>
            <Col xs={12} sm={12}>
              <Input
                label="Email Address"
                name="email"
                placeholder="Enter registered email address"
                refValues={register}
                iconClass="on-input-icon-left"
                icon={<img src={emailIcon} alt="email icon" />}
                error={errors?.email?.message}
                setValue={(name, value: string) => {
                  setValue('email', value.toLowerCase(), { shouldValidate: true });
                  if (getValues('email')) {
                    trigger('email');
                  }
                }}
              />
              <div className="login-submit">
                <Button type="submit" className="btn primary w-100">
                  SUBMIT
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Col>
  );
}

export default Forgotpassword;
