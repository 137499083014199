import React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FISCAL_YEAR_VALUE_FORMAT } from '../../../constants/formats';
import { DateInputProps } from '../../../types';
import { BOOLEANS } from '../../../constants';

function DatepickerInput({ ...props }) {
  return <input type="text" {...props} readOnly />;
}

function DateInput({
  label,
  name,
  minDate,
  wrapperClass,
  dateFormat,
  iconClass,
  selected,
  setValue,
  maxDate,
  placeholder,
  disabled = BOOLEANS.FALSE,
  error,
  showMonthYearPicker,
  showTimeSelectOnly = BOOLEANS.FALSE,
  showTimeSelect = BOOLEANS.FALSE,
  valueFormat = FISCAL_YEAR_VALUE_FORMAT,
  minTime = undefined,
  maxTime,
}: DateInputProps) {
  return (
    <Form.Group className="form-mt-space">
      {label && <Form.Label>{label}</Form.Label>}
      <div className={wrapperClass ?? 'wrap-input wrap-date-input'}>
        <DatePicker
          className="admin-form-input"
          placeholderText={placeholder}
          dateFormat={dateFormat}
          name={name}
          minDate={minDate}
          maxDate={maxDate}
          selected={selected}
          onChange={(e) => setValue(name, moment(e).format(valueFormat), {
            shouldValidate: true,
          })}
          autoComplete="off"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onClickOutside={() => {}}
          customInput={<DatepickerInput />}
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          minTime={minTime}
          maxTime={maxTime}
          timeIntervals={5} // Set time interval to 5 minutes
        />
        {iconClass && <span className={`calender-icon ${iconClass}`} />}
      </div>
      <span className="input-error-message" data-testid={`${name}Err`}>{error as string}</span>
    </Form.Group>
  );
}

export default DateInput;
