import React from 'react';
import { NavLink } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROLE, USER_INFO, menuConfig } from '../../constants';
import { getSession } from '../../utils/helpers';

function UserSideBar({ handleMenu }: { handleMenu?: () => void }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { role = ROLE.USER } = getSession(USER_INFO);

  const isActive = (route: string) => {
    if (route.startsWith(location.pathname) || location.pathname.startsWith(route)) {
      return 'active';
    }
    return '';
  };

  const handleRoute = (route: string) => {
    navigate(route);
    handleMenu?.();
  };

  return (
    <>
      <div className="sidebar-container">
        <h6 className="main-navigation">Main navigation</h6>
        <ul className="nav">
          {menuConfig[role as keyof typeof menuConfig].map((item: any) => (
            <li
              key={item.label}
              className="nav-item"
              onClick={() => handleRoute(item.route)}
            >
              <NavLink className={`nav-link ${isActive(item.route)}`}>
                {item?.iconClass && <span className={item?.iconClass} />}
                {item.lable}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default UserSideBar;
