import React, {
  createContext, useContext, useMemo, useState
}
  from 'react';
import {
  NUMBER, PAYMENT_TYPE, SUBSCRIPTION_TYPE
} from '../constants';
import useGetProbePrice from '../hooks/useGetProbePrice';
import { PaymentContextData, PaymentContextValue, UpdateDataFunction } from '../types';

const PaymentContext = createContext<PaymentContextValue | undefined>(undefined);

export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePaymentContext must be used within PaymentContextProvider');
  }
  return context;
};

const PaymentContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    priceLoading, addOnPriceMonthly, addOnPriceYearly, priceMonthly, priceYearly, paymentData
  } = useGetProbePrice();
  const [data, setData] = useState<PaymentContextData>({
    subscriptionType: 'monthly',
    totalProbe: '',
    isWineMakingPackage: false,
    paymentType: '',
    stripeToken: '',
    singleProbPrice: NUMBER.ZERO,
    addOnProbePrice: NUMBER.ZERO,
    yearlyProbPrice: NUMBER.ZERO,
    type: PAYMENT_TYPE.CARD,
    addOnPriceYearly: NUMBER.ZERO,
  });

  const updateData: UpdateDataFunction = (key, value) => {
    if (key === 'totalProbe') {
      const parsedValue = Number(value);
      const updatedValue = parsedValue === NUMBER.ZERO ? '' : parsedValue;
      const cappedValue = Number(updatedValue) > NUMBER.FIVE_HUNDRED ? NUMBER.FIVE_HUNDRED : updatedValue;
      setData((prevData) => ({
        ...prevData,
        totalProbe: cappedValue,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  const calculateTotal = () => {
    let addOnPrice = NUMBER.ZERO;

    if (data.isWineMakingPackage) {
      if (data.subscriptionType === SUBSCRIPTION_TYPE.ANNUAL) {
        addOnPrice = data.addOnPriceYearly;
      } else {
        addOnPrice = data.addOnProbePrice;
      }
    }

    return data.singleProbPrice * Number(data.totalProbe) + addOnPrice;
  };

  const contextValue: PaymentContextValue = {
    ...data,
    updateData,
    total: calculateTotal(),
    laoding: priceLoading
  };

  useMemo(() => {
    setData({
      ...data,
      addOnProbePrice: addOnPriceMonthly || NUMBER.ZERO,
      addOnPriceYearly: addOnPriceYearly || NUMBER.ZERO,
      singleProbPrice: priceMonthly || NUMBER.FIVE,
      yearlyProbPrice: priceYearly || NUMBER.FIFTY_FOUR
    });
  }, []);

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
