import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ModalWrapper from '../modalWrapper';
import { SubscriptionModalProps } from '../../../../types';
import { ROUTE_PATH } from '../../../../constants';
import editEmailIcon from '../../../../assets/icons/edit-email-icon.svg';

const SubscriptionModal = ({ show, handleClose }: SubscriptionModalProps) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(ROUTE_PATH.SUBSRIPTION);
  };
  return (
    <ModalWrapper show={show} handleClose={handleClose} modalIcon={editEmailIcon} noIcon={false}>
      <div className="modal-body-form unlock-power">
        <h2 className="unlock-power-title">
          Choose Your Affordable Subscription Plan
        </h2>
        <p className="unlock-power-subtitle">
          Unlock full access to customized MSP data by choosing either a monthly or annual subscription plan.
        </p>
      </div>
      <div className="footer-modal">
        <Button
          type="submit"
          className="btn primary modal-btn-submit"
          onClick={handleNavigation}
        >
          Buy Now
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default SubscriptionModal;
