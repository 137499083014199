import React from 'react';
import { Button } from 'react-bootstrap';
import { DetailHeaderActionProps } from '../../../../types';

function DetailHeaderActions({
  deleteLabel, editLabel, onRemove, onEdit, className = ''
}:DetailHeaderActionProps) {
  return (
    <div className={`header-right ${className}`}>
      {deleteLabel && <Button
        className="btn-outline-border header-outline-btn"
        onClick={onRemove}
      >
        {deleteLabel}
      </Button>}
      <Button
        className="btn primary header-primary-btn"
        onClick={onEdit}
      >
        {editLabel}
      </Button>
    </div>
  );
}

export default DetailHeaderActions;
