import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import UserForm from './userForm';
import { GET_USER_LIST_BY_ID_FOR_UPDATE } from '../../graphql/queries/admin.queries';
import CustomLoader from '../shared/common/customLoader';
import { UPDATE_USER_MUTATION } from '../../graphql/mutation/admin.mutation';
import { ROUTE_PATH } from '../../constants';
import { getCurrentMonthYear } from '../../utils/helpers';

const EditUser: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_USER_LIST_BY_ID_FOR_UPDATE, {
    variables: { id },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [updateUser, { loading: isUpdating }] = useMutation(UPDATE_USER_MUTATION);

  const initialValues = data?.getUser?.userInfo || {};
  const updatedInitialValues = {
    ...initialValues,
    wineryFiscalYearEndMonth: initialValues.wineryFiscalYearEndMonth
      ? initialValues.wineryFiscalYearEndMonth
      : getCurrentMonthYear(),
  };
  const handleUpdateDetails = async (values: Record<string, any>) => {
    try {
      const wineryProductionVolume = parseFloat(values?.wineryProductionVolume);
      const mutationVariables: any = {
        id,
        ...values,
        wineryProductionVolume,
      };

      const res = await updateUser({
        variables: mutationVariables,
      });

      if (res?.data?.updateUser) {
        toast.success(res.data.updateUser.message);
        navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
      } else {
        throw new Error('Update user failed');
      }
    } catch (error) {
      navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
    }
  };
  return (
    <>
      {(loading || isUpdating) && <CustomLoader />}
      {data?.getUser?.userInfo && (
        <UserForm
          buttonName="UPDATE"
          headerTitle="Edit User"
          initialValues={updatedInitialValues}
          onSuccess={(values) => {
            handleUpdateDetails(values);
          }}
          role={data?.getUser?.userInfo?.role}
        />
      )}
    </>
  );
};

export default EditUser;
