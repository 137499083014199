import React from 'react';
import { Form } from 'react-bootstrap';
import Input from 'react-phone-input-2';
import { UseFormSetValue } from 'react-hook-form';
import { PhoneData } from '../../../types';
import { BOOLEANS } from '../../../constants/static';

interface Iprops {
  phone: string;
  setValue: UseFormSetValue<any>;
  setCountryCode: (name: string, v: string) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  error?: string;
  label: string;
  name: string;
}

const PhoneInput = ({
  phone,
  setValue,
  setCountryCode,
  isRequired = BOOLEANS.TRUE,
  isDisabled,
  error,
  label,
  name,
}: Iprops) => {
  const handleOnChange = (value: string, data: PhoneData, _: any, __: any) => {
    setValue(name, value, { shouldValidate: true });
    setCountryCode('countryCode', `+${data.dialCode}`);
  };

  return (
    <Form.Group className="form-mt-space" controlId="phone">
      <Form.Label className="admin-form-label">{label}</Form.Label>
      <div className="wrap-input">
        <Input
          country="us"
          value={`${phone}`.replace('+', '')}
          onChange={handleOnChange}
          disabled={isDisabled}
          inputClass="custom-phone-input"
          inputProps={{ 'data-testid': 'phoneInput' }}
        />
      </div>
      <span className="input-error-message" data-testid="phoneErr">{error}</span>
    </Form.Group>
  );
};
export default PhoneInput;
