/**
 * The `useSaveTank` function is a custom hook that handles saving or updating tank data using Apollo
 * Client mutations in a React application.
 * @param {UseSaveTankArgs}  - - `isEditMode`: A boolean value indicating whether the form is in edit
 * mode or not.
 * @returns The `useSaveTank` hook returns an object with two properties:
 */
import { useMutation } from '@apollo/client';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ADD_NEW_TANK_MUTATION, EDIT_TANK_MUTATION } from '../graphql/mutation';
import { UseSaveTankArgs } from '../types';

function useSaveTank({ isEditMode, onSuccess }: UseSaveTankArgs) {
  const [saveTank, { loading }] = useMutation(ADD_NEW_TANK_MUTATION);
  const [updateTank, { loading: updateTankLoading }] = useMutation(EDIT_TANK_MUTATION);

  const submit = async (data: FieldValues) => {
    let res;
    const config = {
      variables: {
        ...data,
        volume: parseFloat(data?.volume),
      },
    };
    if (isEditMode) {
      res = await updateTank({ ...config });
      toast.success(res?.data?.updateTank?.message);
    } else {
      res = await saveTank(config);
      toast.success(res?.data?.addTank?.message);
    }
    if (res?.data) {
      onSuccess();
    }
  };
  return { isLoading: loading || updateTankLoading, submit };
}

export default useSaveTank;
