import React from 'react';

const SectionNine = () => (
  <>
    <h3>Section 12 - Warranty Disclaimers</h3>
    <p className="para">You herein expressly acknowledge and agree that:</p>
    <div className="indent-sec">
      <p className="para">
        a) The use of wine technology inc. Services and software are at the sole
        risk by you. Our services and software shall be provided on an "as is"
        and/or "as available" basis. Wine technology inc. And our subsidiaries,
        affiliates, officers, employees, agents, partners and licensors
        expressly disclaim any and all warranties of any kind whether expressed
        or implied, including, but not limited to any implied warranties of
        title, merchantability, fitness for a particular purpose and
        non-infringement.
      </p>
      <p className="para">
        b) Wine technology inc. And our subsidiaries, officers, employees,
        agents, partners and licensors make no such warranties that (i) wine
        technology inc. Services or software will meet your requirements; (ii)
        wine technology inc. Services or software shall be uninterrupted,
        timely, secure or error-free; (iii) that such results which may be
        obtained from the use of the wine technology inc. Services or software
        will be accurate or reliable; (iv) quality of any products, services,
        any information or other material which may be purchased or obtained by
        you through our services or software will meet your expectations; and
        (v) that any such errors contained in the software shall be corrected.
      </p>
      <p className="para">
        c) Any information or material downloaded or otherwise obtained by way
        of wine technology inc. Services or software shall be accessed by your
        sole discretion and sole risk, and as such you shall be solely
        responsible for and hereby waive any and all claims and causes of action
        with respect to any damage to your computer and/or internet access,
        downloading and/or displaying, or for any loss of data that could result
        from the download of any such information or material.
      </p>
      <p className="para">
        d) No advice and/or information, despite whether written or oral, that
        may be obtained by you from wine technology inc. Or by way of or from
        our services or software shall create any warranty not expressly stated
        in the terms.
      </p>
      <p className="para">
        e) A small percentage of some users may experience some degree of
        epileptic seizure when exposed to certain light patterns or backgrounds
        that may be contained on a computer screen or while using our services.
        Certain conditions may induce a previously unknown condition or
        undetected epileptic symptom in users who have shown no history of any
        prior seizure or epilepsy. Should you, anyone you know or anyone in your
        family have an epileptic condition, please consult a physician if you
        experience any of the following symptoms while using our services:
        dizziness, altered vision, eye or muscle twitches, loss of awareness,
        disorientation, any involuntary movement, or convulsions.
      </p>
    </div>
  </>
);

export default SectionNine;
