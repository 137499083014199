import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { PAYMENT_STATUS, ROUTE_PATH, USER_INFO } from '../../constants';
import { getSession } from '../../utils/helpers';
import PaymentContextProvider from '../../context/PaymentContextProvider';

function SubscriptionLayout() {
  const user = getSession(USER_INFO);
  if (!user) {
    return <Navigate to={ROUTE_PATH.LOGIN} />;
  }

  if (user && !user?.isEmailVerified) {
    return <Navigate to={ROUTE_PATH.VERIFY_OTP} />;
  }

  if (user?.paymentStatus === PAYMENT_STATUS.PAID && user?.subscriptionId) {
    return <Navigate to={ROUTE_PATH.USER_TANKS} />;
  }
  if (user?.paymentStatus === PAYMENT_STATUS.PROGRESS) {
    return <Navigate to={ROUTE_PATH.PAYMENT_SUCCESS} />;
  }
  if (user?.paymentStatus === PAYMENT_STATUS.OPEN) {
    <Navigate to={ROUTE_PATH.SUBSRIPTION} />;
  }

  return (
    <PaymentContextProvider>
      <Outlet />
    </PaymentContextProvider>
  );
}

export default SubscriptionLayout;
