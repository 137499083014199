import React from 'react';
import { CustomizeLabelProps } from '../../types';
import { NUMBER } from '../../constants';

const CustomizedLabel = (props: CustomizeLabelProps) => {
  const {
    cx, cy, payload, dataKey
  } = props;
  const eventName = payload[`${dataKey}Event` as keyof typeof payload];
  if ((payload?.event && payload?.event !== 'null') || (eventName && eventName !== 'null')) {
    return (
      <>
        <text
          fontSize={13}
          x={cx}
          y={cy - NUMBER.TEN}
          stroke="#FFFFFF"
          transform={`rotate(-45.86, ${cx}, ${cy})`}
        >
          {payload?.event ?? eventName}
        </text>
        <circle
          cx={cx}
          cy={cy}
          r="4"
          stroke={payload?.stroke}
          strokeWidth="3"
          fill="#A8C5DA"
        />
      </>
    );
  }
  return null;
};

export default CustomizedLabel;
