import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import { GET_USER_LIST } from '../../../graphql/queries/admin.queries';
import UserListItem from './userListItem';
import CustomLoader from '../../shared/common/customLoader';
import UserListingHeader from './userListingHeader';
import useListing from '../../../hooks/useListing';
import { NUMBER } from '../../../constants';
import TableHead from '../../table/tableHead';
import { USER_LISTING_TABLE_HEADS } from '../../../constants/tableConfig';
import noUserPic from '../../../assets/img/no-user.svg';
import EmptyState from '../../shared/common/emptyState';

const UserListing = () => {
  const {
    results, loading, refetch, metaData
  } = useListing(
    GET_USER_LIST,
    'getUserList',
    'users'
  );
  const [params] = useSearchParams();
  const query = params.get('search');
  let filterTimeout: any; // using debouncing for search
  const onSearch = (search: string) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      if (search.length >= NUMBER.THREE || search.length === NUMBER.ZERO) {
        refetch({ search, page: NUMBER.ONE });
      }
    }, NUMBER.FIVE_HUNDRED);
  };

  return (
    <>
      {loading && <CustomLoader />}
      <div className="admin-common-body ">
        <UserListingHeader onSearch={onSearch} />
        <div className="list-container service-list-container">
          <div className="table-wrapper mobile-optimised">
            <TableHead
              tableFields={USER_LISTING_TABLE_HEADS}
              handleSorting={(obj) => refetch({ ...obj, page: NUMBER.ONE })}
            />
            <div
              className="tanks-infinite"
              style={{
                overflowY: loading ? 'hidden' : 'auto',
                // maxHeight: 'calc(100vh - 0px)',
              }}
            >
              <InfiniteScroll
                dataLength={results?.length || 0}
                hasMore={metaData?.page < metaData?.totalPage}
                next={() => refetch({ search: query, page: metaData?.page + NUMBER.ONE })}
                loader=""
                className="tanks-infinite"
              >
                {results.length === 0 && !loading && (
                <EmptyState
                  title="No Users"
                  subtitle=" There is no user added to the platform yet."
                  imgSrc={noUserPic}
                />
                )}
                <div className="tbody">
                  {results && (
                    <>
                      {results?.map((item: any) => (
                        <UserListItem key={item.id} user={item} />
                      ))}
                    </>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserListing;
