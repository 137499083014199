import React, { useEffect } from 'react';
import {
  Button, Col, Form, Modal, Row
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../../assets/icons/close.svg';
import { usePaymentContext } from '../../context/PaymentContextProvider';
import { handlePasteNumber, keyPressHandle } from '../../constants/helper';
import { ProbeModelProps } from '../../types';
import { SUBSCRIPTION_TYPE } from '../../constants';

const ProbeModel: React.FC<ProbeModelProps> = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const {
    updateData,
    totalProbe,
    isWineMakingPackage,
    paymentType,
    singleProbPrice,
    type,
    subscriptionType,
    yearlyProbPrice,
  } = usePaymentContext();
  const handleContinue = () => {
    handleClose();
    navigate({
      pathname: '/payment',
      search: `?totalProbe=${totalProbe}&isWineMakingPackage=${isWineMakingPackage}&singleProbPrice=${
        subscriptionType === SUBSCRIPTION_TYPE.MONTHLY
          ? singleProbPrice
          : yearlyProbPrice
      }&type=${type}&subscriptionType=${subscriptionType}&addOnProbePrice=`,
    });
  };

  useEffect(() => {
    updateData('totalProbe', '');
  }, []);

  return (
    <Modal
      centered
      className="common-modal boarding-login"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Add Probe</Modal.Title>
        <img
          className="btn-close"
          src={closeIcon}
          alt="close icon"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="modal-body-form">
          <Col xs={12} sm={12}>
            <Form.Group
              className="form-mt-space mb-0"
              controlId="formBasicEmail"
            >
              <Form.Label>
                Probe
                {' '}
                <span>(Max: 500)</span>
              </Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                maxLength={3}
                value={totalProbe}
                placeholder="Enter probe count"
                onChange={(e) => updateData('totalProbe', e.target.value)}
                pattern="/^(?:[0-9]|[1-4][0-9]{2}|500)$/"
                onKeyPress={keyPressHandle}
                onPaste={handlePasteNumber}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="footer-modal mb-10">
          <Button
            className="btn primary modal-btn-submit"
            onClick={handleContinue}
            disabled={Number(totalProbe) <= 0}
          >
            Continue
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default ProbeModel;
