import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form, Button, Row, Col
} from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import ModalWrapper from '../shared/common/modalWrapper';
import {
  API_ISSUE,
  BOOLEANS,
  CONTACT_ADMIN_VALIDATION_SCHEMA,
  NUMBER,
} from '../../constants';
import Input from '../shared/forms/input';
import { SEND_PROBE_REQUEST } from '../../graphql/mutation';
import CustomLoader from '../shared/common/customLoader';
import { ContactFormInputs } from '../../types';

const ContactAdminModal = ({ open, close }: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<ContactFormInputs>({
    resolver: yupResolver(CONTACT_ADMIN_VALIDATION_SCHEMA),
    shouldFocusError: false,
  });
  const [sendProbeRequest, { loading }] = useMutation(SEND_PROBE_REQUEST);

  const onSubmit = async (data: ContactFormInputs) => {
    try {
      const response = await sendProbeRequest({ variables: data, });
      toast.success(response.data.sendProbeRequestToAdmin.message);
      close(BOOLEANS.FALSE);
    } catch (error) {
      toast.error(API_ISSUE);
    }
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <ModalWrapper show={open} handleClose={close} title="Contact Admin">
      {loading && <CustomLoader />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="modal-body-form">
          <Col xs={12} sm={12}>
            <Input
              name="subject"
              label="Subject"
              placeholder="Enter subject"
              refValues={register}
              setValue={setValue}
              error={errors?.subject?.message}
              maxLength={NUMBER.THIRTY_TWO}
              required
            />
          </Col>
          <Col xs={12} sm={12}>
            <Form.Group className="form-mt-space">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="form-input"
                as="textarea"
                rows={NUMBER.FOUR}
                placeholder="Enter description"
                value={watch('description')}
                onChange={(e) => {
                  setValue('description', e.target.value);
                  trigger('description');
                }}
                maxLength={NUMBER.TWO_FIFTY}
                required
              />
              {errors.description && (
                <p className="input-error-message">
                  {errors.description?.message}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <div className="footer-modal login-submit">
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            disabled={!isValid}
          >
            Submit
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default ContactAdminModal;
