import React, { memo } from 'react';
import { CustomizeDotProps } from '../../types';
import { NUMBER } from '../../constants';

const CustomizedDot = (props: CustomizeDotProps) => {
  const {
    cx, cy, stroke, payload, onhover, onLeave, onClick, dataKey, toolTipType, showDots
  } = props;
  const eventKey = payload[`${dataKey}Event` as keyof typeof payload];
  const eventIndex = payload[`${dataKey}Index` as keyof typeof payload] || NUMBER.ZERO;

  if (!showDots) {
    return null;
  }

  if ((payload?.event && payload?.event !== 'null') || (eventKey && eventKey !== 'null')) {
    return (
      <circle
        onMouseEnter={(e: React.MouseEvent) => onhover(e,
          {
            ...payload, dataKey, index: eventIndex, type: 'event'
          })}
        onMouseLeave={onLeave}
        cx={cx}
        cy={cy}
        r="8"
        stroke={stroke}
        strokeWidth="3"
        fill="#21222d"
      />
    );
  }

  return (
    <circle
      onMouseEnter={(e: React.MouseEvent) => onhover(e, {
        ...payload,
        value: Number(payload[dataKey as keyof typeof payload]),
        sensor: dataKey,
        type: toolTipType,
        stroke
      })}
      onMouseLeave={onLeave}
      onClick={() => onClick({ ...payload, dataKey, index: eventIndex })}
      cx={cx}
      cy={cy}
      r="4"
      stroke={payload?.stroke}
      strokeWidth="3"
      fill={stroke}
    />
  );
};

export default memo(CustomizedDot);
