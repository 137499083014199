import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTE_PATH } from '../../../constants';
import SearchInput from '../../shared/forms/searchInput';
import { UserListingHeaderProps } from '../../../types';

const UserListingHeader = ({ onSearch }: UserListingHeaderProps) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const query = params.get('search');

  return (
    <div className="admin-header-wrapper admin-heading">
      <h1 className="admin-header-title">User Management</h1>
      <div className="header-wrapper">
        <div className="header-left">
          <SearchInput
            innerClass="sm-12"
            onSearch={(e: string) => onSearch(e)}
            query={query as string}
            placeholder="Search by Name, Email Address, or Winery Name"
          />
        </div>
        <div className="header-right">
          <Button
            className="btn primary header-primary-btn"
            onClick={() => navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT_ADD_USER)}
          >
            Add New User
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserListingHeader;
