/* eslint-disable import/extensions */
import React, { useState } from 'react';
import {
  Form, Row, Col, Button,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SIGNUP_PERSONAL_INFO_VALIDATION_SCHEMA,
  INPUT_ALPHABETS,
} from '../../../constants';
import Input from '../../shared/forms/input';
import PhoneInput from '../../shared/forms/phoneInput';
import PasswordStrength from '../../shared/forms/passwordStrength';
import { PersonalInfoProps, PersonalInfoType } from '../../../types';
import emailIcon from '../../../assets/icons/email-icon.svg';

function PersonalInfo({ proceed, storedValues }: PersonalInfoProps) {
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: storedValues as PersonalInfoType,
    resolver: yupResolver(SIGNUP_PERSONAL_INFO_VALIDATION_SCHEMA),
    shouldFocusError: false,
  });

  const onSubmitHandler = handleSubmit(async (values: PersonalInfoType) => {
    proceed(values);
  });

  return (
    <Form onSubmit={onSubmitHandler}>
      <Row className="m-0">
        <Col xs={12} sm={6} className="first-50">
          <Input
            type="text"
            name="fullName"
            label="Full Name"
            placeholder="Enter full name"
            refValues={register}
            setValue={setValue}
            error={errors?.fullName?.message}
            iconClass="icon-username on-input-icon-left"
            valuePattern={INPUT_ALPHABETS}
            maxLength={32}
          />
        </Col>
        <Col xs={12} sm={6} className="second-50">
          <PhoneInput
            label="Contact Number"
            phone={getValues('contactNumber')}
            setCountryCode={() => {}}
            setValue={setValue}
            name="contactNumber"
            error={errors?.contactNumber?.message}
          />
        </Col>
      </Row>
      <Row className="m-0">
        <Col xs={12} sm={12} className="first-50 second-50">
          <Input
            name="email"
            type="email"
            label="Email Address"
            placeholder="Enter email address"
            refValues={register}
            error={errors?.email?.message}
            setValue={setValue}
            iconClass="on-input-icon-left"
            icon={<img src={emailIcon} alt="email icon" />}
            maxLength={50}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12}>
          <Input
            name="password"
            type="password"
            label="Password"
            placeholder="Enter password"
            refValues={register}
            iconClass="icon-lock on-input-icon-left"
            setValue={setValue}
            error={
              errors?.password?.type !== 'matches'
                ? errors?.password?.message
                : ''
            }
            onClick={() => setShowPasswordStrength(true)}
            onBlur={() => setShowPasswordStrength(false)}
            maxLength={32}
          />
        </Col>
      </Row>
      {errors?.password?.type === 'matches' && (
        <PasswordStrength password={getValues('password') || ''} />
      )}
      <div className="login-submit">
        <Button type="submit" className="btn primary w-100" disabled={!isValid} data-testid="continueToBuisnessDetails">
          Continue
        </Button>
      </div>
    </Form>
  );
}

export default PersonalInfo;
