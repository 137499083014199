import React from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { BOOLEANS } from '../../constants';
import { ProbeDetailsCardProps } from '../../types';

function ProbeDetailsCard({
  className,
  toggle = BOOLEANS.FALSE,
  isRemovable = BOOLEANS.FALSE,
  checked = BOOLEANS.FALSE,
  probe,
  handleChange = () => { },
  remove = () => { }
}:ProbeDetailsCardProps) {
  return (
    <Col
      xs={12}
      sm={6}
      className={`common-detail-wrapper ${className}`}
    >
      <div className="dashboard-probe-wrapper">
        <div>
          <h4 className="dashboard-probe-subtitle">{probe?.probeId}</h4>
        </div>
        {toggle && (
        <div className="switch-main">
          <span className="switch-main-text">Graphical Format</span>
          <label className="switch" htmlFor="checkbox">
            <input
              type="checkbox"
              id="checkbox"
              checked={checked}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleChange(e?.target?.checked)}
            />
            <span className="slider round" />
          </label>
        </div>
        )}
      </div>
      <ListGroup>
        <ListGroup.Item className="mb-0">
          <div className="detail-title">Start Date & Time</div>
          <div className="detail-subtext2">{probe?.startTime}</div>
        </ListGroup.Item>
        <ListGroup.Item className="mb-0">
          <div className="detail-title">End Date & Time</div>
          <div className="detail-subtext2">{probe?.endTime}</div>
        </ListGroup.Item>
      </ListGroup>
      {isRemovable && <span className="icon-close-with-circle close-icon-right" onClick={remove} />}
    </Col>
  );
}

export default ProbeDetailsCard;
