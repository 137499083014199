import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { usePaymentContext } from '../../context/PaymentContextProvider';
import { NUMBER, SUBSCRIPTION_TYPE, total } from '../../constants';
import { PaymentSummaryProps } from '../../types';
import { formatNumberToLocaleString } from '../../utils/helpers';

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  queryParams,
}) => {
  const {
    totalProbe, subscriptionType, singleProbPrice, packageUpgrade
  } = queryParams;
  const {
    isWineMakingPackage, updateData, addOnProbePrice, addOnPriceYearly
  } = usePaymentContext();

  const addOnPrice = subscriptionType === SUBSCRIPTION_TYPE.ANNUAL ? +addOnPriceYearly : +addOnProbePrice;

  const totalPrice = !isWineMakingPackage
    ? total(+singleProbPrice, +totalProbe, NUMBER.ZERO)
    : total(+singleProbPrice, +totalProbe, +addOnPrice);
  return (
    <Col sm={NUMBER.SIX} xs={NUMBER.TWELVE} className="payment-col-right">
      <div className="payment-col-wrap">
        <div className="subscription-plan">
          <h6 className="subscription-plan-title">Subscription Plan</h6>
          {packageUpgrade ? <div className="mb-20" /> : (
            <div className="plan-item">
              <p className="plan-item-left">
                {Number(totalProbe || NUMBER.ZERO)}
                {' '}
                Probes (Each Probe $
                {singleProbPrice}
                )
              </p>
              <p className="plan-item-right">
                $
                {formatNumberToLocaleString(totalProbe ? totalProbe * singleProbPrice : NUMBER.ZERO)}
              </p>
            </div>
          )}
          <h6 className="add-ons-titile">Add-Ons</h6>
          <div className="add-ons">
            <div className="remember-me-wrap">
              <Form.Check
                type="checkbox"
                label=""
                data-testid="rememberMeCheckBox"
                defaultChecked={isWineMakingPackage || packageUpgrade}
                onChange={() => updateData('isWineMakingPackage', !isWineMakingPackage)}
                disabled={packageUpgrade}
              />
            </div>
            <div className="plan-item">
              <p className="plan-item-left">Winemaking Event Packages</p>
              <p className="plan-item-right">
                $
                {formatNumberToLocaleString(addOnPrice)}
              </p>
            </div>
          </div>
          <hr className="plan-divider" />
          <div className="total-amount">
            <p className="total-amount-text">
              Total Amount Per
              {' '}
              {subscriptionType}
            </p>
            <h2 className="total-amount-price">
              $
              {Number.isNaN(+totalPrice) ? NUMBER.ZERO : formatNumberToLocaleString(+totalPrice)}
            </h2>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default PaymentSummary;
