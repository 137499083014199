import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Button, Form } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import {
  AD_BOTTOM_SLIDER_SETTINGS, SUBSCRIPTION_TYPE
} from '../../constants';
import quoteIcon from '../../assets/icons/quote.svg';
import arrowRight from '../../assets/icons/arrow-right.svg';
import customerArrow from '../../assets/img/customer-arrow.png';
import customMark2 from '../../assets/img/custom-mark2.gif';
import {
  CURRENCY_SYMBOL, DURATION_MONTH, DURATION_YEARLY, MONTHLY
} from '../../constants/string';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useGetProbePrice from '../../hooks/useGetProbePrice';
import CustomLoader from '../shared/common/customLoader';
import { LandingSubscriptionPlanProps } from '../../types';

const LandingSubscriptionPlan = ({ handleLoginRoute }: LandingSubscriptionPlanProps) => {
  const [subscriptionType, setSubscriptionType] = useState(SUBSCRIPTION_TYPE.MONTHLY);
  const {
    priceLoading,
    addOnPriceMonthly,
    addOnPriceYearly,
    priceMonthly,
    priceYearly,
    fetchDataFromApi
  } = useGetProbePrice();

  const handleSubscriptionTypeChange = (type: string) => {
    setSubscriptionType(() => type);
  };

  const DurationLabel = subscriptionType === MONTHLY ? DURATION_MONTH : DURATION_YEARLY;

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  return (
    <div className="landing-page-body">
      {priceLoading && <CustomLoader />}
      <div className="pricing-sec">
        <div className="pricing-wrap">
          <div className="pricing-subtitle">
            <ScrollAnimation animateIn="bounceInLeft">
              <h3>Choose Your Affordable Subscription Plan</h3>
            </ScrollAnimation>
          </div>
          <p className="pricing-desc">
            Unlock full access to customized MSP data by choosing either a monthly or annual subscription plan.
          </p>
          <div className="switch-sec">
            <div className="switch-wrap">
              <div className="tabs">
                <input
                  type="radio"
                  id="radio-1"
                  name="tabs"
                  checked={subscriptionType === SUBSCRIPTION_TYPE.MONTHLY}
                  onChange={() => handleSubscriptionTypeChange(SUBSCRIPTION_TYPE.MONTHLY)}
                />
                <Form.Label className="tab" htmlFor="radio-1">
                  Monthly
                </Form.Label>
                <input
                  type="radio"
                  id="radio-2"
                  name="tabs"
                  checked={subscriptionType === SUBSCRIPTION_TYPE.ANNUAL}
                  onChange={() => handleSubscriptionTypeChange(SUBSCRIPTION_TYPE.ANNUAL)}
                />
                <Form.Label className="tab" htmlFor="radio-2">
                  Annually
                </Form.Label>
                <input type="radio" id="radio-3" name="tabs" />
                <span className="glider" />
              </div>
            </div>
            <div className="discount-sec">
              <h5 className="discount-text">10% Discount</h5>
              <img
                className="custom-mark"
                src={customMark2}
                alt="custom-mark"
              />
            </div>
          </div>
        </div>
        <div className="above-addon">
          <div className="edit-email-package-wrapper">
            <div className="edit-email-package-left">
              <p className="edit-package-left-title">Per Probe</p>
              <h1 className="subprobs-dollar subprobs-dollar-first">
                {CURRENCY_SYMBOL}
                {subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? priceMonthly : priceYearly}
                <span className="default-month">
                  /
                  {DurationLabel}
                </span>
              </h1>
            </div>
            <div className="edit-email-package-right">
              <Button className="btn primary above-addon-btn" onClick={handleLoginRoute}>
                Get Started
                <img className="ml-3" src={arrowRight} alt="right arrow" />
              </Button>
            </div>
          </div>
        </div>
        <h4 className="landing-add-on-title">ADD-ON</h4>
        <div className="addon-sec">
          <div className="package-tag">
            <p>Popular</p>
          </div>
          <div className="addon-infor">
            <div className="edit-email-package-wrapper">
              <div className="edit-email-package-left">
                <p className="edit-package-left-title edit-package2">
                  Winemaking Event Packages
                </p>
                <h1 className="subprobs-dollar subprobs-dollar2">
                  {CURRENCY_SYMBOL}
                  { subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? addOnPriceMonthly : addOnPriceYearly}
                  <span className="default-month default-month2">
                    /
                    {DurationLabel}
                  </span>
                </h1>
              </div>
            </div>
            <div className="addon-des">
              <p>
                This package enables advanced options, including fermentation
                kinetics, temperature stratification, and more.
              </p>
              <p>
                Winemakers will be able to tag data points with winemaking events
                like pump-overs or nutrient additions to help identify fermentation cause and effect.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="our-customer-container">
        {/* <img src={shadow} alt='plan shadow' /> */}
        <div className="customer-heading">
          <img className="customer-arrow" src={customerArrow} alt="cusotmer arrow" />
          <h3 className="customer-title">
            <ScrollAnimation animateIn="bounceInLeft">
              what Our Customer Are Saying
            </ScrollAnimation>
          </h3>
          <p className="customer-subtitle">
            We take pride in delivering exceptional service and top-notch
            products to our valued customers.
          </p>
        </div>
        <div className="ad-slider-multiple">
          <Slider {...AD_BOTTOM_SLIDER_SETTINGS}>
            <div className="testy-item-card">
              <img className="quote" src={quoteIcon} alt="" />
              <p>
                The amount of information we are gathering with the MSP is
                impressive. It&apos;s crazy to think the industry has been making
                wine with a single temperature probe on the tank for this
                long. The temperature stratification we are seeing is truly
                enlightening and we are thinking differently about how we
                manage our ferments.
              </p>
              <h6>- Napa Winemaker</h6>
            </div>
            <div className="testy-item-card">
              <img className="quote" src={quoteIcon} alt="quoteIcon" />
              <p>
                We are looking to turn our tanks over several times per
                vintage. Understanding we had little fermentation action near
                the bottom of the tanks versus possibly overheating in the cap
                was a surprise. We adjusted our mixing and pumpover regimen to
                reduce the temp differences and were able to shorten our tank
                duration by 1-2 days which adds up at our volume.
              </p>
              <h6>- New Zealand Winemaker</h6>
            </div>
            <div className="testy-item-card">
              <img className="quote" src={quoteIcon} alt="quoteIcon" />
              <p>
                It can be boring and tedious work collecting Brix and temps at
                least twice daily. Also finding the labor to perform the task
                has become more difficult lately. The MSP provides us all the
                B&T&apos;s we could ever need and automatically ports the
                information into our inventory system which removes paperwork
                and data entry.
              </p>
              <h6>- Napa Winemaker</h6>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default LandingSubscriptionPlan;
