import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import downArrow from '../../../assets/icons/arrow.svg';
import {
  BOOLEANS, NETWORK_ONLY, ROLE, ROUTE_PATH
} from '../../../constants';
import { DropDownMenuProps } from '../../../types';
import { generateImageUrl } from '../../../utils/helpers';
import { GET_USER_HEADER } from '../../../graphql/mutation';

const DropDownMenu = ({
  handleModalStatus,
  openLogoutModal,
  accessToken,
  handleMenu,
  isHomePage = BOOLEANS.FALSE,
}: DropDownMenuProps) => {
  const navigate = useNavigate();
  const handleAccountRoute = () => {
    navigate(ROUTE_PATH.USER_PROFILE);
    handleMenu?.();
  };

  const { data: userData } = useQuery(GET_USER_HEADER, {
    fetchPolicy: NETWORK_ONLY,
  });
  const userProfileData = userData?.getUser?.userInfo || {};

  const { fullName, profileImg, wineryName } = userProfileData || {};
  const { role } = accessToken || {};

  const handleDashboardLink = () => {
    if (role === ROLE.ADMIN) {
      navigate(ROUTE_PATH.ADMIN_DASHBOARD);
    } else {
      navigate(ROUTE_PATH.LOGIN);
    }
  };

  return (
    <div className="menu-dropdown">
      <Dropdown>
        <Dropdown.Toggle
          className="menu-dropdown-toggle"
          id="dropdown-basic"
          data-testid="menuToggle"
        >
          {role === ROLE.ADMIN ? (
            <>
              <div className="profile-img">
                <img
                  src={generateImageUrl(accessToken.profileImg)}
                  alt="profile"
                />
              </div>
              <div className="header-user-detail">
                <span className="user-title">{accessToken.fullName}</span>
              </div>
            </>
          ) : (
            <>
              <div className="profile-img">
                <img src={generateImageUrl(profileImg)} alt="profile" />
              </div>
              <div className="header-user-detail header-user-detail-mobile">
                <span className="user-title">{fullName}</span>
                <p className="user-subtitle">{wineryName}</p>
              </div>
            </>
          )}
          <span className="dropdown-img">
            <img src={downArrow} alt="down arrow" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="header-profile-top-arrow">
          {isHomePage ? (
            <Dropdown.Item
              className="change-profile"
              onClick={handleDashboardLink}
            >
              <span className="icon-main-menu icon-dashboard-menu">
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 18V8.5H17V18H10ZM0.5 10L0.5 0.5H7.5V10H0.5ZM6 8.5V2H2V8.5H6ZM0.5 18L0.5 12.5H7.5V18H0.5ZM2 16.5H6V14H2V16.5ZM11.5 16.5H15.5V10H11.5V16.5ZM10 0.5H17V6H10V0.5ZM11.5 2V4.5H15.5V2H11.5Z" fill="white" />
                </svg>
              </span>
              <span className="text-main-menu">Dashboard</span>
            </Dropdown.Item>
          ) : null}
          {accessToken.role === ROLE.ADMIN && (
            <Dropdown.Item
              className="change-profile"
              onClick={handleModalStatus}
            >
              <span className="icon-lock icon-main-menu" />
              <span className="text-main-menu">Change Password</span>
            </Dropdown.Item>
          )}
          {accessToken.role === ROLE.USER && (
            <Dropdown.Item
              className="logout-profile"
              onClick={handleAccountRoute}
            >
              <span className="icon-username icon-main-menu" />
              <span className="text-main-menu">My Account</span>
            </Dropdown.Item>
          )}
          <Dropdown.Item className="logout-profile" onClick={openLogoutModal}>
            <span className="icon-logout-new icon-main-menu" />
            <span className="text-main-menu">Log Out</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropDownMenu;
