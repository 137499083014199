import React, { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_PUBLIC_KEY = 'pk_live_51J6KRLS9ItCogUW8u8G35wxJvYC4K0IFAoeU0TAoKAU6ZbzdcaGbTtkE5BwLlFXhvVhh8UxNj8b7vgmbABtgFTSm00epe7HOK1';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const StripeWrapper: React.FC<{ children: ReactNode }> = ({ children }) => <Elements stripe={stripePromise}>{children}</Elements>;

export default StripeWrapper;
