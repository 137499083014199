import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Form, Row, Col,
} from 'react-bootstrap';
import ModalWrapper from '../shared/common/modalWrapper';
import {
  ADD_NEW_PLAN_VALIDATION_SCHEMA,
  BOOLEANS, INPUT_ALPHANUMERIC, INPUT_NUMERIC, NUMBER, addNewPlanSelectPlan
} from '../../constants';
import Input from '../shared/forms/input';
import Select from '../shared/forms/select';

const AddNewPlan = ({ open, close, success }: any) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(ADD_NEW_PLAN_VALIDATION_SCHEMA),
    shouldFocusError: BOOLEANS.FALSE,
  });
  const submit = () => {
    success(getValues());
    reset();
    close(false);
  };

  return (
    <ModalWrapper
      show={open}
      handleClose={close}
      title="Add New Plan"
    >
      <Form onSubmit={handleSubmit(submit)}>
        <Row className="modal-body-form">
          <Col xs={12} sm={12} className=" ">
            <Select
              placeholder="Select"
              control={control}
              name="isWineMaking"
              label="Plan"
              value={getValues('isWineMaking')}
              setValue={setValue}
              options={addNewPlanSelectPlan}
              error={errors?.isWineMaking?.message}
            />
          </Col>
          <Col xs={12} sm={12} className=" ">
            <Input
              label="Plan Name"
              name="productName"
              placeholder="Enter plan name"
              refValues={register}
              setValue={setValue}
              error={errors?.productName?.message}
            />
          </Col>
          <Col xs={12} sm={12} className=" ">
            <Input
              label="Price"
              name="price"
              placeholder="Enter price"
              refValues={register}
              setValue={setValue}
              error={errors?.price?.message}
              valuePattern={INPUT_NUMERIC}
              maxLength={NUMBER.FIVE}
            />
          </Col>
          <Col xs={12} sm={12} className="add-new-address">
            <div className="wrap-input">
              <Input
                error={errors?.description?.message}
                valuePattern={INPUT_ALPHANUMERIC}
                maxLength={NUMBER.ONE_HUNDRED}
                as="textarea"
                label="Description"
                isOptional
                refValues={register}
                setValue={setValue}
                inputClass="mb-0"
                name="description"
                placeholder="Write description"
              />
              <span className="word-count">
                {getValues('description')?.length || 0}
                /
                {NUMBER.ONE_HUNDRED}
              </span>
            </div>
          </Col>
        </Row>
        <div className="footer-modal">
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            disabled={!isValid}
          >
            Add
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default AddNewPlan;
