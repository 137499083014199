import React, { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import paymentSuccessfulIcon from '../../assets/img/payment-successful.svg';
import paymentFailSvg from '../../assets/img/payment-failed.svg';
import UserNavbar from '../../components/shared/common/userNavbar';
import { ROUTE_PATH } from '../../constants/routes';
import { LAST_TRANSACTION } from '../../graphql/mutation';
import CustomLoader from '../../components/shared/common/customLoader';
import {
  DATE_TIME_FORMATE,
  GO_TO_DASHBOARD,
  NUMBER,
  PAYMENT_STATUS,
  REPAY,
  USER_INFO,
} from '../../constants';
import {
  formatNumberToLocaleString, getSession, getUserInfo, saveSessions
} from '../../utils/helpers';
import Footer from '../../components/footer';
import { CREDIT_CARD } from '../../constants/string';

interface PaymentDataProps {
  amount: number;
  paymentStatus: string;
  createdAt: string;
  invoiceId: string;
  isWineMakingPackage: boolean;
  created: number;
}

function PaymentSuccessful() {
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  const [userData, setUserData] = useState<PaymentDataProps | null>(null);
  const session = getSession(USER_INFO);
  const [refetch, { loading }] = useLazyQuery(LAST_TRANSACTION);
  const {
    amount, paymentStatus, invoiceId, isWineMakingPackage, created = NUMBER.ZERO,
  } = userData || {};
  const transactiondate = moment.unix(created).format(DATE_TIME_FORMATE);
  const getData = async () => {
    const { data } = await refetch();
    setUserData(() => data?.lastTransaction?.data);
    saveSessions({ ...session, ...data?.lastTransaction?.data });
  };
  useEffect(() => {
    getData();
  }, []);

  const isPaymentFailed = paymentStatus === PAYMENT_STATUS.OPEN;
  const buttonLabel = isPaymentFailed ? REPAY : GO_TO_DASHBOARD;

  const navigateToDashboard = () => {
    const targetRoute = isPaymentFailed
      ? ROUTE_PATH.SUBSRIPTION
      : ROUTE_PATH.USER_DASHBOARD;
    navigate(targetRoute);
  };
  const paymentPrice = (Number(amount) / NUMBER.ONE_HUNDRED).toFixed(
    NUMBER.TWO
  );

  return (
    <>
      {loading ? <CustomLoader /> : null}
      <UserNavbar />
      <div className="succesull-main-sec">
        <div className="pricing-successful-sec">
          <div className="successful-wrap-sec">
            <img
              className="payment-successful-icon"
              src={isPaymentFailed ? paymentFailSvg : paymentSuccessfulIcon}
              alt="successful icon"
            />
            <h2 className="successful-title">
              {isPaymentFailed ? 'Payment Failed' : 'Payment Successful!'}
            </h2>

            {isPaymentFailed ? (
              <p>
                We regret to inform you that the payment has been failed due to
                some reason. To ensure a smooth and successful transaction, we
                kindly request you to take a moment to review your payment
                details and attempt the payment again.
              </p>
            ) : (
              <p>
                Thank you for your payment and for choosing our services. We
                value your trust and confidence in us. We hope you enjoy the
                benefits of your purchase and have a fantastic experience ahead.
              </p>
            )}

            <div className="successful-listing-wrapper">
              <ListGroup className="successful-listing">
                <ListGroup.Item>
                  <p className="listing-item-left">Transaction ID</p>
                  <p className="listing-item-right">{`#${invoiceId}`}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p className="listing-item-left">Transaction Date & Time</p>
                  <p className="listing-item-right">{transactiondate}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p className="listing-item-left">Amount</p>
                  <p className="listing-item-right">
                    {`$${Number.isNaN(+paymentPrice) ? NUMBER.ZERO : formatNumberToLocaleString(+paymentPrice)}`}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p className="listing-item-left">Add-Ons</p>
                  <p className="listing-item-right">{`${isWineMakingPackage ? 'Yes' : 'No'}`}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p className="listing-item-left">Payment Type</p>
                  <p className="payment-successful-bank">{CREDIT_CARD}</p>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="successful-note">
              <p>
                <span>Note:</span>
                {' '}
                A copy of payment receipt has been mailed to
                your registered email ID (
                {userInfo.email}
                )
              </p>
            </div>
          </div>
          <div className="successful-to-dashboard">
            <Button
              onClick={navigateToDashboard}
              className="btn primary successful-dashboard-btn"
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default PaymentSuccessful;
