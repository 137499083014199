import React, { useState } from 'react';
import UserNavbar from '../../components/shared/common/userNavbar';
import SubscriptionHeader from '../../components/subscription/SubscriptionHeader';
import PackageSection from '../../components/subscription/PackageSection';
import ProbeModel from '../../components/subscription/ProbeModel';
import PaymentSecure from '../../components/subscription/PaymentSecure';
import SubsFooter from '../../components/subscription/SubsFooter';

function Subscription() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <UserNavbar />
      <div className="pricing-sec">
        <SubscriptionHeader />
        <PackageSection handleShow={handleShow} />
        <PaymentSecure />
      </div>
      <SubsFooter />
      <ProbeModel show={show} handleClose={handleClose} />
    </>
  );
}

export default Subscription;
