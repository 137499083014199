import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  USER_ENABLE_MODAL,
  USER_DISABLE_MODAL,
} from '../../../constants/modal';
import { DISABLE_USER } from '../../../graphql/mutation/admin.mutation';
import PromptModal from '../../promptModal';
import CustomLoader from '../../shared/common/customLoader';
import userDisableIcon from '../../../assets/icons/user-active.svg';
import userEnableIcon from '../../../assets/icons/user-disable.svg';
import { DisableUserModalProps } from '../../../types';
import { DISABLE_USER_FAILED, INACTIVE_STATUS, PROBE_STATUS } from '../../../constants';

const DisableUserModal = ({
  selectedUser,
  isModalDisableOpen,
  setIsModalDisableOpen,
  setSelectedUser,
  status,
  onSuccess,
}: DisableUserModalProps) => {
  const [disableUser, { loading: isDisabling }] = useMutation(DISABLE_USER);

  const handleUserDelete = async () => {
    try {
      const res = await disableUser({
        variables: { id: selectedUser },
      });
      if (res) {
        toast.success(res.data.disableUser.message);
        setSelectedUser('');
        setIsModalDisableOpen(false);
        if (status === 'active') {
          onSuccess('inActive');
        } else {
          onSuccess('active');
        }
      } else {
        throw new Error(DISABLE_USER_FAILED);
      }
    } catch (error) {
      setIsModalDisableOpen(false);
    }
  };

  return (
    <>
      {isDisabling && <CustomLoader />}
      {isModalDisableOpen && (
        <PromptModal
          imgPath={status === INACTIVE_STATUS ? userEnableIcon : userDisableIcon}
          open={!!isModalDisableOpen}
          cancel={() => (status === INACTIVE_STATUS ? handleUserDelete() : setIsModalDisableOpen(false))}
          handleAction={() => (status === INACTIVE_STATUS ? setIsModalDisableOpen(false) : handleUserDelete())}
          {...(status === INACTIVE_STATUS ? USER_ENABLE_MODAL : USER_DISABLE_MODAL)}
        />
      )}
    </>
  );
};

export default DisableUserModal;
