import React from 'react';

const SectionTwelve = () => (
  <>
    <h3>Section 17 - Entire Agreement</h3>
    <p className="para">
      This Agreement constitutes the entire agreement between you and Wine
      Technology Inc.and shall govern the use of our Services, superseding any
      prior version of this Agreement between you and us with respect to Wine
      Technology Inc. Services. You may also be subject to additional terms and
      conditions that may apply when you use or purchase certain other Wine
      Technology Inc. Services, affiliate Services, third-party content or
      third-party software.
    </p>

    <h3>Section 18 - Choice Of Law And Forum</h3>
    <p className="para">
      It is at the mutual agreement of both you and Wine Technology Inc. with
      regard to the Agreement that the relationship between the parties shall be
      governed by the laws of the state of California without regard to its
      conflict of law provisions and that any and all claims, causes of action
      and/or disputes, arising out of or relating to theAgreement, or the
      relationship between you and Wine Technology Inc., shall be filed within
      the courts having jurisdiction within the County of Sonoma, California or
      the U.S. District Court located in said state. You and Wine Technology
      Inc. agree to submit to the jurisdiction of the courts as previously
      mentioned, and agree to waive any and all objections to the exercise of
      jurisdiction over the parties by such courts and to venue in such courts.
    </p>

    <h3>Section 19 - Waiver And Severability Of Terms</h3>
    <p className="para">
      You acknowledge, understand and agree that your account is
      non-transferable and any rights to your ID and/or contents within your
      account shall terminate upon your death. Upon receipt of a copy of a death
      certificate, your account may be terminated and all contents therein
      permanently deleted.
    </p>

    <h3>Section 20 - No Right Of Survivorship Non-transferability</h3>
    <p className="para">
      You acknowledge, understand and agree that your account is
      non-transferable and any rights to your ID and/or contents within your
      account shall terminate upon your death. Upon receipt of a copy of a death
      certificate, your account may be terminated and all contents therein
      permanently deleted.
    </p>

    <h3>Section 21 - Statute Of Limitations</h3>
    <p className="para">
      You acknowledge, understand and agree that regardless of any statute or
      law to the contrary, any claim or action arising out of or related to the
      use of our Services or the Agreement must be filed within 1 year(s) after
      said claim or cause of action arose or shall be forever barred.
    </p>

    <h3>Section 22 - Violations</h3>
    <p className="para">
      Please report any and all violations of this Agreement to Wine Technology
      Inc. as follows:
    </p>
    <p className="para">
      Mailing Address: <br />
      Wine Technology Inc <br />
      474 Walten Way <br />
      Windsor, California 95492
    </p>
    <p className="para">
      Telephone: 720.284.2059 <br />
      Email: <a className="static-email" href="mailto:kelly@vinwizard.us">kelly@vinwizard.us</a>
    </p>

    <h3>Section 23 - Government Requests</h3>
    <p className="para">
      In order to cooperate with governmental requests, subpoenas or court
      orders, to protect our systems, or to ensure the integrity and operation
      of our business and systems, we may access and disclose any information we
      consider necessary or appropriate, including and without limitation, your
      information, IP address, and usage history. Our right to disclose any such
      information is governed by the terms of our Privacy Policy.
    </p>

    <h3>Section 24 - Foreign Access Of Site</h3>
    <p className="para">
      The Site is controlled, operated and administered by MSPfrom our offices
      within the USA. If you access the Site from a location outside the USA,
      you are responsible for compliance with all local laws. You agree that you
      will not use MSP's content accessed through the Site in any country or in
      any manner prohibited by any applicable laws, restrictions or regulations
    </p>

    <h3>Section 25 - Errors, Inaccuracies And Omissions</h3>
    <p className="para">
      Occasionally there may be information on our Site that contains
      typographical errors, inaccuracies or omissions that may relate to product
      descriptions, pricing, promotions, offers, product shipping charges,
      transit times and availability. We reserve the right to correct any
      errors, inaccuracies or omissions, and to change or update information or
      cancel orders if any information on the Site or on any related Site is
      inaccurate at any time without prior notice (including after you have
      submitted your order).
    </p>
    <p className="para">
      We undertake no obligation to update, amend or clarify information on the
      Site or on any related Site, including without limitation, pricing
      information, except as required by law. No specified update or refresh
      date applied on the Site or on any related Site, should be taken to
      indicate that all information on the Site or on any related Site has been
      modified or updated.
    </p>
  </>
);

export default SectionTwelve;
