import React from 'react';

const TermsMainHeader = () => (
  <>
    <h1 className="mainheading">Terms & Conditions</h1>
    <p className="para">
      Please read the following terms of service agreement carefully. By
      accessing or using our website (the &quot;
      <strong>Site</strong>
      &quot;) or our services, you hereby agree to be bound by these terms and
      conditions and all terms incorporated herein by reference. It is the
      responsibility of you, the user, customer, or prospective customer
      to read the terms and conditions before proceeding to use this site.
      If you do not expressly agree to all of the terms and conditions,
      then please do not access or use our site or our services.
    </p>
    <p className="para">
      The present terms and conditions (this &quot;
      <strong>Agreement</strong>
      &quot;
      or &quot;
      <strong>Terms</strong>
      &quot;) is a legal agreement between you and Wine Technology Inc.
      (hereinafter &quot;
      <strong>MSP</strong>
      &quot;), a company duly organized and validly existing, located at 474
      Walten Way, Windsor, California 95492. This Agreement annuls and
      voids all previous agreements.
    </p>
    <h3>Overview</h3>
    <p className="para">
      The Site (www.msprobe.com) is operated by MSP. Throughout the Site,
      the terms &quot;
      <strong>we</strong>
      &quot;, &quot;
      <strong>us</strong>
      &quot; and &quot;
      <strong>our</strong>
      &quot; refer to MSP.
      MSPoffers this Site, including all information, tools and services
      available from this Site to you, the user, conditioned upon your
      acceptance of all terms, conditions, policies and notices stated
      here.
    </p>
    <p className="para">
      By visiting our Site and/or purchasing something from us, you engage
      in our &quot;
      <strong>Service</strong>
      &quot; and agree to be bound by the
      following terms and conditions, including those additional terms and
      conditions and policies referenced herein and/or available by
      hyperlink. These Terms apply to all users of the Site, including
      without limitation users who are browsers, vendors, customers,
      merchants, and/or contributors of content. In the event of an
      inconsistency between this Agreement and any additional terms or
      policies referenced herein, the provisions of the additional terms
      or policies shall control.
    </p>
    <p className="para">
      Please read these Terms carefully before accessing or using our
      Site. By accessing or using any part of the Site, you agree to be
      bound by these Terms. If you do not agree to all the Terms of this
      Agreement, then you may not access the Site or use any Service. If
      these Terms are considered an offer, acceptance is expressly limited
      to these Terms.
    </p>
    <p className="para">
      Any new features or tools which are added to the current store shall
      also be subject to the Terms. You can review the most current
      version of the Terms at any time on this page. We reserve the right
      to update, change or replace any part of these Terms by posting
      updates and/or changes to our Site. It is your responsibility to
      check this page periodically for changes. Your continued use of or
      access to the Site following the posting of any changes constitutes
      acceptance of those changes.
    </p>
  </>
);

export default TermsMainHeader;
