import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ADD_USER_MUTATION } from '../../graphql/mutation/admin.mutation';
import UserForm from './userForm';
import { UserFormProps } from '../../types';
import { ROLE, ROUTE_PATH } from '../../constants';
import CustomLoader from '../shared/common/customLoader';

const AddUser: React.FC = () => {
  const [addUser, { loading }] = useMutation(ADD_USER_MUTATION);
  const navigate = useNavigate();

  /**
   * The function `handleAddUser` is an asynchronous function that adds a user and displays a success
   * message using the `toast` library.
   * @param {object} values - The `values` parameter is an object that contains the data for adding a
   * user. It likely includes properties such as `name`, `email`, `password`, etc.
   */
  const handleAddUser = async (values: Record<string, any>) => {
    const res = await addUser({
      variables: {
        ...values,
        wineryProductionVolume: parseFloat(values?.wineryProductionVolume),
        billingAddress: values?.billingAddress || values?.wineryAddress,
      },
    });
    if (res) {
      toast.success(res.data.addUser.message);
      navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT);
    }
  };

  // Pass the onSuccess prop to the UserForm component
  const userFormProps: UserFormProps = {
    buttonName: 'ADD',
    headerTitle: 'Add New User',
    initialValues: {},
    onSuccess: (values: object) => {
      handleAddUser(values);
    },
    role: ROLE.ADMIN,
  };

  return (
    <div>
      {loading && <CustomLoader />}
      <UserForm {...userFormProps} />
    </div>
  );
};

export default AddUser;
