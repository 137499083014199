import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import verifiedSuccessfully from '../../assets/img/verified-successfully.png';
import { ROUTE_PATH } from '../../constants/routes';
import { getSession } from '../../utils/helpers';
import { USER_INFO } from '../../constants/forms/keys';

function VerifiedSuccessfully() {
  const navigate = useNavigate();
  const session = getSession(USER_INFO);

  if (!session?.fullName) {
    return <Navigate to={ROUTE_PATH.LOGIN} />;
  }

  return (
    <Col sm={6} className="p-0 boarding-login onboarding-right">
      <div className="login-inner">
        <div className="boarding-title verified-successfully-wrap">
          <img
            className="verified-successfully"
            src={verifiedSuccessfully}
            alt="verified successfully"
          />
          <h1 className="verified-successfully-title">
            Hi
            {' '}
            {session?.fullName}
            !
          </h1>
          <p className="verified-successfully-des">
            Your email has been verified successfully.
            {' '}
            <br />
            Please use the button below to sign in to your account.
          </p>
        </div>
        <div className="verified-successfully-btn">
          <Button
            className="btn primary w-100"
            onClick={() => navigate(ROUTE_PATH.LOGIN)}
          >
            Back to sign in
          </Button>
        </div>
      </div>
    </Col>
  );
}

export default VerifiedSuccessfully;
