import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { ROUTE_PATH } from '../../../constants/routes';
import DeleteUserModal from './deleteUserModal';
import DisableUserModal from './disableUserModal ';
import {
  BUTTON, NO, PAID, YES, phoneNoFormate
} from '../../../constants';
import { UserListingProps } from '../../../types';

function UserListItem({ user }: UserListingProps) {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDisableOpen, setIsModalDisableOpen] = useState(false);
  const [status, setStatus] = useState(user?.status);

  const handleUserDetails = (id: string) => {
    navigate(`${ROUTE_PATH.ADMIN_USER_MANAGEMENT_USER_DETAILS}/${id}`);
  };

  const handleEditUser = (id: string) => {
    navigate(`${ROUTE_PATH.ADMIN_USER_MANAGEMENT}/${id}/${ROUTE_PATH.EDIT_USER}`);
  };

  return (
    <div
      className="row tr"
      onClick={() => handleUserDetails(user.id)}
      key={user?.id}
    >
      <div className="td flex-table-column-20" data-title="Full name">
        <div className="first-column-wrapper dir-column">
          <h5 className="listing-title">
            {`${user.fullName} - `}
            {user.isEmailVerified === true ? (
              <span className="text-name verify">Verified</span>
            ) : (
              <span className="text-name notverify">Not Verified</span>
            )}
          </h5>
          <div className="text-email text-truncate">{user.email || '–'}</div>
        </div>
      </div>
      <div className="td flex-table-column-15" data-title="Winery name">
        <div className="">
          <p className="listing-normal">{user.wineryName || '–'}</p>
        </div>
      </div>
      <div className="td flex-table-column-15" data-title="Subscription">
        <div className="">
          <p className="listing-normal">
            {user.paymentStatus === PAID ? YES : NO}
          </p>
        </div>
      </div>
      <div className="td flex-table-column-15" data-title="Contact number">
        <div className="">
          <p className="listing-normal">{phoneNoFormate(user.contactNumber) || '–'}</p>
        </div>
      </div>
      <div className="td flex-table-column-15" data-title="Assigned probes">
        <div className="">
          <p className="listing-normal">{user.totalProbe || '–'}</p>
        </div>
      </div>
      <div className="td flex-table-column-10" data-title="Status">
        {status === 'inActive' ? (
          <div className="">
            <p className="btn-status deactive">Disabled</p>
          </div>
        ) : (
          <div className="">
            <p className="btn-status active">Active</p>
          </div>
        )}
      </div>
      <div className="td flex-table-column-10">
        <div className="">
          <div
            className="listing-normal text-center"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
          >
            <DropdownButton
              className="icon-three-dot manage-three-dot three-dot-align"
              title=""
              rootCloseEvent="mousedown"
            >
              <Dropdown.Item
                onClick={() => {
                  setIsModalDisableOpen(true);
                  setSelectedUser(user.id);
                }}
              >
                {status === 'inActive' ? BUTTON.ENABLE : BUTTON.DISABLE}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleEditUser(user.id)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setIsModalOpen(true);
                  setSelectedUser(user.id);
                }}
              >
                Delete
              </Dropdown.Item>
            </DropdownButton>
            {isModalDisableOpen && (
              <DisableUserModal
                selectedUser={selectedUser}
                isModalDisableOpen={isModalDisableOpen}
                setIsModalDisableOpen={setIsModalDisableOpen}
                setSelectedUser={setSelectedUser}
                status={status}
                onSuccess={(s: string) => setStatus(s)}
              />
            )}
            {isModalOpen && (
              <DeleteUserModal
                selectedUser={selectedUser}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setSelectedUser={setSelectedUser}
                userName={user.fullName}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserListItem;
