import React from 'react';

const SectionOne = () => (
  <>
    <h1 className="mainheading">Privacy Policy Agreement</h1>
    <p className="para">
      Wine Technology Inc ( MSP) values its users' privacy. This Privacy Policy
      ("<strong>Policy</strong>") will help you understand how we collect and
      use personal information from those who visit our website or make use of
      our online facilities and services, and what we will and will not do with
      the information we collect. Our Policy has been designed and created to
      ensure those affiliated with Wine Technology Inc of our commitment and
      realization of our obligation not only to meet, but to exceed, most
      existing privacy standards.
    </p>
    <p className="para">
      We reserve the right to make changes to this Policy at any given time. If
      you want to make sure that you are up to date with the latest changes, we
      advise you to frequently visit this page. If at any point in time Wine
      Technology Inc decides to make use of any personally identifiable
      information on file, in a manner vastly different from that which was
      stated when this information was initially collected, the user or users
      shall be promptly notified by email. Users at that time shall have the
      option as to whether to permit the use of their information in this
      separate manner.
    </p>

    <p className="para">
      This Policy applies to Wine Technology Inc, and it governs any and all
      data collection and usage by us. Through the use of{' '}
      <a className="static-link" href="www.msprobe.com" target="_blank">
        www.msprobe.com
      </a>
      , you are therefore consenting to the data collection procedures expressed
      in this Policy.
    </p>

    <p className="para">
      Please note that this Policy does not govern the collection and use of
      information by companies that Wine Technology Incdoes not control, nor by
      individuals not employed or managed by us. If you visit a website that we
      mention or link to, be sure to review its privacy policy before providing
      the site with information. It is highly recommended and suggested that you
      review the privacy policies and statements of any website you choose to
      use or frequent to better understand the way in which websites garner,
      make use of and share the information collected.
    </p>
    <p className="para mb-30">
      Specifically, this Policy will inform you of the following
    </p>
    <div className="indent-sec">
      <p className="indent-num">
        1. What personally identifiable information is collected from you
        through our website.
      </p>
      <p className="indent-num">
        2. Why we collect personally identifiable information and the legal
        basis for such collection.
      </p>
      <p className="indent-num">
        3. How we use the collected information and with whom it may be shared.
      </p>
      <p className="indent-num">
        4. What choices are available to you regarding the use of your data.
      </p>
      <p className="indent-num">
        5. The security procedures in place to protect the misuse of your
        information.
      </p>
    </div>
    <h3>Information We Collect</h3>
    <p className="para">
      It is always up to you whether to disclose personally identifiable
      information to us, although if you elect not to do so, we reserve the
      right not to register you as a user or provide you with any products or
      services. This website collects various types of information, such as:
    </p>
    {/* <ul className="unnum-list">
            <li>
              Information automatically collected when visiting our website,
              which may include cookies, third party tracking technologies and
              server logs.
            </li>
          </ul> */}
    <p className="para">
      Information automatically collected when visiting our website, which may
      include cookies, third party tracking technologies and server logs.
    </p>
    <p className="para">
      In addition, Wine Technology Inc may have the occasion to collect
      non-personal anonymous demographic information, such as age, gender,
      household income, political affiliation, race and religion, as well as the
      type of browser you are using, IP address, or type of operating system,
      which will assist us in providing and maintaining superior quality
      service.
    </p>
    <p className="para">
      Wine Technology Incmay also deem it necessary, from time to time, to
      follow websites that our users may frequent to gleam what types of
      services and products may be the most popular to customers or the general
      public.
    </p>
    <p className="para">
      Why We Collect Information and For How Long We are collecting your data
      for several reasons:
    </p>
    <ul className="indent-sec unnum-list">
      <li>
        To better understand your needs and provide you with the services you
        have requested;
      </li>
      <li>
        To fulfill our legitimate interest in improving our services and
        products.
      </li>
      <li>
        To send you promotional emails containing information we think you may
        like when we have your consent to do so.
      </li>
      <li>
        To contact you to fill out surveys or participate in other types of
        market research, when we have your consent to do so.
      </li>
      <li>
        To customize our website according to your online behavior and personal
        preferences.
      </li>
    </ul>
    <p className="para">
      The data we collect from you will be stored for no longer than necessary.
      The length of time we retain said information will be determined based
      upon the following criteria: the length of time your personal information
      remains relevant; the length of time it is reasonable to keep records to
      demonstrate that we have fulfilled our duties and obligations; any
      limitation periods within which claims might be made; any retention
      periods prescribed by law or recommended by regulators, professional
      bodies or associations; the type of contract we have with you, the
      existence of your consent, and our legitimate interest in keeping such
      information as stated in this Policy.
    </p>
  </>
);

export default SectionOne;
