import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../../constants';

function IagreeCheckBox({ register } : any) {
  return (
    <div className="custom-checkbox">
      <label>
        <input type="checkbox" {...register('Iagree')} />
        <span className="custom-checkbox-wrap">
          I agree to MSP’s
          <Link
            className="custom-checkbox-link"
            to={ROUTE_PATH.TERMS_CONDITIONS}
            target="_blank"
          >
            Terms & Conditions
          </Link>
          and
          <Link
            className="custom-checkbox-link"
            to={ROUTE_PATH.PRIVACY}
            target="_blank"
          >
            Privacy Policy
          </Link>
        </span>
      </label>
    </div>
  );
}

export default IagreeCheckBox;
