import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Input from '../shared/forms/input';
import PhoneInput from '../shared/forms/phoneInput';
import Checkbox from '../shared/forms/checkbox';
import Select from '../shared/forms/select';
import DateInput from '../shared/forms/datePicker';
import {
  FISCAL_YEAR_MONTH_FORMAT,
  INPUT_ALPHABETS,
  INPUT_ALPHANUMERIC,
  NUMBER,
  ROLE,
  WINERY_PRODUCTION_VOLUME,
  productionVolumeCapacity,
} from '../../constants';
import { getFiscalYearDateObject } from '../../utils/helpers';
import AddressInput from '../shared/forms/addressInput';

const UserDetailsForm = ({
  register,
  setValue,
  errors,
  getValues,
  role,
  setError
}: any) => {
  const {
    TWELVE, EIGHT, NINE, SIX, FIFTY, THIRTY_TWO, TEN
  } = NUMBER;
  return (
    <Col sm={TWELVE} md={EIGHT} lg={NINE}>
      <div className="filter-sec bgcolor-input-select filter-sec-bottom">
        <div className="filter-by-heading">User Details</div>
        <Row>
          <Col sm={TWELVE} md={SIX} lg={SIX}>
            <Input
              type="text"
              name="fullName"
              label="Full Name"
              placeholder="Enter full name"
              refValues={register}
              setValue={setValue}
              error={errors?.fullName?.message}
              valuePattern={INPUT_ALPHABETS}
              maxLength={THIRTY_TWO}
            />
          </Col>
          <Col xs={TWELVE} sm={SIX}>
            <PhoneInput
              setValue={setValue}
              name="contactNumber"
              label="Contact Number"
              error={errors?.contactNumber?.message}
              phone={getValues('contactNumber')}
              setCountryCode={() => {}}
            />
          </Col>
        </Row>
        <Col xs={TWELVE} sm={TWELVE}>
          <Input
            label="Email Address"
            type="email"
            inputClass="mb-0"
            name="email"
            placeholder="Enter email address"
            refValues={register}
            error={errors?.email?.message}
            setValue={setValue}
            maxLength={FIFTY}
            disabled={role === ROLE.USER}
          />
        </Col>
        <div className="filter-by-heading winery-detail-space">
          Winery Details
        </div>
        <Row>
          <Col xs={TWELVE} sm={TWELVE}>
            <Input
              type="text"
              name="wineryName"
              label="Winery Name"
              placeholder="Enter winery name"
              refValues={register}
              error={errors?.wineryName?.message}
              setValue={setValue}
              valuePattern={INPUT_ALPHANUMERIC}
              maxLength={FIFTY}
            />
          </Col>
          <Col xs={TWELVE} sm={TWELVE} className="winery-address-sec">
            <AddressInput
              wrapperClass="winery-input"
              type="text"
              name="wineryAddress"
              label="Winery Address"
              placeholder="Enter winery address"
              refValues={register}
              setValue={setValue}
              error={errors?.wineryAddress?.message}
              valuePattern={INPUT_ALPHANUMERIC}
              maxLength={NUMBER.FIFTY}
              getValue={getValues}
              setError={setError}
            />
            <Checkbox
              wrapperClass="checkbox-input"
              name="differentBillingAddress"
              label="Different billing address?"
              refValues={register}
              setValue={setValue}
            />
          </Col>
          {getValues('differentBillingAddress') && (
            <Col xs={TWELVE} sm={TWELVE}>
              <AddressInput
                type="text"
                name="billingAddress"
                label="Billing Address"
                placeholder="Enter billing address"
                refValues={register}
                setValue={setValue}
                error={errors?.billingAddress?.message}
                valuePattern={INPUT_ALPHANUMERIC}
                maxLength={FIFTY}
                getValue={getValues}
                setError={setError}
              />
            </Col>
          )}
          <Col xs={TWELVE} sm={SIX} className="multi-select-custom">
            <Select
              options={productionVolumeCapacity}
              placeholder="Select"
              value={getValues('wineryProductionVolumeUnit')}
              name="wineryProductionVolumeUnit"
              label="Winery Production Volume Unit"
              error={errors?.wineryProductionVolumeUnit?.message}
              setValue={setValue}
            />
          </Col>
          <Col xs={TWELVE} sm={SIX}>
            <Input
              wrapperClass=""
              inputClass="form-mt-space"
              type="text"
              name="wineryProductionVolume"
              label="Volume"
              placeholder="Enter volume"
              refValues={register}
              setValue={setValue}
              error={errors?.wineryProductionVolume?.message}
              valuePattern={WINERY_PRODUCTION_VOLUME}
              maxLength={TEN}
            />
          </Col>
          <Col xs={TWELVE} sm={TWELVE} className="date-select-custom">
            <DateInput
              dateFormat={FISCAL_YEAR_MONTH_FORMAT}
              label="Winery Fiscal Year End Month"
              iconClass="icon-calender on-input-icon-right"
              name="wineryFiscalYearEndMonth"
              setValue={setValue}
              error={errors?.wineryFiscalYearEndMonth?.message}
              minDate={new Date()}
              selected={getFiscalYearDateObject(
                getValues('wineryFiscalYearEndMonth')
              )}
              showMonthYearPicker
              placeholder="Select"
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default UserDetailsForm;
