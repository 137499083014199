import { useQuery } from '@apollo/client';
import React from 'react';
import { GET_WINERY_ADDRESSES } from '../graphql/queries/admin.queries';

interface WineryOption {
  value: string;
  label: string;
}

const useWinerySorting = (): { wineryOptions: WineryOption[] } => {
  const { data } = useQuery(GET_WINERY_ADDRESSES);

  const sortedWineryAddresses: WineryOption[] = React.useMemo(() => {
    if (data?.winaryAddress?.data) {
      return [...data.winaryAddress.data]
        .sort((a, b) => a.wineryName?.localeCompare(b.wineryName))
        .map((element) => ({
          value: element.id,
          label: `${element.wineryName}( ${element.email} )`,
        }));
    }
    return [];
  }, [data?.winaryAddress?.data]);

  return { wineryOptions: sortedWineryAddresses };
};

export default useWinerySorting;
