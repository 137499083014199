import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from '../../../constants/routes';
import tankPic from '../../../assets/img/tank-pic.png';
import deleteIcon from '../../../assets/icons/icon-delete.svg';
import ActionDropDown from '../../../components/tanks/actionDropDown';
import useLogout from '../../../hooks/useLogout';
import LogOutModal from '../../../components/auth/model/LogoutModal';
import {
  ACTIVATE_TEXT,
  ACTIVE_TAG,
  DEACTIVATE_TEXT,
  DEACTIVE_MODEL_BODY,
  DEACTIVE_MODEL_HEADER,
  DELETE_MODEL_BODY,
  DELETE_MODEL_HEADER,
  DELETE_TEXT,
  DISABLED_TAG,
  PROBE_STATUS,
  UNASSIGN_MODEL_CANCEL,
} from '../../../constants';
import {
  CHANGE_PROBE_STATUS,
  DELETE_PROBE,
} from '../../../graphql/mutation/admin.mutation';
import CustomLoader from '../../../components/shared/common/customLoader';
import { checkString, getProbeStatus } from '../../../utils/helpers';

function ProbeItem(props: any) {
  const { item, fetchData, updateDetails } = props;
  const [deleteProbe, { loading }] = useMutation(DELETE_PROBE);
  const [changeStatus, { loading: statusLoading }] = useMutation(CHANGE_PROBE_STATUS);
  const navigate = useNavigate();
  const { openLogoutModal, closeLogoutModal, logoutModal } = useLogout();
  const {
    openLogoutModal: openDeactiveModel,
    closeLogoutModal: closeDeactiveModel,
    logoutModal: deactiveModel,
  } = useLogout();
  const handleUnassign = async () => {
    try {
      const response = await deleteProbe({
        variables: { id: item.id },
      });
      if (response) {
        toast.success(response?.data?.deleteProbe.message);
        closeLogoutModal();
        updateDetails({ id: item.id }, 'delete');
      }
    } catch (error) {
      closeLogoutModal();
    }
  };

  const handleStatus = async () => {
    try {
      const response = await changeStatus({
        variables: { id: item.id },
      });
      if (response) {
        toast.success(response?.data?.changeProbeStatus?.message);
        closeDeactiveModel();
        updateDetails({ ...item, status: item.status === 'active' ? 'inActive' : 'active'});
      }
    } catch (error) {
      closeDeactiveModel();
    }
  };

  const statusType = item.status === PROBE_STATUS.ACTIVE ? ACTIVE_TAG : DISABLED_TAG;
  const reverseStatusType = item.status === PROBE_STATUS.ACTIVE ? DEACTIVATE_TEXT : ACTIVATE_TEXT;
  return (
    <div>
      {(loading || statusLoading) && <CustomLoader />}
      <div
        className="row tr"
        key={item.probeId}
        onClick={() => navigate(`${ROUTE_PATH.PROBE_ADMIN_DETAIL}/${item.id}`)}
      >
        <div className="td flex-table-column-15" data-title="Probe id">
          <div className="cursor-pointer">
            <h5 className="listing-title two-line-ellipsis">
              {item.probeId}
              <span className={`text-name d-block mt-3 
              ${item?.status === PROBE_STATUS.ACTIVE
                ? 'verify' : 'notverify'}`}
              >
                {getProbeStatus(item?.status)}
              </span>
            </h5>
          </div>
        </div>
        <div className="td flex-table-column-15" data-title="Probe name">
          <div className="">
            <p className="listing-normal">
              {checkString(item.probeName)}
            </p>
          </div>
        </div>
        <div className="td flex-table-column-15" data-title="Winery name">
          <div className="">
            <p className="listing-normal">
              {checkString(item?.user?.wineryName)}
            </p>
          </div>
        </div>
        <div className="td flex-table-column-20" data-title="Email address">
          <div className="">
            <p className="listing-normal text-truncate">{checkString(item?.user?.email)}</p>
          </div>
        </div>
        <div className="td flex-table-column-15" data-title="Assigned task">
          <div className="first-column-wrapper">
            <img className="tank-pic" src={tankPic} alt="tank pic" />
            <p className="listing-normal">{checkString(item?.tank?.name)}</p>
          </div>
        </div>
        <div className="td flex-table-column-15" data-title="Status">
          <div className="">
            <p className={`btn-status ${item?.isGettingRealData ? 'active' : 'deactive'}`}>
              {item?.isGettingRealData ? 'Online' : 'Offline' }
            </p>
          </div>
        </div>
        <div className="td flex-table-column-5">
          <div className="">
            <ActionDropDown
              deleteItem={openLogoutModal}
              deleteName="delete"
              editName={reverseStatusType}
              editItem={openDeactiveModel}
            />
          </div>
        </div>
      </div>
      <LogOutModal
        open={logoutModal}
        logoutFuntion={handleUnassign}
        close={closeLogoutModal}
        headerText={DELETE_MODEL_HEADER}
        bodyText={DELETE_MODEL_BODY}
        confirmText={DELETE_TEXT}
        cancelText={UNASSIGN_MODEL_CANCEL}
        imgPath={deleteIcon}
      />
      <LogOutModal
        open={deactiveModel}
        logoutFuntion={handleStatus}
        close={closeDeactiveModel}
        headerText={DEACTIVE_MODEL_HEADER}
        bodyText={DEACTIVE_MODEL_BODY}
        confirmText={reverseStatusType}
        cancelText={UNASSIGN_MODEL_CANCEL}
      />
    </div>
  );
}

export default ProbeItem;
