import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ScrollAnimation from 'react-animate-on-scroll';
import inthedarkLine from '../../assets/img/inthedarkline.svg';
import inthedarkMain from '../../assets/img/inthedarkmain.png';
import inthedarkLeft from '../../assets/img/inthedarkleft.svg';
import inthedarkRight from '../../assets/img/inthedarkright.svg';
import partnerLogo1 from '../../assets/img/partnerlogo1.svg';
import partnerLogo2 from '../../assets/img/partnerlogo2.svg';
import partnerLogo3 from '../../assets/img/partnerlogo3.svg';
import partnerLogo4 from '../../assets/img/partnerlogo4.svg';
import partnerLogo5 from '../../assets/img/partnerlogo5.svg';
import partnerLogo6 from '../../assets/img/partnerlogo6.svg';
import partnerLogo7 from '../../assets/img/partnerlogo7.svg';
import partnerLogo8 from '../../assets/img/partnerlogo8.svg';
import partnerLogo9 from '../../assets/img/partnerlogo9.svg';
import partnerLogo10 from '../../assets/img/partnerlogo10.svg';
import partnerLogo11 from '../../assets/img/partnerlogo11.svg';
import partnerLogo12 from '../../assets/img/partnerlogo12.svg';
import partnerLogo13 from '../../assets/img/partnerlogo13.svg';
import partnerLogo14 from '../../assets/img/partnerlogo14.svg';
import partnerLogo15 from '../../assets/img/partnerlogo15.svg';
import partnerLogo16 from '../../assets/img/partnerlogo16.svg';
import partnerLogo17 from '../../assets/img/partnerlogo17.svg';
import temperatureImg from '../../assets/img/temperature-img.svg';
import temperatureSkeleton from '../../assets/img/temperature-skeleton.svg';
import skeletonImg from '../../assets/img/skeleton-img.gif';
import scrollDown from '../../assets/icons/scroll-down.svg';
import arrowUpRed from '../../assets/img/arrow-up-red.svg';
import LandingSubscriptionPlan from './LandingSubscriptionPlan';
import { ROUTE_PATH } from '../../constants';
import PublicNavBar from '../shared/common/navbar';
import TableGraph from './TableGraph';
import PreFooter from './PreFooter';
import LandingFooter from './LandingFooter';
import GetStartedButton from './GetStartedButton';

const LandingPage = () => {
  const navigate = useNavigate();
  const [scrollPercent, setScrollPercent] = useState(0);
  const handleLoginRoute = () => {
    navigate(ROUTE_PATH.SIGNUP);
  };

  const settings = {
    // centerPadding: '15',
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const [showButton, setShowButton] = useState(false);

  // Show/hide button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollInnerHeight = window.innerHeight;
      const scrollYHeight = scrollHeight - scrollInnerHeight;
      // if (scrollYHeight > 0) {
        const calculatedScrollPercent = (scrollY / scrollYHeight) * 100;
        setScrollPercent(calculatedScrollPercent);        
        if (calculatedScrollPercent > 70) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      // }
    };
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="landing-page-wrapper">
      <div className="landing-page-header">
        <PublicNavBar />
      </div>
      <div className="never-sec">
        <div className="inthe-dark-wrapper bg-image-inthe">
          <div className="bg-image-inthe2">
            <div className="inthe-container">
              <div className="heading-title-sec">
              <div className="heading">
                  Never Ferment
                  {' '}
                  <span>"In The Dark" Again</span>
                  <ScrollAnimation animateIn="flipInY" animateOut="flipOutY">
                    <img src={inthedarkLine} className="heading-line" alt="logo" />
                  </ScrollAnimation>
                </div>
              </div>
              <div className="subheading">
                The MSP provides beverage producers a level of understanding never
                before available. No more guessing what&apos;s happening inside a
                tank... now it&apos;s known.
              </div>
              <GetStartedButton handleLoginRoute={handleLoginRoute} />
              <div className="inthe-dark-img-sec">
                <img
                  className="w-100 inthedarkright3"
                  src={inthedarkMain}
                  alt="In the dark Img"
                />
                <img
                  src={inthedarkLeft}
                  alt="In the dark Img"
                  className="inthedarkleft"
                />
                <img
                  src={inthedarkRight}
                  alt="In the dark Img"
                  className="inthedarkright"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="partner-wrapper">
        <div className="partner-container">
          <div className="partner-sec">
            <div className="heading">
              <ScrollAnimation animateIn="bounceInRight">
                Working with partners around the world
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="bounceInRight">
              <div className="partner-logo-sec">
                <Slider {...settings}>
                  <img src={partnerLogo1} alt="Partner Logo" />
                  <img src={partnerLogo2} alt="Partner Logo" />
                  <img src={partnerLogo3} alt="Partner Logo" />
                  <img src={partnerLogo4} alt="Partner Logo" />
                  <img src={partnerLogo5} alt="Partner Logo" />
                  <img src={partnerLogo6} alt="Partner Logo" />
                  <img src={partnerLogo7} alt="Partner Logo" />
                  <img src={partnerLogo8} alt="Partner Logo" />
                  <img src={partnerLogo9} alt="Partner Logo" />
                  <img src={partnerLogo10} alt="Partner Logo" />
                  <img src={partnerLogo11} alt="Partner Logo" />
                  <img src={partnerLogo12} alt="Partner Logo" />
                  <img src={partnerLogo13} alt="Partner Logo" />
                  <img src={partnerLogo14} alt="Partner Logo" />
                  <img src={partnerLogo15} alt="Partner Logo" />
                  <img src={partnerLogo16} alt="Partner Logo" />
                  <img src={partnerLogo17} alt="Partner Logo" />
                </Slider>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </section>
      <section className="temperature-wrapper">
        <div className="container">
          <Row>
            <Col sm={12} md={12} lg={6} className="p-0">
              <div className="temperature-img-sec">
                <div className="temperature-img">
                  <img
                    className="temp-left"
                    src={temperatureImg}
                    alt="Temperature Img"
                  />
                  <img
                    src={temperatureSkeleton}
                    className="temp-bottom-img"
                    alt="Temperature Skeleton"
                  />
                  <img
                    src={skeletonImg}
                    className="temp-right-img"
                    alt="Skeleton Img"
                  />
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} className="p-0">
              <div className="temperature-text-sec">
                <div className="subtitle">Welcome to MSP</div>
                <ScrollAnimation animateIn="fadeIn">
                  <div className="title">
                    Temperature, Density, & RedOx Throughout The Tank
                  </div>
                </ScrollAnimation>
                <div className="para">
                  Utilizing patent pending technology, the MSP combines proven
                  analytical methods into a single device that lives inside the
                  tank. Designed for a food safe environment, the MSP is
                  santizable and does not need to be removed.
                </div>
                <div className="temperature-btn-sec">
                  <Link to={ROUTE_PATH.ABOUT_PAGE} className="temperature-arrow-btn">
                    See More
                    <img src={arrowUpRed} alt="Arrow Up Red" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <TableGraph />
      <LandingSubscriptionPlan handleLoginRoute={handleLoginRoute} />
      <PreFooter />
      <LandingFooter />
      <div className="scroll-wrapper">
        {showButton ? (
          <button className="scrollBtn" onClick={scrollToTop}>
            <img className="scrol-top" src={scrollDown} alt="scroll Top" />
          </button>
        ) : (
          <button className="scrollBtn" onClick={scrollToBottom}>
            <img
              className="scrol-bottom"
              src={scrollDown}
              alt="scroll bottom"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
