import React from 'react';
import { Row } from 'react-bootstrap';
import CreditCardDisplay from './CreditCardDisplay';
import { CardListProps } from '../../types';

const CardList: React.FC<CardListProps> = ({
  cards,
  handleRemoveCard,
  handleDefaultCard,
}) => (
  <div className="subscription-card-wrapper">
    <Row>
      {cards.map((card) => (
        <CreditCardDisplay
          key={card.id}
          cardData={card}
          handleRemoveCard={handleRemoveCard}
          handleDefaultCard={handleDefaultCard}
        />
      ))}
    </Row>
  </div>
);

export default CardList;
