import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../../../../assets/img/logo.svg';
import { UserNavbarProps } from '../../../../types';
import BurgerMenu from '../../../header/burgerMenu';
import logoutIcon from '../../../../assets/img/logout.svg';
import LogOutModal from '../../../auth/model/LogoutModal';
import useLogout from '../../../../hooks/useLogout';

import {
  BOOLEANS,
  LOGOUT_MODEL_BODY,
  LOGOUT_MODEL_CANCEL,
  LOGOUT_MODEL_HEADER,
  NUMBER,
  PAYMENT_STATUS,
  ROUTE_PATH,
} from '../../../../constants';
import DropDownMenu from '../DropDownMenu';
import ChangePassword from '../changePassword';
import SubscriptionModal from '../modals/SubscriptionModal';
import { getUserInfo } from '../../../../utils/helpers';

function UserNavbar({ showLogo = true }: UserNavbarProps) {
  const data = getUserInfo();
  const {
    logoutUser,
    openLogoutModal,
    closeLogoutModal,
    logoutModal,
    accessToken,
  } = useLogout();
  const {
    openLogoutModal: openSubscriptionModal,
    closeLogoutModal: closeSubscriptionModal,
    logoutModal: subscriptionModal
  } = useLogout();
  const { pathname } = useLocation();
  const [showchangePasswordModal, setshowchangePasswordModal] = useState(
    BOOLEANS.FALSE
  );
  const { USER_DASHBOARD, USER_PROBES, USER_TANKS } = ROUTE_PATH;

  const handleModalStatus = () => setshowchangePasswordModal(!showchangePasswordModal);

  const userDashboardPages = [USER_DASHBOARD, USER_PROBES, USER_TANKS];
  const { paymentStatus, subscriptionId, invoiceId } = data || {};

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if ((!subscriptionId && !invoiceId) && userDashboardPages.includes(pathname)
    ) {
      openSubscriptionModal();
    } else if ((subscriptionId
      && userDashboardPages.includes(pathname)) || pathname === ROUTE_PATH.USER_PROFILE) {
      closeSubscriptionModal();
    }
  }, [pathname, subscriptionId]);

  return (
    <div className="main-header">
      <Navbar className="header-nav" expand="lg">
        <Container fluid className="header-container">
          {windowWidth < NUMBER.MEDIUM_SCREEN ? (
            <></>
          ) : (
            <Navbar.Brand
              className={` ${
                paymentStatus !== PAYMENT_STATUS.OPEN
                && 'manage-subscription-header'
              } header-logo`}
            >
              {showLogo && (
                <Link to={ROUTE_PATH.HOME_PAGE}>
                  <img className="logo" src={Logo} alt="Logo" width={71} />
                </Link>
              )}
            </Navbar.Brand>
          )}
          <Navbar
            className={` ${
              paymentStatus !== PAYMENT_STATUS.OPEN
              && 'manage-subscription-header'
            }`}
          >
            <Nav className="nav-menu">
              <Nav.Item className="pl-30">
                <DropDownMenu
                  handleModalStatus={handleModalStatus}
                  accessToken={accessToken}
                  openLogoutModal={openLogoutModal}
                />
              </Nav.Item>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
      <BurgerMenu data={paymentStatus} />
      <LogOutModal
        open={logoutModal}
        close={closeLogoutModal}
        logoutFuntion={logoutUser}
        headerText={LOGOUT_MODEL_HEADER}
        bodyText={LOGOUT_MODEL_BODY}
        confirmText={LOGOUT_MODEL_HEADER}
        imgPath={logoutIcon}
        cancelText={LOGOUT_MODEL_CANCEL}
      />
      <ChangePassword
        open={showchangePasswordModal}
        close={handleModalStatus}
      />
      <SubscriptionModal show={subscriptionModal} handleClose={closeSubscriptionModal} />
    </div>
  );
}

export default UserNavbar;
