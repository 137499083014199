import { useMutation, useQuery } from '@apollo/client';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ASSIGN_PROBE_MUTATION, UPDATE_PROBE_MUTATION } from '../graphql/mutation';
import { UseProbeArgs } from '../types';
import { USER_TANK_LISTING, USER_UNASSIGNED_PROBES_LISTING } from '../graphql/queries/tank.queries';
import { NUMBER } from '../constants';

function useProbe({ isEditMode, onSuccess }:UseProbeArgs) {
  const [saveTank, { loading }] = useMutation(ASSIGN_PROBE_MUTATION);
  const [updateTank, { loading: updateTankLoading }] = useMutation(UPDATE_PROBE_MUTATION);

  const { refetch, data: { getTankByUser: { tank = [] } = {} } = {} } = useQuery(USER_TANK_LISTING,
    { variables: { limit: NUMBER.ONE_HUNDRED } });
  const {
    refetch: probeFetch,
    data: { getProbeIdByUser: { probes = [] } = { } } = { }
  } = useQuery(USER_UNASSIGNED_PROBES_LISTING);

  /**
 * The `submit` function is an asynchronous function that handles the submission of tank data, either
 * by updating an existing tank or saving a new tank, and displays a success message.
 * @param {FieldValues} data - The `data` parameter is of type `FieldValues`. It is an object that
 * contains the values of the form fields.
 */
  const submit = async (data: FieldValues) => {
    let res;
    const config = {
      variables: {
        ...data,
        volume: parseFloat(data?.volume),
      },
    };
    if (isEditMode) {
      res = await updateTank({ ...config });
      toast.success(res?.data?.updateProbeByUser?.message);
      await refetch();
    } else {
      res = await saveTank(config);
      toast.success(res?.data?.assignProbeByUser?.message);
    }
    if (isEditMode) {
      onSuccess(data);
    } else {
      onSuccess(null);
    }
  };

  return {
    isLoading: loading || updateTankLoading, submit, probes, tank, probeFetch, refetch
  };
}

export default useProbe;
