import React from 'react';
import PublicNavBar from '../../components/shared/common/navbar';
import LandingFooter from '../../components/landingPage/LandingFooter';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';

function PrivacyPolicy() {
  return (
    <>
    <div className="landing-page-wrapper">
      <div className="landing-page-header">
        <PublicNavBar />
        <section className="policy-wrapper bg-img">
          <div className="policy-sec">
            <SectionOne />
            <SectionTwo />
            
            <h3>Security</h3>
            <p className="para">
              Wine Technology Inc takes precautions to protect your information.
              When you submit sensitive information via the website, your
              information is protected both online and offline. Wherever we
              collect sensitive information (e.g. credit card information), that
              information is encrypted and transmitted to us in a secure way. You
              can verify this by looking for a lock icon in the address bar and
              looking for ”https" at the beginning of the address of the webpage.
            </p>
            <p className="para">
              While we use encryption to protect sensitive information transmitted
              online, we also protect your information offline. Only employees who
              need the information to perform a specific job (for example, billing
              or customer service) are granted access to personally identifiable
              information. The computers and servers in which we store personally
              identifiable information are kept in a secure environment. This is
              all done to prevent any loss, misuse, unauthorized access,
              disclosure or modification of the user's personal information under
              our control.
            </p>
            <p className="para">
              The company also uses Secure Socket Layer (SSL) for authentication
              and private communications to build users' trust and confidence in
              the internet and website use by providing simple and secure access
              and communication of credit card and personal information. In
              addition, Wine Technology Inc is a licensee of TRUSTe. The website
              is also secured by VeriSign.
            </p>
            <h3>Acceptance of Terms</h3>
            <p className="para">
              By using this website, you are hereby accepting the terms and
              conditions stipulated within the Privacy Policy Agreement. If you
              are not in agreement with our terms and conditions, then you should
              refrain from further use of our sites. In addition, your continued
              use of our website following the posting of any updates or changes
              to our terms and conditions shall mean that you agree and acceptance
              of such changes.
            </p>
            <h3>How to Contact Us</h3>
            <p className="para">
              If you have any questions or concerns regarding the Privacy Policy
              Agreement related to our website, please feel free to contact us at
              the following email, telephone number or mailing address.
            </p>
            <div className="privacy-address">
              <p className="para">
                <strong>Email:</strong> kelly@vinwizard.us
              </p>
              <p className="para">
                <strong>Telephone Number:</strong> 7202842059
              </p>
              <p className="para">
                <strong>Mailing Address:</strong> <br />
                Wine Technology Inc <br />
                474 Walten Way <br />
                Windsor, California <br />
                95492
              </p>
            </div>
            <p className="para">
              The data controller responsible for your personal information for
              the purposes of GDPR compliance is:
            </p>
            <p className="para">
              Kelly Graves <br />
              Kelly@vinwizard.us <br />
              720-284-2059 474 <br />
              Walten Way, Windsor CA 95492
            </p>

            <h3>GDPR Disclosure:</h3>
            <p className="para">
              If you answered "yes" to the question Does your website comply with
              the General Data Protection Regulation ("<strong>GDPR</strong>")? then the Privacy
              Policy above includes language that is meant to account for such
              compliance. Nevertheless, in order to be fully compliant with GDPR
              regulations your company must fulfill other requirements such as:
            </p>
            <ul className="indent-sec unnum-list">
              <li>
                Doing an assessment of data processing activities to improve
                security.
              </li>
              <li>
                Have a data processing agreement with any third party vendors.
              </li>
              <li>
                Appoint a data protection officer for the company to monitor GDPR
                compliance.
              </li>
              <li>
                Designate a representative based in the EU under certain
                circumstances.
              </li>
              <li>
                Have a protocol in place to handle a potential data breach. For
                more details on how to make sure your company is fully compliant
                with GDPR, please visit the official website at <a href="https //odor eu." target='_blank'>https //odor eu.</a> {' '}
                FormSwift and its subsidiaries are in no way responsible for
                determining whether or not your company is in fact compliant with
                GDPR and takes no responsibility for the use you make of this
                Privacy Policy or for any potential liability your company may
                face in relation to any GDPR compliance issues.
              </li>
            </ul>

            <h3>COPPA Compliance Disclosure:</h3>
            <p className="para">
              This Privacy Policy presumes that your website is not directed at
              children under the age of 13 and does not knowingly collect personal
              identifiable information from them or allow others to do the same
              through your site. If this is not true for your website or online
              service and you do collect such information (or allow others to do
              so), please be aware that you must be compliant with all COPPA
              regulations and guidelines in order to avoid violations which could
              lead to law enforcement actions, including civil penalties.
            </p>
            <p className="para">
              In order to be fully compliant with COPPA your website or online
              service must fulfill other requirements such as:
            </p>
            <ul className="indent-sec unnum-list">
              <li>
                Posting a privacy policy which describes not only your practices,
                but also the practices of any others collecting personal
                information on your site or service — for example, plug-ins or ad
                neMorks.
              </li>
              <li>
                Include a prominent link to your privacy policy anywhere you
                collect personal information from children.
              </li>
              <li>
                Include a description of parental rights (e.g. that you won't
                require a child to disclose more information than is reasonably
                necessary, that they can review their child's personal
                information, direct you to delete it, and refuse to allow any
                further collection or use of the child’s information, and the
                procedures to exercise their rights).
              </li>
              <li>
                Give parents "direct notice" of your information practices before
                collecting information from their children.
              </li>
              <li>
                Obtain the parents' "verifiable consent" before collecting, using
                or disclosing personal information from a child. For more
                information on the definition of these terms and how to make sure
                your website or online service is fully compliant with COPPA
                please visit 
                <a
                  href="https://www.ftc.gov/tips-advice/business-center/guidance/childrens-online-privacy-protection-rule-six-step-"
                  target="_blank"
                >
                  {' '} https://www.ftc.gov/tips-advice/business-center/guidance/childrens-online-privacy-protection-rule-six-step-
                </a>{' '}
                compliance. FormSwift and its subsidiaries are in no way
                responsible for determining whether or not your company is in tact
                compliant with COPPA and takes no responsibility for the use you
                make of this Privacy Policy or for any potential liability your
                company may face in relation to any COPPA compliance.
              </li>
            </ul>
          </div>
        </section>

        <LandingFooter />
      </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
