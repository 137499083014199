import React, { useState } from 'react';
import {
  Container,
  Nav,
  Navbar,
  Dropdown,
  Button,
  Row,
  Col,
  NavLink,
  Form,
  InputGroup,
  DropdownButton,
} from 'react-bootstrap';
import Logo from '../../../assets/img/logo.svg';
import footerLogo from '../../../assets/img/footer-logo.svg';
import downArrow from '../../../assets/icons/arrow.svg';
import logoutIcon from '../../../assets/img/logout.svg';
import profileIcon from '../../../assets/icons/profile-icon.svg';
import tankPic from '../../../assets/img/tank-pic.png';
import UserSideBar from '../../../components/sidebar/userSideBar';
import useLogout from '../../../hooks/useLogout';
import LogOutModal from '../../../components/auth/model/LogoutModal';
import {
  LOGOUT_MODEL_BODY,
  LOGOUT_MODEL_CANCEL,
  LOGOUT_MODEL_HEADER,
} from '../../../constants/static';
import AdminDashboard from '../../../components/adminDashboard';
import DropDownMenu from '../../../components/shared/common/DropDownMenu';
import { getUserInfo } from '../../../utils/helpers';

function Dashboard() {
  return (<AdminDashboard />);
}

export default Dashboard;
