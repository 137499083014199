import React from 'react';
import { useNavigate } from 'react-router-dom';
import DetailHeaderActions from './detailHeaderAction';
import leftArrow from '../../../../assets/icons/arrow-left.svg';
import { DetailHeaderProps } from '../../../../types';
import { NUMBER } from '../../../../constants';
import Breadcrumbs from '../breadCrumbs';

function DetailHeader({
  onRemove, onEdit, deleteLabel, editLabel, detailLabel, itemName, itemId = ''
}: DetailHeaderProps) {
  const navigate = useNavigate();
  return (
    <>
      <div className="for-mobile">
        <div className="header-wrapper-mobile">
          <img className="left-arrow" src={leftArrow} alt="Arrow" onClick={() => navigate(-NUMBER.ONE)} />
          <h5 className="admin-header-title mb-0">
            {detailLabel}
            {' '}
            Details
          </h5>
        </div>
      </div>
      <div className="admin-header-wrapper for-desktop">
        <Breadcrumbs crumbs={[`${detailLabel} Catalog`, itemId]} />
        <div className="header-wrapper">
          <div className="header-left">
            <h1 className="admin-header-title mb-0">
              {detailLabel}
              {' '}
              Details
            </h1>
          </div>
          <DetailHeaderActions
            onRemove={onRemove}
            onEdit={onEdit}
            deleteLabel={deleteLabel}
            editLabel={editLabel}
            itemName=""
          />
        </div>
      </div>
    </>
  );
}

export default DetailHeader;
