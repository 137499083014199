import React from 'react';
import WelcomeWrapper from '../../../components/userDashboard/welcomeWrapper';
import DashboardCardWrapper from '../../../components/userDashboard/dashboardCardWrapper';
import ProbeSection from '../../../components/userDashboard/probeSection';

function UserDashboard() {
  return (
    <div className="admin-common-body dashboard-welcome">
      <WelcomeWrapper />
      <DashboardCardWrapper />
      <ProbeSection />
    </div>
  );
}

export default UserDashboard;
