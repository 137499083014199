import { gql } from '@apollo/client';

export const GET_USER_LIST = gql`
  query getUserList(
    $limit: Int!
    $page: Int!
    $sort: String!
    $sortBy: String!
    $search: String
  ) {
    getUserList(
      limit: $limit
      page: $page
      sort: $sort
      sortBy: $sortBy
      search: $search
    ) {
      users {
        id
        email
        contactNumber
        fullName
        totalProbe
        wineryName
        paymentStatus
        isEmailVerified
        status
        profileImg
      }
      totalCount
    }
  }
`;

export const GET_USER_LIST_BY_ID = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      userInfo {
        email
        contactNumber
        fullName
        wineryName
        totalProbe
        paymentStatus
        isEmailVerified
        paymentStatus
        wineryProductionVolume
        wineryProductionVolumeUnit
        wineryFiscalYearEndMonth
        wineryAddress
        status
        lastAmountPaid
        subscriptionType
        isWineMakingPackage
        subscriptionPeriodEnd
        subscriptionPeriodStart
        billingAddress
        differentBillingAddress
        profileImg
        assignedProbe
      }
    }
  }
`;

export const GET_WINERY_ADDRESSES = gql`
  query wineryAddress {
    winaryAddress {
      data {
        id
        wineryName
        email
      }
    }
  }
`;

export const GET_PROBE_LISTING = gql`
query probeListing(
  $limit: Int
  $page: Int
  $sort: String
  $sortBy: String
  $search: String
) {
  probeListing(
    limit: $limit
    page: $page
    sort: $sort
    sortBy: $sortBy
    search: $search
  ) {
    totalCount: count
    data {
      id
      probeId
      probeName
      description
      userId
      status
      isGettingRealData
      user {
        email
        wineryName
      }
      tank {
        name
      }
    }
  }
}
`;

export const GET_USER_LIST_BY_ID_FOR_UPDATE = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      userInfo {
        email
        contactNumber
        fullName
        wineryName
        wineryProductionVolume
        wineryProductionVolumeUnit
        wineryFiscalYearEndMonth
        wineryAddress
        billingAddress
        differentBillingAddress
        profileImg
        role
      }
    }
  }
`;
export const GET_PROBE_BY_ID = gql`
query getProbeById($id: String!) {
  getProbeById(id: $id) {
    message
    data {
      description
      id
      probeId
      probeName
      isGettingRealData
      status
      tank{
        name
        id
        location
        volume
        description
        capacity
      }
      tankId
      user {
        email
        wineryName
      }
      userId
    }
  }
}
`;

export const GET_USER_ASSIGNED_PROBE = gql`
query GetUserAssignedProbe(
  $id: String!
  $limit: Int!
  $page: Int!
  $sort: String!
  $sortBy: String!
  $search: String
) {
  getUserAssignedProbeByAdmin(
    id: $id
    sort: $sort
    search: $search
    sortBy: $sortBy
    page: $page
    limit: $limit
  ) {
    totalCount
    probe {
      id
      probeName
      probeId
      status
      isGettingRealData
      tank {
        name
      }
      user {
        wineryName
        email
      }
    }
  }
}
`;

export const ADD_PROBES_MUTATION = gql`
mutation addProbeByCsv($probes: [ProbeInput!]!) {
  addProbeByCsv(probes: $probes) {
    message
  }
}
`;

export const ADD_TANK_CSV_MUTATION = gql`
mutation addTankByCsv($tanks: [CsvTankInput!]!) {
  addTankByCsv(tanks: $tanks) {
    message
  }
}
`;

export const GET_PROBE_AND_TANK_COUNT = gql`
query GetProbeAndTankCountByAdmin($id: String!) {
  getProbeAndTankCountByAdmin(id: $id) {
    totalTank
    totalAttachedProbe
    totalAvailableProbe
    assignProbe
  }
}
`;

export const GET_SUBSCRIPTION_PLAN = gql`
  query getSubscriptionPrice {
    getSubscriptionPrice {
      data {
        id
        productName
        priceMonthly
        priceYearly
        isDeleted
        isCurrent
        isWineMaking
        description
      }
    }
  }
`;

export const GET_DASHBOARD_PROBE_COUNT = gql`
  query {
    getDashboardProbeCount {
      totalProbe
      totalAvailabeleProbes
      totalAssignedProbes
      totalSoldProbes
      totalActiveProbe
      totalDeactivatedProbes
    }
  }
`;

export const GET_DASHBOARD_USER_COUNT = gql`
  query {
    getDashboardUserCount {
      totalUsers
      totalActiveUser
      totalDisableUser
      purchasedPackageUser
    }
  }
`;
