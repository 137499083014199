import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import {
  ADD_NEW_PROBE_VALIDATION_SCHEMA, ADD_NEW_PROBE_VALIDATION_SCHEMA_ASSIGN, BOOLEANS, EMPTY_PROBE, INPUT_ALPHANUMERIC, NUMBER,
} from '../../constants';
import CustomLoader from '../shared/common/customLoader';
import ModalWrapper from '../shared/common/modalWrapper';
import Input from '../shared/forms/input';
import Select from '../shared/forms/select';
import useProbe from '../../hooks/useProbe';
import { ITank } from '../../types';

function AssignProbe({
  probeData, success, close, open, type, updateList
}: any) {
  const isEditMode = !!probeData?.id;
  const hasTank = !!probeData?.tank;
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid, },
  } = useForm({
    defaultValues: {
      ...EMPTY_PROBE,
      ...probeData,
      probeId: probeData?.id,
      probeName: probeData?.probeName || '',
      description: probeData?.description || '',
    },
    resolver: yupResolver(type === 'edit' ? ADD_NEW_PROBE_VALIDATION_SCHEMA : ADD_NEW_PROBE_VALIDATION_SCHEMA_ASSIGN),
    shouldFocusError: false,
  });

  const onSuccess = (data: ITank) => {
    if (data) {
      success({ ...data, probeId: probeData?.probeId });
    } else {
      updateList();
    }
    reset();
    close(BOOLEANS.FALSE);
  };

  const {
    isLoading, submit, probes, tank, probeFetch, refetch
  } = useProbe({ isEditMode, onSuccess });

  useEffect(() => {
    probeFetch();
    refetch();
  }, []);

  return (
    <>
      {isLoading && <CustomLoader />}
      <ModalWrapper
        show={open}
        handleClose={close}
        title={isEditMode ? 'Edit Probe' : 'Assign Available Probe'}
      >
        <Form onSubmit={handleSubmit(submit)}>
          <Row className="modal-body-form mb-10">
            <Col xs={12} sm={12} className=" ">
              <Select
                disabled={isEditMode}
                placeholder="Select"
                value={getValues('probeId')}
                name="probeId"
                label="Probe ID"
                labelKey="probeId"
                valueKey="id"
                setValue={setValue}
                options={[...probes, { id: probeData?.id, probeId: probeData?.probeId }]}
                error={errors?.probeId?.message}
              />
            </Col>
            <Col xs={12} sm={12} className=" ">
              <Input
                isOptional
                label="Probe Name"
                name="probeName"
                placeholder="Enter probe name"
                refValues={register}
                setValue={setValue}
                error={errors?.probeName?.message}
              />
            </Col>
            {type !== 'edit' && (
            <Col xs={12} sm={12} className=" ">
              <Select
                placeholder="Select"
                control={control}
                name="tankId"
                label="Assigned Tank"
                setValue={setValue}
                options={tank}
                labelKey="name"
                valueKey="id"
                value={getValues('tankId')}
                error={errors?.tankId?.message}
              />
            </Col>
            )}
            <Col xs={12} sm={12} className=" ">
              <div className="wrap-input">
                <Input
                  inputClass="mb-0"
                  as="textarea"
                  label="Description"
                  isOptional
                  name="description"
                  placeholder="Write description"
                  refValues={register}
                  setValue={setValue}
                  error={errors?.description?.message}
                  valuePattern={INPUT_ALPHANUMERIC}
                  maxLength={NUMBER.ONE_HUNDRED}
                />
                <span className="word-count">
                  {getValues('description')?.length || NUMBER.ZERO}
                  /
                  {NUMBER.ONE_HUNDRED}
                </span>
              </div>
            </Col>
          </Row>
          <div className="footer-modal">
            <Button
              type="submit"
              className="btn primary modal-btn-submit"
              disabled={!isValid}
            >
              {isEditMode ? 'Update' : 'Add'}
            </Button>
          </div>
        </Form>
      </ModalWrapper>
    </>
  );
}

export default AssignProbe;
