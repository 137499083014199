import AWS from 'aws-sdk';
import { UploadImageResponse } from '../../types';
import { AWS_ACL_CONSTANT, US_REGION } from '../../constants';
import { awsBucketName, awsIdentityCode } from '../../constants/config';

AWS.config.update({
  region: US_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: awsIdentityCode,
  }),
});

export default function uploadToAws(file: File, path = '') {
  return new Promise((resolve, reject) => {
    const splitExtension = file?.name.split('.');
    const getIndex = splitExtension?.length;
    const getValue = splitExtension[getIndex - 1];
    const basePath = `profile/${new Date().getTime()}.${getValue}`;
    const filePath = path ? path + basePath : basePath;

    const uploadAwsObj = new AWS.S3.ManagedUpload({
      params: {
        Bucket: awsBucketName,
        Key: filePath,
        Body: file,
        ACL: AWS_ACL_CONSTANT,
        ContentType: file.type,
      },
    });

    uploadAwsObj.promise().then(
      (data: UploadImageResponse) => resolve(data),
      (err: any) => reject(err),
    );
  });
}
