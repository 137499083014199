import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import CustomLoader from '../../../components/shared/common/customLoader';
import { BOOLEANS, NUMBER } from '../../../constants';
import { ADD_PROBES_MUTATION, GET_PROBE_LISTING } from '../../../graphql/queries/admin.queries';
import useModal from '../../../hooks/useModal';
import AssignModalForm from './assignModalForm';
import ProbeItem from './probeItem';
import TableHead from '../../../components/table/tableHead';
import {
  PROBE_TABLE_FIELDS,
} from '../../../constants/tableConfig';
import useListing from '../../../hooks/useListing';
import EmptyProbPage from '../../../components/probes/EmptyProbPage';
import UploadCsv from '../../../components/shared/common/uploadCsv/UploadCSV';

const PROBE_MODEL_ID = 'probe_model_id';
const ProbeListing = () => {
  const {
    results, loading, refetch, metaData, refetchCurrentPage, updateDetails
  } = useListing(
    GET_PROBE_LISTING,
    'probeListing',
    'data'
  );
  const [contactAdminModal, setContactAdminModal] = useState(BOOLEANS.FALSE);
  const { modalStates, toggleModal } = useModal();
  const [searchQuery, setSearchQuery] = useState('');
  const [showCsvModal, setShowCsvModal] = useState(BOOLEANS.FALSE);

  const [params] = useSearchParams();
  let filterTimeout: any; // using debouncing for search
  const query = params.get('search');
  const onSearch = (search: string) => {
    setSearchQuery(search);
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      if (search.length >= NUMBER.THREE || search.length === NUMBER.ZERO) {
        refetch({ search, page: NUMBER.ONE });
      }
    }, NUMBER.FIVE_HUNDRED);
  };

  const handleCsvSubmit = () => {
    refetchCurrentPage();
    setShowCsvModal(BOOLEANS.FALSE);
    // Logic to handle CSV submit in ProbeListing
  };

  return (
    <>
      {loading && <CustomLoader />}
      <div className="admin-common-body">
        <div className="admin-header-wrapper">
          <h1 className="admin-header-title">Probe Catalog</h1>
          <div className="header-wrapper">
            <div className="header-left">
              <Form className="search-sec message-search">
                <InputGroup className="search-group user-search-admin">
                  <InputGroup.Text id="basic-addon1" className="search-icon">
                    <span className="icon-search" />
                  </InputGroup.Text>
                  <Form.Control
                    className="form-input search-input"
                    type="search"
                    placeholder="Search by Winery Name or Probe ID"
                    value={searchQuery}
                    onChange={(e) => onSearch(e.target.value)}
                  />
                </InputGroup>
              </Form>
            </div>
            <div className="header-right">
              <div>
                <Button
                  className="btn-outline-border header-outline-btn"
                  onClick={() => setShowCsvModal(true)}
                >
                  Upload csv
                </Button>
              </div>
              <Button
                className="btn primary header-primary-btn"
                onClick={() => toggleModal(PROBE_MODEL_ID)}
              >
                ASSIGN PROBE
              </Button>
            </div>
          </div>
        </div>
        <UploadCsv
          open={showCsvModal}
          close={setShowCsvModal}
          mutation={ADD_PROBES_MUTATION}
          type="file"
          onSubmit={handleCsvSubmit}
          title="Upload Probe CSV"
          tankData={{
            name: '',
            capacity: '',
            description: '',
            location: '',
          }}
        />
        <div className="list-container service-list-container">
          <div className="table-wrapper mobile-optimised">
            <div className="thead">
              <TableHead
                tableFields={PROBE_TABLE_FIELDS}
                handleSorting={(obj) => refetch({ ...obj, page: NUMBER.ONE, search: searchQuery })}
              />
            </div>
            {results.length === NUMBER.ZERO && !loading ? (
              <EmptyProbPage close={setContactAdminModal} isAdmin={BOOLEANS.TRUE} />
            )
              : (
                <InfiniteScroll
                  dataLength={results?.length || NUMBER.ZERO}
                  hasMore={metaData?.page < metaData?.totalPage}
                  next={() => refetch({ search: query, page: metaData?.page + NUMBER.ONE })}
                  loader=""
                  className="tanks-infinite"
                >
                  <div className="tbody">
                    {results && (
                    <>
                      {results?.map((item: any) => (
                        <ProbeItem
                          key={item.id}
                          item={item}
                          fetchData={refetchCurrentPage}
                          updateDetails={updateDetails}
                        />
                      ))}
                    </>
                    )}
                  </div>

                </InfiniteScroll>
              )}
            <AssignModalForm
              show={modalStates}
              handleClose={() => toggleModal(PROBE_MODEL_ID)}
              onSuccess={() => refetchCurrentPage(NUMBER.ONE)}
            />
          </div>

        </div>
      </div>
    </>
  );
};

export default ProbeListing;
