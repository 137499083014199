import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import TableHead from '../table/tableHead';
import {
  PROBE_TABS_CONFIG,
  USER_PROBES_TABLE_HEADS,
  formattedTotalCount,
} from '../../constants/tableConfig';
import {
  BOOLEANS, EMPTY_TANK, NUMBER, PARAMS,
} from '../../constants';
import CustomLoader from '../shared/common/customLoader';
import { ITank } from '../../types';
import useListing from '../../hooks/useListing';
import { USER_ASSIGNED_PROBES } from '../../graphql/queries/tank.queries';
import AssignProbe from './assignProbe';
import AssignedProbeRow from './assignedProbeRow';
import ContactAdminModal from './ContactAdminModal';
import ListingHeader from '../tanks/listingHeader';
import NoData from '../tanks/noData';
import noTanksImage from '../../assets/img/emptyTank.svg';
import { NO_ACTIVE_TANKS } from '../../constants/string';

function Probelisting() {
  const [editProbe, setEditProbe] = useState<ITank>();
  const [assignNew, setAssignProbe] = useState(BOOLEANS.FALSE);
  const [contactAdminModal, setContactAdminModal] = useState(BOOLEANS.FALSE);
  const [params] = useSearchParams();
  const searchValue = params.get(PARAMS.SEARCH);
  const tab = params.get('tab');

  const openAssignModal = assignNew || !!editProbe?.id;

  const {
    results, loading, refetch, metaData, updateDetails
  } = useListing(
    USER_ASSIGNED_PROBES,
    'getAssignedProbesById',
    'probe',
    { isGettingRealData: tab === 'active' ? BOOLEANS.TRUE : BOOLEANS.FALSE }
  );

  /* The `filterTimeout` variable is used to implement debouncing for the search functionality in the
  `Probelisting` component. */
  let filterTimeout: any; // using debouncing for search
  const onSearch = (search: string) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      if (search.length >= NUMBER.THREE || search.length === NUMBER.ZERO) {
        refetch({ search, page: NUMBER.ONE });
      }
    }, NUMBER.FIVE_HUNDRED);
  };

  /* The `useMemo` hook is used to memoize the result of a computation and only recompute it when the
  dependencies change. In this case, the computation is updating the titles of the
  `PROBE_TABS_CONFIG` array based on the `metaData.totalCount` value. */
  useMemo(() => {
    PROBE_TABS_CONFIG[0].title = `Online Probes - ${formattedTotalCount(metaData.activeProbeCount)}`;
    PROBE_TABS_CONFIG[1].title = `Offline Probes - ${formattedTotalCount(metaData.disableProbeCount)}`;
  }, [metaData?.totalCount]);

  return (
    <div className="admin-common-body">
      {loading && <CustomLoader />}
      <ContactAdminModal
        open={contactAdminModal}
        close={setContactAdminModal}
      />
      {(assignNew || editProbe?.id) && (
        <AssignProbe
          probeData={editProbe as ITank}
          open={openAssignModal}
          type={assignNew ? '' : 'edit'}
          close={() => {
            setAssignProbe(false);
            setEditProbe(EMPTY_TANK);
          }}
          success={() => refetch({ page: NUMBER.ONE })}
          updateList={() => refetch({ page: NUMBER.ONE })}
        />
      )}
      <ListingHeader
        setAddNew={setAssignProbe}
        onSearch={(s: string) => onSearch(s)}
        buttonLabel="Assign available probe"
        csvLabel="prob"
        title="Probe Catalog"
        hasTabs
        tabsConfig={PROBE_TABS_CONFIG}
        refetchList={(isRefetch: boolean) => isRefetch && refetch({ page: NUMBER.ONE })}
      />
      <div className="list-container service-list-container">
        <div className="table-wrapper mobile-optimised">
          <TableHead
            tableFields={USER_PROBES_TABLE_HEADS}
            handleSorting={(obj) => refetch({ ...obj, page: NUMBER.ONE })}
          />
          {((results.length === NUMBER.ZERO && !searchValue))
            && (
            <NoData
              img={noTanksImage}
              isSearch={!!searchValue}
              text={tab === 'active' ? NO_ACTIVE_TANKS.ACTIVE_TEXT : NO_ACTIVE_TANKS.DISABLED_TEXT}
              heading={NO_ACTIVE_TANKS.HEADING}
              actionText=""
              handleAction={() => {}}
            />
            )}
          <div className="tbody">
            <InfiniteScroll
              dataLength={results?.length || 0}
              hasMore={metaData?.page < metaData?.totalPage}
              next={() => refetch({ page: metaData.page + NUMBER.ONE })}
              loader=""
              className="tanks-infinite"
            >
              {results?.map((item: any) => (
                <AssignedProbeRow
                  key={item.id}
                  probe={item}
                  unassignProbe={() => {}}
                  setEditProbe={(probe: any) => setEditProbe(probe)}
                  refetch={updateDetails}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Probelisting;
