import React from 'react';
import ModalWrapper from '../shared/common/modalWrapper';
import { ColorCodeInfoModalProps, ModalWrapperProps } from '../../types';
import colorOne from '../../assets/img/color-1.png';
import colorTwo from '../../assets/img/color-2.png';
import colorThree from '../../assets/img/color-3.png';
import colorFour from '../../assets/img/color-4.png';
import colorFive from '../../assets/img/color-5.png';
import colorSix from '../../assets/img/color-6.png';
import colorSeven from '../../assets/img/color-7.png';

function ColorCodeInfoModal({ show, close  }: ColorCodeInfoModalProps) {
  return (
    <ModalWrapper
      show={show}
      handleClose={close} title="Temperature Band (°F) & Color Value"
      innerClass="graph-info-modal">
      <div className="graph-info-container">
        <div className="graph-info-left">
            <h4 className="graph-info-title">Temperature Band (°F)</h4>
            <ul className="graph-info-list">
                <li className="graph-item">&gt;90</li>
                <li className="graph-item">80-89</li>
                <li className="graph-item">70-79</li>
                <li className="graph-item">60-69</li>
                <li className="graph-item">50-59</li>
                <li className="graph-item">40-49</li>
                <li className="graph-item">&lt;39</li>
            </ul>
        </div>
        <div className="graph-info-middle">
          <h4 className="graph-info-title">Color Names</h4>
          <ul className="graph-info-list">
              <li className="graph-item">Darkest Red</li>
              <li className="graph-item">Faded Red/Orange</li>
              <li className="graph-item">Tinted Yellow</li>
              <li className="graph-item">Yellow</li>
              <li className="graph-item">Light Green</li>
              <li className="graph-item">Dark Green</li>
              <li className="graph-item">Darkest Green</li>
          </ul>
        </div>
        <div className="graph-info-right">
          <h4 className="graph-info-title">Colors</h4>
            <ul className="graph-info-list">
              <li className="graph-item">
                <img src={colorOne} alt="color code" />
              </li>
              <li className="graph-item">
                <img src={colorTwo} alt="color code" />
              </li>
              <li className="graph-item">
                <img src={colorThree} alt="color code" />
              </li>
              <li className="graph-item">
                <img src={colorFour} alt="color code" />
              </li>
              <li className="graph-item">
                <img src={colorFive} alt="color code" />
              </li>
              <li className="graph-item">
                <img src={colorSix} alt="color code" />
              </li>
              <li className="graph-item">
                <img src={colorSeven} alt="color code" />
              </li>
          </ul>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ColorCodeInfoModal;
