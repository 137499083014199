import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { NETWORK_ONLY, ROUTE_PATH } from '../../constants';
import { formatNumberToLocaleString, getUserInfo } from '../../utils/helpers';
import {
  GET_DASHBOARD_PROBE_COUNT,
  GET_DASHBOARD_USER_COUNT,
} from '../../graphql/queries/admin.queries';
import CustomLoader from '../shared/common/customLoader';
import { Col, Row } from 'react-bootstrap';
import errorIcon from '../../assets/icons/error-black-icon.svg';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  const { loading, data } = useQuery(GET_DASHBOARD_PROBE_COUNT, {
    fetchPolicy: NETWORK_ONLY,
  });
  const { loading: userLoading, data: userData } = useQuery(
    GET_DASHBOARD_USER_COUNT,
    {
      fetchPolicy: NETWORK_ONLY,
    }
  );

  const {
    totalProbe,
    totalAssignedProbes,
    totalAvailabeleProbes,
    totalSoldProbes,
    totalActiveProbe,
    totalDeactivatedProbes,
  } = data?.getDashboardProbeCount || {};

  const {
    totalUsers,
    totalActiveUser,
    totalDisableUser,
    purchasedPackageUser,
  } = userData?.getDashboardUserCount || {};

  return (
    <div className="admin-common-body dashboard-welcome admin-dashboard">
      {(loading || userLoading) && <CustomLoader />}
      <div className="dashboard-wel-wrapper">
        <div className="name">
          Hi <span>{userInfo?.fullName}</span>,
        </div>
        <div className="welcome">Dashboard</div>
      </div>
      <div className="dashboard-card-wrapper dashboard-card-wrapper-new">
        <Row className="dashboard-card-wrapper-new-row">
          <Col
            md={8}
            xs={12}
            className="card-left bg-left cursor-pointer card-left-new"
            onClick={() => navigate(ROUTE_PATH.ADMIN_PROBE_LISTING)}
          >
            <div className="admin-main-title">
              <div className="card-data-sec">
                <div className="card-num">
                  {formatNumberToLocaleString(totalAvailabeleProbes)}
                </div>
                <div className="card-total">
                  Probes Assigned to Wineries, Not Allocated
                  <ReactTooltip
                    className="admin-tooltip"
                    id="my-tooltip-1"
                    place="bottom"
                    content="The total number of probes the Admin has assigned to a Winery but the Winery has NOT yet allocated/attached the probe to a tank"
                  />
                  <img
                    src={errorIcon}
                    alt="error icon"
                    data-tooltip-id="my-tooltip-1"
                  />
                </div>
              </div>
              <div className="card-data-sec">
                <div className="card-num">
                  {formatNumberToLocaleString(totalAssignedProbes)}
                </div>
                <div className="card-total">
                  Probes Assigned to Wineries, Tank Allocated
                  <ReactTooltip
                    className="admin-tooltip"
                    id="my-tooltip-2"
                    place="bottom"
                    content="The total number of probes which have both been assigned to a Winery and the Winery has the probe allocated to a tank."
                  />
                  <img
                    src={errorIcon}
                    alt="error icon"
                    data-tooltip-id="my-tooltip-2"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col
            md={4}
            xs={12}
            className="card-left bg-left cursor-pointer card-left-new2"
            onClick={() => navigate(ROUTE_PATH.ADMIN_PROBE_LISTING)}
          >
            <div className="admin-main-subtitle">
              <div className="card-data-subsec">
                <div className="card-num">
                  {formatNumberToLocaleString(totalSoldProbes)}
                </div>
                <div className="card-total">Total Number of Sold Probes </div>
              </div>
              <div className="card-data-subsec">
                <div className="card-num">
                  {formatNumberToLocaleString(totalActiveProbe)}
                </div>
                <div className="card-total">Total Number of Active Probes </div>
              </div>
              <div className="card-data-subsec">
                <div className="card-num">
                  {formatNumberToLocaleString(totalDeactivatedProbes)}
                </div>
                <div className="card-total">
                  Total Number of Disabled Probes{' '}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="card-left bg-middle cursor-pointer"
          onClick={() => navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT)}
        >
          <div className="admin-main-title">
            <div className="card-data-sec">
              <div className="card-num">
                {formatNumberToLocaleString(totalUsers)}
              </div>
              <div className="card-total">Total Number of Users</div>
            </div>
          </div>
          <div className="admin-main-subtitle ">
            <div className="card-data-subsec card-data-subsec2">
              <div className="card-num">
                {formatNumberToLocaleString(totalActiveUser)}
              </div>
              <div className="card-total">Total Number of Active Users </div>
            </div>
            <div className="card-data-subsec">
              <div className="card-num">
                {formatNumberToLocaleString(totalDisableUser)}
              </div>
              <div className="card-total">Total Number of Disabled Users </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-card-wrapper">
        <div
          className="card-left bg-right-admin cursor-pointer"
          onClick={() => navigate(ROUTE_PATH.ADMIN_SUBSCRIPTION)}
        >
          <div className="admin-main-title admin-main-third-title">
            <div className="card-data-sec">
              <div className="card-num">
                {formatNumberToLocaleString(purchasedPackageUser)}
              </div>
              <div className="card-total ">
                Total Number of Winemaking Packages
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
