import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import RemoveTank from './removeTank';
import tankPic from '../../assets/img/tank-pic.png';
import { USER_TANK_LISTING } from '../../graphql/queries/tank.queries';
import CustomLoader from '../shared/common/customLoader';
import TableHead from '../table/tableHead';
import { USER_TANKS_TABLE_HEADS } from '../../constants/tableConfig';
import ActionDropDown from './actionDropDown';
import { ITank } from '../../types';
import AddNewTank from './addNewTank';
import {
  EMPTY_TANK, NUMBER, PARAMS,
} from '../../constants';
import useListing from '../../hooks/useListing';
import ListingHeader from './listingHeader';
import NoData from './noData';
import noTanksImage from '../../assets/img/emptyTank.svg';
import { NO_TANKS } from '../../constants/string';

function TanksListing() {
  const [removeTank, setRemoveTank] = useState('');
  const [editTank, setEditTank] = useState<ITank>();
  const [addNew, setAddNewTank] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const searchValue = params.get(PARAMS.SEARCH);
  const openAddNewModal = addNew || !!editTank?.id;

  const {
    results, loading, refetch, metaData, updateDetails, refetchCurrentPage
  } = useListing(USER_TANK_LISTING, 'getTankByUser', 'tank');

  let filterTimeout: any; // using debouncing for search
  const onSearch = (search: string) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      if (search.length >= NUMBER.THREE || search.length === NUMBER.ZERO) {
        refetch({ search, page: NUMBER.ONE });
      }
    }, NUMBER.FIVE_HUNDRED);
  };

  return (
    <div className="admin-common-body">
      {loading && <CustomLoader />}
      {removeTank && (
      <RemoveTank
        open={!!removeTank}
        close={(c) => setRemoveTank('')}
        tankId={removeTank}
        onSuccess={() => updateDetails({ id: removeTank }, "delete")}
      />
      )}
      {(addNew || editTank?.id) && (
      <AddNewTank
        tankData={editTank as ITank}
        open={openAddNewModal}
        close={(c) => {
          setAddNewTank(false);
          setEditTank(EMPTY_TANK);
        }}
        success={() => refetchCurrentPage()}
      />
      )}
      <ListingHeader
        setAddNew={setAddNewTank}
        buttonLabel="Add New Tank"
        csvLabel="Upload CSV"
        onSearch={onSearch}
        title="Tank Catalog"
        refetchList={(isRefetch: boolean) => isRefetch && refetch({ page: NUMBER.ONE })}
      />
      <div className="list-container service-list-container">
        <div className="table-wrapper mobile-optimised">
          <TableHead
            tableFields={USER_TANKS_TABLE_HEADS}
            handleSorting={(obj) => refetch({ ...obj, page: NUMBER.ONE })}
          />
          {results.length === NUMBER.ZERO && !loading
            && (
            <NoData
              img={noTanksImage}
              isSearch={!!searchValue}
              text={NO_TANKS.TEXT}
              heading={NO_TANKS.HEADING}
              // actionText={NO_TANKS.ACTION_TEXT}
              handleAction={setAddNewTank}
            />
            )}
          <div className="tbody">
            <InfiniteScroll
              dataLength={results?.length || 0}
              hasMore={metaData?.page < metaData?.totalPage}
              next={() => refetch({ page: metaData.page + NUMBER.ONE })}
              loader=""
              className="tanks-infinite"
            >
              {results?.map((tank: any) => (
                <div className="row tr" key={tank.id} onClick={() => navigate(`${tank.id}`)} data-testid="tankRow">
                  <div
                    className="td flex-table-column-35"
                    data-title="Tank Name"
                    data-testid="tankName"
                  >
                    <div className="first-column-wrapper">
                      <span
                        className="listing-normal pick-on-click"
                      >
                        <img
                          className="tank-pic"
                          src={tankPic}
                          alt="tank pic"
                        />
                        <h5 className="listing-title">{tank?.name}</h5>
                      </span>
                    </div>
                  </div>
                  <div
                    className="td flex-table-column-30"
                    data-title="Tank Capacity"
                  >
                    <div className="">
                      <p className="listing-normal text-capitalize">{tank?.capacity}</p>
                    </div>
                  </div>
                  <div
                    className="td flex-table-column-30"
                    data-title="Tonnage Capacity"
                  >
                    <div className="">
                      <p className="listing-normal">{tank?.volume}</p>
                    </div>
                  </div>
                  <div className="td flex-table-column-5">
                    <div className="">
                      <ActionDropDown
                        deleteItem={() => setRemoveTank(tank?.id)}
                        editItem={() => setEditTank(tank)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TanksListing;
