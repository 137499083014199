import React from 'react';
import {
  Form, Button, Row, Col
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { encryptData } from '../../constants/string';
import closeIcon from '../../assets/icons/close.svg';
import { NUMBER, RESET_PASSWORD_VALIDATION_SCHEMA, ROUTE_PATH } from '../../constants';
import PasswordStrength from '../../components/shared/forms/passwordStrength';
import Input from '../../components/shared/forms/input';
import { RESET_PASSWORD_MUTATION } from '../../graphql/mutation/admin.mutation';

function Resetpassword() {
  const navigate = useNavigate();
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION);
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const {
    register,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(RESET_PASSWORD_VALIDATION_SCHEMA),
    shouldFocusError: false,
  });

  const handleResetPassword = async (formData: any) => {
    try {
      const encryptedPassword = encryptData(formData.password);
      const { data } = await resetPassword({
        variables: {
          token,
          password: encryptedPassword,
        },
      });
      toast.success(data.resetPassword.message);
      navigate(ROUTE_PATH.SUCCESS_RESET);
    } catch (error) {
      //
    }
  };
  const onSubmit = (event: any) => {
    event.preventDefault();
    handleResetPassword(getValues());
  };

  return (
    <>
      <Col sm={6} className="p-0 boarding-login onboarding-right">
        <div className="login-inner">
          <div className="back-page-wrap reset-password-container space-for-back">
            <img
              className="reset-close"
              src={closeIcon}
              alt="close icon"
              onClick={() => navigate(ROUTE_PATH.LOGIN)}
            />
            <h5 className="back-title-heading mb-0">Cancel</h5>
          </div>
          <div className="boarding-title back-boarding-title">
            <h1>Reset Password</h1>
            <p className="back-subtitle">
              Enter the details below to reset your password.
            </p>
          </div>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col xs={NUMBER.TWELVE} sm={NUMBER.TWELVE}>
                <Form.Group
                  className="form-mt-space mb-0"
                  controlId="formBasicPassword"
                >
                  <Row className="modal-body-form">
                    <Col xs={NUMBER.TWELVE} sm={NUMBER.TWELVE}>
                      <Input
                        label="New Password"
                        name="password"
                        type="password"
                        placeholder="Enter new password"
                        refValues={register}
                        setValue={setValue}
                        iconClass="icon-lock on-input-icon-left"
                        error={
                          errors?.password?.type !== 'matches'
                            ? errors?.password?.message
                            : ''
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            {errors?.password?.type === 'matches' && <PasswordStrength password={getValues('password')} />}
            <div className="login-submit">
              <Button
                type="submit"
                className="btn primary w-100"
                disabled={!isValid}
              >
                RESET
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </>
  );
}

export default Resetpassword;
