import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from '../../utils/helpers';
import arrowRight from '../../assets/icons/arrow-right.svg';
import { ROLE, ROUTE_PATH } from '../../constants';
import { GetStartedButtonProps } from '../../types';

const GetStartedButton = ({
  handleLoginRoute,
  buttonName = 'get started',
  classes = ['btn-signin inthedark-btn', 'inthedark-btn-sec'],
}: GetStartedButtonProps) => {
  const userInfo = getUserInfo();
  const navigate = useNavigate();

  const handleDashboardLink = () => {
    if (userInfo?.role === ROLE.ADMIN) {
      navigate(ROUTE_PATH.ADMIN_DASHBOARD);
    } else {
      navigate(ROUTE_PATH.LOGIN);
    }
  };

  return (
    <div className={classes[1]}>
      {userInfo ? (
        <Button
          className={classes[0]}
          variant="outline-success"
          onClick={handleDashboardLink}
        >
          go to dashboard
          {'  '}
          <img src={arrowRight} alt="right arrow" />
        </Button>
      ) : (
        <Button
          className={classes[0]}
          variant="outline-success"
          onClick={handleLoginRoute}
        >
          {buttonName}
          {' '}
          <img src={arrowRight} alt="right arrow" />
        </Button>
      )}
    </div>
  );
};

export default GetStartedButton;
