import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ModalWrapper from '../modalWrapper';
import { AddOnModalProps } from '../../../../types';
import { CURRENCY_SYMBOL } from '../../../../constants/string';
import editEmailIcon from '../../../../assets/icons/edit-email-icon.svg';
import { SUBSCRIPTION_TYPE } from '../../../../constants';
import CustomLoader from '../customLoader';
import useGetProbePrice from '../../../../hooks/useGetProbePrice';

const AddOnModal = ({
  logoutModal,
  closeLogoutModal,
  buyAddOnPlan,
  subscriptionType,
  loading
}: AddOnModalProps) => {
  const {
    priceLoading, addOnPriceMonthly, addOnPriceYearly
  } = useGetProbePrice();

  return (
    <ModalWrapper
      paymentClass="edit-email-modal"
      show={logoutModal}
      handleClose={closeLogoutModal}
      title="Edit Email"
      modalIcon={editEmailIcon}
    >
      {(priceLoading || loading) && <CustomLoader />}
      <Modal.Body>
        <div className="modal-body-form unlock-power unlock-power-new">
          <h2 className="unlock-power-title">
            Unlock the Power of System Computation!
          </h2>
          <p className="unlock-power-subtitle">
            This popular plan is going to provide you with unparalleled access and
            functionality to unleash the full potential of system computation.
          </p>
          <div className="addon-sec">
            <div className="addon-infor">
              <div className="edit-email-package-wrapper">
                <div className={`edit-email-package-left  
                ${subscriptionType === SUBSCRIPTION_TYPE.MONTHLY && 'active'}`}
                >
                  <p className="edit-package-left-title">
                    Winemaking Event Packages
                  </p>
                  <h1 className="subprobs-dollar">
                    {CURRENCY_SYMBOL}
                    {addOnPriceMonthly}
                    <span className="default-month">/Month</span>
                  </h1>
                </div>
                <div className={`edit-email-package-right 
                  ${subscriptionType === SUBSCRIPTION_TYPE.ANNUAL && 'active'}`}
                >
                  <p className="edit-package-right-title">Winemaking Event Packages</p>
                  <h1 className="subprobs-dollar">
                    {CURRENCY_SYMBOL}
                    {addOnPriceYearly}
                    <span className="default-month">/Year</span>
                    {' '}
                    <span className="annually">10% Off</span>
                  </h1>
                </div>
              </div>
              <div className="addon-des">
                <p>
                  This package enables advanced options, including fermentation
                  kinetics, temperature stratification, and more.
                </p>
                <p>
                  Winemakers will be able to tag data points with winemaking events
                  like pump-overs or nutrient additions to help identify fermentation cause and effect.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-modal">
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            onClick={buyAddOnPlan}
          >
            Buy Now
          </Button>
        </div>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default AddOnModal;
