import React from 'react';

const SectionTwo = () => (
  <>
    <h3>Use of nformation Collected</h3>
    <p className="para">
      Wine Technology Inc does not now, nor will it in the future, sell, rent or
      lease any of its customer lists and/or names to any third parties.
    </p>
    <p className="para">
      Wine Technology Inc may collect and may make use of personal information
      to assist in the operation of our website and to ensure delivery of the
      services you need and request. At times, we may find it necessary to use
      personally identifiable information as a means to keep you informed of
      other possible products and/or services that may be available to you from{' '}
      <a className="static-link" href="www.msprobe.com" target="_blank">
        www.msprobe.com
      </a>
    </p>
    <p className="para">
      Wine Technology Inc may also be in contact with you with regards to
      completing surveys and/or research questionnaires related to your opinion
      of current or potential future services that may be offered.
    </p>
    <h3>Disclosure of nformation</h3>
    <p className="para">
      Wine Technology Inc may not use or disclose the information provided by
      you except under the following circumstances:
    </p>
    <ul className="indent-sec unnum-list">
      <li>As necessary to provide services or products you have ordered.</li>
      <li>
        In other ways described in this Policy or to which you have otherwise
        consented.
      </li>
      <li>
        In the aggregate with other information in such a way so that your
        identity cannot reasonably be determined.
      </li>
      <li>
        As required by law, or in response to a subpoena or search warrant.
      </li>
      <li>
        To outside auditors who have agreed to keep the information
        confidential.
      </li>
      <li>As necessary to enforce the Terms of Service.</li>
      <li>
        As necessary to maintain, safeguard and preserve all the rights and
        property of Wine Technology Inc.
      </li>
    </ul>
    <h3>Non-Marketing Purposes</h3>
    <p className="para">
      Wine Technology Inc greatly respects your privacy. We do maintain and
      reserve the right to contact you if needed for non- marketing purposes
      (such as bug alerts, security breaches, account issues, and/or changes in
      Wine Technology Inc products and services). In certain circumstances, we
      may use our website, newspapers, or other public means to post a notice.
    </p>
    <h3>Children under the age of 13</h3>
    <p className="para">
      Wine Technology Inc's website is not directed to, and does not knowingly
      collect personal identifiable information from, children under the age of
      thirteen (13). If it is determined that such information has been
      inadvertently collected on anyone under the age of thirteen (13), we shall
      immediately take the necessary steps to ensure that such information is
      deleted from our system's database, or in the alternative, that verifiable
      parental consent is obtained for the use and storage of such information.
      Anyone under the age of thirteen (13) must seek and obtain parent or
      guardian permission to use this website.
    </p>
    <h3>Unsubscribe or Opt-Out</h3>
    <p className="para">
      All users and visitors to our website have the option to discontinue
      receiving communications from us by way of email or newsletters. To
      discontinue or unsubscribe from our website please send an email that you
      wish to unsubscribe to{' '}
      <a className="static-email" href="mailto:Kelly@vinwizard.us">
        Kelly@vinwizard.us
      </a>
      . If you wish to unsubscribe or opt-out from any third-party websites, you
      must go to that specific website to unsubscribe or opt-out. Wine
      Technology Inc will continue to adhere to this Policy with respect to any
      personal information previously collected.
    </p>
    <h3>Links to Other Websites</h3>
    <p className="para">
      Our website does contain links to affiliate and other websites. Wine
      Technology Inc does not claim nor accept responsibility for any privacy
      policies, practices and/or procedures of other such websites. Therefore,
      we encourage all users and visitors to be aware when they leave our
      website and to read the privacy statements of every website that collects
      personally identifiable information. This Privacy Policy Agreement applies
      only and solely to the information collected by our website.
    </p>
    <h3>Notice to European Union Users</h3>
    <p className="para">
      Wine Technology Inc's operations are located primarily in the United
      States. If you provide information to us, the information will be
      transferred out of the European Union (EU) and sent to the United States.
      (The adequacy decision on the EU-US Privacy became operational on August
      1, 2016. This framework protects the fundamental rights of anyone in the
      EU whose personal data is transferred to the United States for commercial
      purposes. It allows the free transfer of data to companies that are
      certified in the US under the Privacy Shield.) By providing personal
      information to us, you are consenting to its storage and use as described
      in this Policy.
    </p>
    <h3>Your Rights as a Data Subject</h3>
    <p className="para">
      Under the regulations of the General Data Protection Regulation ("GDPR")
      of the EU you have certain rights as a Data Subject. These rights are as
      follows:
    </p>
    <ul className="indent-sec unnum-list">
      <li>
        <strong>The right to be informed:</strong> this means we must inform you
        of how we intend to use your personal data and we do this through the
        terms of this Policy.
      </li>
      <li>
        <strong>The right of access: </strong>
        this means you have the right to request access to the data we hold
        about you and we must respond to those requests within one month. You
        can do this by sending an email to{' '}
        <a className="static-email" href="mailto:Kelly@vinwizard.us">
          Kelly@vinwizard.us
        </a>
      </li>
      <li>
        <strong>The right to rectification: </strong>
        this means that if you believe some of the date, we hold is incorrect,
        you have the right to have it corrected. You can do this by logging into
        your account with us, or by sending us an email with your request.
      </li>
      <li>
        <strong>The right to erasure: </strong>
        this means you can request that the information we hold be deleted, and
        we will comply unless we have a compelling reason not to, in which case
        you will be informed of same. You can do this by sending an email to{' '}
        <a className="static-email" href="mailto:Kelly@vinwizard.us">
          Kelly@vinwizard.us
        </a>
      </li>
      <li>
        <strong>The right to restrict processing: </strong>
        this means you can change your communication preferences or opt-out of
        certain communications. You can do this by sending an email to{' '}
        <a className="static-email" href="mailto:Kelly@vinwizard.us">
          Kelly@vinwizard.us
        </a>
      </li>
      <li>
        <strong>The right of data portability: </strong>
        this means you can obtain and use the data we hold for your own purposes
        without explanation. If you wish to request a copy of your information,
        contact us at{' '}
        <a className="static-email" href="mailto:Kelly@vinwizard.us">
          Kelly@vinwizard.us
        </a>
      </li>
      <li>
        <strong>The right to object: </strong>
        this means you can file a formal objection with us regarding our use of
        your information with regard to third parties, or its processing where
        our legal basis is our legitimate interest in it. To do this, please
        send an email to{' '}
        <a className="static-email" href="mailto:Kelly@vinwizard.us">
          Kelly@vinwizard.us
        </a>
      </li>
    </ul>
    <p className="para">
      In addition to the rights above, please rest assured that we will always
      aim to encrypt and anonymize your personal information whenever possible.
      We also have protocols in place in the unlikely event that we suffer a
      data breach and we will contact you if your personal information is ever
      at risk. For more details regarding our security protections see the
      section below or visit our website at{' '}
      <a className="static-link" href="www.msprobe.com" target="_blank">
        www.msprobe.com
      </a>
    </p>
  </>
);

export default SectionTwo;
