import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import successReset from '../../assets/img/successfuly-reset.png';
import { ROUTE_PATH } from '../../constants/routes';

function SuccessReset() {
  const navigate = useNavigate();

  return (
    <Col sm={6} className="p-0 boarding-login onboarding-right">
      <div className="login-inner">
        <div className="boarding-title verified-successfully-wrap">
          <img
            className="verified-successfully"
            src={successReset}
            alt="verified successfully"
          />
          <div className="verified-successfully-title">Password changed successfully</div>
          <p className="verified-successfully-des">
          Congratulations! Your password has been successfully changed.
            {' '}
            <br />
            Please sign in with your new password.
          </p>
        </div>
      </div>
      <div className="verified-successfully-btn successfuly-reset-btn">
        <Button
          className="btn primary w-100"
          onClick={() => navigate(ROUTE_PATH.LOGIN)}
        >
          BACK TO SIGN IN
        </Button>
      </div>
    </Col>
  );
}

export default SuccessReset;
