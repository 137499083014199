/**
 * The `ModalWrapper` function is a React component that renders a modal with a title, close button,
 * and content.
 * @param {ModalWrapperProps}  - - `show`: a boolean value indicating whether the modal should be shown
 * or not
 * @returns The ModalWrapper component is being returned.
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import closeIcon from '../../../../assets/icons/close.svg';
import { ModalWrapperProps } from '../../../../types';
import { BOOLEANS } from '../../../../constants/static';

function ModalWrapper({
  show,
  handleClose,
  title = '',
  paymentClass,
  children,
  modalIcon,
  innerClass = '',
  noIcon = true
}: ModalWrapperProps) {
  return (
    <Modal
      centered
      className={`common-modal boarding-login ${paymentClass}`}
      show={show}
      onHide={() => noIcon && handleClose(BOOLEANS.FALSE)}
      backdrop="static"
    >
      <Modal.Header>
        {modalIcon
          ? (
            <img
              src={modalIcon}
              alt="edit icon"
            />
          ) : <Modal.Title>{title}</Modal.Title>}
        {noIcon && (
        <img
          className="btn-close"
          src={closeIcon}
          alt="close icon"
          data-testid="closeIcon"
          onClick={() => handleClose(BOOLEANS.FALSE)}
        />
        )}
      </Modal.Header>
      <Modal.Body className={`upload-csv-new ${innerClass}`}>{children}</Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default ModalWrapper;
