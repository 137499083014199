export const ROLE = {
  USER: 'user',
  ADMIN: 'admin',
};

export const SORT_BY = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const SORT = {
  CREATED_AT: 'createdAt',
  EMAIL: 'email',
  NAME: 'fullName',
};

export const FETCH_POLICY = {
  NETWORK_ONLY: 'network- only',
};

export const PARAMS = {
  SEARCH: 'search',
  DATA_FORMAT: 'dataFormat',
  PROBE_ID: 'probeId',
  COMPUTATION_TYPE: 'computationType'
};

export const PAYMENT_TYPE = {
  CARD: 'card',
  ACH: 'ach',
};

export const BOOLEAN_VALUES = {
  TRUE: true,
  FALSE: false,
};

export const BUTTON = {
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
  Active: 'active',
  Deactive: 'deactive',
};

export const PDF_CONFIG = {
  WIDTH: 180,
  HEIGHT: 100,
  GRAPH_HEIGHT: 120,
  MARGIN_LEFT: 15,
  THRESHOLD_HEIGHT: 260,
  NAME: 'Exported-Graphs.pdf',
  IMAGE_TYPE: 'JPEG',
  MARGIN_TOP: 20
};

export const SYSTEM_COMPUTATION_GRAPH = {
  TEMPERATURE: 'temperature',
  DENSITY: 'density',
  STRATIFICATION: 'stratification',
  FERMENTATION: 'fermentation',
  TEMP_DENSITY: 'densitytemperature',
  DENSITY_REDOX: 'densityredox',
  TEMP_REDOX: 'temperatureredox',
};

export const TEMPERATURE = 'temperature';
export const DENSITY = 'density';
export const STRATIFICATION = 'stratification';
export const FERMENTATION = 'fermentation';
export const REDOX = 'redox';

export const TOOLTIP = {
  DETAILED: 'detailed',
  GENERIC: 'generic',
  EVENT: 'event',
};

export const SENSOR_COLORS = {
  'darkest-red': '#FD181C',
  orange: '#FD5F20',
  'tinted-yellow': '#FEC662',
  yellow: '#FFFF2E',
  'light-green': '#C6FF93',
  'dark-green': '#13A649',
  'darkest-green': '#0C7410',
};
