import * as Yup from 'yup';
import { CONTACT_ADMIN, VALIDATION_MESSAGES } from './validationMessages';
import { NUMBER } from '../number';
import {
  BANK_REGEX,
  EMAIL_REGEX,
  PASSWORD_MATCH_REGEX,
  ROUTING_NUMBER_REGEX,
} from '../regex';
import { BOOLEANS } from '../static';
import { getTimeStampDateObject } from '../../utils/helpers';

export const LOGIN_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .max(NUMBER.TWO_FIFTY)
    .required(VALIDATION_MESSAGES.EMAIL.REQUIRED)
    .matches(EMAIL_REGEX, VALIDATION_MESSAGES.EMAIL.INVALID),
  password: Yup.string()
    .min(NUMBER.EIGHT, VALIDATION_MESSAGES.PASSWORD.MIN_LENGTH)
    .required(VALIDATION_MESSAGES.PASSWORD.REQUIRED),
  rememberMe: Yup.boolean().default(false),
});

export const timeValidation = (value: string) => {
  const curr = new Date();
  if (getTimeStampDateObject(value).getTime() > curr.getTime()) {
    return BOOLEANS.FALSE;
  }
  return BOOLEANS.TRUE;
};

export const SIGNUP_PERSONAL_INFO_VALIDATION_SCHEMA = Yup.object({
  fullName: Yup.string()
    .required(VALIDATION_MESSAGES.FIRST_NAME.REQUIRED)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  email: Yup.string()
    .max(NUMBER.TWO_FIFTY)
    .required(VALIDATION_MESSAGES.EMAIL.REQUIRED)
    .matches(EMAIL_REGEX, VALIDATION_MESSAGES.EMAIL.INVALID),
  password: Yup.string()
    .required(VALIDATION_MESSAGES.PASSWORD.REQUIRED)
    .matches(PASSWORD_MATCH_REGEX, VALIDATION_MESSAGES.PASSWORD.FORMAT),
  contactNumber: Yup.string()
    .required(VALIDATION_MESSAGES.PHONE.REQUIRED)
    .test('format', VALIDATION_MESSAGES.PHONE.INVALID, (value) => {
      if (value) {
        return value.length > NUMBER.TEN;
      }
      return BOOLEANS.TRUE;
    }),
});

export const CHANGE_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
  oldPassword: Yup.string()
    .required(VALIDATION_MESSAGES.CURRENT_PASSWORD.REQUIRED),
  newPassword: Yup.string()
    .required(VALIDATION_MESSAGES.NEW_PASSWORD.REQUIRED)
    .matches(PASSWORD_MATCH_REGEX, VALIDATION_MESSAGES.PASSWORD.FORMAT)
    .test(
      'passwords-not-match',
      VALIDATION_MESSAGES.CURRENT_PASSWORD.MATCH,
      function validatePassword(value) {
        return this.parent.oldPassword !== value;
      }
    ),
});

export const SIGNUP_BUISNESS_INFO_VALIDATION_SCHEMA = Yup.object({
  wineryName: Yup.string()
    .required(VALIDATION_MESSAGES.WINERY_NAME.REQUIRED)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  wineryAddress: Yup.string()
    .max(NUMBER.TWO_FIFTY)
    .required(VALIDATION_MESSAGES.WINERY_ADDRESS.REQUIRED),
  differentBillingAddress: Yup.boolean().oneOf([true, false]),
  wineryProductionVolumeUnit: Yup.string().required(
    VALIDATION_MESSAGES.WINERY_PRODUCTION_VOLUME.REQUIRED
  ),
  wineryProductionVolume: Yup.string()
    .required(VALIDATION_MESSAGES.WINERY_VOLUME.REQUIRED)
    .test('test', VALIDATION_MESSAGES.VOLUME.INVALID, (value) => {
      if (Number(value) === NUMBER.ZERO) {
        return BOOLEANS.FALSE;
      }
      return BOOLEANS.TRUE;
    }),
  wineryFiscalYearEndMonth: Yup.string().required(
    VALIDATION_MESSAGES.WINERY_YEAR.REQUIRED
  ),
  Iagree: Yup.bool().oneOf([true], VALIDATION_MESSAGES.IAGREE.REQUIRED),
  billingAddress: Yup.string().when(
    'differentBillingAddress',
    (differentBillingAddress) => {
      if (Boolean(differentBillingAddress[0]) === false) {
        return Yup.string();
      }
      return Yup.string().required(
        VALIDATION_MESSAGES.BILLING_ADDRESS.REQUIRED
      );
    }
  ),
});

export const FORGOT_PASSWORD_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .email(VALIDATION_MESSAGES.EMAIL.INVALID)
    .max(NUMBER.TWO_FIFTY)
    .required(VALIDATION_MESSAGES.EMAIL.REQUIRED)
    .matches(EMAIL_REGEX, VALIDATION_MESSAGES.EMAIL.INVALID),
});

export const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object({
  password: Yup.string()
    .required(VALIDATION_MESSAGES.PASSWORD.REQUIRED)
    .matches(PASSWORD_MATCH_REGEX, VALIDATION_MESSAGES.PASSWORD.FORMAT)
});

export const BankAccountValidationSchema = Yup.object({
  account_holder_name: Yup.string()
    .required(VALIDATION_MESSAGES.ACCOUNT_HOLDER_NAME.MASSAGE)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  account_holder_type: Yup.string()
    .required(VALIDATION_MESSAGES.ACCOUNT_HOLDER_TYPE.MESSAGE)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  account_number: Yup.string()
    .required(VALIDATION_MESSAGES.ACCOUNT_NUMBER.REQUIRED)
    .matches(BANK_REGEX, VALIDATION_MESSAGES.ACCOUNT_NUMBER.MATCHED),
  routing_number: Yup.string()
    .required(VALIDATION_MESSAGES.ROUTING_NUMBER.REQUIRED)
    .matches(ROUTING_NUMBER_REGEX, VALIDATION_MESSAGES.ROUTING_NUMBER.MATCHED),
});

export const PaymentFormValidationSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  billing_details: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.object().shape({
      line1: Yup.string().required('Address Line 1 is required'),
    }),
  }),
});

export const ADD_NEW_TANK_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required(VALIDATION_MESSAGES.NAME.REQUIRED)
    .matches(/^(?=.*[a-zA-Z]).*$/, VALIDATION_MESSAGES.NAME.INVALID),
  capacity: Yup.string().required(VALIDATION_MESSAGES.CAPACITY.REQUIRED),
  volume: Yup.string().test(
    'test',
    VALIDATION_MESSAGES.VOLUME.REQUIRED,
    (value) => {
      if (Number(value) === NUMBER.ZERO) {
        return BOOLEANS.FALSE;
      }
      return BOOLEANS.TRUE;
    }
  ),
  location: Yup.string(),
  description: Yup.string(),
});

export const AddUserVadlidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(VALIDATION_MESSAGES.FIRST_NAME.REQUIRED)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  email: Yup.string()
    .max(NUMBER.TWO_FIFTY)
    .required(VALIDATION_MESSAGES.EMAIL.REQUIRED)
    .matches(EMAIL_REGEX, VALIDATION_MESSAGES.EMAIL.INVALID),
  contactNumber: Yup.string()
    .required(VALIDATION_MESSAGES.PHONE.REQUIRED)
    .test('format', VALIDATION_MESSAGES.PHONE.INVALID, (value) => {
      if (value) {
        return value.length > NUMBER.TEN;
      }
      return BOOLEANS.TRUE;
    }),
  wineryName: Yup.string()
    .required(VALIDATION_MESSAGES.WINERY_NAME.REQUIRED)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  wineryAddress: Yup.string()
    .required(VALIDATION_MESSAGES.WINERY_ADDRESS.REQUIRED)
    .max(NUMBER.TWO_FIFTY)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  differentBillingAddress: Yup.boolean().default(BOOLEANS.FALSE),
  wineryProductionVolumeUnit: Yup.string().required(
    VALIDATION_MESSAGES.WINERY_PRODUCTION_VOLUME.REQUIRED
  ),
  wineryProductionVolume: Yup.string()
    .required(VALIDATION_MESSAGES.WINERY_VOLUME.REQUIRED)
    .test(
      'greaterThanZero',
      VALIDATION_MESSAGES.WINERY_VOLUME.MIN_VALUE,
      (value) => {
        if (parseInt(value, NUMBER.TEN) > NUMBER.ZERO) {
          return true;
        }
        return false;
      }
    ),
  wineryFiscalYearEndMonth: Yup.string().required(
    VALIDATION_MESSAGES.WINERY_YEAR.REQUIRED
  ),
  billingAddress: Yup.string().default('').when(
    'differentBillingAddress',
    (differentBillingAddress) => {
      if (Boolean(differentBillingAddress[0]) === false) {
        return Yup.string();
      }

      return Yup.string()
        .required(VALIDATION_MESSAGES.BILLING_ADDRESS.REQUIRED)
        .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN);
    }
  ),
});

export const ADD_NEW_PROBE_VALIDATION_SCHEMA = Yup.object().shape({
  probeId: Yup.string().required(VALIDATION_MESSAGES.PROBE_ID.REQUIRED),
  probeName: Yup.string().nullable(),
  description: Yup.string().nullable(),
});

export const ADD_NEW_PROBE_VALIDATION_SCHEMA_ASSIGN = Yup.object().shape({
  probeId: Yup.string().required(VALIDATION_MESSAGES.PROBE_ID.REQUIRED),
  probeName: Yup.string().nullable(),
  tankId: Yup.string().required(VALIDATION_MESSAGES.TANK.REQUIRED),
  description: Yup.string().nullable(),
});

export const FETCH_PROBES_VALIDATION_SCHEMA = Yup.object().shape({
  probeId: Yup.string().required(VALIDATION_MESSAGES.PROBE_ID.REQUIRED),
  startTime: Yup.string().required(VALIDATION_MESSAGES.START_TIME.REQUIRED)
    .test('test', VALIDATION_MESSAGES.TIME.INVALID, (value) => timeValidation(value)),
  endTime: Yup.string().required(VALIDATION_MESSAGES.END_TIME.REQUIRED)
    .test('testwithSaer', VALIDATION_MESSAGES.TIME.INVALID, function test(value) {
      const currTime = new Date();
      const curr = getTimeStampDateObject(this.parent?.startTime).getTime();
      const endTime = getTimeStampDateObject(value).getTime();
      if (endTime < curr || currTime < endTime) {
        return BOOLEANS.FALSE;
      }
      return BOOLEANS.TRUE;
    })
    // .test('testWithCurrAsSame', VALIDATION_MESSAGES.TIME.SAME_END_DATE, function testWithCurrAsSame(value) {
    //   const curr = getTimeStampDateObject(this.parent?.startTime).getTime();
    //   const endTime = getTimeStampDateObject(value).getTime();
    //   if (curr === endTime) {
    //     return BOOLEANS.FALSE;
    //   }
    //   return BOOLEANS.TRUE;
    // }),
});

export const ADD_AND_ASSIGN_VALIDATION_SCHEMA = Yup.object().shape({
  probeId: Yup.array().min(1).required(VALIDATION_MESSAGES.PROBE_ID.REQUIRED),
  userId: Yup.string().required(VALIDATION_MESSAGES.WINARY_NAME.REQUIRED),
});

export const CONTACT_ADMIN_VALIDATION_SCHEMA = Yup.object().shape({
  subject: Yup.string()
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN)
    .required(CONTACT_ADMIN.SUBJECT),
  description: Yup.string()
    .min(NUMBER.TEN, VALIDATION_MESSAGES.MIN_TEN)
    .required(CONTACT_ADMIN.DESCRIPTION),
});

export const FILTERS_SCHEMA = Yup.object().shape({
  measurement: Yup.string()
});

export const TABULAR_COMPUTATION_FILTERS_SCHEMA = Yup.object().shape({
  sensorType: Yup.string().nullable().optional(),
  measurement: Yup.string().required(VALIDATION_MESSAGES.PROBE_ID.REQUIRED),
  startTime: Yup.string().required(VALIDATION_MESSAGES.START_TIME.REQUIRED)
    .test('test', VALIDATION_MESSAGES.TIME.INVALID, (value) => timeValidation(value)),
  endTime: Yup.string().required(VALIDATION_MESSAGES.END_TIME.REQUIRED)
    .test('test', VALIDATION_MESSAGES.TIME.INVALID, function test(value) {
      const { startTime } = this.parent;
      const curr = getTimeStampDateObject(startTime);
      if (getTimeStampDateObject(value).getTime() < curr.getTime()) {
        return BOOLEANS.FALSE;
      }
      return BOOLEANS.TRUE;
    }),
});

export const ADD_EVENT_SCHEMA = Yup.object().shape({
  sensor: Yup.string(),
  event: Yup.string().required(VALIDATION_MESSAGES.EVENT.REQUIRED),
  description: Yup.string(),
});

export const EditProbePriceValidationSchema = Yup.object().shape({
  productName: Yup.string()
    .required(VALIDATION_MESSAGES.PROBE_PRICE.REQUIRED)
    .min(NUMBER.ONE, VALIDATION_MESSAGES.MIN),
  description: Yup.string().nullable(),
});

export const ADD_NEW_PLAN_VALIDATION_SCHEMA = Yup.object().shape({
  productName: Yup.string()
    .required(VALIDATION_MESSAGES.PLAN.PRODUCT_NAME)
    .matches(/^(?=.*[a-zA-Z]).*$/, VALIDATION_MESSAGES.NAME.INVALID),
  isWineMaking: Yup.string().required(VALIDATION_MESSAGES.PLAN.SUBSCRIPTION_PLAN),
  price: Yup.string().test(
    'test',
    VALIDATION_MESSAGES.PLAN.PRICE,
    (value) => {
      if (Number(value) === NUMBER.ZERO) {
        return BOOLEANS.FALSE;
      }
      return BOOLEANS.TRUE;
    }
  ),
  description: Yup.string(),
});

export const ContactUsFormSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(VALIDATION_MESSAGES.FULL_NAME.REQUIRED)
    .min(NUMBER.THREE, VALIDATION_MESSAGES.MIN),
  email: Yup.string()
    .max(NUMBER.TWO_FIFTY)
    .required(VALIDATION_MESSAGES.EMAIL.REQUIRED)
    .matches(EMAIL_REGEX, VALIDATION_MESSAGES.EMAIL.INVALID),
  contactNumber: Yup.string()
    .required(VALIDATION_MESSAGES.PHONE.REQUIRED)
    .test('format', VALIDATION_MESSAGES.PHONE.INVALID, (value) => {
      if (value) {
        return value.length > NUMBER.TEN;
      }
      return BOOLEANS.TRUE;
    }),
  message: Yup.string()
    .required(VALIDATION_MESSAGES.MESSAGE.REQUIRED)
    .min(NUMBER.TEN, VALIDATION_MESSAGES.MESSAGE.MIN),
  subject: Yup.string()
    .required(VALIDATION_MESSAGES.SUBJECT.REQUIRED)
    .min(NUMBER.FIVE, VALIDATION_MESSAGES.SUBJECT.MIN),
});
