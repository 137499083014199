import React from 'react';
import LogOutModal from '../auth/model/LogoutModal';
import {
  CANCEL_TEXT,
  LOGOUT_MODEL_CANCEL,
  RENEW_TEXT,
  SUBSRIPTION_MODEL_BODY,
  SUBSRIPTION_MODEL_HEADER,
  SUBSRIPTION_RENEW_MODEL_BODY,
  SUBSRIPTION_RENEW_MODEL_HEADER,
} from '../../constants';
import AddOnModal from '../shared/common/modals/AddOnModal';

const AllSubscriptionModel = ({
  logoutModal, canclePlan,
  closeAddOnModal, closeLogoutModal,
  addOnModal, buyAddOnPlan,
  subscriptionType, updateLoading,
  subscriptionModal, userRenewSubscription,
  closeSubscriptionModal
}: any) => (
  <>
    <LogOutModal
      open={logoutModal}
      logoutFuntion={canclePlan}
      close={closeLogoutModal}
      headerText={SUBSRIPTION_MODEL_HEADER}
      bodyText={SUBSRIPTION_MODEL_BODY}
      confirmText={CANCEL_TEXT}
      cancelText={LOGOUT_MODEL_CANCEL}
    />
    <AddOnModal
      closeLogoutModal={closeAddOnModal}
      logoutModal={addOnModal}
      buyAddOnPlan={buyAddOnPlan}
      subscriptionType={subscriptionType}
      loading={updateLoading}
    />
    <LogOutModal
      open={subscriptionModal}
      logoutFuntion={userRenewSubscription}
      close={closeSubscriptionModal}
      headerText={SUBSRIPTION_RENEW_MODEL_HEADER}
      bodyText={SUBSRIPTION_RENEW_MODEL_BODY}
      confirmText={RENEW_TEXT}
      cancelText={LOGOUT_MODEL_CANCEL}
    />
  </>
);

export default AllSubscriptionModel;
