import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLazyQuery } from '@apollo/client';
import {
  ASSIGNED_PROBE, BOOLEANS, NUMBER, USER_MANAGEMENT
} from '../../constants';
import TableHead from '../table/tableHead';
import { USER_ASSIGN_PROBE_TABLE_HEADS } from '../../constants/tableConfig';
import SearchInput from '../shared/forms/searchInput';
import { GET_PROBE_AND_TANK_COUNT, GET_USER_ASSIGNED_PROBE } from '../../graphql/queries/admin.queries';
import useListing from '../../hooks/useListing';
import AssignedProbItem from './assignedProbItem';
import CustomLoader from '../shared/common/customLoader';
import { getCurrentUser } from '../../utils/helpers';
import Breadcrumbs from '../shared/common/breadCrumbs';
import EmptyProbPage from '../probes/EmptyProbPage';

function AssignedProbes() {
  const { id } = useParams();
  const {
    results, loading, refetch, metaData
  } = useListing(
    GET_USER_ASSIGNED_PROBE,
    'getUserAssignedProbeByAdmin',
    'probe',
    { id }
  );
  const [getProbeData, { data: probeData }] = useLazyQuery(GET_PROBE_AND_TANK_COUNT, {
    variables: { id }
  });
  const currentUser = getCurrentUser();
  const [params] = useSearchParams();
  const query = params.get('search');
  let filterTimeout: any; // using debouncing for search
  const onSearch = (search: string) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      refetch({ search, page: NUMBER.ONE });
    }, NUMBER.FIVE_HUNDRED);
  };

  const getList = async () => {
    await refetch({ search: '', page: NUMBER.ONE });
    await getProbeData();
  };

  useEffect(() => {
    getList();
  }, [id]);

  const {
    totalAttachedProbe,
    totalAvailableProbe,
    assignProbe
  } = probeData?.getProbeAndTankCountByAdmin || {};

  return (
    <>
      <div className="admin-common-body">
        <div className="admin-header-wrapper admin-heading-sec">
          <Breadcrumbs crumbs={[USER_MANAGEMENT, currentUser.fullName, ASSIGNED_PROBE]} />
          <div className="total-assigned-probes-sec">
            <Row className="total-assigned-row">
              <Col
                sm={NUMBER.FOUR}
                xs={NUMBER.TWELVE}
                className="total-assigned-sec"
              >
                <div className="total-assigned-title">
                  Total Probes Assigned by Admin
                </div>
                <div className="total-assigned-count">{assignProbe}</div>
              </Col>
              <Col
                sm={NUMBER.FOUR}
                xs={NUMBER.TWELVE}
                className="total-assigned-sec"
              >
                <div className="total-assigned-title">
                  Total Attached Probes
                </div>
                <div className="total-assigned-count">{totalAttachedProbe}</div>
              </Col>
              <Col
                sm={NUMBER.FOUR}
                xs={NUMBER.TWELVE}
                className="total-assigned-sec"
              >
                <div className="total-assigned-title">
                  Total Available Probes
                </div>
                <div className="total-assigned-count">{totalAvailableProbe}</div>
              </Col>
            </Row>
          </div>
          <div className="header-wrapper">
            <div className="header-left">
              <SearchInput
                onSearch={(e: string) => onSearch(e)}
                query={query as string}
                placeholder="Search"
                innerClass="probes-search-width"
              />
            </div>
          </div>
        </div>
        <div className="list-container service-list-container">
          {loading && <CustomLoader />}
          <div className="table-wrapper mobile-optimised">
            <TableHead
              tableFields={USER_ASSIGN_PROBE_TABLE_HEADS}
              handleSorting={(obj) => refetch({ ...obj, page: NUMBER.ONE })}
            />
            <InfiniteScroll
              dataLength={results?.length || 0}
              hasMore={metaData?.page < metaData?.totalPage}
              next={() => refetch({ search: query, page: metaData?.page + NUMBER.ONE })}
              loader=""
              className="tanks-infinite"
            >
              {results.length === 0 && !loading && (
                <EmptyProbPage isAdmin={BOOLEANS.TRUE} />
              )}
              {' '}
              <div className="tbody">
                {results?.map((data: any) => (
                  <AssignedProbItem data={data} key={data.id} />
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignedProbes;
