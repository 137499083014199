import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomLoader from '../shared/common/customLoader';
import {
  BOOLEANS,
  BUTTON,
  ROUTE_PATH,
  phoneNoFormate,
} from '../../constants';
import { UserDetailsProps } from '../../types';
import ChangePassword from '../shared/common/changePassword';
import { capitalizeFirst, checkString, generateImageUrl } from '../../utils/helpers';

const UserDetails = ({ loading, userData, status }: UserDetailsProps) => {
  const {
    contactNumber,
    fullName,
    wineryAddress,
    wineryFiscalYearEndMonth,
    wineryName,
    email,
    wineryProductionVolume,
    wineryProductionVolumeUnit,
    profileImg,
    totalProbe,
  } = userData || {};
  const navigate = useNavigate();
  const [showchangePasswordModal, setshowchangePasswordModal] = useState(
    BOOLEANS.FALSE
  );

  const handleModalStatus = () => setshowchangePasswordModal(!showchangePasswordModal);
  return (
    <>
      {loading && <CustomLoader />}
      <div className="filter-sec account-profile-sec">
        <div className="profile-logo-sec">
          <div className="profile-logo-left">
            <div className="profile-logo">
              <img src={generateImageUrl(profileImg)} alt="Menu icon" />
            </div>
          </div>
          <div className="profile-logo-right">
            <div className="profile-name-sec">
              <div className="profile-name">{fullName}</div>
              <div className="profile-subname">{wineryName}</div>
              {status && (
                <div
                  className={`profile-name-btn btn-status ${
                    status === 'inActive' ? BUTTON.Deactive : BUTTON.Active
                  }`}
                >
                  {status === 'inActive' ? BUTTON.DISABLED : BUTTON.ACTIVE}
                </div>
              )}
            </div>
            <div className="profile-email-sec">
              <div className="details-sec-data">
                <div className="details-title">Email Address</div>
                <div className="details-data">{checkString(email)}</div>
              </div>
              <div className="details-sec-data">
                <div className="details-title">Contact Number</div>
                <div className="details-data">
                  {checkString(phoneNoFormate(contactNumber))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-details-sec">
          <div className="profile-details-heading">Winery Information</div>
          <div className="details-sec">
            <div className="details-sec-data">
              <div className="details-title">Winery Address</div>
              <div className="details-data">{checkString(wineryAddress)}</div>
            </div>
            <div className="details-sec-data">
              <div className="details-title">Winery Production Volume</div>
              <div className="details-data">
                {`${wineryProductionVolume} 
              ${capitalizeFirst(wineryProductionVolumeUnit)
              }`}
              </div>
            </div>
            <div className="details-sec-data">
              <div className="details-title">Winery Fiscal Year End Month</div>
              <div className="details-data">
                {checkString(wineryFiscalYearEndMonth)}
              </div>
            </div>
            <div className="details-sec-data">
              <div className="details-title">Total Purchased Probes</div>
              <div className="details-data">{checkString(totalProbe?.toString())}</div>
            </div>
          </div>
        </div>
        <div className="my-account-profile-btn">
          <Button
            className="btn-outline-border header-outline-btn edit-profile-btn"
            onClick={handleModalStatus}
          >
            Change Password
          </Button>
          <Button
            className="btn primary header-primary-btn edit-profile-btn"
            type="submit"
            onClick={() => navigate(ROUTE_PATH.USER_PROFILE_EDIT)}
          >
            Edit Profile
          </Button>
        </div>
      </div>

      <ChangePassword
        open={showchangePasswordModal}
        close={handleModalStatus}
      />
    </>
  );
};

export default UserDetails;
