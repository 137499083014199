import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { generateImageUrl } from '../../utils/helpers';
import { ROUTE_PATH } from '../../constants/routes';
import { AddUserFormInputs, UserFormProps } from '../../types';
import { AddUserVadlidationSchema } from '../../constants/forms/validationSchema';
import Breadcrumbs from '../shared/common/breadCrumbs';
import editLogo from '../../assets/icons/pen.svg';
import leftArrow from '../../assets/icons/arrow-left.svg';

import {
  BOOLEANS, NUMBER, ROLE, USER_MANAGEMENT
} from '../../constants';
import uploadToAws from './uploadToAws';
import CustomLoader from '../shared/common/customLoader';
import UserDetailsForm from './userDetailsForm';

function UserForm({
  buttonName,
  headerTitle = '',
  initialValues,
  onSuccess,
  isProfile,
  role = ROLE.USER,
}: UserFormProps) {
  const navigate = useNavigate();
  const [imageUploading, setImageUploading] = useState<boolean>(BOOLEANS.FALSE);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors, isValid },
  } = useForm<AddUserFormInputs>({
    resolver: yupResolver(AddUserVadlidationSchema),
    defaultValues: initialValues,
  });

  const handleCancle = () => (isProfile
    ? navigate(ROUTE_PATH.USER_PROFILE)
    : navigate(ROUTE_PATH.ADMIN_USER_MANAGEMENT));

  const onSubmitHandler = handleSubmit(async (values: Record<string, any>) => {
    if (values.differentBillingAddress === false) {
      values.billingAddress = '';
    }
    onSuccess?.(values);
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageUploading(() => BOOLEANS.TRUE);
    const file = event.target.files && event.target.files[NUMBER.ZERO];

    if (!file) {
      setImageUploading(() => BOOLEANS.FALSE);
      return;
    }
    const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!allowedFileTypes.includes(file.type)) {
      toast.error('Only .jpg, .jpeg, or .png files are allowed.');
      setImageUploading(() => BOOLEANS.FALSE);
      return;
    }

    if (file.size >= NUMBER.TWO_MB) {
      toast.error('Max file size allowed is 2MB.');
      setImageUploading(() => BOOLEANS.FALSE);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const inputImage = new Image();
      if (reader.result) {
        inputImage.src = reader.result as string;
        inputImage.onload = () => {
          uploadToAws(file).then((data: any) => {
            setValue('profileImg', data.key);
            const image = new Image();
            image.src = generateImageUrl(getValues('profileImg'));
            image.onload = () => {
              setImageUploading(() => BOOLEANS.FALSE);
            };
          });
        };
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      {imageUploading && <CustomLoader />}
      <div className="admin-common-body">
        <div className="edit-prifile-wrapper">
          <form onSubmit={onSubmitHandler}>
            <div className="for-mobile">
              <div className="header-wrapper-mobile">
                <img
                  className="left-arrow"
                  src={leftArrow}
                  alt="Arrow"
                  onClick={() => navigate(-NUMBER.TWO)}
                />
                <h5 className="admin-header-title mb-0">{headerTitle}</h5>
              </div>
            </div>
            <div className="admin-header-wrapper">
              <Breadcrumbs
                crumbs={[USER_MANAGEMENT, headerTitle]}
                innerClass="for-desktop"
              />
              <div className="header-wrapper">
                <div className="header-left for-desktop">
                  <h1 className="admin-header-title mb-26">{headerTitle}</h1>
                </div>
                <div className="header-right after-edit-profile mb-26">
                  <Button
                    className="btn-outline-border header-outline-btn"
                    onClick={handleCancle}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn primary header-primary-btn"
                    type="submit"
                    disabled={Object.keys(errors).length > 0 || !isValid}
                  >
                    {buttonName}
                  </Button>
                </div>
              </div>
            </div>
            <div className="edit-prifile-sec">
              <Row>
                <Col sm={12} md={4} lg={3}>
                  <div className="filter-sec profile-logo-sec">
                    <div className="logo-img-sec">
                      <div className="logo-img">
                        <img
                          className="edit-img-profile"
                          src={generateImageUrl(getValues('profileImg'))}
                          alt="User Placeholder"
                        />
                        <div className="logo-edit-img">
                          <input
                            type="file"
                            name="profileImg"
                            id="profileImg"
                            accept=".jpg, .jpeg, .png"
                            className="custom-input-file"
                            onChange={handleFileChange}
                          />
                          <img
                            className="edit-img"
                            src={editLogo}
                            alt="Icon Edit"
                          />
                        </div>
                      </div>
                      {errors.profileImg?.message && (
                        <span className="input-error-message">
                          {errors.profileImg?.message}
                        </span>
                      )}
                    </div>
                    <div className="logo-size">
                      Image size should have a maximum file size of 2 MB.
                    </div>
                    <div className="logo-format">
                      Image format: jpg, jpeg, png.
                    </div>
                  </div>
                </Col>
                <UserDetailsForm
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  getValues={getValues}
                  role={role}
                  setError={setError}
                />
              </Row>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UserForm;
