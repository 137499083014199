export const TITLE_REQUIRED = 'Title is required.';

export const VALIDATION_MESSAGES = {
  MIN: 'Please enter a minimum of 3 characters.',
  PROFILE_IMG: 'Profile Image is required',
  MIN_TEN: 'Please enter a minimum of 10 characters.',
  FULL_NAME: { REQUIRED: 'Full name is mandatory.' },
  FIRST_NAME: { REQUIRED: 'First Name is mandatory.' },
  LAST_NAME: { REQUIRED: 'Last Name is mandatory.' },
  USER_NAME: { REQUIRED: 'Username is mandatory.' },
  WINERY_NAME: { REQUIRED: 'Winery name is mandatory.' },
  WINERY_ADDRESS: { REQUIRED: 'Winery address is mandatory.' },
  WINERY_VOLUME: { REQUIRED: 'Volume is mandatory.', MIN_VALUE: 'Volume must be greater than 0.' },
  WINERY_PRODUCTION_VOLUME: {
    REQUIRED: 'Winery production volume is mandatory.',
  },
  WINERY_YEAR: { REQUIRED: 'Winery fiscal year end month is mandatory.' },
  IAGREE: { REQUIRED: 'Please agree to the Terms and conditions' },
  EMAIL: {
    REQUIRED: 'Email is mandatory.',
    INVALID: 'Please enter a valid email address.',
  },
  PHONE: {
    REQUIRED: 'Contact number is mandatory',
    INVALID: 'Please enter a valid contact number.',
  },
  PASSWORD: {
    REQUIRED: 'Password is mandatory.',
    MIN_LENGTH: 'Password must be at least 8 characters.',
    FORMAT:
      'Must be 8 characters, at least one alpha-numeric, one special character, one uppercase and one lowercase.',
    MATCH: 'Confirm New Password must match with New Password.',
  },
  OLD_PASSWORD: {
    REQUIRED: 'Old Password is mandatory.',
  },
  CONFIRM_NEW_PASSWORD: {
    REQUIRED: 'Confirm New Password is mandatory.',
  },
  USERNAME: {
    REQUIRED: 'Username is mandatory.',
  },
  NAME: {
    REQUIRED: 'Name is mandatory.',
    INVALID: 'Please enter atleast one alphabet.',
  },
  CURRENT_PASSWORD: {
    REQUIRED: 'Password is mandatory.',
    MIN_LENGTH: 'Password must be at least 8 characters.',
    FORMAT:
      'Must be 8 characters, at least one alpha-numeric, one special character, one uppercase and one lowercase.',
    MATCH: 'Current Password should not match with the new Password.',
  },
  NEW_PASSWORD: {
    REQUIRED: 'New Password is mandatory.',
    MIN_LENGTH: 'New Password must be at least 8 characters.',
    FORMAT:
      'Password must include a special symbol (!, *, $, etc.) and at least one number. ',
  },
  CONFIRM_PASSWORD: {
    REQUIRED: 'Confirm New Password is mandatory.',
    MIN_LENGTH: 'Confirm Password must be at least 8 characters.',
    FORMAT:
      'Confirm Password must include a special symbol (!, *, $, etc.) and at least one number. ',
    MATCH: 'New Password and Confirm New Password should match.',
  },
  BILLING_ADDRESS: { REQUIRED: 'Billing address is mandatory.' },
  DOB: { REQUIRED: 'Date of Birth is mandatory.' },
  AVATAR: {
    REQUIRED: 'Avatar is mandatory.',
  },
  INVALIDLOGIN: 'Could not find any account with entered credentials.',
  SUBSCRIPTIONDONE: 'Subscribed to Course successfully.',
  CONTACT_FORM_SUCCESS: 'Thank you for your question.',
  TITLE: { REQUIRED: TITLE_REQUIRED },
  CAPACITY: {
    REQUIRED: 'Capacity is mandatory.',
  },
  VOLUME: {
    REQUIRED: 'This value is not supported.',
    INVALID: 'Please enter a valid input.',
  },
  ACCOUNT_HOLDER_NAME: {
    MASSAGE: 'Account holder name is required',
  },
  ACCOUNT_HOLDER_TYPE: {
    MESSAGE: 'Account holder type is required',
  },
  ACCOUNT_NUMBER: {
    REQUIRED: 'Account number is required',
    MATCHED: 'Account number must be 9 to 12 digits long',
  },
  ROUTING_NUMBER: {
    REQUIRED: 'Routing number is required',
    MATCHED: 'Routing number must be 9 digits long',
  },
  PROBE_ID: {
    REQUIRED: 'Probe is mandatory.',
  },
  PROBE_PRICE: {
    REQUIRED: 'Price is mandatory.',
    MIN_VALUE: 'Price must be greater than 0.',
  },
  WINERY_EVENT: {
    REQUIRED: 'Winery Event Making price is mandatory.',
    MIN_VALUE: 'Price must be greater than 0.',
  },
  TANK: {
    REQUIRED: 'Tank is mandatory.',
  },
  START_TIME: {
    REQUIRED: 'Start time is mandatory.',
  },
  END_TIME: {
    REQUIRED: 'End time is mandatory.',
  },
  WINARY_NAME: {
    REQUIRED: 'Winary name is mandatory.',
  },
  TIME: {
    INVALID: 'Please select a valid date & time.',
    SAME_END_DATE: 'End date and time should not be same as Start date and Time.',
  },
  EVENT: {
    REQUIRED: 'Please select an Event.',
  },
  DESCRIPTION: {
    REQUIRED: 'Description is mandatory.',
  },
  PLAN: {
    SUBSCRIPTION_PLAN: 'Subscription Plan is mandatory.',
    PRODUCT_NAME: 'Plan name is mandatory.',
    PRICE: 'Price is mandatory.',
  },
  MESSAGE: {
    REQUIRED: 'Message is mandatory',
    MIN: 'Please enter a minimum of 10 characters.',
  },
  SUBJECT: {
    REQUIRED: 'Subject is mandatory',
    MIN: 'Please enter a minimum of 5 characters.',
  },
};

export const MESSAGES = {
  FORGOT_PASSWORD_SUCCESS:
    'We have sent a recovery password link. Please check your email.',
  FORGOT_PASSWORD_ERROR: 'Error on forgot password',
  MARK_COMPLETE: 'Are you sure you want to mark this mission as complete?',
  REMOVE_PROFILE: 'Please ensure and then confirm!',
  PROFILE_REMOVED: 'Profile removed successfully',
  REMOVE_COURSE: 'Course Un-assigned',
};

export const CONTACT_ADMIN = {
  SUBJECT: {
    REQUIRED: 'Subject is mandatory!',
  },
  DESCRIPTION: {
    REQUIRED: 'Description is mandatory!',
  },
};

export const PASSWORD_MESSAGE = {
  SUCCESS: 'Password has been successfully changed!',
  ERROR_NOT_MATCH: 'Confirm New Password field and not New Password field.',
  ERROR_NOT_FILL: 'Please fill in all fields.',
  ERROR_CONFIRM_PASSWORD: 'Please enter the correct password in Current Password.'
};

export const UPLOAD_CSV_MESSAGE = {
  ERROR_CSV: 'Please select a valid CSV file.',
  CSV_SUCCESS_MESSAGE: 'File uploaded and parsed successfully!',
  CSV_NOT_UPLOAD: 'Error parsing CSV file. Please try again.',
  VALID_CSV: 'Please select a valid CSV file before uploading.',
};

export const RESET_SUCCESS = {
  RESET_MESSAGE: 'Password reset successful!',
};
