import React from 'react';
import { SelectableLegendProps } from '../../../../types';
import { LINE_COLORS_CODES } from '../../../../constants';

function SelectableLegend({ legends, handleChange, selectedLegends }: SelectableLegendProps) {
  return (
    <div className="temp-sensor-wrap">
      <div className="remember-me-wrap temp-sensor-checkbox-colored">
        <div className="using-custom-checkbox" onClick={handleChange}>
          {legends.map((legend: string, index: number) => {
            const isChecked = selectedLegends?.includes(legend);
            return (
              <div className={`using-custom-checkbox-item ${isChecked ? 'checked' : ''}`} key={legend}>
                <p id={legend} style={{ borderColor: LINE_COLORS_CODES[index] }}>{isChecked && <span id={legend} style={{ backgroundColor: LINE_COLORS_CODES[index] }}/>}</p>
                {legend}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SelectableLegend;
