import { gql } from '@apollo/client';

export const DELETE_USER = gql`
  mutation DeleteUserById($id: String!) {
    deleteUser(id: $id) {
      message
    }
  }
`;
export const DISABLE_USER = gql`
  mutation disableUser($id: String!) {
    disableUser(id: $id) {
      message
    }
  }
`;
export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      message
    }
  }
`;

export const ADD_USER_MUTATION = gql`
  mutation addUser(
    $fullName: String!
    $email: String!
    $wineryName: String!
    $contactNumber: String!
    $wineryAddress: String!
    $wineryFiscalYearEndMonth: String!
    $wineryProductionVolume: Float!
    $differentBillingAddress: Boolean!
    $wineryProductionVolumeUnit: String!
    $billingAddress: String!
    $profileImg: String
  ) {
    addUser(
      fullName: $fullName
      email: $email
      wineryName: $wineryName
      contactNumber: $contactNumber
      wineryAddress: $wineryAddress
      wineryFiscalYearEndMonth: $wineryFiscalYearEndMonth
      wineryProductionVolume: $wineryProductionVolume
      differentBillingAddress: $differentBillingAddress
      wineryProductionVolumeUnit: $wineryProductionVolumeUnit
      billingAddress: $billingAddress
      profileImg: $profileImg
    ) {
      message
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: String!
    $fullName: String
    $wineryName: String
    $contactNumber: String
    $wineryAddress: String
    $wineryFiscalYearEndMonth: String
    $wineryProductionVolume: Float
    $differentBillingAddress: Boolean
    $wineryProductionVolumeUnit: String
    $billingAddress: String
    $profileImg: String
  ) {
    updateUser(
      id: $id
      fullName: $fullName
      wineryName: $wineryName
      contactNumber: $contactNumber
      wineryAddress: $wineryAddress
      wineryFiscalYearEndMonth: $wineryFiscalYearEndMonth
      wineryProductionVolume: $wineryProductionVolume
      differentBillingAddress: $differentBillingAddress
      wineryProductionVolumeUnit: $wineryProductionVolumeUnit
      billingAddress: $billingAddress
      profileImg: $profileImg
    ) {
      message
    }
  }
`;
export const FORGOT_PASSWORD_MUTATION = gql`
mutation ForgotPassword($email: String!,$captchaToken:String!){
  forgotPassword(email: $email,captchaToken:$captchaToken) {
    message
  }
}
`;
export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      message
    }
  }
`;
export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CreateSubscriptionPrice(
  $productName: String!,
  $price: String!,
  $description: String
  $isWineMaking:Boolean!
) {
  createSubscriptionPrice(
    productName: $productName,
    price: $price,
    description: $description,
    isWineMaking:$isWineMaking
  ) {
    message
  }
}`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updateSubscriptionPlan(
    $id: String!,
    $description:String!,
    $productName:String!,
    ) {
    updateSubscriptionPlan(
      id: $id,
      productName:$productName,
      description:$description
      ) {
      message
    }
  }
`;
export const DEFAULT_SUBSCRIPTION_PLAN = gql`
  mutation makePlanCurrent(
    $id: String!,
    ) {
      makePlanCurrent(
      id: $id,
      ) {
      message
    }
  }
`;

export const DELETE_PROBE = gql`
  mutation DeleteProbe($id: String!) {
    deleteProbe(id: $id) {
      message
    }
  }
`;

export const CHANGE_PROBE_STATUS = gql`
mutation ChangeProbeStatus($id: String!) {
  changeProbeStatus(id: $id) {
    message
  }
}
`;
