import { useApolloClient, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BOOLEANS, ROLE, ROUTE_PATH, USER_INFO
} from '../constants';
import { LOGOUT_MUTATION } from '../graphql/mutation';
import { getSession, removeCookies } from '../utils/helpers';
import { selectedProbes } from '../graphql/reactiveVariables';

const useLogout = () => {
  const client = useApolloClient();
  const accessToken = getSession(USER_INFO);
  const navigate = useNavigate();
  const [Logout] = useMutation(LOGOUT_MUTATION);
  const [logoutModal, setLogoutModal] = useState(BOOLEANS.FALSE);
  const closeLogoutModal = () => {
    setLogoutModal(BOOLEANS.FALSE);
  };

  const openLogoutModal = () => {
    setLogoutModal(true);
  };
  const logoutUser = async () => {
    try {
      await client.clearStore();
      await selectedProbes([]);
      const response = await Logout();
      if (response) {
        localStorage.removeItem(USER_INFO);
        removeCookies();
        closeLogoutModal();
        navigate(ROUTE_PATH.LOGIN);
      }
    } catch (e) {
      // error
    }
  };

  const handleRedirect = (
    role: string,
    subscriptionId: string | null
  ): void => {
    if (role === ROLE.ADMIN) {
      navigate(ROUTE_PATH.ADMIN_DASHBOARD);
    } else if (role === ROLE.USER && !subscriptionId) {
      navigate(ROUTE_PATH.SUBSRIPTION);
    } else if (role === ROLE.USER && subscriptionId) {
      navigate(ROUTE_PATH.USER_DASHBOARD);
    }
  };

  return {
    logoutUser,
    openLogoutModal,
    closeLogoutModal,
    logoutModal,
    accessToken,
    handleRedirect
  };
};

export default useLogout;
