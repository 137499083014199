import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TankCountProps } from '../../types';
import { ROUTE_PATH } from '../../constants';

function TanksCount({ count }: TankCountProps) {
  const navigate = useNavigate();
  return (
    <div className="card-left bg-left cursor-pointer" onClick={() => navigate(ROUTE_PATH.USER_TANKS)}>
      <div className="card-data-sec">
        <div className="card-num pt-25">{count}</div>
        <div className="card-total">Total Number of Tanks</div>
      </div>
    </div>
  );
}

export default TanksCount;
