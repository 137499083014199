import React from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { ROUTE_PATH } from '../../constants';
import GetStartedButton from './GetStartedButton';

const PreFooter = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="landing-pre-footer">
        <div className="pre-footer-sec">
          <div className="pre-footer-title">
            <ScrollAnimation animateIn="bounceInLeft">
              Let’s Get Started
            </ScrollAnimation>
          </div>
          <div className="pre-footer-subtitle">
            Sign up now and purchase your probes to take the guesswork out of
            winemaking.
          </div>
          <GetStartedButton
            handleLoginRoute={() => navigate(ROUTE_PATH.SIGNUP)}
            buttonName="SIGN UP NOW"
            classes={['btn-signin pre-footer-btn', 'pre-footer-btn-sec']}
          />
        </div>
      </section>
    </>
  );
};

export default PreFooter;
