import React from 'react';
import { formatDate } from '../../../utils/dateHelper';
import { SystemComputationDataRowProps } from '../../../types';
import { capitalizeFirst, checkString } from '../../../utils/helpers';

function SystemTableRow({ sensor }:SystemComputationDataRowProps) {
  return (
    <div className="row tr">
      <div
        className="td flex-table-column-20"
        data-title="Time Stamp"
      >
        <div className="first-column-wrapper">
          <span
            className="pick-on-click"
          >
            <h5 className="listing-title">
              {sensor?.timestampDate
                ? formatDate(sensor?.timestampDate, 'MMM DD, YYYY hh:mm A') : '-'}
            </h5>
          </span>
        </div>
      </div>
      <div
        className="td flex-table-column-16"
        data-title="Sensor"
      >
        <div className="">
          <p className="listing-normal">{checkString(capitalizeFirst(sensor?.sensorType))}</p>
        </div>
      </div>
      <div
        className="td flex-table-column-16"
        data-title="Value"
      >
        <div className="">
          <p className="listing-normal">{checkString(sensor?.value)}</p>
        </div>
      </div>
      <div
        className="td flex-table-column-16"
        data-title="Max Temp"
      >
        <div className="">
          <p className="listing-normal">{checkString(sensor?.highTemperature)}</p>
        </div>
      </div>
      <div
        className="td flex-table-column-16"
        data-title="Min Temp"
      >
        <div className="">
          <p className="listing-normal">{checkString(sensor?.lowTemperature)}</p>
        </div>
      </div>
      <div
        className="td flex-table-column-16"
        data-title="Event"
      >
        <div className="">
          <p className="listing-normal">{checkString(sensor?.event)}</p>
        </div>
      </div>
    </div>
  );
}

export default SystemTableRow;
