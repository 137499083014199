import React from 'react';
import { ToolTipWrapperProps } from '../../types';
import { NUMBER, TOOLTIP } from '../../constants';
import deleteIcon from '../../assets/icons/graph-delete.svg';
import { getLinearGredient, getSensorColor } from '../../utils/helpers';
import segmentTemp from '../../assets/img/segment-temp.png';
import errorIcon from '../../assets/icons/grey-error-icon.svg';

function ToolTipWrapper({
  event,
  onhover,
  onRemove,
  lines,
  visibleLines,
  payload,
  openInfo
}: ToolTipWrapperProps) {
  const visibleValues = lines.map((i: string) => payload[i as keyof typeof payload] as number) || [];
  const eventIndex: any = payload[`${payload?.dataKey}Index` as keyof typeof payload] || NUMBER.ZERO;

  const descValue = payload?.description
    || payload[`${payload?.dataKey}Desc` as keyof typeof payload];

  const getPositionStyle = (pageX: number, pageY: number) => {
    let offsetLeft = NUMBER.THIRTY;
    let offsetTop = NUMBER.FIFTY;
    const cardHeight = NUMBER.FIFTY;
    const top = pageY;
    const left = pageX;
    // if data point is at right most side of screen
    if (left + NUMBER.TWO_SEVENTY > window.innerWidth) {
      offsetLeft -= NUMBER.ONE_FIFTY;
    }

    // position according to y
    offsetTop -= 1 * cardHeight;
    return { top: `${top + offsetTop}px`, left: `${left + offsetLeft}px` };
  };

  if (payload?.type === TOOLTIP.GENERIC) {
    return (
      <div
        className="graph-tooltip"
        style={{
          zIndex: '1',
          position: 'absolute',
          backgroundColor: payload?.stroke,
          padding: '10px',
          ...getPositionStyle(
            event ? event?.pageX : NUMBER.ZERO,
            event ? event?.pageY : NUMBER.ZERO
          ),
        }}
      >
        <p>
          {payload?.sensor}
          :
          {' '}
          {payload?.value || NUMBER.ZERO}
        </p>
      </div>
    );
  }

  // Showing the temprature detailed tooltip on Base computation graph
  if (payload?.type === TOOLTIP.DETAILED) {
    return (
      <div
        className="graph-tooltip"
        style={{
          zIndex: '1',
          position: 'absolute',
          ...getPositionStyle(
            event ? event?.pageX - NUMBER.THREE_HUNDRED_TEN : NUMBER.ZERO,
            event ? event?.pageY + NUMBER.TEN : NUMBER.ZERO
          ),
        }}
        onMouseEnter={() => {
          onhover(true, null, 'tooltip');
        }}
        onMouseLeave={() => {
          onhover(false, null, 'tooltip');
        }}
      >
        <div className="below-graph onhover-graph-respresenation">
          <div className="below-graph-right">
            <div className="header-tank-representaion d-flex">
              <p
                className="below-graph-title cursor-pointer"
              >
                Temperature (°F/°C) (Unit)
                <img className="error-icon" src={errorIcon} alt="error icon" onClick={() => openInfo(true)} />
              </p>
            </div>
            <div className="below-graph-temp-wrapper">
              {lines?.map((i: string) => (
                <div className="below-graph-temp-item" key={i}>
                  <div className="below-graph-text">
                    {i}
                  </div>
                  <div
                    className={`below-graph-number ${getSensorColor(
                      Number(payload[i as keyof typeof payload]) || NUMBER.ZERO
                    )}`}
                  >
                    {payload[i as keyof typeof payload] || NUMBER.ZERO}
                    {' '}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="below-graph-left">
            <div className="custom-bar-container">
              <img src={segmentTemp} alt="segment pic" />
              <ul
                className="custom-bar-representation"
                style={{ backgroundImage: getLinearGredient(visibleValues) }}
              >
                {lines?.map((i: string) => (
                  <li
                    key={i}
                    className="custom-bar-item"
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="graph-tooltip"
      style={{
        ...getPositionStyle(
          event ? event?.pageX : NUMBER.ZERO,
          event ? event?.pageY : NUMBER.ZERO
        ),
      }}
      onMouseEnter={() => {
        onhover(true, null, 'tooltip');
      }}
      onMouseLeave={() => {
        onhover(false, null, 'tooltip');
      }}
    >
      <div className="graph-tooltip-container">
        <div className="graph-tooltip-first">
          <p className="graph-tooltip-title">Event:</p>
          <p className="graph-title-ans">
            {payload?.event
              || payload[`${payload?.dataKey}Event` as keyof typeof payload]}
          </p>
        </div>
        <div className="graph-tooltip-first">
          <p className="graph-tooltip-title">Description:</p>
          <p className="graph-title-ans">
            {descValue || '---'}
          </p>
        </div>
        <img
          onClick={() => onRemove({
            id: payload?.id,
            dataKey: payload?.dataKey,
            graphType: payload?.graphType,
            index: eventIndex,
          })}
          className="delete-icon cursor-pointer"
          src={deleteIcon}
          alt="delete icon"
        />
      </div>
    </div>
  );
}

export default ToolTipWrapper;
