import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LogoutModelProps } from '../../../types';

function LogOutModal({
  open,
  close,
  logoutFuntion,
  headerText,
  bodyText,
  confirmText,
  imgPath,
  cancelText,
}: LogoutModelProps) {
  return (
    <Modal centered className="common-modal remove-modal" show={open} onHide={close}>
      <Modal.Header>
        {imgPath ? (
          <img className="logout-icon" src={imgPath} alt="logout icon" />
        ) : (
          <span className="icon-close-with-circle" />
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-form">
          <h4 className="remove-modal-tile">{headerText}</h4>
          <p>{bodyText}</p>
        </div>
        <div className="footer-modal">
          <Button className="footer-outline-btn" onClick={logoutFuntion}>
            {`Yes, ${confirmText}`}
          </Button>
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            onClick={close}
          >
            {cancelText}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default LogOutModal;
