import moment, { MomentInput } from 'moment';
import cryptoJs from 'crypto-js';
import { DATE_TIME_FORMATE } from './static';
/* eslint-disable import/prefer-default-export */
export const String = {
  emailexistMessage: ' Email already exist.',
};
export const PAYMENT_ERROR = 'Payment Error';
export const CREDIT_CARD = 'Credit Card';
export const NERWORK_ERROR_SUFFIX = 'Please check your internet connection.';

export const NO_TANKS = {
  TEXT: 'There are no tanks added yet. Please click the above button to add new tank.',
  HEADING: 'No Tanks',
  ACTION_TEXT: 'Add new Tank'
};

export const NO_ACTIVE_TANKS = {
  ACTIVE_TEXT: 'There are no active probes.',
  DISABLED_TEXT: 'There are no disabled probes.',
  HEADING: 'No Probes',
  ACTION_TEXT: 'Assign new Tank'
};

export const NO_SEARCH_DATA = {
  TEXT: 'May be data is empty or try adjusting your filter.',
  HEADING: 'No Data Available',
};

export const DURATION_MONTH = ' month';
export const DURATION_YEARLY = ' year';
export const MONTHLY = 'monthly';

export const CURRENCY_SYMBOL = '$';
export const formatPrice = (price: { toLocaleString: (arg0: string) => any; }) => price.toLocaleString('en-US');

export const priceDATA = (price: string | number) => {
  if (Number.isNaN(+price)) {
    return '0';
  }
  return (+price).toLocaleString('en-US');
};
export function getFormattedDate(timestamp: MomentInput) {
  const parsedTimestamp = moment(timestamp).format(DATE_TIME_FORMATE);
  return parsedTimestamp.replace('[At]', 'at');
}

export const ADMIN_USER = '/admin/users/';

export const CSV_SUCCESS_MESAAGE = 'CSV is uploaded. Please check your email inbox for the status.';

export const MAX_PROBES_WARN = 'Maximum 5 probes can be added.';

export function decryptData(data: any) {
  const bytes = cryptoJs.AES.decrypt(data, 'mspVinwizard');
  return bytes.toString(cryptoJs.enc.Utf8);
}

export function encryptData(data: any) {
  return cryptoJs.AES.encrypt(data, 'mspVinwizard').toString();
}
