import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADD_PROBE_MUTATION = gql`
  mutation AddProbe($probeId: [String!]!, $userId: String!) {
    addProbe(probeId: $probeId, userId: $userId) {
      message
    }
  }
`;
