import React, { useState } from 'react';

type ModalStates = Record<string, boolean>;

const useModal = () => {
  const [modalStates, setModalStates] = useState<ModalStates>({});

  const toggleModal = (modalId: string) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalId]: !prevState[modalId],
    }));
  };

  return { modalStates, toggleModal };
};

export default useModal;
