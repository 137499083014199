import React from 'react';

const SectionFive = () => (
  <>
    <h3>Section 5 - Submitted Content</h3>
    <p className="para">
      Wine Technology Inc. shall not lay claim to ownership of any content
      submitted by any visitor, member, or user, nor make such content available
      for inclusion on our website Services. Therefore, you hereby grant and
      allow for Wine Technology Inc. the below listed worldwide, royalty-free
      and non-exclusive licenses, as applicable:
    </p>
    <div className="indent-sec">
      <p className="para">
        a) The content submitted or made available for inclusion on the publicly
        accessible areas of Wine Technology Inc.'s Sites, the license provided
        to permit to use, distribute, reproduce, modify, adapt, publicly perform
        and/or publicly display said Content on our network Services is for the
        sole purpose of providing and promoting the specific area to which this
        content was placed and/or made available for viewing. This license shall
        be available so long as you are a member of Wine Technology Inc.'s
        sites, and shall terminate at such time when you elect to discontinue
        your membership.
      </p>
      <p className="para">
        b) Photos, audio, video and/or graphics submitted or made available for
        inclusion on the publicly accessible areas of Wine Technology Inc.'s
        sites, the license provided to permit to use, distribute, reproduce,
        modify, adapt, publicly perform and/or publicly display said Content on
        our network Services are for the sole purpose of providing and promoting
        the specific area in which this content was placed and/or made available
        for viewing. This license shall be available so long as you are a member
        of Wine Technology Inc.'s sites and shall terminate at such time when
        you elect to discontinue your membership.
      </p>
      <p className="para">
        c) For any other content submitted or made available for inclusion on
        the publicly accessible areas of Wine Technology Inc.'s sites, the
        continuous, binding and completely sub-licensable license which is meant
        to permit to use, distribute, reproduce, modify, adapt, publish,
        translate, publicly perform and/or publicly display said content,
        whether in whole or in part, and the incorporation of any such Content
        into other works in any arrangement or medium current used or later
        developed.
      </p>
    </div>
    <p className="para">
      Those areas which may be deemed "publicly accessible" areas of Wine
      Technology Inc.'s Sites are those such areas of our network properties
      which are meant to be available to the general public, and which would
      include message boards and groups that are openly available to both users
      and members. However, those areas which are not open to the public, and
      thus available to members only, would include our mail system and instant
      messaging.
    </p>

    <h3>Contributions To Company Website</h3>
    <p className="para">
      Wine Technology Inc. may provide an area for our user and members to
      contribute feedback to our website. When you submit ideas, documents,
      suggestions and/or proposals ("<strong>Contributions</strong>") to our
      site, you acknowledge and agree that:
    </p>
    <div className="indent-sec">
      <p className="para">
        a) Your contributions do not contain any type of confidential or
        proprietary information.
      </p>
      <p className="para">
        b) MSP shall not be liable or under any obligation to ensure or maintain
        confidentiality, expressed or implied, related to any Contributions.
      </p>
      <p className="para">
        c) MSP shall be entitled to make use of and/or disclose any such
        Contributions in any such manner as they may see fit.
      </p>
      <p className="para">
        d) The contributor's Contributions shall automatically become the sole
        property of MSP.
      </p>
      <p className="para">
        e) MSP is under no obligation to either compensate or provide any form
        of reimbursement in any manner or nature.
      </p>
    </div>
  </>
);

export default SectionFive;
