import React from 'react';
import { Button } from 'react-bootstrap';

const SunbscriptionNoPackage = ({
  openAddOnModal,
  isWineMakingPackage,
  subscriptionId,
  isCancelSubscription
}: any) => (
  <>
    {!isWineMakingPackage ? (
      <div className="wine-making-container">
        <div className="wine-making-left">
          <h4 className="wine-making-title">Add-Ons</h4>
          <p className="wine-making-subtitle">
            Winemaking Event Packages (Add-Ons)
          </p>
        </div>
        <div className="wine-making-right">
          <Button
            className="btn primary header-primary-btn edit-profile-btn"
            onClick={openAddOnModal}
            disabled={!subscriptionId || isCancelSubscription}
          >
            Buy Now
          </Button>
        </div>
      </div>
    ) : null}
  </>
);

export default SunbscriptionNoPackage;
