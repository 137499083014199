import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SearchInputProps } from '../../../types';

function SearchInput({ onSearch, query, placeholder, innerClass = '' }: SearchInputProps) {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [value, setValue] = useState(query);

  useEffect(() => {
    setValue(query);
  }, [query]);

  return (
    <Form className="search-sec message-search">
      <InputGroup className="search-group">
        <InputGroup.Text id="basic-addon1" className="search-icon">
          <span className="icon-search" />
        </InputGroup.Text>
        <Form.Control
          ref={inputRef}
          className={`form-input search-input ${innerClass}`}
          type="search"
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            onSearch(e.target.value);
          }}
          value={value}
          data-testid="searchInput"
        />
      </InputGroup>
    </Form>
  );
}

export default SearchInput;
