/**
 * The `useVerifyEmail` function is a custom hook that handles the verification of an email address,
 * including sending a verification code, resending the verification code, and verifying the code
 * entered by the user.
 * @param {UseVerifyEmailArgs}  - - `setTimerCompletion`: a function that sets the completion status of
 * a timer. It is used to notify the component when the timer for resending OTP is completed.
 * @returns The `useVerifyEmail` function returns an object with the following properties:
 */
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { USER_INFO } from '../constants/forms/keys';
import {
  RESEND_OTP_MUTATION,
  VERIFY_EMAIL_MUTATION,
} from '../graphql/mutation';
import { clearCounter, getSession, saveSessions } from '../utils/helpers';
import { ROUTE_PATH } from '../constants/routes';
import { UseVerifyEmailArgs } from '../types';
import { BOOLEANS } from '../constants';
import useGetProbePrice from './useGetProbePrice';

function useVerifyEmail({ setTimerCompletion }: UseVerifyEmailArgs) {
  const session: any = getSession(USER_INFO);
  const navigate = useNavigate();
  const { fetchDataFromApi } = useGetProbePrice();
  const [mutate, { loading }] = useMutation(VERIFY_EMAIL_MUTATION);
  const [resend, { loading: resendLoading }] = useMutation(RESEND_OTP_MUTATION);

  /**
   * The function `verify` takes an OTP (one-time password) as input, sends it to a server for
   * verification, and if the verification is successful, it navigates to a subscription page, displays
   * a success message using a toast notification, and updates the session data to indicate that the
   * email has been verified.
   * @param {string} otp - The `otp` parameter is a string that represents the one-time password that
   * is being verified.
   */
  const verify = async (otp: string) => {
    const res = await mutate({
      variables: { otp },
    });
    if (res?.data) {
      navigate(ROUTE_PATH.SUBSRIPTION);
      toast.success(res.data?.verifyEmail?.message);
      saveSessions({ ...session, isEmailVerified: BOOLEANS.TRUE, firstTimeUser: BOOLEANS.TRUE });
      fetchDataFromApi();
    }
  };

  /**
   * The function `resendOtp` sends a request to resend an OTP (one-time password) using the `resend`
   * function, and then displays a success message and updates a timer completion state.
   */
  const resendOtp = async () => {
    clearCounter();
    const res = await resend({ variables: { accessToken: session?.token } });
    toast.success(res.data?.resendOtp?.message);
    setTimerCompletion(false);
  };

  return {
    resendOtp,
    loading,
    resendLoading,
    verify,
  };
}

export default useVerifyEmail;
