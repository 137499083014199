import React from 'react';
import PublicNavBar from '../../components/shared/common/navbar';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import TermsMainHeader from './TermsMainHeader';
import LandingFooter from '../../components/landingPage/LandingFooter';
import SectionFive from './SectionFive';
import SectionSix from './SectionSix';
import SectionSeven from './SectionSeven';
import SectionEight from './SectionEight';
import SectionNine from './SectionNine';
import SectionTen from './SectionTen';
import SectionEleven from './SectionEleven';
import SectionTwelve from './SectionTwelve';

function TermsAndConditions() {
  return (
    <>
      <div className="landing-page-wrapper">
        <div className="landing-page-header">
          <PublicNavBar />
          <section className="policy-wrapper bg-img">
            <div className="policy-sec">
              <TermsMainHeader />

              <SectionOne />
              <SectionTwo />

              <SectionThree />
              <SectionFour />
              <SectionFive />
              <SectionSix />
              <SectionSeven />
              <SectionEight />
              <SectionNine />
              <SectionTen />
              <SectionEleven />
              <SectionTwelve />
            </div>
          </section>
          <LandingFooter />
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
