import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import UserTransactionHistory from '../../../pages/userTransaction/UserTransactionHistory';
import { TRANSACTION_HISTORY_QUERY } from '../../../graphql/mutation';
import { BOOLEAN_VALUES, USER_MANAGEMENT } from '../../../constants';
import { getCurrentUser } from '../../../utils/helpers';
import Breadcrumbs from '../../shared/common/breadCrumbs';

const TransactionHistory = () => {
  const { id } = useParams();
  const currentUser = getCurrentUser();
  const { loading, error, data } = useQuery(TRANSACTION_HISTORY_QUERY, {
    variables: { userId: id },
  });

  if (error) {
    return (
      <p>
        Error:
        {' '}
        {error.message}
      </p>
    );
  }

  const transactionData = data?.transactionHistory?.data || [];

  return (
    <div className="admin-common-body">
      <div className="admin-header-wrapper admin-heading-sec">
        <Breadcrumbs crumbs={[USER_MANAGEMENT, currentUser?.fullName, 'Transaction History']} />

        <h1 className="admin-header-title">Transaction History</h1>
      </div>

      <UserTransactionHistory
        transactionData={transactionData}
        isAdmin={BOOLEAN_VALUES.TRUE}
        loading={loading}
      />
    </div>
  );
};

export default TransactionHistory;
