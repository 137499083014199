import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import tablegraphcirc from '../../assets/img/tablegraphcirc.svg';
import tablegrapharrow from '../../assets/img/tablegrapharrow.svg';
import tablegraphimg1 from '../../assets/img/tablegraphimg1.svg';
import tablegraphimg2 from '../../assets/img/tablegraphimg2.png';
import tableGraph4 from '../../assets/img/table-graph4.svg';
import tableGraph22 from '../../assets/img/tablegraphimg2.svg';

const TableGraph = () => (
  <>
    <section className="tables-graph-wrapper">
      <Container>
        <div className="tables-graph-sec">
          <div className="tables-graph-heading-sec">
            <div className="tables-graph-heading">
              <div className="heading">
                  Explore Probe Readings In
                  {' '}
                  <span>Tables & Graphs</span>
                  <ScrollAnimation animateIn='flipInX'>
                    <img
                        className="tables-graph-img"
                        src={tablegraphcirc}
                        alt="right arrow"
                      />
                  </ScrollAnimation>
              </div>
            </div>
            <div className="tables-graph-subheading">
              Viewing probe data in tabular and graphical formats provides a
              comprehensive view of critical readings, enabling you to make
              date-driven decisions effortlessly. <strong>PROVIDING</strong> precise
              temperature measurements <strong>AND</strong> timely insights, stay informed and
              empowered with our intuitive data presentation.
            </div>
            <img
              className="tables-graph-arrow"
              src={tablegrapharrow}
              alt="right arrow"
            />
          </div>
          <div className="tables-graph-body-sec">
            <Row className="tables-graph-data-row">
              <Col sm={12} md={4} className="tables-graph-data first-sec">
                <div className="heading">Selected MSP Data</div>
                <div className="subheading">
                  The MSP is a comprehensive solution that allows you to
                  collect, analyze, and leverage valuable data from every
                  aspect of your winemaking process.
                </div>
                <div className="msp-data-img-sec">
                  <img
                    className="msp-data-img"
                    src={tablegraphimg1}
                    alt="right arrow"
                  />
                </div>
              </Col>
              <Col sm={12} md={4} className="tables-graph-data second-sec">
                <img
                  className="probe-data-img"
                  src={tableGraph22}
                  alt="right arrow"
                />
              </Col>
              <Col sm={12} md={4} className="tables-graph-data third-sec">
                <div className="system-data-img-sec">
                  <img
                    className="system-data-img2"
                    src={tableGraph4}
                    alt="right arrow"
                  />
                </div>
                <div className="third-sec-data">
                  <div className="heading">System Computation</div>
                  <div className="subheading">
                    Winemakers will be able to tag data points with winemaking
                    events like pumpovers or nutrient additions to help
                    identify fermentation cause and effect.
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  </>
);

export default TableGraph;
