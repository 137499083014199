import { ReactiveVar, makeVar } from '@apollo/client';
import { NUMBER, SORT, SORT_BY } from '../../constants';

export const pagination: any = makeVar({
  page: NUMBER.ONE,
  sortBy: SORT_BY.DESC,
  sort: SORT.CREATED_AT,
});

export const selectedProbes:any = makeVar([]);

export const isGraphExporting:ReactiveVar<boolean> = makeVar(false);
