import React, { useMemo, useState } from 'react';
import {
  Navigate,
  createSearchParams, useLocation, useNavigate, useSearchParams
} from 'react-router-dom';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { toast } from 'react-toastify';
import { SELECTED_PROBES_TABS_CONFIG } from '../../../constants/tableConfig';
import ProbeDetailsCard from '../../../components/userDashboard/probeDetailsCard';
import {
  BASE,
  BOOLEANS, COMPUTATION_TYPE, DASHBOARD, NUMBER, PROBE_DATA, ROUTE_PATH, SYSTEM
} from '../../../constants';
import CustomTabs from '../../../components/table/customTabs';
import { IPagination, IProbeData } from '../../../types';
import useLogout from '../../../hooks/useLogout';
import { GET_USER_INFORMATION, UPDATE_WINE_PACKAGE } from '../../../graphql/mutation';
import CustomLoader from '../../../components/shared/common/customLoader';
import FilterSection from '../../../components/userDashboard/filterSection';
import BaseComputation from '../../../components/userDashboard/baseComputation';
import SystemComputation from '../../../components/userDashboard/systemComputation';
import Breadcrumbs from '../../../components/shared/common/breadCrumbs';
import AddOnModal from '../../../components/shared/common/modals/AddOnModal';
import { pagination } from '../../../graphql/reactiveVariables';
import leftArrow from '../../../assets/icons/arrow-left.svg';

function ProbeData() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [params] = useSearchParams();
  const searchParams = Object.fromEntries([...params]);
  const { dataFormat = '', computationType = '', probeId } = searchParams;
  const [selectedProbe, setSelectedProbe] = useState(state?.filter((i:any) => i.id === probeId)[0]);
  const { openLogoutModal, logoutModal, closeLogoutModal } = useLogout();
  const {
    loading, data, refetch
  } = useQuery(GET_USER_INFORMATION);
  const [updateWinePackage, { loading: updateLoading }] = useMutation(UPDATE_WINE_PACKAGE);
  const { isWineMakingPackage, subscriptionType } = data?.getUser?.userInfo || {};
  const paginationData: IPagination = useReactiveVar(pagination);

  /**
   * The function `pushParams` takes in a name and value, and updates the search parameters in the URL
   * with the new name-value pair.
  */
  const pushParams = (name: string, value: string) => {
    navigate({
      pathname,
      search: createSearchParams({
        ...searchParams,
        [name]: value,
      }).toString(),
    }, { state });
  };

  /**
   * The function `selectProbe` takes an `id` as input and selects the probe data with that `id` from
   * the `state` array, then sets the selected probe data using the `setSelectedProbe` function.
   * @param {string} id - The `id` parameter is a string that represents the unique identifier of a
   * probe.
   */
  const selectProbe = (id: string) => {
    const selectedData = state.find((i: IProbeData) => i.id === id);
    if (selectedData) {
      setSelectedProbe(selectedData);
      navigate({
        pathname,
        search: createSearchParams({
          ...searchParams,
          probeId: selectedData?.id,
          startTime: selectedData?.startTime,
          endTime: selectedData?.endTime,
        }).toString(),
      }, { state });
      pagination({ ...paginationData, page: NUMBER.ONE });
    }
  };

  useMemo(() => {
    if (state?.length) {
      setSelectedProbe(state.filter((i: any) => i.id === probeId)[0]);
    }
  }, [state]);

  useMemo(() => {
    if (searchParams.computationType === SYSTEM && !isWineMakingPackage && loading === BOOLEANS.FALSE) {
      openLogoutModal();
    }
  }, [searchParams.computationType, subscriptionType]);

  if (state?.length === 0 || !state) {
    return <Navigate to={ROUTE_PATH.USER_DASHBOARD} />;
  }

  const buyAddOnPlan = async () => {
    try {
      const response = await updateWinePackage();
      if (response) {
        await refetch();
        toast.success(response.data.updateWinePackage.message);
      }
      closeLogoutModal();
    } catch (paymentError) {
      closeLogoutModal();
      pushParams(COMPUTATION_TYPE, BASE);
    }
  };

  const handleCloseModel = () => {
    closeLogoutModal();
    pushParams(COMPUTATION_TYPE, BASE);
  };

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <div className="admin-common-body dashboard-welcome">
      <div className="admin-header-wrapper for-desktop">
        <Breadcrumbs crumbs={[DASHBOARD, PROBE_DATA]} />
      </div>
      <div className="dashboard-data-tabs">
        <div className="for-mobile">
          <div className="probe-wrapper-mobile">
            <img className="left-arrow" src={leftArrow} alt="Arrow" onClick={() => navigate(-NUMBER.TWO)} />
            <h5 className="dashboard-title">
              Probe Data
              <span> (Tabular and Graphical Format)</span>
            </h5>
          </div>
        </div>
        <CustomTabs
          onSelect={(s: string) => pushParams(COMPUTATION_TYPE, s)}
          config={SELECTED_PROBES_TABS_CONFIG}
          defaultKey={computationType || BASE}
          className="user-custom-tabs"
        />
      </div>
      <div className="dashboard-multiple-tabs">
        <CustomTabs
          onSelect={selectProbe}
          config={state}
          defaultKey={probeId as string}
          className="user-custom-tabs-wrapper"
        />
      </div>
      <div className="dashboard-probe-details mb-30">
        <h4 className="dashboard-probe-title">Probe Details</h4>
        <ProbeDetailsCard
          className="selected-probes-card w-100 col-sm-12 col-12 selected-probes-card-mobile"
          toggle={BOOLEANS.TRUE}
          handleChange={(s:boolean) => pushParams('dataFormat', s ? 'graphical' : 'tabular')}
          probe={selectedProbe}
          checked={dataFormat === 'graphical'}
        />
      </div>
      <FilterSection />
      <div className="below-filter">
        {computationType === BASE ? <BaseComputation /> : <SystemComputation />}
      </div>
      <AddOnModal
        closeLogoutModal={handleCloseModel}
        logoutModal={logoutModal}
        buyAddOnPlan={buyAddOnPlan}
        subscriptionType={subscriptionType}
        loading={updateLoading}
      />
    </div>
  );
}

export default ProbeData;
