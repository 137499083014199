import React from 'react';
import { EmptyStateProps } from '../../../../types';

const EmptyState = ({ subtitle, imgSrc, title }: EmptyStateProps) => (
  <div className="no-probes-card">
    <div className="subscription-nocard-sec ">
      <div className="nocard-img">
        <img src={imgSrc} alt="No probes card" />
      </div>
      <div className="nocard-title">{title}</div>
      <div className="nocard-subtitle">
        {subtitle}
      </div>
    </div>
  </div>
);

export default EmptyState;
