import React from 'react';

const SectionTen = () => (
  <>
    <h3>Section 13 - Limitation Of Liability</h3>
    <p className="para">
      You explicitly acknowledge, understand and agree that wine technology inc. And our subsidiaries,
      affiliates, officers, employees, agents, partners and licensors shall not be liable to you for any punitive,
      indirect, incidental, special, consequential or exemplary damages, including, but not limited to,
      damages which may be related to the loss of any profits, goodwill, use, data and/or other intangible losses,
      even though we may have been advised of such possibility that said damages may occur, and result from:

    </p>
    <div className="indent-sec">
      <p className="para">
        a) The use or inability to use our service.
      </p>
      <p className="para">
        b) The cost of procuring substitute goods and services.
      </p>
      <p className="para">
        c) Unauthorized access to or the alteration of your transmissions and/or data.
      </p>
      <p className="para">
        d) Statements or conduct of any such third party on our service.
      </p>
      <p className="para">
        e) And any other matter which may be related to our service.
      </p>
    </div>
  </>
);

export default SectionTen;
