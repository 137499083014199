import React, { useState } from 'react';
import { Container, Navbar, Offcanvas } from 'react-bootstrap';
import menuIcon from '../../../assets/img/menu-icon.svg';
import closeIcon from '../../../assets/icons/close.svg';
import Logo from '../../../assets/img/logo.svg';
import UserSideBar from '../../sidebar/userSideBar';
import {
  BOOLEANS,
  LOGOUT_MODEL_BODY,
  LOGOUT_MODEL_CANCEL,
  LOGOUT_MODEL_HEADER,
  PAYMENT_STATUS,
  ROUTE_PATH,
  SCREEN_SIZE,
} from '../../../constants';
import DropDownMenu from '../../shared/common/DropDownMenu';
import useLogout from '../../../hooks/useLogout';
import LogOutModal from '../../auth/model/LogoutModal';
import logoutIcon from '../../../assets/img/logout.svg';
import ChangePassword from '../../shared/common/changePassword';
import { useNavigate } from 'react-router-dom';

function BurgerMenu({ data }: any) {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const [showchangePasswordModal, setshowchangePasswordModal] = useState(
    BOOLEANS.FALSE
  );

  const handleModalStatus = () => setshowchangePasswordModal(!showchangePasswordModal);
  const {
    logoutUser,
    openLogoutModal,
    closeLogoutModal,
    logoutModal,
    accessToken,
  } = useLogout();

  const handleMenu = () => setMenu(!menu);
  const handleLogoutModal = () => {
    openLogoutModal();
    handleMenu();
  };

  const handleChangePassword = () => {
    handleModalStatus();
    handleMenu();
  };

  return (
    <>
      <div className="main-header header-mobile for-mobile">
        {data !== PAYMENT_STATUS.OPEN ? (
          <div className="header-mobile-left">
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${SCREEN_SIZE.xs}`}
            >
              <img
                src={menuIcon}
                alt="Menu icon"
                onClick={handleMenu}
                data-testid="menuIcon"
              />
            </Navbar.Toggle>
            <div className="mobile-logo" onClick={() => navigate(ROUTE_PATH.USER_DASHBOARD)}>
              <img src={Logo} alt="Menu icon" />
            </div>
          </div>
        ) : (
          <div className="header-mobile-right">
            <div className="cart-wrapper">{/* <ProfileMenu /> */}</div>
          </div>
        )}
      </div>
      <Container fluid className="custom-menu-burger">
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${SCREEN_SIZE.xs}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${SCREEN_SIZE.xs}`}
          show={menu}
          onHide={handleMenu}
        >
          <Offcanvas.Body className="menu-burger-body">
            <div className="sidebar-profile">
              <img
                className="sidebar-close"
                src={closeIcon}
                alt="close icon"
                onClick={handleMenu}
              />
              <DropDownMenu
                accessToken={accessToken}
                openLogoutModal={handleLogoutModal}
                handleModalStatus={handleChangePassword}
                handleMenu={handleMenu}
              />
            </div>
            <UserSideBar handleMenu={handleMenu} />
            <div className="sidebar-bottom">
              <p className="sidebar-bottom-tag">Multi-Sensor Probe</p>
              <h6 className="sidebar-bottom-title">
                Unlock the art
                <br />
                of winemaking
                <br />
                with MSP
              </h6>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
      <LogOutModal
        open={logoutModal}
        close={closeLogoutModal}
        imgPath={logoutIcon}
        logoutFuntion={logoutUser}
        headerText={LOGOUT_MODEL_HEADER}
        bodyText={LOGOUT_MODEL_BODY}
        confirmText={LOGOUT_MODEL_HEADER}
        cancelText={LOGOUT_MODEL_CANCEL}
      />
      <ChangePassword
        open={showchangePasswordModal}
        close={handleModalStatus}
      />
    </>
  );
}

export default BurgerMenu;
