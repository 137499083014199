import React, { useEffect, useState } from 'react';
import { NUMBER } from '../../../../constants/number';
import { getCounter, saveCounter } from '../../../../utils/helpers';
import { COMPLETED } from '../../../../constants';

function Timer({ seconds, completed }: any) {
  const storeTimer = getCounter();
  const [counter, setCounter] = useState(storeTimer || seconds);
  useEffect(() => {
    if (counter > NUMBER.ONE) {
      setTimeout(() => setCounter(counter - NUMBER.ONE), NUMBER.THOUSAND);
      saveCounter(counter);
    } else {
      completed();
      saveCounter(COMPLETED);
    }
  }, [counter]);

  if (counter === NUMBER.ONE) {
    return null;
  }

  return (
    <>
      {storeTimer !== COMPLETED && (
        <div className="time-remaining-wrap">
          Resend OTP in
          {' '}
          <span className="left-time">
            00:
            {counter < NUMBER.TEN ? `0${counter}` : counter}
            {' '}
          </span>
          {' '}
          seconds
        </div>
      )}
    </>
  );
}

export default Timer;
