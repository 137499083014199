import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import tickGreenIcon from '../../../assets/icons/tick-green.svg';
import tickGreyIcon from '../../../assets/icons/tick-grey.svg';
import { passwordStrengthParams } from '../../../constants/forms/static';
import { NUMBER } from '../../../constants';

function PasswordStrength({ password }: any) {
  const [error, setError] = useState<any>({});

  const hasLetter = /[a-zA-Z]/.test(password);
  const hasDigit = /[0-9]/.test(password);

  useEffect(() => {
    setError({
      0: password?.length >= NUMBER.EIGHT,
      1: hasLetter && hasDigit,
      2: /\W/g.test(password),
      3: /[A-Z]/g.test(password),
    });
  }, [password]);

  return (
    <div className="custom-error-list" data-testid="passwordStrength">
      <ListGroup>
        {passwordStrengthParams.map((item, index) => (
          <ListGroup.Item key={item.text}>
            <span className={`error-${error[index] ? 'green' : 'grey'}`}>
              <img
                className={`${error[index] ? 'green' : 'grey'}-tick`}
                src={error[index] ? tickGreenIcon : tickGreyIcon}
                alt="tick icon"
              />
              {item.text}
            </span>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default PasswordStrength;
