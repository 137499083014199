import { useLazyQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import {
  BASE, BOOLEANS, NETWORK_ONLY, SYSTEM
} from '../constants';
import { SENSOR_LIST_EXPORT, SYSTEM_COMPUTATION_EXPORT } from '../graphql/queries/user/dashboard.queries';
import { fetchFile, getTimeStampDateObject } from '../utils/helpers';
import { pagination } from '../graphql/reactiveVariables';

function useTableExport() {
  const [params] = useSearchParams();
  const searchParams = Object.fromEntries([...params]);
  const [fetchBaseReport, { loading }] = useLazyQuery(SENSOR_LIST_EXPORT, {
    variables: {
      sensorType: searchParams?.measurement,
      startDate: getTimeStampDateObject(searchParams?.startTime, BOOLEANS.TRUE),
      endDate: getTimeStampDateObject(searchParams?.endTime, BOOLEANS.TRUE),
      isDownload: BOOLEANS.TRUE,
      probeId: searchParams?.probeId,
      ...pagination
    },
    defaultOptions: {
      fetchPolicy: NETWORK_ONLY
    }
  });

  const [fetchSystemReport] = useLazyQuery(SYSTEM_COMPUTATION_EXPORT, {
    variables: {
      sensorType: searchParams?.sensorType || 'all',
      startDate: getTimeStampDateObject(searchParams?.startTime, BOOLEANS.TRUE),
      endDate: getTimeStampDateObject(searchParams?.endTime, BOOLEANS.TRUE),
      isDownload: BOOLEANS.TRUE,
      probeId: searchParams?.probeId,
      ...pagination
    },
    defaultOptions: {
      fetchPolicy: NETWORK_ONLY
    }
  });

  const exportTable = async (format: string) => {
    let filePath = '';
    if (searchParams?.computationType === BASE) {
      const baseData = await fetchBaseReport({ variables: { downloadType: format } });
      filePath = baseData?.data?.sensorList?.filePath;
    } else if (searchParams?.computationType === SYSTEM) {
      const systemData = await fetchSystemReport({ variables: { downloadType: format } });
      filePath = systemData?.data?.dashboardComputationList?.filePath;
    }
    await fetchFile(filePath);
  };
  return { isLoading: loading, exportTable };
}

export default useTableExport;
