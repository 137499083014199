import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BOOLEANS } from '../constants';

function PromptModal({
  desc,
  title,
  note,
  handleAction,
  open,
  cancel,
  imgPath,
  actionBtnText,
  cancelBtnText,
}: any) {
  return (
    <Modal centered className="common-modal remove-modal" show={open}>
      <Modal.Header>
        {imgPath
          ? <img className="logout-icon" src={imgPath} alt="logout icon" /> :
          <span className="icon-close-with-circle" />}
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-form">
          <h4 className="remove-modal-tile">{title}</h4>
          <p>{desc}</p>
          {note && (
            <div className="successful-note">
              <p>
                <span>Note:</span>
                {' '}
                {note}
              </p>
            </div>
          )}
        </div>
        <div className="footer-modal">
          <Button className="footer-outline-btn" onClick={handleAction}>
            {actionBtnText}
          </Button>
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            onClick={() => cancel(BOOLEANS.FALSE)}
          >
            {cancelBtnText}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default PromptModal;
