import React, { useState } from 'react';
import TagsInput from 'react-tagsinput';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Col, Row, Form, Button
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ADD_AND_ASSIGN_VALIDATION_SCHEMA } from '../../../constants/forms/validationSchema';
import { AssignModalFormProps, ProbeAddAssigne } from '../../../types/index';
import Select from '../../../components/shared/forms/select';
import useWinerySorting from '../../../hooks/useWinerySorting';
import ModalWrapper from '../../../components/shared/common/modalWrapper';
import { ADD_PROBE_MUTATION } from '../../../graphql/mutation/probe.mutation';
import CustomLoader from '../../../components/shared/common/customLoader';
import { NUMBER, PROBE_MODEL_ID } from '../../../constants';

const AssignModalForm = ({
  show,
  handleClose,
  onSuccess
}: AssignModalFormProps) => {
  const [addProbeMutation, { loading }] = useMutation(ADD_PROBE_MUTATION);
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm<ProbeAddAssigne>({
    resolver: yupResolver(ADD_AND_ASSIGN_VALIDATION_SCHEMA),
    defaultValues: { probeId: [], userId: '' },
  });

  const { wineryOptions } = useWinerySorting();

  const onSubmit = handleSubmit(async (values: ProbeAddAssigne) => {
    try {
      const response = await addProbeMutation({
        variables: values,
      });
      if (response?.data?.addProbe?.message) {
        toast.success(response.data.addProbe.message);
        onSuccess();
        handleClose();
      }
    } catch (error) {
      // error
    }
    reset();
  });
  const handleCloseModal = () => {
    reset();
    handleClose();
  };
  return (
    <ModalWrapper
      show={show[PROBE_MODEL_ID]}
      handleClose={() => handleCloseModal()}
      title="Add & Assign Probe"
    >
      {loading && <CustomLoader />}
      <Form onSubmit={onSubmit}>
        <Row className="modal-body-form">
          <Col xs={NUMBER.TWELVE} sm={NUMBER.TWELVE} className="">
            <div className="form-mt-space multi-select-wrap">
              <Form.Label>Probe ID</Form.Label>
              <Controller
                name="probeId"
                control={control}
                render={({ field }) => (
                  <TagsInput
                    className="form-input"
                    value={field.value}
                    onChange={(tags) => field.onChange(tags)}
                    inputProps={{ placeholder: 'Enter probe ID ' }}
                    addKeys={[32, 13, 9]}
                    onlyUnique
                  />
                )}
              />
            </div>
          </Col>
          <Col xs={NUMBER.TWELVE} sm={NUMBER.TWELVE}>
            <Select
              options={wineryOptions}
              placeholder="Select"
              value={getValues('userId')}
              name="userId"
              label="Winery Name"
              error={errors?.userId?.message}
              setValue={setValue}
            />
          </Col>
          <Col xs={NUMBER.TWELVE} sm={NUMBER.TWELVE}>
            <div className="sign-up-note mb-10">
              <p>
                <span>Note: </span>Press Enter or Tab Key to add Probe ID
              </p>
            </div>
          </Col>
        </Row>
        <div className="footer-modal">
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
            disabled={!isValid}
          >
            Add & Assign
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default AssignModalForm;
