import React, { useEffect, useState } from 'react';
import { Button, Row, Col, } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import CreditCardForm from '../../components/stripe/CreditCardForm';
import { usePaymentContext } from '../../context/PaymentContextProvider';
import PaymentSummary from '../../components/stripe/PaymentSummary';
import { GET_USER, PAYMENT_MUTATION } from '../../graphql/mutation';
import { getSession, getAccessToken, saveSessions } from '../../utils/helpers';
import useLoader from '../../hooks/useLoader';
import { ROUTE_PATH } from '../../constants/routes';
import UserNavbar from '../../components/shared/common/userNavbar';
import CustomLoader from '../../components/shared/common/customLoader';
import {
  NETWORK_ONLY,
  NUMBER,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  SUBSCRIPTION_TYPE,
  USER_INFO,
  total,
} from '../../constants';
import useCreditForm from '../../hooks/useCreditForm';
import Footer from '../../components/footer';
import { PAYMENT_ERROR, priceDATA, CURRENCY_SYMBOL } from '../../constants/string';

function Payment() {
  const session = getSession(USER_INFO);
  const [paymentMode, setPaymentMode] = useState(false);
  const { isLoading, startLoading, stopLoading } = useLoader();
  const location = useLocation();
  const [refetch, { loading }] = useLazyQuery(GET_USER, { fetchPolicy: NETWORK_ONLY });
  const searchParams = new URLSearchParams(location.search);
  const searchQuery: { [key: string]: string } = Object.fromEntries(
    searchParams.entries()
  );

  const [paymentError, setPaymentError] = useState('');
  const { totalProbe, subscriptionType: type, singleProbPrice } = searchQuery;
  const { isWineMakingPackage, addOnPriceYearly, addOnProbePrice } = usePaymentContext();
  const {
    stripe,
    elements,
    cardElement,
    cardCvc,
    cardExpiry,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    handleInputChange,
    paymentButtonDisable,
    cardFormData,
    cardError
  } = useCreditForm();
  const [paymentMutation] = useMutation(PAYMENT_MUTATION);
  const navigate = useNavigate();

  const accessToken = getAccessToken();

  const paymentConfirmation = async () => {
    stopLoading();
    getUserDetails();
    await navigate(ROUTE_PATH.PAYMENT_SUCCESS);
  };

  const getUserDetails = async () => {
    const user = await refetch();
    saveSessions({ ...session, ...user?.data?.getUser?.userInfo });
  };
  const { ANNUAL } = SUBSCRIPTION_TYPE;

  useEffect(() => {
    if (session?.paymentStatus === PAYMENT_STATUS.PAID && session?.subscriptionId) {
      navigate(ROUTE_PATH.USER_DASHBOARD);
    } else if (session?.paymentStatus === PAYMENT_STATUS.PROGRESS) {
      navigate(ROUTE_PATH.PAYMENT_SUCCESS);
    }
  }, [session]);

  const onCreditPaymentSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    startLoading();
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!cardElement || !cardCvc || !cardExpiry) {
      return;
    }

    const { token, error } = await stripe?.createToken(cardElement, {
      name: cardFormData.name,
      address_line1: cardFormData.address_line1,
    });

    if (error) {
      const errorMessage = error.message || PAYMENT_ERROR;
      setPaymentError(errorMessage);
      stopLoading();
    } else {
      try {
        const response = await paymentMutation({
          variables: {
            totalProbe: +totalProbe,
            subscriptionType: type,
            isWineMakingPackage: Boolean(isWineMakingPackage),
            paymentType: PAYMENT_TYPE.CARD,
            paymentId: token?.id,
            accessToken,
          },
        });
        if (response) {
          paymentConfirmation();
        }
      } catch (errorPayment) {
        stopLoading();
      }
    }
  };

  const addOnPrice = () => {
    if (!isWineMakingPackage) {
      return NUMBER.ZERO;
    }
    if (type === ANNUAL) {
      return addOnPriceYearly;
    }
    return addOnProbePrice;
  };

  const totalPrice = total(+singleProbPrice, +totalProbe, +addOnPrice());

  return (
    <>
      {isLoading || loading ? <CustomLoader /> : null}
      <UserNavbar />
      <div className="payment-sec">
        <h1 className="payment-title">Payment</h1>
        <Row className="payment-row">
          <Col sm={NUMBER.SIX} xs={NUMBER.TWELVE} className="payment-col-left">
            <p className="card-text">Enter Your Credit Card Details</p>
            <Row className="payment-row">
              <div className="payment-ach-option payment-ach-option-stripe">
                <CreditCardForm
                  CardNumberElement={CardNumberElement}
                  CardCvcElement={CardCvcElement}
                  CardExpiryElement={CardExpiryElement}
                  handleInputChange={handleInputChange}
                  cardError={cardError}
                />
              </div>
            </Row>

            <Button
              className="btn primary w-100 ach-payment-btn"
              disabled={!paymentButtonDisable()}
                   // @ts-ignore
              onClick={onCreditPaymentSubmit}
            >
              Pay
              {' '}
              {CURRENCY_SYMBOL}
              {priceDATA(totalPrice)}
            </Button>

            {paymentError && (
              <div className="input-error-message mb-20">{paymentError}</div>
            )}
          </Col>
          <PaymentSummary
            setPaymentMode={setPaymentMode}
            paymentMode={paymentMode}
            startLoading={startLoading}
            stopLoading={stopLoading}
            queryParams={searchQuery}
            paymentType={PAYMENT_TYPE.CARD}
          />
        </Row>
      </div>

      <Footer />
    </>
  );
}

export default Payment;
