import React from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect, { SingleValue } from 'react-select';
import { NUMBER } from '../../../constants';

function Select({
  name,
  options,
  valueKey = 'value',
  labelKey = 'label',
  label,
  icon,
  iconClass,
  placeholder,
  error,
  setValue,
  disabled,
  value
}: any) {
  const handleOnchange = (e: SingleValue<{ value: any }>) => {
    if (e) {
      setValue(name, e[valueKey as keyof typeof e], { shouldValidate: true });
    }
  };

  return (
    <Form.Group className="form-mt-space">
      <Form.Label className="form-label">{label}</Form.Label>
      <div className="wrap-select wrap-input">
        <ReactSelect
          noOptionsMessage={() => <p>No options to choose</p>}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={placeholder}
          options={options}
          getOptionValue={(option: any) => option[valueKey]}
          getOptionLabel={(option: any) => option[labelKey]}
          onChange={(e) => handleOnchange(e)}
          name={name}
          isDisabled={disabled}
          value={
                  !value
                    ? null
                    : options?.filter((i: any) => i[valueKey] === value)[NUMBER.ZERO]
                }
        />
        {iconClass && <span className={iconClass}>{icon}</span>}
      </div>
      <span className="input-error-message" data-testid={`${name}Err`}>{error as string}</span>
    </Form.Group>
  );
}

export default Select;
