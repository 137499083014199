import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { TankDetailSectionProps } from '../../../types';
import tankPic from '../../../assets/img/tank-detail.png';
import { capitalizeFirst, checkString } from '../../../utils/helpers';

function TankDetailSection({ tank }:TankDetailSectionProps) {
  return (
    <>
      <div className="tank-detail-left">
        <img className="tank-detail" src={tankPic} alt="tank detail" />
      </div>
      <div className="tank-detail-right">
        <div className="common-detail-wrapper tank-detail-listing">
          <h4 className="common-detail-title">
            {checkString(tank?.name)}
          </h4>
          <ListGroup>
            <ListGroup.Item>
              <div className="detail-title">Tank Capacity (Unit)</div>
              <div className="detail-subtext">
                {checkString(capitalizeFirst(tank?.capacity))}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="detail-title">Volume</div>
              <div className="detail-subtext">
                {checkString(tank?.volume)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="detail-title">Location</div>
              <div className="detail-subtext">
                {checkString(tank?.location)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="detail-title">Description</div>
              <div className="detail-subtext">
                {checkString(tank?.description)}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    </>
  );
}

export default TankDetailSection;
