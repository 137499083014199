import { useLazyQuery } from '@apollo/client';
import {
  BOOLEANS, NUMBER, PAYMENT_PRICE
} from '../constants';
import { ACTIVE_SUBSCRIPTION_PLAN } from '../graphql/queries/user/dashboard.queries';
import { IApiDataPrice } from '../types';
import { getSession, savePaymentPricing } from '../utils/helpers';

const useGetProbePrice = () => {
  const [fetchData, { loading: priceLoading }] = useLazyQuery(
    ACTIVE_SUBSCRIPTION_PLAN
  );

  const paymentData = getSession(PAYMENT_PRICE);
  const { TRUE, FALSE } = BOOLEANS;
  const {
    priceMonthly: dollarAddOnMonthlyPrice,
    priceYearly: dollarAddOnYearlyPrice
  } = paymentData?.activeSubscriptionPlan?.data?.find((item: IApiDataPrice) => item.isWineMaking === TRUE) || {};
  const {
    priceMonthly,
    priceYearly
  } = paymentData?.activeSubscriptionPlan?.data?.find((item: IApiDataPrice) => item.isWineMaking === FALSE) || {};
  const addOnPriceMonthly = dollarAddOnMonthlyPrice / NUMBER.ONE_HUNDRED;
  const addOnPriceYearly = dollarAddOnYearlyPrice / NUMBER.ONE_HUNDRED;

  // Function to fetch data when needed
  const fetchDataFromApi = async () => {
    const response = await fetchData();
    if (response) {
      savePaymentPricing(response.data);
    }
  };

  return {
    priceLoading,
    addOnPriceMonthly,
    addOnPriceYearly,
    priceMonthly: priceMonthly / NUMBER.ONE_HUNDRED,
    priceYearly: priceYearly / NUMBER.ONE_HUNDRED,
    paymentData,
    fetchDataFromApi, // Expose a function to fetch data when needed
  };
};

export default useGetProbePrice;
