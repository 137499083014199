import React from 'react';
import footerLogo from '../../assets/img/footer-logo.svg';

function Footer() {
  return (
    <div className="subscription-footer-sec">
      <div className="subscription-footer">
        <p className="footer-copy-right">
          © 2023 Multi-Sensor Probe (MSP). All Rights Reserved.
        </p>
        <img className="footer-logo" src={footerLogo} alt="footer-logo" />
      </div>
    </div>
  );
}

export default Footer;
