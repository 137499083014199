import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { BOOLEANS, NUMBER } from '../constants';

const useCreditForm = () => {
  const stripe = useStripe();
  const [cardError, setCardError] = useState({});
  const elements = useElements();
  const cardElement = elements?.getElement(CardNumberElement);
  const cardCvc = elements?.getElement(CardCvcElement);
  const cardExpiry = elements?.getElement(CardExpiryElement);
  const [cardFormData, setcardFormData] = useState({
    cardNumber: '',
    cardCvc: '',
    cardExpiry: '',
    name: '',
    address_line1: '',
  });

  const handleInputChange = (e: any) => {
    if (e?.target) {
      const { value, name } = e.target;
      setcardFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    if (e.elementType) {
      if (e.error) {
        setCardError({ ...cardError, [e.elementType]: e.error.message });
      } else {
        setCardError({ ...cardError, [e.elementType]: '' });
      }

      setcardFormData((prevFormData) => ({
        ...prevFormData,
        [e.elementType]: e.complete,
      }));
    }
  };

  const paymentButtonDisable = () => {
    const isFormValid = cardFormData.cardNumber
      && cardFormData.cardCvc
      && cardFormData.cardExpiry
      && cardFormData.address_line1.trim().length > NUMBER.ONE
      && cardFormData.name.trim().length > NUMBER.ONE;
    if (isFormValid) {
      return BOOLEANS.TRUE;
    }
    return BOOLEANS.FALSE;
  };

  return {
    stripe,
    elements,
    cardElement,
    cardCvc,
    cardExpiry,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    handleInputChange,
    cardFormData,
    paymentButtonDisable,
    cardError,
  };
};

export default useCreditForm;
