import React from 'react';
import {
  ListGroup,
} from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import tankDetail from '../../../assets/img/tank-detail.png';
import { GET_PROBE_BY_ID } from '../../../graphql/queries/admin.queries';
import ScrollToTop from '../../../components/shared/common/scrollToTop';
import CustomLoader from '../../../components/shared/common/customLoader';
import Breadcrumbs from '../../../components/shared/common/breadCrumbs';
import { capitalizeFirst, checkString, getCurrentUser } from '../../../utils/helpers';
import { ADMIN_USER } from '../../../constants/string';
import {
  ACTIVE_TAG, ASSIGNED_PROBE, INACTIVE_TAG, PROBE_STATUS, USER_MANAGEMENT
} from '../../../constants';

const AdminProbeDetail = () => {
  const { id } = useParams();
  const { data: { getProbeById = {} } = {}, loading } = useQuery(
    GET_PROBE_BY_ID,
    {
      variables: { id },
    },
  );
  const { pathname } = useLocation();
  const currentUser = getCurrentUser();
  const statusType = getProbeById?.data?.status === PROBE_STATUS.ACTIVE ? ACTIVE_TAG : INACTIVE_TAG;
  return (
    <>
      <ScrollToTop />
      {loading && <CustomLoader />}
      <div className="admin-common-body">
        <div className="admin-heading">
          <Breadcrumbs
            crumbs={
    pathname.startsWith(ADMIN_USER)
      ? [USER_MANAGEMENT, currentUser.fullName, ASSIGNED_PROBE, getProbeById?.data?.probeId]
      : ['Probe Catalog', getProbeById?.data?.probeId]
  }
          />

          <div className="header-wrapper">
            <div className="header-left">
              <h1 className="admin-header-title mb-26">Probe Details</h1>
            </div>
          </div>
        </div>

        <div className="common-detail-wrapper probes-detail-topbot">
          <div className="dashboard-probe-wrapper">
            <div>
              <h4 className="dashboard-probe-subtitle">
                {checkString(getProbeById?.data?.probeId)}
              </h4>
            </div>
            <p
              className={`btn-status ${getProbeById?.data?.isGettingRealData ? 'active' : 'deactive'}`}
            >
              {getProbeById?.data?.isGettingRealData ? 'Online' : 'Offline' }
            </p>
          </div>
          <ListGroup className="w-100">
            <ListGroup.Item>
              <div className="detail-title">Probe Name</div>
              <div className="detail-subtext2">
                {checkString(getProbeById?.data?.probeName)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="detail-title">Winery Name</div>
              <div className="detail-subtext2">
                {checkString(getProbeById?.data?.user?.wineryName)}
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="mb-0">
              <div className="detail-title">Description</div>
              <div className="detail-subtext2">
                {checkString(getProbeById?.data?.description)}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>

        <div className="tank-detail-sec tank-assigned-sec">
          <div className="box-heading">Assigned Tank</div>
          <div className="tank-detail-wrapper">
            <div className="tank-detail-left">
              <img className="tank-detail" src={tankDetail} alt="tank detail" />
            </div>
            <div className="tank-detail-right">
              <div className="common-detail-wrapper tank-detail-listing">
                <h4 className="common-detail-title">{checkString(getProbeById?.data?.tank?.name)}</h4>
                <ListGroup>
                  <ListGroup.Item>
                    <div className="detail-title">Tank Capacity (Unit)</div>
                    <div className="detail-subtext">
                      {capitalizeFirst(checkString(getProbeById?.data?.tank?.capacity))}
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="detail-title">Volume</div>
                    <div className="detail-subtext">
                      {' '}
                      {checkString(getProbeById?.data?.tank?.volume)}
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="detail-title">Location</div>
                    <div className="detail-subtext">
                      {checkString(getProbeById?.data?.tank?.location)}
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="detail-title">Description</div>
                    <div className="detail-subtext">
                      {checkString(getProbeById?.data?.tank?.description)}
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProbeDetail;
