import { gql } from '@apollo/client';

export const USER_TANK_LISTING = gql`
  query getTankByUser(
    $limit: Int
    $page: Int
    $search: String
    $sort: String
    $sortBy: String
  ) {
    getTankByUser(
      limit: $limit
      page: $page
      search: $search
      sort: $sort
      sortBy: $sortBy
    ) {
      tank {
        id
        name
        volume
        capacity
        location
        description
      }
      totalCount
    }
  }
`;

export const USER_TANK_DETAILS = gql`
  query getTankById($id: String!, $fetch: Boolean!) {
    getTankById(id: $id) {
      message
      data @include(if: $fetch) {
        id
        name
        volume
        capacity
        location
        description
      }
    }
  }
`;

export const DELETE_TANK_QUERY = gql`
  query DeleteTankById($id: String!) {
    DeleteTankById(id: $id) {
      message
    }
  }
`;

export const USER_UNASSIGNED_PROBES_LISTING = gql`
  query getProbeIdByUser {
    getProbeIdByUser {
      probes{
        probeId
        id
        probeName
      }
    }
  }`;

export const USER_ASSIGNED_PROBES = gql`
query getAssignedProbesById(
  $limit: Int
  $page: Int
  $search: String
  $sort: String
  $sortBy: String
  $isGettingRealData: Boolean
) {
  getAssignedProbesById(
    limit: $limit
    page: $page
    search: $search
    sort: $sort
    sortBy: $sortBy
    isGettingRealData: $isGettingRealData
  ) {
    probe {
      id
      probeId
      probeName
      description
      isGettingRealData
      tankId
      status
      tank{
        name
      }
    }
    totalCount
    activeProbeCount
    disableProbeCount
  }
}`;

export const USER_ASSIGNED_PROBE_DETAILS = gql`
query getProbeById($id: String!) {
  getProbeById(id : $id) {
    data {
      id
      probeName
      probeId
      description
      status
      isGettingRealData,
      tank {
        id
        name
        capacity
        description
        volume
        location
      }
    }
  }
}`;

export const PROBES_ASSIGNED_TO_TANK = gql`
  query probesAssignedToTank {
    probesAssignedToTank {
      probes{
        probeId
        id
        probeName
      }
    }
  }`;

export const CHECK_TANK_ASSIGNMENT = gql`
    mutation checkTankIsAssignToProbe($tankId: String!) {
      checkTankIsAssignToProbe(tankId: $tankId) {
        status
      }
    }`;
