import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { NUMBER } from '../../../../constants';
import { BreadcrumbsProps } from '../../../../types';

function Breadcrumbs({ crumbs, innerClass }: BreadcrumbsProps) {
  const { pathname } = useLocation();
  return (
    <div className={`custom-breadcrum ${innerClass}`}>
      <Breadcrumb>
        {crumbs?.map((crumb: string, index: number) => {
          const a = pathname.split('/');
          return (
            <Breadcrumb.Item key={crumb}>
              <Link
                key={crumb}
                to={`${a.splice(NUMBER.ZERO, index + NUMBER.THREE).join('/')}`}
              >
                {crumb}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
}

export default Breadcrumbs;
