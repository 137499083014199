import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { USER_ASSIGNED_PROBE_DETAILS } from '../../../graphql/queries/tank.queries';
import CustomLoader from '../../../components/shared/common/customLoader';
import { IProbe } from '../../../types';
import AssignProbe from '../../../components/probes/assignProbe';
import { EMPTY_PROBE } from '../../../constants';
import TankDetailSection from '../tanks/tankDetailSection';
import DetailHeaderActions from '../../../components/shared/common/detailSection/detailHeaderAction';
import DetailHeader from '../../../components/shared/common/detailSection/detailHeader';
import ProbeDetailSection from './probeDetailSection';
import PromptModal from '../../../components/promptModal';
import { REMOVE_PROBE_MODAL } from '../../../constants/modal';
import { UNASSIGN_PROBE } from '../../../graphql/mutation';

function ProbeDetails() {
  const { id } = useParams();
  const [unassignProbeId, setUnassignProbe] = useState('');
  const [editDetails, setEditDetails] = useState<IProbe>();
  const { data: { getProbeById: { data: probeDetails = {} } = {} } = {}, loading, refetch } = useQuery(
    USER_ASSIGNED_PROBE_DETAILS,
    {
      variables: { id, fetch: !!id },
    },
  );

  const [unassign, { loading: isUnassigning }] = useMutation(UNASSIGN_PROBE, {
    onCompleted: (res) => {
      refetch();
      toast.success(res?.unassignProbeByUser?.message);
      setUnassignProbe('');
    }
  });

  return (
    <div className="admin-common-body">
      {(loading || isUnassigning) && <CustomLoader />}
      {(editDetails?.id) && (
        <AssignProbe
          probeData={editDetails as IProbe}
          open={!!editDetails?.id}
          close={() => {
            setEditDetails(EMPTY_PROBE);
          }}
          success={() => refetch()}
        />
      )}
      {!!unassignProbeId && (
        <PromptModal
          open={!!unassignProbeId}
          cancel={() => setUnassignProbe('')}
          handleAction={() => unassign({ variables: { ProbeId: unassignProbeId } })}
          {...REMOVE_PROBE_MODAL}
        />
      )}
      <DetailHeader
        detailLabel="Probe"
        editLabel="Edit"
        deleteLabel={probeDetails?.tank ? 'Unassign' : ''}
        onRemove={() => setUnassignProbe(probeDetails?.id)}
        itemId={probeDetails.probeId}
        onEdit={() => setEditDetails({ ...probeDetails, tankId: probeDetails?.tank?.id })}
      />
      <ProbeDetailSection probe={probeDetails} />
      <div className="tank-detail-sec probes-detail-sec">
        <h5 className="probes-detail-title">Assigned Tank</h5>
        <div className="tank-detail-wrapper probes-detail-wrapper">
          <TankDetailSection tank={probeDetails?.tank} />
          <DetailHeaderActions
            onRemove={() => { }}
            onEdit={() => setEditDetails({ ...probeDetails, tankId: probeDetails?.tank?.id })}
            className="header-right-mobile for-mobile"
            deleteLabel="Remove"
            editLabel="Edit"
          />
        </div>
      </div>
    </div>
  );
}

export default ProbeDetails;
