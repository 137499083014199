import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { InputPropsType } from '../../../types';
import { BOOLEANS, NUMBER } from '../../../constants';

function Input(props: InputPropsType): JSX.Element {
  const {
    label,
    type,
    showPassword = BOOLEANS.TRUE,
    placeholder = 'Write description',
    disabled = false,
    maxLength = NUMBER.ONE_HUNDRED,
    refValues,
    setValue = () => undefined,
    error,
    name = '',
    valuePattern = /^/,
    wrapperClass = 'wrap-input',
    iconClass = '',
    inputClass = '',
    icon = '',
    isOptional,
    ...rest
  } = props;
  const [inputType, setTypeInput] = useState(type);
  const changeInputType = () => {
    if (inputType === 'password') {
      setTypeInput('text');
      return;
    }
    setTypeInput('password');
  };

  return (
    <Form.Group className={`form-mt-space ${inputClass}`}>
      {isOptional ? (
        <>
          <Form.Label>{label}</Form.Label>
          {' '}
          <Form.Text>(Optional)</Form.Text>
        </>
      ) : (
        <Form.Label>{label}</Form.Label>
      )}
      <div className={wrapperClass ?? 'wrap-input'}>
        <Form.Control
          rows={4}
          type={inputType}
          className={`form-input ${inputClass ? 'red-border' : ''} ${disabled && 'input-disabled'}`}
          placeholder={placeholder}
          disabled={disabled}
          {...refValues(name)}
          maxLength={maxLength.toString()}
          name={name}
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(
              name,
              e.target.value.trimStart().replace(valuePattern, ''),
              { shouldValidate: true },
            );
          }}
          {...rest}
          data-testid={`${name}Input`}
        />
        {iconClass && <span className={iconClass}>{icon}</span>}
        {type === 'password' && showPassword && (
          <span
            className={`icon-${
              inputType === 'password' ? 'cut' : 'open'
            }-eye on-input-icon-right`}
            onClick={changeInputType}
          />
        )}
      </div>
      <span className="input-error-message" data-testid={`${name}Err`}>{error as string}</span>
    </Form.Group>
  );
}

export default Input;
