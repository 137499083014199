import React from 'react';
import nocard from '../../assets/img/no-card.svg';
import { SavedCardProps } from '../../types';
import CardList from '../stripe/CardList';
import CustomLoader from '../shared/common/customLoader';

const SavedCard = ({
  openLogoutModal,
  userCards,
  loading,
  handleRemoveCard,
  handleDefaultCard,
}: SavedCardProps) => {
  if (loading) {
    <CustomLoader />;
  }
  return (
    <div className="filter-sec account-profile-sec">
      <div className="subscription-head-sec">
        <div className="subscription-head-left">
          <div className="box-heading">Saved Cards</div>
        </div>
        <div className="subscription-head-cancel">
          <div
            className="subscription-history text-cap"
            onClick={openLogoutModal}
          >
            Add Credit Card
          </div>
        </div>
      </div>
      <div className="subscription-card-sec">
        <div className="subscription-card-sec">
          {userCards ? (
            <CardList
              cards={userCards}
              handleRemoveCard={handleRemoveCard}
              handleDefaultCard={handleDefaultCard}
            />
          ) : (
            <div className="subscription-nocard-sec">
              <div className="nocard-img">
                <img src={nocard} alt="No Credit Card" />
              </div>
              <div className="subscription-head-cancel">
                <div
                  className="subscription-history text-cap"
                  onClick={openLogoutModal}
                >
                  Add Credit Card
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedCard;
