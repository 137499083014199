import moment from 'moment';
import { NUMBER } from '../number';
import { PHONE_CONVERTING_REGEX, PHONE_PLACE } from '../regex';
import { H_MM_A, MMMM_DO_YYYY } from '../formats';

export const keyPressHandle = (event: any) => {
  if (!/\d/.test(event.key)) {
    event.preventDefault();
  }
};

export const keyPressHandleAlphabate = (event: any) => {
  if (!/[a-zA-Z\s]/.test(event.key)) {
    event.preventDefault();
  }
};

export const total = (
  singleProbPrice: number,
  totalProbe: number,
  packagePrice: number
) => singleProbPrice * totalProbe + packagePrice;

export const handlePasteNumber = (e: any) => {
  const pastedText = e.clipboardData.getData('text/plain');
  if (!/^\d+$/.test(pastedText)) {
    e.preventDefault();
  }
};

export const phoneNoFormate = (contactNumber: any) => {
  const lastTenDigits = contactNumber?.slice(-NUMBER.TEN) || '-----------';
  const firstDigits = contactNumber?.slice(NUMBER.ZERO, -NUMBER.TEN) || '-';

  return `+${firstDigits} ${lastTenDigits?.replace(
    PHONE_CONVERTING_REGEX,
    PHONE_PLACE
  )}`;
};

export const formatDate = (createdAt: string) => {
  const date = moment(createdAt);
  const formattedDate = date.format(MMMM_DO_YYYY);
  const formattedTime = date.format(H_MM_A);
  return `${formattedDate}, ${formattedTime}`;
};

export const priceFormate = (amount: number = NUMBER.ZERO): string => {
  if (!amount) {
    return '';
  }
  return `$${(amount / NUMBER.ONE_HUNDRED).toFixed(NUMBER.TWO)}`;
};
