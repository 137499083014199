import React from 'react';
import { NUMBER } from '../../constants';
import { formatNumberToLocaleString } from '../../utils/helpers';

const SubscriptionPayment = ({
  totalProbe,
  eachProbePrice,
  totalProbAmount,
  isWineMakingPackage,
  addOnPrice,
}: any) => (
  <div className="subscription-payment-bottom">
    <div className="payment-detail-sec">
      <div className="payment-detail-title">
        {`${totalProbe || NUMBER.ZERO} Probes (Each Probe $${eachProbePrice})`}
      </div>
      <div className="payment-detail-price">
        {`$${formatNumberToLocaleString(+totalProbAmount)}`}
      </div>
    </div>
    {isWineMakingPackage ? (
      <div className="payment-detail-sec">
        <div className="payment-detail-title">
          Winemaking Event Packages (Add-Ons)
        </div>
        <div className="payment-detail-price">{`$${addOnPrice}`}</div>
      </div>
    ) : null}
  </div>
);

export default SubscriptionPayment;
