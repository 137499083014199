import { NUMBER } from './number';

export const BASIC_DASHBOARD = {
  TEMPERATURE: 'temperature',
  PRESSURE: 'pressure',
  DENSITY: 'density',
  REDOX: 'redox'
};

export const SYSTEM_COMPUTATION_DASHBOARD = {
  FERMENTATION: 'fermentation',
  AVG_TEMPERATURE: 'temperature',
  AVG_DENSITY: 'density',
  STRATIFICATION: 'stratification',
  DENSITY_REDOX: 'densityRedox',
  DENSITY_TEMP: 'densityTemp',
  TEMP_REDOX: 'tempRedox',
};

export const LINE_GRAPH_MARGINS = {
  top: NUMBER.TEN,
  bottom: NUMBER.FOURTY,
  left: NUMBER.TWENTY,
};

export const LINE_COLORS_CODES = ['#A8C5DA', '#479375', '#6E9F20', '#C6C7F8', '#DAA8AE', '#A0E77D', '#F8E7C6',
  '#72DAE5', '#74E572', '#68CBA3', '#80ADB8', '#9980B8', '#C0AED7', '#D476A8', '#F50786', '#F58C07', '#E7B87D',
  '#6E9F20', '#ACC5A0', '#9BBDB0', '#2DB0B8', '#9077B0', '#CF91C5', '#CCA6BA'];
