export const REMEMBER_ME = 'rememberMe';
export const USER_INFO = 'userInfo';
export const COUNTER_KEY = 'counter_timer';
export const SUBSCRIPTION_ID = 'subscriptionId';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const DISABLE_USER_FAILED = 'Disable user failed';
export const DELETE_USER_FAILED = 'Delete user failed';
export const UPDATE_USER_FAILED = 'Update user failed';
export const ADD_USER_FAILED = 'Add user failed';
export const USER_FULLNAME = 'userFullName';
export const USER_MANAGEMENT = 'User Management';
export const ASSIGNED_PROBE = 'Assigned Probe';
export const DASHBOARD = 'Dashboard';
export const PROBE_DATA = 'Probe Data';
export const MY_ACCOUNT = 'My Account';
export const TRANSACTION_HISTORY = 'Transaction History';
export const PAYMENT_PRICE = 'paymentPrice';
export const COMPLETED = 'completed';
