import React from 'react';
import filterUp from '../../assets/icons/filter-up.svg';
import filterDown from '../../assets/icons/filter-down.svg';
import defaultIcon from '../../assets/icons/defaultSort.svg';
import { SORT } from '../../constants';
import { SortIconProps } from '../../types';

const filterIcons = {
  ASC: filterUp,
  DESC: filterDown,
};

function SortIcon({ sortKey, selected, sortBy }:SortIconProps) {
  if (selected === SORT.CREATED_AT) {
    return <img src={defaultIcon} alt="filter up" />;
  }
  return (
    <>
      {selected === sortKey
        ? <img src={filterIcons[sortBy as keyof typeof filterIcons]} alt="filter up" />
        : <img src={defaultIcon} alt="filter up" />}
    </>
  );
}

export default SortIcon;
