import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import UserForm from '../../components/userManagement/userForm';
import { getCurrentMonthYear, getSession, saveSessions } from '../../utils/helpers';
import { EDIT_USER_MUTATION, GET_USER_DETAILS_FOR_UPDATE } from '../../graphql/mutation';
import {
  BOOLEANS, NETWORK_ONLY, ROUTE_PATH, USER_INFO
} from '../../constants';
import CustomLoader from '../../components/shared/common/customLoader';

const EditUserProfile = () => {
  const navigate = useNavigate();
  const session = getSession(USER_INFO);
  const { data, loading } = useQuery(
    GET_USER_DETAILS_FOR_UPDATE, { fetchPolicy: NETWORK_ONLY }
  );
  const initialValues = data?.getUser?.userInfo || {};
  const updatedInitialValues = {
    ...initialValues,
    profileImg: initialValues.profileImg ? initialValues.profileImg : '',
    wineryFiscalYearEndMonth: initialValues.wineryFiscalYearEndMonth
      ? initialValues.wineryFiscalYearEndMonth
      : getCurrentMonthYear(),
  };

  const [editUser, { loading: edituserLoading }] = useMutation(EDIT_USER_MUTATION);

  const handleUpdateDetails = async (values: Record<string, any>) => {
    try {
      const wineryProductionVolume = parseFloat(values?.wineryProductionVolume);
      const mutationVariables: any = {
        ...values,
        wineryProductionVolume,
      };

      const res = await editUser({
        variables: mutationVariables,
      });
      if (res?.data?.editUser) {
        toast.success(res.data.editUser.message);
        saveSessions({
          ...session,
          fullName: mutationVariables.fullName,
          wineryName: mutationVariables.wineryName,
          profileImg: mutationVariables.profileImg
        });
        navigate(ROUTE_PATH.USER_PROFILE);
      } else {
        throw new Error('Update user failed');
      }
    } catch (error) {
      navigate(ROUTE_PATH.USER_PROFILE);
    }
  };

  return (
    <>
      {(loading || edituserLoading) && <CustomLoader />}
      {data && data.getUser && data.getUser.userInfo && (
        <UserForm
          initialValues={updatedInitialValues}
          buttonName="UPDATE"
          headerTitle="Edit Profile"
          onSuccess={handleUpdateDetails}
          isProfile={BOOLEANS.TRUE}
        />
      )}
    </>
  );
};

export default EditUserProfile;
