import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { CustomTabsProps, ITabsConfig } from '../../types';

function CustomTabs({
  onSelect, config = [], defaultKey, className
}: CustomTabsProps) {
  return (
    <div className={className}>
      <Tabs
        id="justify-tab-example"
        className="user-custom-tabs-wrapper"
        onSelect={(s:string | null, _) => onSelect(s as string)}
        justify
        activeKey={defaultKey}
      >
        {config.map((tab:ITabsConfig, index:number) => (
          <Tab
            key={tab.eventKey}
            eventKey={tab.eventKey}
            title={tab.title}
          />
        ))}
      </Tabs>
    </div>
  );
}

export default CustomTabs;
