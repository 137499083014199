import React from 'react';
import textCircle from '../../assets/img/text-cirlce.png';
import customMark from '../../assets/img/custom-mark.png';
import pricingLeft from '../../assets/img/pricing-bg-left.svg';
import { usePaymentContext } from '../../context/PaymentContextProvider';
import { SUBSCRIPTION_TYPE } from '../../constants';

const SubscriptionHeader: React.FC = () => {
  const { updateData, subscriptionType } = usePaymentContext();

  const handleSubscriptionTypeChange = (type: string) => {
    updateData('subscriptionType', type);
  };

  return (
    <div className="pricing-wrap">
      <img className="pricing-left-bg" src={pricingLeft} alt="left bg" />
      <img className="pricing-right-bg" src={pricingLeft} alt="right bg" />
      <h4 className="pricing-title">PRICING</h4>
      <div className="pricing-subtitle">
        <h3>Choose Your Affordable Subscription Plan</h3>
        <img className="custom-circle" src={textCircle} alt="text circle" />
      </div>
      <p className="pricing-desc">
        Unlock full access to customized MSP data by choosing either a monthly or annual subscription plan.
      </p>
      {/* switch code  */}

      <div className="switch-sec">
        <div className="switch-wrap">
          <div className="tabs">
            <input
              type="radio"
              id="radio-1"
              name="tabs"
              checked={subscriptionType === 'monthly'}
              onChange={() => handleSubscriptionTypeChange('monthly')}
            />
            <label className="tab" htmlFor="radio-1">
              Monthly
            </label>
            <input
              type="radio"
              id="radio-2"
              name="tabs"
              checked={subscriptionType === SUBSCRIPTION_TYPE.ANNUAL}
              onChange={() => handleSubscriptionTypeChange(SUBSCRIPTION_TYPE.ANNUAL)}
            />
            <label className="tab" htmlFor="radio-2">
              Annual
            </label>
            <input type="radio" id="radio-3" name="tabs" />
            <span className="glider" />
          </div>
        </div>
        <div className="discount-sec">
          <h5 className="discount-text">10% Discount</h5>
          <img className="custom-mark" src={customMark} alt="custom-mark" />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionHeader;
