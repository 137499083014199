import React from 'react';

const SectionFour = () => (
  <>
    <h3>Section 4 - Global Use; Export/import Compliance</h3>
    <p className="para">
      Due to the global nature of the internet, through the use of our
      network you hereby agree to comply with all local rules relating to
      online conduct and that which is considered acceptable content.
      Uploading, posting and/or transferring of software, technology and
      other technical data may be subject to the export and import laws of
      the United States and possibly other countries. Through the use of our
      network, you thus agree to comply with all applicable export and
      import laws, statutes and regulations, including, but not limited to,
      the Export Administration Regulations (
      <a href="http://www.access.gpo.gov/bis/ear/ear_data.html">
        http://www.access.gpo.gov/bis/ear/ear_data.html
      </a>
      ), as well as the sanctions control program of the United States (
      <a href="http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx">
        http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx
      </a>
      ). Furthermore, you state and pledge that you:
    </p>
    <p className="para">
      Furthermore, you herein agree not to make use of Wine Technology
      Inc.&apos;s Services for the purpose of:
    </p>
    <div className="indent-sec">
      <p className="para">
        a) are not on the list of prohibited individuals which may be
        identified on any government export exclusion report (
        <a href="http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm">
          http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm
        </a>
        ) nor a member of any other government which may be part of an
        export-prohibited country identified in applicable export and import
        laws and regulations;
      </p>
      <p className="para">
        b) agree not to transfer any software, technology or any other
        technical data through the use of our network Services to any
        export-prohibited country;
      </p>
      <p className="para">
        c) agree not to use our website network Services for any military,
        nuclear, missile, chemical or biological weaponry end uses that
        would be a violation of the U.S. export laws; and
      </p>
      <p className="para">
        d) agree not to post, transfer nor upload any software, technology
        or any other technical data which would be in violation of the U.S.
        or other applicable export and/or import laws.
      </p>
    </div>
  </>
);

export default SectionFour;
