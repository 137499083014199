/* eslint-disable max-len */
export const EMAIL_REGEX_OLD = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PASSWORD_MATCH_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*/.-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const PASSWORD_LENGTH_REGEX = /^.{8,}$/;
export const PHONE_HYPHEN_REGEX = '^[A-Za-z0-9\\-\\s]+$';
export const DEFAULT_INPUT = /^\s+/g;
export const USERNAME_REGEX = / /g;
export const EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ALLOW_ONLYALPHANUMERIC_REGEX = /^[a-zA-Z0-9_]+$/;
export const DURATION_REGEX = /^(\d{1,2}(:\d{1,2})?)?$/;

export const INPUT_ALPHANUMERIC = /[^a-z0-9\s]/gi;
export const INPUT_NUMERIC = /[^0-9\s]/gi;
export const INPUT_ALPHABETS = /[^a-z\s]/gi;
export const INPUT_WINERY_ADDRESS = /[^a-z0-9\-,.\s]/gi;
export const BANK_REGEX = /^[0-9]{9,12}$/;
export const ROUTING_NUMBER_REGEX = /^[0-9]{9}$/;
export const PHONE_CONVERTING_REGEX = /(\d{3})(\d{3})(\d{4})/;
export const PHONE_PLACE = '$1-$2-$3';
export const WINERY_PRODUCTION_VOLUME = /[^0-9]/gi;
export const base64Regex = /^data:image\/[a-z]+;base64,/;
