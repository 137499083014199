import React from 'react';
import { Dropdown } from 'react-bootstrap';
import downArrow from '../../../../assets/icons/arrow-white.svg';

function ExportDropDown({ config, onSelect, value = '', innerClass = '' }: any) {
  return (
    <div className={`sidebar-profile dashboard-export-dropdown ${innerClass}`}>
      <div className="menu-dropdown">
        <Dropdown onSelect={onSelect}>
          <Dropdown.Toggle
            className="menu-dropdown-toggle"
            id="dropdown-basic"
            data-testid="menuToggle"
          >
            <div className="header-user-detail">
              <p className="user-subtitle">Export</p>
            </div>
            <span className="dropdown-img">
              <img src={downArrow} alt="down arrow" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="header-profile-top-arrow">
            {config.map((item : any) => (
              <Dropdown.Item className="logout-profile" key={item.label} eventKey={item.value}>
                <span className="text-main-menu">{item.label}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default ExportDropDown;
