import React from 'react';
import { useQuery } from '@apollo/client';
import { TRANSACTION_HISTORY_QUERY } from '../../graphql/mutation';
import UserTransactionHistory from './UserTransactionHistory';
import Breadcrumbs from '../../components/shared/common/breadCrumbs';
import { MY_ACCOUNT, TRANSACTION_HISTORY } from '../../constants';

const UserTransaction = () => {
  const { loading, error, data } = useQuery(TRANSACTION_HISTORY_QUERY);

  if (error) {
    <p>
      Error:
      {' '}
      {error.message}
    </p>;
  }

  const transactionData = data?.transactionHistory?.data || [];

  return (
    <div className="admin-common-body">
      <div className="admin-header-wrapper admin-heading-sec">
        <Breadcrumbs crumbs={[MY_ACCOUNT, TRANSACTION_HISTORY]} />
        <h1 className="admin-header-title">Transaction History</h1>
      </div>

      <UserTransactionHistory
        transactionData={transactionData}
        loading={loading}
      />
    </div>
  );
};

export default UserTransaction;
