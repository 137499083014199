import React from 'react';
import { Button } from 'react-bootstrap';
import noTanksPic from '../../assets/img/noSearchData.svg';
import { NO_SEARCH_DATA } from '../../constants/string';
import { NoDataProps } from '../../types';

const NoData = ({
  img, text, heading, handleAction, actionText = '', isSearch
}:NoDataProps) => (
  <div className="admin-header-wrapper">
    <div className="no-probes-card">
      <div className="subscription-nocard-sec ">
        <div className="nocard-img">
          <img src={isSearch ? noTanksPic : img} alt="No probes card" />
        </div>
        <div className="nocard-title" data-testid="noDataTitle">{isSearch ? NO_SEARCH_DATA.HEADING : heading}</div>
        <div className="nocard-subtitle" data-testid="noDataText">
          {isSearch ? NO_SEARCH_DATA.TEXT : text}
        </div>
        {actionText && !isSearch && (
        <div className="contact-to-admin" data-testid="noDataActionText">
          <Button className="btn primary" onClick={handleAction}>
            {actionText}
          </Button>
        </div>
        )}
      </div>
    </div>
  </div>
);

export default NoData;
