import React, { useEffect } from 'react';
import {
  Form, Button, Col, Row
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD_VALIDATION_SCHEMA, BOOLEANS } from '../../../../constants';
import { CHANGE_PASSWORD_MUTATION } from '../../../../graphql/mutation/admin.mutation';
import ModalWrapper from '../modalWrapper';
import CustomLoader from '../customLoader';
import Input from '../../forms/input';
import { IChangePasswordProps, IChangePasswordkeys } from '../../../../types';

function ChangePassword({ open, close }: IChangePasswordProps) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CHANGE_PASSWORD_VALIDATION_SCHEMA),
    shouldFocusError: BOOLEANS.FALSE,
  });

  const [changePasswordRequest, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION);

  /**
   * The function onSubmit is an asynchronous function that sends a change password request.
   * @param {IChangePasswordkeys} data - The `data` parameter is of type `IChangePasswordkeys`.
   */
  const onSubmit = async (data: IChangePasswordkeys) => {
    const response = await changePasswordRequest({ variables: data, });
    toast.success(response.data.changePassword.message);
    close();
  };

  /* useEffect` hook is used to reset the form values when the `open` prop changes. */
  useEffect(() => {
    reset();
  }, [open]);

  return (
    <ModalWrapper show={open} handleClose={close} title="Change Password">
      {loading && <CustomLoader />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="modal-body-form change-password-form">
          <Col xs={12} sm={12} className="change-new-password">
            <Input
              name="oldPassword"
              label="Current Password"
              placeholder="Enter current password"
              type="password"
              refValues={register}
              setValue={(name, value: string) => {
                setValue('oldPassword', value, { shouldValidate: true });
                if (getValues('newPassword')) {
                  trigger('newPassword');
                }
              }}
              error={errors?.oldPassword?.message}
            />
          </Col>
          <Col xs={12} sm={12}>
            <Input
              name="newPassword"
              label="New Password"
              placeholder="Enter new password"
              refValues={register}
              type="password"
              setValue={(name, value: string) => {
                setValue('newPassword', value, { shouldValidate: true });
                if (getValues('oldPassword')) {
                  trigger('oldPassword');
                }
              }}
              error={errors?.newPassword?.message}
            />
          </Col>
        </Row>
        <div className="footer-modal">
          <Button
            type="submit"
            className="btn primary modal-btn-submit"
          >
            Update
          </Button>
        </div>
      </Form>
    </ModalWrapper>
  );
}

export default ChangePassword;
