import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { BOOLEANS, NUMBER, ROUTE_PATH } from '../../constants';
import SearchInput from '../shared/forms/searchInput';
import { IPagination, ListingHeaderProps } from '../../types';
import CustomTabs from '../table/customTabs';
import UploadCsv from '../shared/common/uploadCsv/UploadCSV';
import { ADD_TANK_CSV_MUTATION } from '../../graphql/queries/admin.queries';
import { pagination } from '../../graphql/reactiveVariables';
import { useReactiveVar } from '@apollo/client';

function ListingHeader({
  setAddNew,
  onSearch,
  buttonLabel,
  csvLabel,
  hasTabs = BOOLEANS.FALSE,
  tabsConfig = [],
  title,
  refetchList
}: ListingHeaderProps) {
  const [params] = useSearchParams();
  const query = params.get('search');
  const tab = params.get('tab');
  const navigate = useNavigate();
  const [showCsvModal, setShowCsvModal] = useState(false);
  const { pathname } = useLocation();
  const paginationData: IPagination = useReactiveVar(pagination);

  const onTabChange = (value: string) => {
    navigate({
      pathname,
      search: createSearchParams({ tab: value || '' }).toString(),
    });
    pagination({ ...paginationData, page: NUMBER.ONE });
  };

  const handleCsvSubmit = () => {
    refetchList(BOOLEANS.TRUE);
    setShowCsvModal(BOOLEANS.FALSE);
  };
  // useEffect(() => {
  //   pathname === ROUTE_PATH.USER_PROBES && onTabChange('active');
  // }, []);
  return (
    <>
      <div className="admin-header-wrapper">
        <h1 className="admin-header-title">{title}</h1>
        {hasTabs && (
          <CustomTabs
            onSelect={onTabChange}
            config={tabsConfig}
            defaultKey={tab as string}
            className="user-custom-tabs"
          />
        )}
        <div className="header-wrapper">
          <div className="header-left">
            <SearchInput
              innerClass="probes-search-width"
              onSearch={(e: string) => onSearch(e)}
              query={query as string}
              placeholder="Search by Probe Name, Probe ID or Assigned Tank"
            />
          </div>
          <div className="header-right">
            <div>
              {csvLabel !== 'prob' && (
              <Button
                className="btn-outline-border header-outline-btn"
                onClick={() => setShowCsvModal(true)}
              >
                {csvLabel}
              </Button>
              )}
            </div>
            <Button
              className="btn primary header-primary-btn"
              onClick={() => setAddNew(BOOLEANS.TRUE)}
              data-testid="actionBtn"
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
        <UploadCsv
          open={showCsvModal}
          close={setShowCsvModal}
          mutation={ADD_TANK_CSV_MUTATION}
          type="tankFile"
          onSubmit={handleCsvSubmit}
          title="Upload CSV"
          tankData={{
            name: '',
            capacity: '',
            description: '',
            location: '',
          }}
        />
      </div>
    </>
  );
}

export default ListingHeader;
