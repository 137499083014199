import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import tankDetail from '../../../assets/img/tank-detail.png';
import leftArrow from '../../../assets/icons/arrow-left.svg';
import AddNewTank from '../../../components/tanks/addNewTank';
import RemoveTank from '../../../components/tanks/removeTank';
import { USER_TANK_DETAILS } from '../../../graphql/queries/tank.queries';
import CustomLoader from '../../../components/shared/common/customLoader';
import { BOOLEANS, EMPTY_TANK, ROUTE_PATH } from '../../../constants';
import { ITank } from '../../../types';
import ScrollToTop from '../../../components/shared/common/scrollToTop';
import TankDetailSection from './tankDetailSection';
import DetailHeader from '../../../components/shared/common/detailSection/detailHeader';
import DetailHeaderActions from '../../../components/shared/common/detailSection/detailHeaderAction';

function TankDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const client = useApolloClient();
  const [editTank, setEditTank] = useState<ITank>();
  const [removeTank, setRemoveTank] = useState(false);

  const { data: { getTankById = {} } = {}, loading } = useQuery(
    USER_TANK_DETAILS,
    {
      variables: { id, fetch: !!id },
    },
  );

  return (
    <>
      <ScrollToTop />
      {loading && <CustomLoader />}
      {removeTank && (
        <RemoveTank
          tankId={id as string}
          open={removeTank}
          close={() => setRemoveTank(BOOLEANS.FALSE)}
          onSuccess={() => navigate(ROUTE_PATH.USER_TANKS)}
        />
      )}
      {!!editTank?.id && (
        <AddNewTank
          open={!!editTank?.id}
          close={() => setEditTank(EMPTY_TANK)}
          tankData={editTank}
          success={() => client.refetchQueries({ include: [USER_TANK_DETAILS] })}
        />
      )}
      <div className="admin-common-body">
        <DetailHeader
          detailLabel="Tank"
          editLabel="Edit"
          deleteLabel="Remove"
          itemId={getTankById?.data?.name}
          itemName={getTankById?.data?.name}
          onRemove={() => setRemoveTank(getTankById?.data?.id)}
          onEdit={() => setEditTank(getTankById?.data)}
        />
        <div className="tank-detail-sec">
          <div className="tank-detail-wrapper">
            <TankDetailSection tank={getTankById?.data} />
            <DetailHeaderActions
              onRemove={() => setRemoveTank(getTankById?.data?.id)}
              onEdit={() => setEditTank(getTankById?.data)}
              className="header-right-mobile for-mobile"
              deleteLabel="Remove"
              editLabel="Edit"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TankDetail;
